import * as React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'
import DatePicker from "react-datepicker"

import {
  UikButton,
  Uikon,
  UikFormInputGroup,
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
  UikTabContainer,
  UikTabItem,
  UikSelect,
  UikContentTitle,
  UikWidgetTable,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import {
  UikChart,
} from '@componentWrappers'

import numberFormatZeros from '@lib/numberFormatZeros'

import TopBar from '../../../../@components/TopBar'
import * as images from '@eAssets'

import * as actions from '@redux/analytics/actions'
import cls from './stations-performance.scss'

class RevenueAnalyticsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      startDate: moment().subtract(1,'month').format().slice(0,19)+"Z",
      endDate: moment().format().slice(0,19)+"Z",
      dateRange: { value: 'month', label: 'Last Month'},
    }

    props.fetchRevenueStats(this.state.stationId, new Date(this.state.startDate), new Date(this.state.endDate));
  }

  handleDatePickerChange = (dateName, date) => {
    console.log(dateName, date)
    let newState = {"dateRange": dateRangeOptions.find(dro => dro.value === 'custom')};
    newState[dateName] = moment(date).format().slice(0,19)+"Z";

    this.setState(newState);

    let payload = {"stationId": this.state.stationId};
    if (dateName === "startDate") {
      payload["startDate"] = newState[dateName];
      payload["endDate"] = this.state.endDate;
    } else {
      payload["startDdate"] = this.state.startDate;
      payload["endDate"] = newState[dateName];
    }

    this.props.fetchRevenueStats(payload["stationId"], new Date(payload["startDate"]), new Date(payload["endDate"]));
  }

  handleDropdownDateChange = (event) => {
    let newState = {"dateRange": dateRangeOptions.find(dro => dro.value === event.value)};
    if (event.value !== 'custom') {
      let startDate = moment().subtract(1, event.value).format().slice(0,19)+"Z";
      let endDate = moment().format().slice(0,19)+"Z";
      newState["endDate"] = endDate;
      newState["startDate"] = startDate;
      this.setState(newState);
    } else {
      this.setState(newState);
    }

    console.log(newState)

    this.props.fetchRevenueStats(this.state.stationId, new Date(newState["startDate"]), new Date(newState["endDate"]));
  }

  render = () => {
    const {
      startDate,
      endDate,
      dateRange,
    } = this.state

    const { stats } = this.props;

    let lineChartLabels = [];
    let usLineChartValues = [];
    let intlLineChartValues = [];
    let usTotal = 0;
    let intlTotal = 0;

    if (stats && stats.charts) {
      // Since the API guarantees there will be the same number of data points
      // in bot 'us' and 'intl', it doesn't really matter which one we use
      // for determinig labels
      lineChartLabels = stats.charts.us.map(entry => moment(entry.time).format("M/D"))

      usLineChartValues = stats.charts.us.map(entry => entry.val)
      intlLineChartValues = stats.charts.intl.map(entry => entry.val)

      usTotal = stats.sums.us
      intlTotal = stats.sums.intl
    }

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Ad Revenue Report"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikWidget margin>
            <UikWidgetHeader rightEl={ (
              <div className={ cls.dateInputs }>
                <UikFormInputGroup className={ cls.dateInputWrapper }>
                  <DatePicker
                    selected={ moment(startDate).toDate() }
                    onChange={ (date) => this.handleDatePickerChange("startDate", date) }
                    dateFormat="MMM d, yyyy"
                  />
                </UikFormInputGroup>

                <label className="uik-content-title__wrapper" style={{"padding": "0 10px"}}>-</label>

                <UikFormInputGroup className={ cls.dateInputWrapper }>
                  <DatePicker
                      selected={ moment(endDate).toDate() }
                      onChange={ (date) => this.handleDatePickerChange("endDate", date) }
                      dateFormat="MMM d, yyyy"
                  />
                </UikFormInputGroup>

                <UikSelect
                  className={ cls.selectRange }
                  onChange={ this.handleDropdownDateChange }
                  defaultValue={ ["week"] }
                  value={[dateRange]}
                  options={ dateRangeOptions }
                  placeholder="Select Range"
                />
              </div>
              )}
            >
              Summary
            </UikWidgetHeader>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
              <div className={ cls.box }>
                <UikContentTitle>US</UikContentTitle>
                <div className={ cls.boxContent }>
                  { usTotal }
                </div>
              </div>
              <div className={ cls.box }>
                <UikContentTitle>International</UikContentTitle>
                <div className={ cls.boxContent }>
                  { intlTotal }
                </div>
              </div>
            </div>
          </UikWidget>
          <UikWidget margin>
            <UikWidgetContent>
                  <UikChart
                    chartType="Line"
                    data={ {
                      labels: lineChartLabels,
                      datasets: [
                        {
                          borderColor: '#1665D8',
                          backgroundColor: 'transparent',
                          label: 'US Ad Impressions',
                          lineTension: 0,
                          pointRadius: 5,
                          pointBackgroundColor: '#1665D8',
                          pointBorderColor: '#FFF',
                          pointBorderWidth: 2,
                          data: usLineChartValues,
                        },
                        {
                          borderColor: '#cccccc',
                          backgroundColor: 'transparent',
                          label: 'Intl Ad Impressions',
                          lineTension: 0,
                          pointRadius: 5,
                          pointBackgroundColor: '#cccccc',
                          pointBorderColor: '#FFF',
                          pointBorderWidth: 2,
                          data: intlLineChartValues,
                        },
                      ],
                    } }
                    height={ 480 }
                    id="performance-chart"
                    options={ {
                      scales: {
                        yAxes: [{
                          ticks: {
                            callback: value => `${numberFormatZeros(value)}   `,
                          },
                          gridLines: {
                            drawBorder: false,
                            drawTicks: true,
                          },
                        }],
                      },
                    } }
                  />
            </UikWidgetContent>
          </UikWidget>
        </UikLayoutMain>
      </UikContainerVertical>
    );
  }
}

export default connect(
  store => ({
    stats: store.analytics.revenue.stats
  }),
  {
    fetchRevenueStats: actions.fetchRevenueStats
  },
)(RevenueAnalyticsPage)


const dateRangeOptions = [
  { value: 'day', label: 'Last Day'},
  { value: 'week', label: 'Last Week' },
  { value: 'month', label: 'Last Month' },
  { value: 'year', label: 'Last Year' },
  { value: 'custom', label: 'Custom' },
]

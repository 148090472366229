import * as React from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'

/* eslint-disable max-len */

import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux'
import * as packageActions from '@redux/packages/actions'
import * as stationActions from '@redux/stations/actions'
import * as tracksActions from '@redux/tracks/actions'
import * as analyticsActions from '@redux/analytics/actions'
import * as configApp from '@redux/constants'

import {
  UikWidgetChartSummarySmall2,
} from '@componentWrappers'

import {
  Uikon,
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikWidget,
} from '@components'

import { doughnutData } from './../../../../Analytics/Home/data/chartData'

import TopBar from '../../../../@components/TopBar'

import cls from './stations-dashboard.scss'

import TrackQueue from './../../../../Analytics/Home/components/TrackQueue'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// @flow
type StationsDashboardProps = {
  stationService: Object,
}

// @flow
class StationsDashboard extends React.Component<StationsDashboardProps> {

  constructor(props){

    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      startDate: moment().startOf('month').format().slice(0,19)+"Z",
      endDate: moment().format().slice(0,19)+"Z",
      currentListeners: 0,
    }

    this.fetchQueues = this.fetchQueues.bind(this);
    this.props.fetchStation(this.state.stationId);
    this.props.fetchHistoricalStations({
      stationIds: [this.state.stationId],
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
    this.props.fetchStationService(this.state.stationId);
    this.props.fetchHistoricalListeners({
      stationIds: [this.state.stationId],
      startDate: moment().startOf('day').format().slice(0,19)+"Z",
      endDate: moment().endOf('day').format().slice(0,19)+"Z"
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.activeStation !== this.props.activeStation){
      this.fetchQueues();
    }
    if(prevProps.stationService !== this.props.stationService){
      let packageId = this.props.stationService["relationships"]["package"]["data"]["id"];
      if(packageId){
        this.props.fetchPackage(packageId);
      }
    }
    if (typeof(this.props.realtimeStations) === 'object' && this.props.realtimeStations["stations"] && this.props.realtimeStations["stations"][this.state.stationId]
      && this.state.currentListeners !== this.props.realtimeStations["stations"][this.state.stationId].active_sessions) {
      this.setState({"currentListeners": this.props.realtimeStations["stations"][this.state.stationId].active_sessions})
    }
  }

  fetchQueues(){
    if(this.props.activeStation && this.props.location.pathname.indexOf('/stations/') > -1 && this.props.location.pathname.indexOf('/overview') > -1){
      this.props.fetchPlayedQueue({mountId: (this.props.activeStation.attributes||{}).mount_id});
      this.props.fetchPlayingQueue({mountId: (this.props.activeStation.attributes||{}).mount_id});
      this.props.fetchUpcomingQueue({mountId: (this.props.activeStation.attributes||{}).mount_id});
    }
  }

  render() {
    const { stationId, currentListeners } = this.state
    const {
      playedQueue,
      playing,
      stationService,
      activePackage,
      historicalListeners,
      historicalStations,
    } = this.props;

    let listenersToday = 0
    if (historicalListeners && historicalListeners["listeners"] && historicalListeners["listeners"][stationId]) {
      listenersToday = historicalListeners["listeners"][stationId][0]["reach"]
    }

    const stationServiceAttrs = (stationService||{})["attributes"] || {};
    const activePackageAttrs = (activePackage||{})["attributes"] || {};
    const stationAttrs = (this.props.activeStation||{})["attributes"] || {};
    const totalTlh = (((historicalStations||{})['stations']||{})[stationId]||{})['tlh']||0;
    const pathName = this.props.location.pathname;
    
    //console.log(stationAttrs)
    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title={stationAttrs.name}/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem>
            <div
              className={ classnames(cls.upgradeBanner, {[cls.imgPlaceholder]: stationAttrs.cover_uuid === null}) }
              style={ {
                backgroundImage: stationAttrs.cover_uuid ? `url("${mediaStageBaseUrl}/download/${stationAttrs.cover_uuid}.${stationAttrs.cover_extension}")` : '',
              } }
            >
              <Link
                className={ cls.btnUpdateCover }
                to={`/stations/${stationId}/settings`}>
                <Uikon>
                  camera
                </Uikon>
                 Edit Cover
              </Link>
              <span className={ cls.upgradeName }>
                <Uikon></Uikon>
                <span className={ cls.pro }>{stationAttrs["source"]}</span>
              </span>
              <h2 className={ cls.bannerTitle }>{stationAttrs.name}</h2>
            </div>
          </UikContentItem>
          <div className={ cls.tables }>
            <TrackQueue
              className={ cls.widgetMostVisited }
              playing={playing}
              playedQueue={playedQueue}
              fetchQueues={this.fetchQueues}
              pathname={pathName}
              onAir={stationAttrs.on_air}
              liveDj={stationAttrs.source === "Live"}
            />
            <div className={ cls.analyticsContainer }>
              <div className={ cls.analyticsRow }>
                <UikWidget
                  margin
                  className={ cls.currentListenersWidget }
                >
                  <div className={ cls.row }>
                    <div className={ cls.largeValue }>
                    {currentListeners}
                    </div>
                    <h2 className={ cls.label }>
                      Current Listeners
                      <Tooltip title="This is how many listeners are tuned in to your station at this moment. Updates every minute." placement="bottom" className={ cls.tooltip }>
                        <i className="fas fa-question-circle"></i>
                      </Tooltip>
                    </h2>
                  </div>
                  <hr/>
                  <div className={ cls.row }>
                    <div className={ cls.largeValue }>
                      {/* TODO FIX THIS */}
                      {/* {console.log(listenersToday)} */}
                      {listenersToday}
                    </div>
                    <h2 className={ cls.label }>
                      Listeners Today
                      <Tooltip title="This is how many listeners tuned into your station in the last 24 hours." placement="bottom" className={ cls.tooltip }>
                        <i className="fas fa-question-circle"></i>
                      </Tooltip>
                      <small>Last 24 Hours</small>
                    </h2>
                  </div>
                </UikWidget>
                <UikWidgetChartSummarySmall2
                  className={ cls.miniChart }
                  data={ doughnutData(1) }
                  diff={ 0.1 }
                  margin
                  tooltip="This number represents your US, CA, and UK TLH (Total Listening Hours) used within your current billing cycle."
                  label="TLH"
                  iconClass="fas fa-headphones-alt"
                  value={ totalTlh }
                  max={ activePackageAttrs["tlh"] }
                  maxUnit={ "TLH" }
                />
                  {/*subtitle={ "( resets 05/01 )" }*/}
                <UikWidgetChartSummarySmall2
                  className={ cls.miniChart }
                  data={ doughnutData(2) }
                  diff={ 0.1 }
                  margin
                  label="Storage"
                  tooltip="This indicates how much storage your uploaded tracks take up."
                  iconClass="fas fa-hdd"
                  value={ (stationServiceAttrs["bytes_used"] / 1024 / 1024 / 1024).toFixed(2) }
                  max={ (activePackageAttrs["bytes_available"] / 1024 / 1024 / 1024).toFixed(2) }
                  maxUnit={ "GB" }
                />
              </div>
            </div>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    stationService: store.stations.stationService,
    activePackage: store.packages.activePackage.package,
    activeStation: store.stations.activeStation,
    playedQueue: store.tracks.playedQueue.queue,
    playing: store.tracks.playing,
    upcomingQueue: store.tracks.upcomingQueue.queue,
    historicalListeners: store.analytics.historicalListeners.stats,
    historicalStations: store.analytics.historicalStations.stats,
    realtimeStations: store.analytics.realtimeStations.stats,
  }),
  {
    fetchHistoricalStations: analyticsActions.fetchHistoricalStations,
    fetchHistoricalListeners: analyticsActions.fetchHistoricalListeners,
    fetchStationService: stationActions.fetchStationService,
    fetchPackage: packageActions.fetchPackage,
    fetchStation: stationActions.fetchStation,
    fetchPlayedQueue: tracksActions.fetchPlayedQueue,
    fetchPlayingQueue: tracksActions.fetchPlayingQueue,
    fetchUpcomingQueue: tracksActions.fetchUpcomingQueue,
  },
)(StationsDashboard)

import initialState from './initialState'

import {
  FETCH_REALTIME_SESSIONS,FETCH_REALTIME_SESSIONS_SUCCESS,FETCH_REALTIME_SESSIONS_PAGE_SUCCESS,
  FETCH_REALTIME_SESSIONS_FAILURE,FETCH_REALTIME_STATIONS,FETCH_REALTIME_STATIONS_SUCCESS,
  FETCH_REALTIME_STATIONS_FAILURE,FETCH_REALTIME_COUNTRIES,FETCH_REALTIME_COUNTRIES_SUCCESS,
  FETCH_REALTIME_COUNTRIES_FAILURE,FETCH_REALTIME_PLATFORMS,FETCH_REALTIME_PLATFORMS_SUCCESS,FETCH_REALTIME_PLATFORMS_FAILURE,
  FETCH_HISTORICAL_LISTENERS,FETCH_HISTORICAL_LISTENERS_SUCCESS,FETCH_HISTORICAL_LISTENERS_FAILURE,
  FETCH_HISTORICAL_STATIONS,FETCH_HISTORICAL_STATIONS_SUCCESS,FETCH_HISTORICAL_STATIONS_FAILURE,
  FETCH_HISTORICAL_COUNTRIES,FETCH_HISTORICAL_COUNTRIES_SUCCESS,FETCH_HISTORICAL_COUNTRIES_FAILURE,
  FETCH_HISTORICAL_PLATFORMS,FETCH_HISTORICAL_PLATFORMS_SUCCESS,FETCH_HISTORICAL_PLATFORMS_FAILURE,
  FETCH_REVENUE_STATS, FETCH_REVENUE_STATS_SUCCESS, FETCH_REVENUE_STATS_ERROR
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_REALTIME_SESSIONS:
    return { ...state, realtimeSessions: {stats:[], error: null, loading: true} };
  case FETCH_REALTIME_SESSIONS_PAGE_SUCCESS:
    return { ...state, realtimeSessions: {stats: [...state.realtimeSessions.stats, ...action.payload], error: null, loading: true}}
  case FETCH_REALTIME_SESSIONS_SUCCESS:
    return { ...state, realtimeSessions: {stats: state.realtimeSessions.stats, error:null, loading: false} };
  case FETCH_REALTIME_SESSIONS_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, realtimeSessions: {stats: [], error: error, loading: false} };

  case FETCH_REALTIME_STATIONS:
    return { ...state, realtimeStations: {stats:[], error: null, loading: true} };
  case FETCH_REALTIME_STATIONS_SUCCESS:
    return { ...state, realtimeStations: {stats: action.payload, error:null, loading: false} };
  case FETCH_REALTIME_STATIONS_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, realtimeStations: {stats: [], error: error, loading: false} };

  case FETCH_REALTIME_COUNTRIES:
    return { ...state, realtimeCountries: {stats:[], error: null, loading: true} };
  case FETCH_REALTIME_COUNTRIES_SUCCESS:
    return { ...state, realtimeCountries: {stats: action.payload, error:null, loading: false} };
  case FETCH_REALTIME_COUNTRIES_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, realtimeCountries: {stats: [], error: error, loading: false} };

  case FETCH_REALTIME_PLATFORMS:
    return { ...state, realtimePlatforms: {stats:[], error: null, loading: true} };
  case FETCH_REALTIME_PLATFORMS_SUCCESS:
    return { ...state, realtimePlatforms: {stats: action.payload, error:null, loading: false} };
  case FETCH_REALTIME_PLATFORMS_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, realtimePlatforms: {stats: [], error: error, loading: false} };


  case FETCH_HISTORICAL_LISTENERS:
    return { ...state, historicalListeners: {stats:[], error: null, loading: true} };
  case FETCH_HISTORICAL_LISTENERS_SUCCESS:
    return { ...state, historicalListeners: {stats: action.payload, error:null, loading: false} };
  case FETCH_HISTORICAL_LISTENERS_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, historicalListeners: {stats: [], error: error, loading: false} };

  case FETCH_HISTORICAL_STATIONS:
    return { ...state, historicalStations: {stats:[], error: null, loading: true} };
  case FETCH_HISTORICAL_STATIONS_SUCCESS:
    return { ...state, historicalStations: {stats: action.payload, error:null, loading: false} };
  case FETCH_HISTORICAL_STATIONS_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, historicalStations: {stats: [], error: error, loading: false} };

  case FETCH_HISTORICAL_COUNTRIES:
    return { ...state, historicalCountries: {stats:[], error: null, loading: true} };
  case FETCH_HISTORICAL_COUNTRIES_SUCCESS:
    return { ...state, historicalCountries: {stats: action.payload, error:null, loading: false} };
  case FETCH_HISTORICAL_COUNTRIES_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, historicalCountries: {stats: [], error: error, loading: false} };

  case FETCH_HISTORICAL_PLATFORMS:
    return { ...state, historicalPlatforms: {stats:[], error: null, loading: true} };
  case FETCH_HISTORICAL_PLATFORMS_SUCCESS:
    return { ...state, historicalPlatforms: {stats: action.payload, error:null, loading: false} };
  case FETCH_HISTORICAL_PLATFORMS_FAILURE:
    error = action.payload || {message: action.payload.message};
    return { ...state, historicalPlatforms: {stats: [], error: error, loading: false} };

  case FETCH_REVENUE_STATS:
    return { ... state, revenue: { stats: {}, error: null, loading: true }};
  case FETCH_REVENUE_STATS_SUCCESS:
    return { ... state, revenue: { stats: action.payload, error: null, loading: false }};
  case FETCH_REVENUE_STATS_ERROR:
    error = action.payload || {message: action.payload.message};
    return { ...state, revenue: {stats: {}, error: error, loading: false} };

  default:
    return state;
  }
}

import * as React from 'react'
import classnames from 'classnames'
import * as configApp from '@redux/constants'

/* eslint-disable max-len */

import {
  Uikon,
  UikWidget,
  UikWidgetTable,
  UikCheckbox,
  UikButton,
  UikTagContainer,UikTag,
  UikDropdown,UikDropdownItem,
} from '@components'

import cls from '../media.scss'
import tableCls from './multi-select-table.scss'
import PacmanLoader from '../../../../../@components/PacmanLoader'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// @flow
type TracksProps = {
  tracks: Array
}

class StationsDashboard extends React.Component<TracksProps> {
  constructor(props) {
    super(props);

    this.state = {
      selectedTrack: null,
      playerState: "stopped",
      rowChecked: [],
      checkAll: false,
      sortBy: 'title'
    };

    this.checkAll = this.checkAll.bind(this);
    this.checkRow = this.checkRow.bind(this);
    this.handleOrderClick = this.handleOrderClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.tracks !== this.props.tracks){
      let rowChecked = [];
      for(var i = 0; i < this.props.tracks.length; i++) {
        rowChecked[i] = false;
      }
      this.setState({ rowChecked });
    }
  }

  handleOrderClick = (sortBy) => {
    if (this.state.sortBy === sortBy) {
      sortBy = sortBy.indexOf('-') > -1 ? sortBy : "-" + sortBy;
    }
    this.setState({sortBy});
    this.props.sortBy(sortBy);
  }

  checkRow = (id) => {
    let rowChecked = this.state.rowChecked;
    rowChecked[id] = !this.state.rowChecked[id];
    this.setState({
      rowChecked: this.state.rowChecked,
      checkAll: this.state.rowChecked.reduce((sum, next) => sum && next, true)
    });
    this.props.onTrackSelect(this.state.rowChecked.reduce((out, bool, index) => bool ? out.concat(index) : out, []));
  }

  checkAll = () => {
    let checkAll = !this.state.checkAll;
    let rowChecked = this.state.rowChecked.map(r => { return checkAll });
    this.setState({rowChecked, checkAll});
    this.props.onTrackSelect(rowChecked.reduce((out, bool, index) => bool ? out.concat(index) : out, []));
  }

  playTrack(fileName){
    if ( this.state.playerState === "playing" && this.state.selectedTrack === fileName) {
      this.player.pause()
      this.setState({playerState: "stopped"})
      this.player.src = '';
    } else {
      this.player.src = mediaStageBaseUrl + "/download/" + fileName;
      this.player.play()
      this.setState({playerState: "playing", selectedTrack: fileName})
    }
  }

  getTrackCategories = (trackId) => {
    const trackCategories = this.props.tracksCategories.filter(tc => tc.relationships.track.data.id === `${trackId}`)
    const categoryIds = new Set(trackCategories.map(tc => tc.relationships.category.data.id));
    const categories = this.props.categories.filter(c => categoryIds.has(c.id))
    return categories;
  }

  render() {
    const { selectedTrack, playerState, rowChecked, sortBy } = this.state;
    const { editTrack, deleteTrack, tracks, categoriesLoading } = this.props;

    tracks.forEach(t => console.log(`track["categories"]: ${t["categories"]}`))

    let { checkAll, loading } = this.props;
    checkAll = rowChecked.reduce((sum, next) => sum && next, true)
    checkAll = rowChecked.length === 0 ? false : checkAll;
    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={tableCls.multiSelectTable}>
          <thead>
            <tr>
              <th>
                <UikCheckbox
                  name="selectAllUsers"
                  onChange={this.checkAll}
                  checked={checkAll}
                />
              </th>
              <th>
              </th>
              <th className={classnames(tableCls.orderable, {[tableCls.orderBy]: sortBy.indexOf("title") > -1, [tableCls.orderAsc]: sortBy.indexOf('-') > -1 })}
                onClick={this.handleOrderClick.bind(this, 'title')}
              >
                Title
              </th>
              <th className={classnames(tableCls.orderable, {[tableCls.orderBy]: sortBy.indexOf("artist") > -1, [tableCls.orderAsc]: sortBy.indexOf('-') > -1 })}
                onClick={this.handleOrderClick.bind(this, 'artist')}
              >
                Artist
              </th>
              <th className={classnames(tableCls.orderable, {[tableCls.orderBy]: sortBy.indexOf("album") > -1, [tableCls.orderAsc]: sortBy.indexOf('-') > -1 })}
                onClick={this.handleOrderClick.bind(this, 'album')}
              >
                Album
              </th>
              <th className={classnames(tableCls.orderable, {[tableCls.orderBy]: sortBy.indexOf("length") > -1, [tableCls.orderAsc]: sortBy.indexOf('-') > -1 })}
                onClick={this.handleOrderClick.bind(this, 'length')}
              >
                Duration
              </th>
              <th className={classnames(tableCls.orderable, {[tableCls.orderBy]: sortBy.indexOf("year") > -1, [tableCls.orderAsc]: sortBy.indexOf('-') > -1 })}
                onClick={this.handleOrderClick.bind(this, 'year')}
              >
                Year
              </th>
              <th>
                Type
              </th>
              <th>
                Categories
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              tracks.map((track, index) => (
                <tr key={ `track-${(track["id"]||'')}` }>
                  <td>
                    <UikCheckbox
                      name={`track-cb-${track.id}`}
                      onChange={this.checkRow.bind(this, index)}
                      checked={rowChecked[index]}
                    />
                  </td>
                  <td>
                    <div
                      className={ classnames(cls.trackImg, {
                        [cls.playing]: selectedTrack === `${track["id"]}.${track["attributes"]["extension"]}` && playerState === "playing"
                      }) }
                      onClick={
                        track["attributes"]["media_type"] !== "advert"
                        ? this.playTrack.bind(this, `${track["id"]}.${track["attributes"]["extension"]}`)
                        : function() {}
                      }
                    >
                      <img alt="" src={ track["attributes"]["album_art_uuid"] ? `${mediaStageBaseUrl}/download/${track["attributes"]["album_art_uuid"]}.${track["attributes"]["album_art_extension"]}` : '/img/song_placeholder.png'}/>
                      {track["attributes"]["media_type"] !== "advert" && 
                        <i className={ selectedTrack === `${track["id"]}.${track["attributes"]["extension"]}` && playerState === "playing" ? 'fas fa-pause' : 'fas fa-play' }></i>
                      }
                    </div>
                  </td>
                  <td>
                    { track.attributes.title }
                  </td>
                  <td>
                    {track.attributes.artist}
                  </td>
                  <td>
                    {track.attributes.album}
                  </td>
                  <td>
                    { formatMicroseconds(track.attributes.length) || "0:00"}
                  </td>
                  <td>
                    {track.attributes.year}
                  </td>
                  <td>
                    {track.attributes.media_type}
                  </td>
                  <td>
                    <UikTagContainer className={ cls.tags }>
                      {/* { track["categories"] && track["categories"].length
                        ? (track["categories"]||[]).map(cat => (
                          <UikTag
                            color={(cat||{}).color || "#333"}
                            fill
                            key={`cat-tag-${cat ? cat["id"] : Math.random()}`}
                          >
                            {(cat||{}).name}
                          </UikTag>
                      )) : null } */}
                      {
                        categoriesLoading
                        ? (
                          <small><i className="fas fa-spinner"></i></small>
                        )
                        : (
                            this.getTrackCategories(track.id).map(category => (
                              <UikTag
                                color={category["attributes"]["color"]}
                                fill
                                key={`cat-tag${category.id}`}
                              >
                                {category["attributes"]["name"]}
                              </UikTag>
                            ))

                        )
                      }
                    </UikTagContainer>
                  </td>
                  <td>
                    <UikDropdown
                      DisplayComponent={
                      props => (
                        <UikButton
                          clear
                          icon={ <Uikon>more</Uikon> }
                          iconOnly
                          {...props}
                        />
                      )
                    }
                      position="bottomRight"
                    >
                      <UikDropdownItem onClick={editTrack.bind(this, track)}>
                        Edit
                      </UikDropdownItem>
                      <UikDropdownItem onClick={deleteTrack.bind(this, {track, index})}>
                        Delete
                      </UikDropdownItem>
                    </UikDropdown>
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { tracks.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No tracks found.</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
        <audio ref={ref => this.player = ref} />
      </UikWidget>
    )
  }
}

function formatMicroseconds(s){
  s = s / 1000;
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  mins = mins + "";
  secs = secs + "";

  return ((hrs > 0) ? hrs + ':' + mins.padStart(2,'0') : mins) + ':' + secs.padStart(2,'0');
}

export default StationsDashboard

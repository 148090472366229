export default [
  {
    name: 'Price',
    data: [
      'All',
      'Beginner',
      'Intermediate',
      'Advanced',
    ],
  },
  {
    name: 'Level',
    data: [
      'All',
      'Free Tutorials',
      'Premium Tutorials',
    ],
  },
  {
    name: 'Type',
    data: [
      'All',
      'Course',
      'Video',
      'Text',
    ],
  },
]

/* eslint-disable max-len */
import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as userActions from '@redux/users/actions'

import { connect } from 'react-redux'

import {
  UikContainerHorizontal,
  UikContainerVertical,
} from '@components'

import Navigation from './components/Navigation'

import ls from 'local-storage'

// pages
import PageStations from './pages/Stations'
import PagePerformance from './pages/Performance'
import PageUserSettings from './pages/Settings'
import PageBillingServices from './pages/Billing/services'
import PageBillingPayment from './pages/Billing/payment'
import PageBillingInvoices from './pages/Billing/invoices'
import PageUsers from './pages/Users'
import PageTerms from './pages/Terms'
import PageContactUs from './pages/ContactUs'

// @flow
type AccountProps = {
}

class AccountDashboard extends React.Component<AccountProps> {
  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: this.props.loggedInUser
    }

    if(Object.keys(this.props.loggedInUser).length === 0){
      this.props.fetchUser(ls.get('u'));
    }
  }

  render() {
    return (
      <UikContainerHorizontal>
        <Navigation
          pathname={this.props.location.pathname}
        />
        <UikContainerVertical >
          <Switch>
            <Route
              component={ PagePerformance }
              path="/account/analytics"
            />
            <Route
              component={ PageBillingInvoices }
              path="/account/billing/invoices"
            />
            <Route
              component={ PageBillingInvoices }
              path="/account/billing/services/:serviceId/invoices"
            />
            <Route
              component={ PageBillingServices }
              path="/account/billing/services"
            />
            <Route
              component={ PageBillingPayment }
              path="/account/billing/payment"
            />
            <Route
              component={ PageUserSettings }
              path="/account/settings"
            />
            <Route
              component={ PageUsers }
              path="/account/users/:user_id"
            />
            <Route
              component={ PageUsers }
              path="/account/users"
            />
            <Route
              component={ PageStations }
              exact
              path="/account/stations"
              strict
            />
            <Route
              component={ PageTerms }
              exact
              path="/account/terms"
              strict
            />
            <Route
              component={ PageContactUs }
              exact
              path="/account/contact"
              strict
            />
            <Route
              component={ PagePerformance }
              path="/account/performance"
            />
            <Redirect to="/account/stations" />
          </Switch>
        </UikContainerVertical>
      </UikContainerHorizontal>
    )
  }
}

export default connect(
  store => ({
    loggedInUser: store.users.loggedInUser,
  }),
  {
    fetchUser: userActions.fetchUser,
  },
)(AccountDashboard)

import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import DocsStyleguide from './pages/Styleguide'

import {
  UikContainerHorizontal,
} from '@components'

const DocsGettingStarted = () => (
  <UikContainerHorizontal>
    <Switch>
      <Redirect to="/docs/react/styleguide" />
    </Switch>
    <DocsStyleguide/>
  </UikContainerHorizontal>
)

export default DocsGettingStarted

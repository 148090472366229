import * as React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import cls from './stations-grid-item.scss'

// @flow
type StationsListingGridItemProps = {
  imgUrl?: String,
  text?: React.Node,
  title?: React.Node,
  status?: React.Node,
  Component?: React.ElementType,
  id: Number,
}

const StationsListingGridItem = ({
  imgUrl,
  title,
  text,
  status,
  Component,
  id,
}: StationsListingGridItemProps) => (
  <Component
    className={ cls.wrapper }
    to={ `/stations/${id}/overview` }
  >
    {/* Img */}
    {
      <div className={ cls.imageWrapper }>
        <div
          className={ cls.imageWrapperInner }
          style={ {
            backgroundImage: `url("${imgUrl}")`
          } }
        />
      </div>
    }

    {/* Headline */}
    {
      title ? (
        <h2 className={ cls.title }>
          {title}
        </h2>
      ) : title
    }

    {/* Text */}
    {
      text ? (
        <div className={ cls.text }>
          {text}
        </div>
      ) : null
    }
  </Component>
)

StationsListingGridItem.defaultProps = {
  imgUrl: null,
  title: null,
  text: null,
  status: null,
  Component: NavLink,
}

export default withRouter(StationsListingGridItem)

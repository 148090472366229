import * as React from 'react'
import classnames from 'classnames'
import Modal from '@material-ui/core/Modal';
import * as actions from '@redux/playlists/actions'
import { SketchPicker } from 'react-color'

/* eslint-disable max-len */

import { connect } from 'react-redux'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikInput,
  UikFormInputGroup,
} from '@components'

import TopBar from '../../../../@components/TopBar'
import PlaylistList from './components/PlaylistList'

import cls from './playlists.scss'

// @flow
type PlaylistsProps = {
  playlists: Array
}

class MediaPlaylistsPage extends React.Component<PlaylistsProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      addMode: true,
      editPlaylistModalOpen: false,
      deletePlaylistModalOpen: false,
      activePlaylist: {},
    };
    this.props.fetchPlaylists(this.state.stationId);
  };

  handleAddPlaylistModalOpen = () => {
    this.setState({ activePlaylist: {"attributes": {"color":"#ff0000"}}, addMode: true, editPlaylistModalOpen: true });
  };

  handleEditPlaylistModalOpen = (playlist) => {
    this.setState({ addMode: false, editPlaylistModalOpen: true, activePlaylist: JSON.parse(JSON.stringify(playlist)) });
  };

  handleEditPlaylistModalClose = () => {
    this.setState({ editPlaylistModalOpen: false });
  };

  handleDeletePlaylistModalOpen = (playlist) => {
    this.setState({ deletePlaylistModalOpen: true, activePlaylist: playlist });
  };

  handleDeletePlaylistModalClose = () => {
    this.setState({ deletePlaylistModalOpen: false });
  };

  handleDeletePlaylistClick = () => {
    this.props.deletePlaylist(this.state.activePlaylist.id);
    this.setState({ deletePlaylistModalOpen: false });
  };

  handleEditPlaylistClick = () => {
    if(this.state.addMode){
      this.props.createPlaylist(this.state.activePlaylist, this.state.stationId);
    } else {
      this.props.updatePlaylist(this.state.activePlaylist);
    }
    this.setState({ editPlaylistModalOpen: false });
  };

  handleEditChange(event) {
    let ap = this.state.activePlaylist;
    ap["attributes"][event.target.name] = event.target.value;
    if (ap["attributes"]["tracks"] === null) {
      ap["attributes"]["tracks"] = []
    }
    this.setState({activePlaylist: ap});
  }

  handleColorChange(clr) {
    let playlist = this.state.activePlaylist;
    playlist["attributes"]["color"] = (clr||{}).hex;
    this.setState({activeCategory: playlist});
  }

  render() {
    const { addMode, activePlaylist } = this.state;
    const { playlists, playlistsLoading } = this.props;

    let pAttrs = activePlaylist.attributes || {};
    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Playlists"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            <h2>Playlists ({playlists.length})</h2>
            <UikButton primary onClick={this.handleAddPlaylistModalOpen}>Add Playlist</UikButton>
          </UikContentItem>

          <Modal
            open={this.state.editPlaylistModalOpen}
            onClose={this.handleEditPlaylistModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                { addMode ? 'Add' : 'Edit' } Playlist
              </div>
              <div className={ cls.modalBody }>
                <UikFormInputGroup>
                  <UikInput
                    label="Name"
                    name="title"
                    defaultValue={ pAttrs.title }
                    onChange={ this.handleEditChange.bind(this) }
                    maxLength={40}
                  />
                </UikFormInputGroup>
                <br/>
                <UikFormInputGroup>
                  <label className="uik-content-title__wrapper">Color</label>
                  <SketchPicker
                    color={pAttrs.color}
                    onChangeComplete={ this.handleColorChange.bind(this) }
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.modalFooter }>
                <UikButton isExpanded success onClick={this.handleEditPlaylistClick}>Save</UikButton>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deletePlaylistModalOpen}
            onClose={this.handleDeletePlaylistModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Delete Playlist
              </div>
              <div className={ cls.modalBody }>
                <p>Are you sure you wish to delete <b>{ pAttrs.name }</b> ?</p>
              </div>
              <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton)  }>
                <UikButton onClick={this.handleDeletePlaylistModalClose}>Cancel</UikButton>
                <UikButton error onClick={this.handleDeletePlaylistClick}>Delete</UikButton>
              </div>
            </div>
          </Modal>
          <PlaylistList
            editPlaylist={this.handleEditPlaylistModalOpen}
            deletePlaylist={this.handleDeletePlaylistModalOpen}
            playlists={playlists}
            loading={playlistsLoading}
          />
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}


export default connect(
  store => ({
    playlists: store.playlists.playlistsList.playlists,
    playlistsLoading: store.playlists.playlistsList.loading,
  }),
  {
    fetchPlaylists: actions.fetchPlaylists,
    createPlaylist: actions.createPlaylist,
    updatePlaylist: actions.updatePlaylist,
    deletePlaylist: actions.deletePlaylist,
  },
)(MediaPlaylistsPage)


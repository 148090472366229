/* eslint-disable max-len */
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import * as configApp from '@redux/constants'

import { connect } from 'react-redux'

import {
  UikNavSection,
  UikNavLinkDark,
  UikNavLinkTwo,
  UikNavLinkTwoContainer,
  UikNavUser,
  UikDivider,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
  UikNavPanel,
  UikContainerVertical,
  UikScrollArea,
  UikNavLinkSecondary,
} from '@components'

import cls from './account-navigation.scss'

// @flow
type AccountSettingsProps = {
  users: Array,
  loggedInUser: Object,
}

class AccountNavigation extends React.Component<AccountSettingsProps> {
  static defaultProps = {
    className: '',
  }

  constructor(props) {
    super(props)

    this.state = {
      isUserManagementHandled: false,
      menuLinks: [
        {
          text: 'Stations',
          to: 'stations',
          icon: (
            <i className="fas fa-headphones-alt"></i>
          ),
        },
        // {
        //   text: 'Analytics',
        //   to: 'analytics',
        //   icon: (
        //     <i className="fas fa-chart-area"></i>
        //   ),
        // },
        {
          text: 'Billing',
          open: false,
          icon: (
            <i className="fas fa-dollar-sign"></i>
          ),
          items: [
            {
              text: 'Services',
              to: 'billing/services',
            },
            {
              text: 'Payment',
              to: 'billing/payment',
            },
            {
              text: 'Invoices',
              to: 'billing/invoices',
            },
          ],
        },
        {
          text: 'Account Settings',
          to: 'settings',
          // count: 2,
          icon: (
            <i className="fas fa-cog"></i>
          ),
        }
      ]
    }
  }

  handleNavLinkOpenClick = (linkText, e) => {
    let menuLinks = this.state.menuLinks.slice(0);
    for (var i = 0; i < menuLinks.length; ++i) {
      if(menuLinks[i].items){
        menuLinks[i].open = (menuLinks[i].text === linkText) ? !menuLinks[i].open : false;
      }
    }
    this.setState({menuLinks});
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loggedInUser != this.props.loggedInUser) {
      if (this.props.loggedInUser.type !== 'user' &&  !prevState.isUserManagementHandled) {
        this.setState({
          menuLinks: [
            {
              text: 'User Management',
              to: 'users',
              icon: (
                <i className="fas fa-user"></i>
              ),
            },
            ...this.state.menuLinks
          ],
          isUserManagementHandled: true
        })
      }
    }
  }

  handleLogoutClick(e){
    e.preventDefault();
    localStorage.setItem('bearerToken', '');
    window.location = `${configApp.USERAPI_SERVER}/logout`;
  }

  render() {
    const { className, loggedInUser } = this.props;
    const menuLinks = this.state.menuLinks || [];
    const pathname = this.props.pathname || '';

    return (
      <UikNavPanel className={ classnames(cls.wrapper, className) }>
        <UikContainerVertical>

          <UikTopBar className={ cls.topBar }>
            <UikTopBarSection>
              <UikTopBarTitle className={ cls.title }>
                <div
                  className={ cls.navigationLogoWhite }
                  alt="navigationLogoWhite"
                ></div>
              </UikTopBarTitle>
            </UikTopBarSection>
          </UikTopBar>

          <UikScrollArea className={ cls.content }>
            <UikNavUser
              gravatarEmail={ loggedInUser.email }
              name={ loggedInUser.last_name ? `${loggedInUser.first_name} ${loggedInUser.last_name}` : '' }
              textTop={ loggedInUser.type }
            />
            <UikDivider className="muted-white"/>
            <UikNavLinkTwoContainer>
              {
                menuLinks.map(({
                  to,
                  count,
                  text,
                  open,
                  icon,
                  items,
                  ...rest
                }) => (
                  <React.Fragment key={ text }>
                    <UikNavLinkTwo
                      key={ text }
                      Component={ NavLink }
                      className={ classnames(cls.navLink, {
                        [cls.open]: open,
                        [cls.activeLink]: pathname.indexOf(to) > -1
                      }) }
                      rightEl={
                        items ? (
                          <span className={ cls.navCaret }></span>
                        ) : null
                      }
                      icon={ icon }
                      to={ to ? `/account/${to}` : '#'}
                      onClick={this.handleNavLinkOpenClick.bind(this,text)}
                      { ...rest }
                    >
                      {text}
                    </UikNavLinkTwo>
                    <div>
                      {
                        items && items.map(subLink => (
                          <UikNavLinkSecondary
                            Component={ NavLink }
                            key={ subLink.text }
                            to={ `/account/${subLink.to}` }
                            className={ classnames(cls.navLinkSecondary, {
                              [cls.activeLink]: pathname.indexOf(subLink.to) > -1
                            }) }
                          >
                            {subLink.text}
                          </UikNavLinkSecondary>
                        ))
                      }
                    </div>
                  </React.Fragment>
                ))
              }
            </UikNavLinkTwoContainer>
            <UikNavSection>
            {/* This will return
              <a
                className={ cls.externalNavLink }
                href="http://help.live365.com/support/tickets"
                target="_blank"
                rel="noreferrer noopener"
              >
                Support Tickets
              </a>
            **/}
              <a
                className={ cls.externalNavLink }
                href="http://help.live365.com/support/solutions"
              >
                Knowledge Base
              </a>
              <UikNavLinkDark
                key="terms"
                Component={ NavLink }
                to="/account/terms"
              >
                Terms
              </UikNavLinkDark>

              <UikNavLinkDark
                key="logout"
                Component={ NavLink }
                to="/logout"
                onClick={this.handleLogoutClick.bind(this)}
              >
                Logout
              </UikNavLinkDark>
            </UikNavSection>
          </UikScrollArea>
        </UikContainerVertical>
      </UikNavPanel>
    )
  }
}

export default connect(
  store => ({
    loggedInUser: store.users.loggedInUser,
  }),
  {},
)(AccountNavigation)


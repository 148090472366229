import * as React from 'react'

// import { connect } from 'react-redux'
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom'

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {
  withDimensionOnChangeRdx,
} from '@hoc'

import cls from './App.scss'
import routeMap from './routeMap'
import Auth from './Auth.js'

// @flow
type AppProps = {
  location: Object,
  bearerToken: String
}


@withRouter
@withDimensionOnChangeRdx
class App extends React.PureComponent<AppProps> {
  render() {

    const { location, location: { pathname } } = this.props
    const key = pathname.slice('/')[0]
    return (
      <div className={ cls.app }>
        <Switch
          key={ key }
          location={ location }
        >
          <Route
            component={ Auth }
            exact
            path="/"
            strict
          />
          <Route
            component={ Auth }
            path="/oauth/callback"
          />
          <Route
            component={ Auth }
            path="/oauth2/callback"
          />
          <Route
            component={ routeMap.Knowledge }
            path="/knowledge"
          />
          <Route
            component={ routeMap.Socialio }
            path="/social"
          />
          <Route
            component={ routeMap.Tutorials }
            path="/tutorials"
          />
          <Route
            component={ routeMap.Documentation }
            path="/docs"
          />
          <Route
            component={ routeMap.Analytics }
            path="/analytics"
          />
          <Route
            component={ routeMap.Dashboards }
            path="/"
          />
          <Redirect to="/" />
        </Switch>
        <ToastContainer
          className='toast-container'
          toastClassName="dark-toast"
          autoClose={4000}
          closeButton={<CloseButton/>}
          />
      </div>
    )
  }
}

  // <i className="fas fa-check-square"></i>

const CloseButton = ({ YouCanPassAnyProps, closeToast }) => (
  <i
    className="fas fa-times"
    onClick={closeToast}
  >
  </i>
);


export default App


import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

export const FETCH_SERVER = 'FETCH_SERVER';
export const FETCH_SERVER_SUCCESS = 'FETCH_SERVER_SUCCESS';
export const FETCH_SERVER_ERROR = 'FETCH_SERVER_ERROR';
export const RESET_SERVER = 'RESET_SERVER';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1`;

const requestingServer = () => ({
  type: FETCH_SERVER
});

const receivedServer = (json) => ({
  type: FETCH_SERVER_SUCCESS,
  payload: json
});

const receivedError = (json) => ({
  type: FETCH_SERVER_ERROR,
  payload: json
});

const resetServer = () => ({
  type: RESET_SERVER
});

export const fetchServer = (activeStationId) => {
  console.log(activeStationId)
  return (dispatch) => {
    if (!activeStationId) return;

    dispatch(resetServer());
    dispatch(requestingServer());

    const bearerToken = ls.get('bearerToken') || null;
    return fetch(`${BROADCASTER_ROOT_URL}/stations/${activeStationId}/server`, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      }
    })
    .then(res => {
      if (res.status === 401) {
        dispatch(push("/"));
      }
      return res.json();
    })
    .then(json => {
      if (json["errors"] && json["errors"].length){
        let msg = json["errors"][0]["detail"];
        toast.error(msg);
      } else {
        console.log(json)
        dispatch(receivedServer(json.data))
      }
    })
    .catch(err => {
      console.error(err);
    });
  }
}

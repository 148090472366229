import * as React from 'react'
import classnames from 'classnames'

import Modal from '@material-ui/core/Modal';

/* eslint-disable max-len */
import { connect } from 'react-redux'
import ls from 'local-storage'

import * as serviceActions from '@redux/services/actions'
import * as stationActions from '@redux/stations/actions'
import * as packagesActions from '@redux/packages/actions'

import Pagination from '../../../../@components/Pagination'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikInput,
  UikSelect,
  UikFormInputGroup,
  UikWidgetContent,
  UikCheckbox,
  UikRadio
} from '@components'

import TopBar from '../../../../@components/TopBar'
import ServiceList from './components/ServiceList'

import cls from './billing.scss'
import moment from "moment-timezone";
// @flow
type ServicesProps = {
  services: Array,
  packages: Array,
  stations: Array
}

class ServicesPage extends React.Component<ServicesProps> {

  constructor(props) {
    super(props);

    this.state = {
      addMode: true,
      editServiceModalOpen: false,
      deleteServiceModalOpen: false,
      activeService: {},
      stationName: "",
      packageId: 0,
      serviceId: 0,
      perPage: 30,
      page: 1,
      cancelServiceType: "Immediate",
      cancelActive: false,
      cancelAgreement: {}
    };

    this.props.fetchStations({
      perPage: 500,
      page: 1
    });

    this.props.fetchServices({perPage: 30, page: 1});
    this.props.fetchPackages();
  }

  handleAddServiceModalOpen = () => {
    this.setState({ addMode: true, editServiceModalOpen: true });
  };

  handleEditServiceModalOpen = (activeService) => {
    this.setState({ activeService, addMode: false, editServiceModalOpen: true });
  };

  handleEditServiceModalClose = () => {
    this.setState({ editServiceModalOpen: false });
  };

  handleEditServiceModalClick = () => {
    if(this.state.addMode){
      const userId = this.props.userId || ls.get("u");
      this.props.createStation({
        userId: userId,
        stationAttributes: {"name": this.state.stationName},
        packageId: this.state.packageId
      });
    } else {
      if(this.state.stationName){
        this.props.updateStation({
          "id": this.state.activeService.station.id,
          "attributes": {
            "name": this.state.stationName
          }
        });
      }
      if(this.state.packageId !== this.state.activeService.package.id){
        this.props.updateService({
          "id": this.state.activeService.id,
          "relationships": {
            "package": {
              "data": {
                "id": this.state.packageId,
                "type": "packages"
              }
            }
          }
        });
      }
    }
    this.setState({ editServiceModalOpen: false });
  };

  handleDeleteServiceModalOpen = (activeService) => {
    this.setState({ activeService, deleteServiceModalOpen: true });
  };

  handleDeleteServiceModalClose = () => {
    this.setState({ deleteServiceModalOpen: false });
  };

  handleDeleteServiceClick = () => {
    this.props.updateService({
      "id": this.state.activeService.id,
      "attributes": {
        "status": "Cancelled",
        "cancellation_type": this.state.cancelServiceType
      }
    });
    this.setState({ deleteServiceModalOpen: false });
  };

  handleChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleSelectChange(name, event) {
    let newState = {};
    newState[name] = event.value;
    this.setState(newState);
  }

  handleCancelBoxChecked(event) {
    let newState = {};
    let cancelAgreement = Object.assign({}, this.state.cancelAgreement);

    if (event.target.name.indexOf("|") > -1){
      let attrs = event.target.name.split("|");
      cancelAgreement[attrs[1]] = event.target.checked;
      newState["cancelAgreement"] = cancelAgreement
    }

    if (Object.keys(cancelAgreement).length === 4){
      let result = true;
      Object.keys(cancelAgreement).forEach(function(key) {
        if (cancelAgreement[key] === false) {
            result = false;
        }
      });
      newState["cancelActive"] = result;
    } else {
      newState["cancelActive"] = false;
    }

    this.setState(newState);
  }

  handleperPageChange(event) {
    this.setState({perPage: event.value, page: 1});
    this.props.fetchServices({
      page: 1,
      perPage: event.value
    });
  }

  handlePageChange(page) {
    this.setState({page});
    this.props.fetchServices({
      page: page,
      perPage: this.state.perPage
    });
  }

  render() {
    const { addMode, activeService, perPage, page, cancelServiceType, cancelActive, cancelAgreement } = this.state;
    const { services, totalServices, packages, stations, servicesLoading } = this.props;

    services.forEach((service) => {
      service.package = packages.find((p) => p.id === service.relationships.package.data.id)
      service.station = stations.find((s) => s.id === service.relationships.station.data.id)
    });

    let packageOptions = packages.map((pl) => {
      return { value: pl.id, label: pl.attributes.name}
    });

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Services"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            <UikButton primary onClick={this.handleAddServiceModalOpen}>Add Service</UikButton>
          </UikContentItem>
          <Modal
            open={this.state.editServiceModalOpen}
            onClose={this.handleEditServiceModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                { addMode ? 'Add' : 'Edit' } Service
              </div>
              <div className={ cls.modalBody }>
                <UikFormInputGroup className={ cls.signInForm }>
                  <UikInput
                    label="Station"
                    name="stationName"
                    placeholder="Name"
                    defaultValue={ ((activeService.station||{}).attributes||{}).name }
                    onChange={ this.handleChange.bind(this) }
                  />
                  <span className="uik-content-title__wrapper">Package</span>
                  <UikSelect
                    defaultValue={ [(activeService.package||{}).id] }
                    options={ packageOptions }
                    placeholder="Package"
                    onChange={ this.handleSelectChange.bind(this, 'packageId')  }
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.modalFooter }>
                <UikButton isExpanded success onClick={this.handleEditServiceModalClick}>Save</UikButton>
                <h5>
                  Note: You are creating a new service and station.
                  If you are attempting to reactivate a previous station,
                  please close this dialog box and select 'Reactivate' from the 'Actions' menu.
                </h5>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deleteServiceModalOpen}
            onClose={this.handleDeleteServiceModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Delete Service
              </div>
              <div className={ cls.modalBody }>
                <p>Are you sure you wish to delete <b>{(((activeService||{}).station||{}).attributes||{}).name} ?</b></p>
                <p>
                  If you cancel your station, it will be terminated, taken off air and will no longer broadcast.
                  In order to cancel your subscription, please acknowledge the following:
                </p>
                <UikWidgetContent>
                  <UikFormInputGroup vertical>
                    <UikCheckbox
                      label="At the end of the billing period, I want to terminate my Live365 subscription for this service."
                      name="cancelAgreement|first"
                      defaultChecked={ cancelAgreement["first"] }
                      onChange={ this.handleCancelBoxChecked.bind(this) }
                    />
                    <UikCheckbox
                      label="I understand that I will be unable to log into my station, and my station will be taken off air."
                      name="cancelAgreement|second"
                      defaultChecked={ cancelAgreement["second"] }
                      onChange={ this.handleCancelBoxChecked.bind(this) }
                    />
                    <UikCheckbox
                      label="I acknowledge that my radio page will no longer function, nor will my station appear on the Live365 mobile app."
                      name="cancelAgreement|third"
                      defaultChecked={ cancelAgreement["third"] }
                      onChange={ this.handleCancelBoxChecked.bind(this) }
                    />
                    <UikCheckbox
                      label="Any content I have uploaded will be deleted and any schedules and playlists I've made will be removed."
                      name="cancelAgreement|fourth"
                      defaultChecked={ cancelAgreement["fourth"] }
                      onChange={ this.handleCancelBoxChecked.bind(this) }
                    />
                  </UikFormInputGroup>
                </UikWidgetContent>
                <label>Cancel my service:</label>
                <UikFormInputGroup horizontal className={ cls.modalRadio }>
                  <UikRadio
                    label="Within 24 hours"
                    name="cancelServiceType"
                    value="Immediate"
                    defaultChecked={ cancelServiceType === "Immediate" }
                    onChange={ this.handleChange.bind(this) }
                  />
                  <UikRadio
                    label="At the end of the current billing period"
                    name="cancelServiceType"
                    value="End of Billing Period"
                    defaultChecked={ cancelServiceType === "End of Billing Period" }
                    onChange={ this.handleChange.bind(this) }
                  />
                </UikFormInputGroup>
                <p>
                  Refunds are not available, regardless of cancellation date.
                  If you believe you've been charged in error, please contact our support.
                  Plans on Live365 are subject to change and may not be available at a later date.
                  If you need assistance, or you would like to book a personal training session, please contact us at support@live365.com before submitting this request.
                </p>
              </div>
              <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton) }>
                <UikButton onClick={this.handleDeleteServiceModalClose}>Cancel</UikButton>
                <UikButton error disabled={!cancelActive} onClick={this.handleDeleteServiceClick}>Delete</UikButton>
              </div>
            </div>
          </Modal>

          <ServiceList
            services={services}
            loading={servicesLoading}
            editService={this.handleEditServiceModalOpen}
            deleteService={this.handleDeleteServiceModalOpen}
          />
        </UikLayoutMain>
        <Pagination
        showTotal={true}
        showingCount={services.length}
        total={ totalServices }
        page={ page }
        perPage={ perPage }
        changePage={ this.handlePageChange.bind(this) }
        changePerPage={ this.handleperPageChange.bind(this) }
        aroundPage = { 3 }
        collectionType="services"
        />
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    services: store.services.servicesList.services,
    totalServices: store.services.totalServices,
    servicesLoading: store.services.servicesList.loading,
    stations: store.stations.stationsList.stations,
    packages: store.packages.packagesList.packages
  }),
  {
    createStation: stationActions.createStation,
    updateStation: stationActions.updateStation,
    fetchStations: stationActions.fetchStations,
    updateService: serviceActions.updateService,
    fetchServices: serviceActions.fetchServices,
    fetchPackages: packagesActions.fetchPackages,
  },
)(ServicesPage)


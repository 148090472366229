export default [
  {
    text: 'CMS API Reference',
  },
  {
    text: 'Authentication',
  },
  {
    text: 'Meta',
  },
  {
    text: 'Sites',
  },
  {
    text: 'Domains',
  },
  {
    text: 'Collections',
    items: [
      {
        text: 'Model',
      },
      {
        text: 'List Collections',
      },
      {
        text: 'Get Collections',
      },
    ],
  },
  {
    text: 'Fields',
  },
  {
    text: 'Items',
  },
  {
    text: 'Images',
  },
  {
    text: 'Webhooks',
  },
  {
    text: 'Errors',
  },
  {
    text: 'Rate Limits',
  },
  {
    text: 'API Versions',
  },
  {
    text: 'Changelog',
  },

]

import initialState from './initialState'

import {
  FETCH_CLOCKWHEELS, FETCH_CLOCKWHEELS_SUCCESS, FETCH_CLOCKWHEELS_FAILURE, RESET_CLOCKWHEELS, FETCH_CLOCKWHEELS_PAGE_SUCCESS,
  UPDATE_CLOCKWHEEL, UPDATE_CLOCKWHEEL_SUCCESS,  UPDATE_CLOCKWHEEL_FAILURE,
  FETCH_CLOCKWHEEL, FETCH_CLOCKWHEEL_SUCCESS,  FETCH_CLOCKWHEEL_FAILURE, RESET_ACTIVE_CLOCKWHEEL,
  CREATE_CLOCKWHEEL, CREATE_CLOCKWHEEL_SUCCESS, CREATE_CLOCKWHEEL_FAILURE, RESET_NEW_CLOCKWHEEL,
  DELETE_CLOCKWHEEL, DELETE_CLOCKWHEEL_SUCCESS, DELETE_CLOCKWHEEL_FAILURE, RESET_DELETED_CLOCKWHEEL,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_CLOCKWHEELS:// start fetching clockwheels and set loading = true
    return { ...state, clockwheelsList: {clockwheels:[], error: null, loading: true} };
  case FETCH_CLOCKWHEELS_PAGE_SUCCESS:
    return { ...state, clockwheelsList: {clockwheels: [...state.clockwheelsList.clockwheels, ...action.payload], error: null, loading: true}}
  case FETCH_CLOCKWHEELS_SUCCESS:
    return { ...state, clockwheelsList: {clockwheels: state.clockwheelsList.clockwheels, error:null, loading: false} };
  case FETCH_CLOCKWHEELS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, clockwheelsList: {clockwheels: [], error: error, loading: false} };
  case RESET_CLOCKWHEELS:// reset clockwheelList to initial state
    return { ...state, clockwheelsList: {clockwheels: [], error:null, loading: false} };

  case FETCH_CLOCKWHEEL:
    return { ...state, activeClockwheel:{...state.activeClockwheel, loading: true}};
  case FETCH_CLOCKWHEEL_SUCCESS:
    return { ...state, activeClockwheel: {clockwheel: action.payload, error:null, loading: false}};
  case FETCH_CLOCKWHEEL_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeClockwheel: {clockwheel: null, error:error, loading:false}};
  case RESET_ACTIVE_CLOCKWHEEL:
    return { ...state, activeClockwheel: {clockwheel: null, error:null, loading: false}};

  case UPDATE_CLOCKWHEEL:
    return { ...state, activeClockwheel:{...state.activeClockwheel, loading: true}};
  case UPDATE_CLOCKWHEEL_SUCCESS:
    return { ...state, activeClockwheel: {clockwheel: action.payload, error:null, loading: false}};
  case UPDATE_CLOCKWHEEL_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeClockwheel: {clockwheel: null, error:error, loading:false}};

  case CREATE_CLOCKWHEEL:
    return {...state, newClockwheel: {...state.newClockwheel, loading: true}}
  case CREATE_CLOCKWHEEL_SUCCESS:
    let newClockwheels = state.clockwheelsList.clockwheels.slice();
    newClockwheels.push(action.payload);
    return {...state,
      clockwheelsList: { clockwheels: newClockwheels },
      newClockwheel: {clockwheel:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_CLOCKWHEEL_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newClockwheel: {clockwheel:null, error:error, loading: false}}
  case RESET_NEW_CLOCKWHEEL:
    return {...state,  newClockwheel:{clockwheel:null, error:null, loading: false}}

  case DELETE_CLOCKWHEEL:
     return {...state, deletedClockwheel: {...state.deletedClockwheel, loading: true}}
  case DELETE_CLOCKWHEEL_SUCCESS:
    let newCws = state.clockwheelsList.clockwheels.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, clockwheelsList: { clockwheels: newCws }, deletedClockwheel: {clockwheel:action.payload, error:null, loading: false}}
  case DELETE_CLOCKWHEEL_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedClockwheel: {clockwheel:null, error:error, loading: false}}
  case RESET_DELETED_CLOCKWHEEL:
    return {...state,  deletedClockwheel:{clockwheel:null, error:null, loading: false}}

  default:
    return state;
  }
}

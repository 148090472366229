import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Invoice list
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const RESET_INVOICES = 'RESET_INVOICES';

//Update INVOICE
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE';

//Fetch INVOICE
export const FETCH_INVOICE = 'FETCH_INVOICE';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAILURE = 'FETCH_INVOICE_FAILURE';
export const RESET_ACTIVE_INVOICE = 'RESET_ACTIVE_INVOICE';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestInvoices = () => ({
  type: FETCH_INVOICES,
})

export const receiveInvoices = (json, meta) => ({
  type: FETCH_INVOICES_SUCCESS,
  payload: json,
  meta: meta
})

export const requestUpdateInvoice = () => ({
  type: UPDATE_INVOICE,
})

export const invoiceUpdated = (json) => ({
  type: UPDATE_INVOICE_SUCCESS,
  payload: json
})

export function fetchInvoices({perPage, page, query}) {

  perPage = perPage || 20;
  page = page || 1;

  return function(dispatch) {

    dispatch(requestInvoices())

    const bearerToken = ls.get('bearerToken') || null;

    let url = `${BROADCASTER_ROOT_URL}invoices/?sort=-due_date&page[size]=${perPage}&page[number]=${page}`;

    var patt = /[a-zA-Z][\d]{5}/g;
    if(patt.test(query)){
      url += `&filter[mount_id]=${query}`;
    } else {
      url += `&filter[user_email]=${query}`;
    }

    let status = 200;
    let statusText = 'OK';

    return fetch(url, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        let contentType = 'unknown';
        res.headers.forEach((value, key, map) => {
            if (key == 'content-type')
              contentType = value;
        })

        if (res.status >= 400 && res.headers && contentType !== 'application/vnd.api+json') {
          toast.error('An error occurred: ' + res.statusText);
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error('Please contact support: ' + msg);
        } else {
          dispatch(receiveInvoices(json.data, json.meta))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateInvoice(invoice) {

  return function(dispatch) {

    dispatch(requestUpdateInvoice())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "invoices",
        "id": invoice.id + "",
        "attributes": invoice.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}invoices/${invoice.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json => {
        toast.success("Invoice has been saved !");
        dispatch(invoiceUpdated(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchInvoicesSuccess(invoices) {
  return {
    type: FETCH_INVOICES_SUCCESS,
    payload: invoices
  };
}

export function fetchInvoicesFailure(error) {
  return {
    type: FETCH_INVOICES_FAILURE,
    payload: error
  };
}

export function fetchInvoiceSuccess(activeInvoice) {
  return {
    type: FETCH_INVOICE_SUCCESS,
    payload: activeInvoice
  };
}

export function fetchInvoiceFailure(error) {
  return {
    type: FETCH_INVOICE_FAILURE,
    payload: error
  };
}
import * as React from 'react'

/* eslint-disable max-len */

import {
  Uikon,
  UikWidget,
  UikWidgetTable,
  UikButton,
  UikDropdown,UikDropdownItem,
} from '@components'

import { NavLink, withRouter } from 'react-router-dom'

import PacmanLoader from '../../../../../@components/PacmanLoader'

import cls from '../clockwheels.scss'

// @flow
type ClockwheelsProps = {
  editClockwheel: Function,
  deleteClockwheel: Function,
  clockwheels: Array,
  stationId: String
}

class ClockwheelList extends React.Component<ClockwheelsProps> {
  viewClockwheel(cw,event){
    this.props.history.push(`/stations/${this.props.stationId}/schedule/clockwheels/${cw.id}`)
  }

  render() {
    const { editClockwheel, deleteClockwheel, clockwheels, stationId, loading } = this.props;

    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={cls.table}>
          <thead>
            <tr>
              <th>
                ClockWheel
              </th>
              <th>
                Color
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              clockwheels.map(clockwheel => (
                <tr key={ `clockwheel-${clockwheel.id}` }>
                  <td>
                    <NavLink
                      to={ `/stations/${stationId}/schedule/clockwheels/${clockwheel.id}` }
                      >
                      {clockwheel.attributes.name}
                    </NavLink>
                  </td>
                  <td>
                    <div
                      className={ cls.colorSquare}
                      style={{ backgroundColor: clockwheel.attributes.color }}>
                    </div>
                  </td>
                  <td>
                    <UikDropdown
                      DisplayComponent={
                      props => (
                        <UikButton
                          clear
                          icon={ <Uikon>more</Uikon> }
                          iconOnly
                          {...props}
                        />
                      )
                    }
                      position="bottomRight"
                    >
                      <UikDropdownItem
                          onClick={this.viewClockwheel.bind(this, clockwheel)}
                      >
                        View
                      </UikDropdownItem>
                      <UikDropdownItem onClick={editClockwheel.bind(this, clockwheel)}>
                        Edit
                      </UikDropdownItem>
                      <UikDropdownItem onClick={deleteClockwheel.bind(this, clockwheel)}>
                        Delete
                      </UikDropdownItem>
                    </UikDropdown>
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { clockwheels.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No clockwheels found.</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default withRouter(ClockwheelList)


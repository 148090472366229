import * as React from 'react'
import classnames from 'classnames'
import * as configApp from '@redux/constants'

import {
  Uikon,
} from '@components'

import StationsGridItem from '../ListingGridItem'
import cls from './stations-grid-view.scss'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// @flow
type StationsGridViewProps = {
  data: Array,
  className?: String
}

const StationsGridView = ({
  data,
  className,
}: StationsGridViewProps) => (
  <div className={ classnames(cls.wrapper, className) }>
    {
      data.map(item => (
        <StationsGridItem
          key={ Math.random() }
          id={ item.id }
          imgUrl={ item.attributes.logo_uuid ? `${mediaStageBaseUrl}/download/${item.attributes.logo_uuid}.${item.attributes.logo_extension}` : '/img/icon-radio.jpg' }
          status={ item.attributes.status }
          text={ (
            <React.Fragment>
              <div className={ cls.extra }>
                <div className={ cls.extraItem }>
                  <Uikon className={ cls.extraIcon }>
                    profile_round
                  </Uikon>
                  { "123"}
                  {' Sessions'}
                </div>
                <div className={ cls.extraItem }>
                  <Uikon className={ cls.extraIcon }>
                    clock
                  </Uikon>
                  { "32" }
                  {' TLH'}
                </div>
              </div>
            </React.Fragment>
          ) }
          title={ item.attributes.name }
        />
      ))
    }
  </div>
)

StationsGridView.defaultProps = {
  className: null,
}

export default StationsGridView

import * as React from 'react'

import Modal from '@material-ui/core/Modal';

import { connect } from 'react-redux'
import * as actions from '@redux/users/actions'

/* eslint-disable max-len */

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikInput,
  UikSelect,
  UikFormInputGroup,
  UikWidget,
  UikWidgetContent,
} from '@components'

import TopBar from '../../../../@components/TopBar'
import UserList from './components/UserList'
import SearchInput from '../../../../@components/SearchInput'

import cls from './stations-users.scss'

// @flow
type UsersProps = {
  users: Array,
}

class UsersPage extends React.Component<UsersProps> {

  constructor(props) {
    super(props);

    this.state = {
      addMode: true,
      editUserModalOpen: false,
      bulkAction: '',
      activeUser: {},
      selectedUsers: [],
      invitationEmail: "",
      invitationRole: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    this.props.fetchUsers();
  }

  handleTabClick = (activeTab) => {
    this.setState({activeTab: activeTab});
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSelectUser = (userId) => {
  };

  handleSelectAllUsers = () => {
  };

  handleAddUserModalOpen = () => {
    this.setState({ addMode: true, editUserModalOpen: true });
  };

  handleEditUserModalOpen = (user) => {
    this.setState({ activeUser: user, addMode: false, editUserModalOpen: true });
  };

  handleEditUserModalSave = () => {
    if (this.state.addMode) {
      this.props.sendInvitation({stationId: 1, invitationEmail: this.state.invitationEmail, invitationRole: this.state.invitationRole})
    } else {
      this.props.updateUserRole({userId: this.state.activeUser.id, accessLevel: this.state.invitationRole})
    }
    this.setState({ editUserModalOpen: false });
  };

  handleEditUserModalClose = () => {
    this.setState({ editUserModalOpen: false });
  };

  handleDeleteUserModalOpen = (user) => {
    this.setState({ activeUser: user, deleteUserModalOpen: true });
  };

  handleDeleteUserModalClose = () => {
    this.setState({ deleteUserModalOpen: false });
  };

  handleEditInvitationModalOpen = () => {
    console.log('handleEditInvitationModalOpen')
  }

  handleDeleteInvitationModalOpen = () => {
    console.log('handleDeleteInvitationModalOpen')
  }

  handleSelectInvitation = () => {
    console.log('handleSelectInvitation')
  }

  handleDeleteServiceClick = () => {
    alert('😭 Sadly, 😭 😭 cannot 😭 delete 😭 😭 😭 through API 😭');
    this.setState({ deleteUserModalOpen: false });
  };

  handleBulkActionChange = (event) => {
    this.setState({bulkAction: event.value});
    if ( event.value === 'delete') {
      this.handleDeleteUserModalOpen();
    }
  };

  applyBulkActions = () => {
    if (this.state.bulkAction === 'edit' ) {
    }
  };

  handleEditChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleEmailChange(event) {
    this.setState({"invitationEmail": event.target.value});
  }

  handleSelectChange(name, event) {
    let newState = {};
    newState[name] = event.value;
    this.setState(newState);
  }

  handleSearch({query}) {
    this.setState({'searchQuery': query});
  }

  render() {
    const {
      addMode,
      bulkAction,
      activeUser,
      searchQuery,
      invitationRole,
      invitationEmail,
    } = this.state;

    let bulkEditWidget;

    let { users,
      usersLoading
    } = this.props;

    let stationUsers = users.map((user) => {
      return {...user.attributes};
    })

    if (searchQuery && searchQuery.length) {
      stationUsers = stationUsers.filter(user => {
        return (user["first_name"] && user["first_name"].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
          || (user["last_name"] && user["last_name"].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
          || (user["email"] && user["email"].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
      });
    }

    if ( bulkAction === 'edit') {
      bulkEditWidget = <UikWidget
          margin
          style={{"display": "none"}}
        >
          <UikWidgetContent>
            <h3>Bulk Edit</h3>
            <div className={ cls.bulkEditForm }>
              <UikFormInputGroup direction="horizontal" className={ cls.formHorizontal }>
                <label>Station 1</label>
                <UikSelect
                  excluded={ [3] }
                  placeholder="Role"
                  options={ [
                    {
                      value: 1,
                      label: 'Admin',
                    },
                    {
                      value: 2,
                      label: 'Editor',
                    },
                    {
                      value: 3,
                      label: 'Guest',
                    },
                  ] }
                />
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal" className={ cls.formHorizontal }>
                <label>Station 2</label>
                <UikSelect
                  excluded={ [3] }
                  placeholder="Role"
                  options={ [
                    {
                      value: 1,
                      label: 'Admin',
                    },
                    {
                      value: 2,
                      label: 'Editor',
                    },
                    {
                      value: 3,
                      label: 'Guest',
                    },
                  ] }
                />
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal" className={ cls.formHorizontal }>
                <label>Station 3</label>
                <UikSelect
                  excluded={ [3] }
                  placeholder="Role"
                  options={ [
                    {
                      value: 1,
                      label: 'Admin',
                    },
                    {
                      value: 2,
                      label: 'Editor',
                    },
                    {
                      value: 3,
                      label: 'Guest',
                    },
                  ] }
                />
              </UikFormInputGroup>
            </div>
            <UikButton success>Save</UikButton>
          </UikWidgetContent>
        </UikWidget>;
     }

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Users"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
{/*            <div className={ cls.bulkActionsWrapper }>
              <UikSelect
                className={ cls.selectRange }
                onChange={ this.handleBulkActionChange.bind(this) }
                options={ [
                  {
                    value: 'edit',
                    label: (
                      <span>
                        {'Edit'}
                      </span>
                    ),
                  },
                  {
                    value: 'delete',
                    label: (
                      <span>
                        {'Delete'}
                      </span>
                    ),
                  },
                ] }
                placeholder="Bulk Actions"
              />
            </div>*/}
            <SearchInput
              onSearch={ this.handleSearch }
              collectionName="Users"
            />
            <UikButton primary onClick={this.handleAddUserModalOpen}>Invite User</UikButton>
          </UikContentItem>

          <Modal
            open={this.state.editUserModalOpen}
            onClose={this.handleEditUserModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                { addMode ? 'Invite' : 'Edit' } User
              </div>
              <div className={ cls.modalBody }>
                { addMode && (
                  <UikFormInputGroup>
                    <UikInput
                      label="Email Address"
                      name="invitationEmail"
                      placeholder=""
                      defaultValue={ activeUser.email && invitationEmail }
                      onChange={ this.handleEmailChange }
                      maxLength={40}
                    />
                  </UikFormInputGroup>
                )}
                <UikFormInputGroup direction="horizontal" className={ cls.formHorizontal }>
                  <label>Role</label>
                  <UikSelect
                    placeholder="Role"
                    defaultValue={ activeUser.access_level }
                    options={ [
                      {
                        value: "editor",
                        label: 'Editor',
                      },
                      {
                        value: "manager",
                        label: 'Manager',
                      },
                      {
                        value: "owner",
                        label: 'Owner',
                      }
                    ] }
                    onChange={ this.handleSelectChange.bind(this, 'invitationRole')  }
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.modalFooter }>
                <UikButton
                  isExpanded
                  success
                  disabled={invitationEmail.length === 0 || invitationRole.length === 0}
                  onClick={this.handleEditUserModalSave.bind(this)}
                >
                  {addMode ? 'Send Invitation' : 'Save'}
                </UikButton>
              </div>
            </div>
          </Modal>

          { bulkEditWidget }

          <UserList
            users={ stationUsers }
            loading={ usersLoading }
            editUser={this.handleEditUserModalOpen}
            deleteUser={this.handleDeleteUserModalOpen}
            selectUser={this.handleSelectUser}
            selectAllUsers={this.handleSelectAllUsers}
            showAccessLevel={false}
          />
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    users: store.users.users,
    usersLoading: store.users.usersLoading,
  }),
  {
    fetchUsers: actions.fetchUsers,
    sendInvitation: actions.sendInvitation,
    updateUserRole: actions.updateUserRole,
  },
)(UsersPage)

import * as React from 'react'
import classnames from 'classnames'

import Modal from '@material-ui/core/Modal';
import * as actions from '@redux/clockwheels/actions'
import { SketchPicker } from 'react-color';

/* eslint-disable max-len */

import { connect } from 'react-redux'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikInput,
  UikFormInputGroup,
} from '@components'

import TopBar from '../../../../@components/TopBar'
import ClockwheelList from './components/ClockwheelList'

import cls from './clockwheels.scss'

// @flow
type ClockwheelsProps = {
  clockwheels: Array
}

class Clockwheels extends React.Component<ClockwheelsProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      addMode: true,
      editClockwheelModalOpen: false,
      deleteClockwheelModalOpen: false,
      activeClockwheel: {},
    };

    this.props.fetchClockwheels(this.state.stationId);

    this.handleEditChange = this.handleEditChange.bind(this);
  }

  handleAddClockwheelModalOpen = () => {
    this.setState({
      addMode: true,
      editClockwheelModalOpen: true,
      activeClockwheel: {"attributes":{
        "color": "#191DCB"
      }}
    });
  };

  handleEditClockwheelModalOpen = (cw) => {
    this.setState({ addMode: false, editClockwheelModalOpen: true, activeClockwheel: cw });
  };

  handleEditClockwheelModalClose = () => {
    this.setState({ editClockwheelModalOpen: false });
  };

  handleDeleteClockwheelModalOpen = (cw) => {
    this.setState({ deleteClockwheelModalOpen: true, activeClockwheel: cw });
  };

  handleDeleteClockwheelModalClose = () => {
    this.setState({ deleteClockwheelModalOpen: false });
  };

  handleDeleteClockwheelClick = () => {
    this.props.deleteClockwheel(this.state.activeClockwheel.id);
    this.setState({ deleteClockwheelModalOpen: false });
  };

  handleEditClockwheelClick = () => {
    if(this.state.addMode){
      this.props.createClockwheel(this.state.activeClockwheel, this.state.stationId);
    } else {
      this.props.updateClockwheel(this.state.activeClockwheel);
    }
    this.setState({ editClockwheelModalOpen: false });
  };

  handleEditChange(event) {
    let acw = this.state.activeClockwheel || {};
    acw["attributes"][event.target.name] = event.target.value;
    this.setState({activeClockwheel: acw});
  }

  handleColorChange(clr) {
    let acw = this.state.activeClockwheel;
    acw["attributes"]["color"] = (clr||{}).hex;
    this.setState({activeClockwheel: acw});
  }

  render() {
    const { addMode, activeClockwheel, stationId } = this.state;
    const { clockwheels, clockwheelsLoading } = this.props;
    let cwAttrs = activeClockwheel.attributes || {};
    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Schedule > Clockwheels" />
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            <h2>Clockwheels ({clockwheels.length})</h2>
            <UikButton primary onClick={this.handleAddClockwheelModalOpen}>Add Clockwheel</UikButton>
          </UikContentItem>

          <Modal
            open={this.state.editClockwheelModalOpen}
            onClose={this.handleEditClockwheelModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                { addMode ? 'Add' : 'Edit' } Clockwheel
              </div>
              <div className={ cls.modalBody }>
                <UikFormInputGroup>
                  <UikInput
                    label="Name"
                    name="name"
                    placeholder=""
                    defaultValue={ cwAttrs.name }
                    onChange={ this.handleEditChange }
                  />
                </UikFormInputGroup>
                <br/>
                <UikFormInputGroup>
                  <label className="uik-content-title__wrapper">Color</label>
                  <SketchPicker
                    color={cwAttrs.color}
                    onChangeComplete={ this.handleColorChange.bind(this) }
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.modalFooter }>
                <UikButton isExpanded success onClick={this.handleEditClockwheelClick}>Save</UikButton>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deleteClockwheelModalOpen}
            onClose={this.handleDeleteClockwheelModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Delete Clockwheel
              </div>
              <div className={ cls.modalBody }>
                <p>Are you sure you wish to delete <b>{ cwAttrs.name }</b> ?</p>
              </div>
              <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton)  }>
                <UikButton onClick={this.handleDeleteClockwheelModalClose}>Cancel</UikButton>
                <UikButton error onClick={this.handleDeleteClockwheelClick}>Delete</UikButton>
              </div>
            </div>
          </Modal>
          <ClockwheelList
            editClockwheel={this.handleEditClockwheelModalOpen}
            deleteClockwheel={this.handleDeleteClockwheelModalOpen}
            clockwheels={ clockwheels || [] }
            stationId={ stationId }
            loading={clockwheelsLoading}
          />
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    clockwheels: store.clockwheels.clockwheelsList.clockwheels,
    clockwheelsLoading: store.clockwheels.clockwheelsList.loading,
  }),
  {
    fetchClockwheels: actions.fetchClockwheels,
    createClockwheel: actions.createClockwheel,
    updateClockwheel: actions.updateClockwheel,
    deleteClockwheel: actions.deleteClockwheel,
  },
)(Clockwheels)

import * as React from 'react'

/* eslint-disable max-len */

import {
  UikAvatar,
  UikWidget,
  UikWidgetTable,
  UikTagContainer,UikTag,
} from '@components'

import PacmanLoader from '../../../../../@components/PacmanLoader'

import cls from '../stations-users.scss'
import tableCls from './multi-select-table.scss'

class UserList extends React.Component<{}> {

  render() {
    const { users, showAccessLevel, loading }  = this.props;

    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={tableCls.multiSelectTable}>
          <thead>
            <tr>
{/*              <th>
                <UikCheckbox
                  name="selectAllUsers"
                  onClick={this.selectAllUsers}
                />
              </th>*/}
              <th>
                Name
              </th>
              { showAccessLevel && (
                <th>
                  Access
                </th>
              )}
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              users.map(user => (
                <tr key={ `tr-${Math.random()}` }>
{/*                  <td key={ `td-check-${Math.random()}` }>
                    <UikCheckbox
                      name={`user-${user.id}`}
                      value={user.id}
                    />
                  </td>*/}
                  <td>
                    <UikAvatar
                      highlighted
                      gravatarEmail={ user.email }
                      name={ `${user.first_name} ${user.last_name} ` }
                      textBottom={ user.email }
                    />
                  </td>
                  { showAccessLevel && (
                    <td>
                      <UikTagContainer className={ cls.tags }>
                        <UikTag
                          color="green"
                          fill
                        >
                        { user.access_level }
                        </UikTag>
                      </UikTagContainer>
                    </td>
                  )}
                  <td>
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { !loading && users.length === 0 && (
              <tr><td colSpan="10">No Users</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default UserList

import * as React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/* eslint-disable max-len */

import {
  Uikon,
  UikWidget,
  UikWidgetTable,
  UikButton,
  UikDropdown,UikDropdownItem,
  UikTag,
} from '@components'

import PacmanLoader from '../../../../../@components/PacmanLoader'

import cls from '../clockwheels.scss'

// @flow
type ClockwheelsProps = {
  match: Object,
  playlists: Array
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: "white",
  border: isDragging ? "2px solid orange" : "none",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = list.slice();
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ClockwheelList extends React.Component<ClockwheelsProps> {
  constructor(props) {
    super(props);
    this.state = {
      clockwheelEntries: this.props.clockwheelEntries
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.clockwheelEntries !== this.props.clockwheelEntries){
      this.setState({ clockwheelEntries: this.props.clockwheelEntries });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let cwEntry = this.state.clockwheelEntries[result.source.index];
    cwEntry.attributes.position = (result.destination.index) + "";

    this.props.updateClockwheelEntry(cwEntry);

    const clockwheelEntries = reorder(
      this.state.clockwheelEntries,
      result.source.index,
      result.destination.index
    );

    this.setState({ clockwheelEntries });
  }

  getCategoryColor = (categoryId) => {
    if (this.props.categories) {
      let category = this.props.categories.find(c => c.id === `${categoryId}`);
      if (category) {
        return category["attributes"]["color"];
      }
    }
    return '#f1f1f1';
  }

  getCategoryName = (categoryId) => {
    if (this.props.categories) {
      let category = this.props.categories.find(c => c.id === `${categoryId}`);
      if (category) {
        return category["attributes"]["name"];
      }
    }
    return 'Loading Category';
  }

  render() {
    const { clockwheelEntries } = this.state;
    const { deleteClockwheelEntry, loading } = this.props;

    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={cls.table}>
          <thead>
            <tr>
              <th>
                Type or Category
              </th>
              <th>
                Selection Algorithm
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <tbody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {clockwheelEntries.map((clockwheelEntry, index) => (
                      <Draggable key={clockwheelEntry.id} draggableId={clockwheelEntry.id} index={index}>
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            key={ `clockwheelEntry-${clockwheelEntry.id}` }
                          >
                            <td className={cls.capitalize}>
                              {clockwheelEntry.attributes.media_type ? clockwheelEntry.attributes.media_type : (
                                <UikTag
                                  // color={(clockwheelEntry.category||{}).attributes.color}
                                  color={this.getCategoryColor(clockwheelEntry.relationships.category.data.id)}
                                  // color="#f1f1f1"
                                  fill
                                  // key={`cat-tag-${((clockwheelEntry.category||{}).attributes.name||'').replace(/ /gi, '')}`}
                                  // key={`cat-tag-${clockwheelEntry.category}`}
                                >
                                  {/* {(clockwheelEntry.category||{}).attributes.name} */}
                                  {this.getCategoryName(clockwheelEntry.relationships.category.data.id)}
                                </UikTag>
                              )}
                            </td>
                            <td className={cls.capitalize}>
                              {(clockwheelEntry.attributes.select_type||{}).replace(/_/gi, " ")}
                            </td>
                            <td>
                              <UikDropdown
                                DisplayComponent={
                                props => (
                                  <UikButton
                                    clear
                                    icon={ <Uikon>more</Uikon> }
                                    iconOnly
                                    {...props}
                                  />
                                )
                              }
                                position="bottomRight"
                              >
                                <UikDropdownItem onClick={deleteClockwheelEntry.bind(this, clockwheelEntry)}>
                                  Delete
                                </UikDropdownItem>
                              </UikDropdown>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { clockwheelEntries.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No Clockwheel entries found.</td></tr>
            )}
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default ClockwheelList


import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;
const MEDIA_ROOT_URL = `${configApp.MEDIAAPI_SERVER}/`;
const USER_ROOT_URL = `${configApp.USERAPI_SERVER}/api/v1/`;

export const requestUsers = () => ({
  type: 'REQUEST_USERS',
})

export const requestUser = () => ({
  type: 'REQUEST_USER',
})

export const receiveUsers = (json) => ({
  type: 'RECEIVE_USERS',
  users: json.data,
  meta: json.meta
})

export const receiveUser = (json) => ({
  type: 'RECEIVE_USER',
  user: json.attributes
})

export const requestStationUserRoles = () => ({
  type: 'REQUEST_STATION_USER_ROLES',
})

export const receiveStationUserRoles =  (json) => ({
  type: 'RECEIVE_STATION_USER_ROLES',
  payload: json
})

export const updateStationUserRole = () => ({
  type: 'UPDATE_STATION_USER_ROLE',
})

export const stationUserRoleUpdated = (json) => ({
  type: 'STATION_USER_ROLE_UPDATED',
  payload: json
})

export const requestUploadImage = () => ({
  type: 'REQUEST_UPLOAD_IMAGE',
})

export const imageUploaded = (json) => ({
  type: 'IMAGE_UPLOADED',
  payload: json
})

export const requestInvitations = () => ({
  type: 'REQUEST_INVITATIONS',
})

export const receiveInvitations = (json) => ({
  type: 'RECEIVE_INVITATIONS',
  payload: json
})

export const createInvitation = () => ({
  type: 'CREATE_INVITATION',
})

export const createInvitationSuccess =  (json) => ({
  type: 'CREATE_INVITATION_SUCCESS',
  payload: json
})

export function fetchUsers() {

  return function(dispatch) {

    dispatch(requestUsers())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(USER_ROOT_URL + `users/`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json => {
          if (json["errors"] && json["errors"].length){
            let msg = json["errors"][0]["detail"];
            toast.error(msg);
          } else {
            dispatch(receiveUsers(json))
          }
        }
      )
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchUser(userId) {

  return function(dispatch) {

    dispatch(requestUser())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(USER_ROOT_URL + `users/${userId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json =>{
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          json.data["id"] = userId;
          dispatch(receiveUser(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function uploadAvatar({file,mediaServiceUUID}) {

  return function(dispatch) {

    const bearerToken = ls.get('bearerToken') || null;

    dispatch(requestUploadImage());

    const formData = new FormData();

    formData.append('file', file);

    return fetch(`${MEDIA_ROOT_URL}upload/${mediaServiceUUID}/station_cover`, {
        method: "POST",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${bearerToken}`
        },
        body: formData,
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json =>{
        console.log('json',json)
        dispatch(imageUploaded(json.data))
        toast.success("Image Uploaded");
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateUser(data) {

  return function(dispatch) {

    const bearerToken = ls.get('bearerToken') || null;

    const userId = data.userId;

    delete data["userId"];
    delete data["type"];
    delete data["email"];
    delete data["gravatarHelpVisible"];
    delete data["is_email_verified"];

    let payLoad = {
      "data": {
        "type": "users",
        "id": userId,
        "attributes": data
      }
    }
    return fetch(USER_ROOT_URL + `users/${userId}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(payLoad), // data can be `string` or {object}!
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json =>{
        dispatch(receiveUser(json.data))
        toast.success("User updated !");
      }
      )
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchStationUserRoles({stationId}) {

  return function(dispatch) {

    dispatch(requestStationUserRoles())

    const bearerToken = ls.get('bearerToken') || null;

    let url = BROADCASTER_ROOT_URL + "stations-users/";
    if (stationId) {
      url += `?filter[station]=${stationId}`;
    }
    return fetch(url, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json =>{
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receiveStationUserRoles(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateUserRole(userRole) {

  return function(dispatch) {

    dispatch(updateStationUserRole())

    const bearerToken = ls.get('bearerToken') || null;

    let payLoad = {
      "data": {
        "type": "stations_users",
        "id": userRole.userId,
        "attributes": {
            "level": userRole.accessLevel
        }
      }
    }

    return fetch(`${BROADCASTER_ROOT_URL}stations-users/${userRole.userId}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(payLoad)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json =>{
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(stationUserRoleUpdated(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchInvitations({stationId}) {

  return function(dispatch) {

    dispatch(requestInvitations())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}invitations/?filter[station]=${stationId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json =>
        {
          dispatch(receiveInvitations(json.data))}
      )
      .catch(err => {
        console.error(err);
      });
  }
}

export function sendInvitation({stationId, invitationEmail, invitationRole}) {

  return function(dispatch) {

    dispatch(createInvitation())

    const bearerToken = ls.get('bearerToken') || null;

    let payLoad = {
       "data": {
            "type": "invitations",
            "attributes": {
                "user_email": invitationEmail,
                "access_level": invitationRole
            },
            "relationships": {
                "station": {
                    "data": {
                        "type": "stations",
                        "id": stationId + ""
                    }
                }
            }
        }
    };

    return fetch(`${BROADCASTER_ROOT_URL}invitations/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(payLoad), // data can be `string` or {object}!
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json =>{
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(createInvitationSuccess(json.data))
          toast.success("Invitation have been sent !");
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}



import * as React from 'react'
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Divider from '@material-ui/core/Divider'
import { connect } from 'react-redux'
import * as packagesActions from '@redux/packages/actions'
import classnames from 'classnames'

/* eslint-disable max-len */

import {
  UikButton,
} from '@components'

import cls from './onboarding-modals.scss'

// @flow

class ChoosePackageModal extends React.Component<{
}> {
  constructor(props) {
    super(props);

    this.state = {
    }

    this.props.fetchPackages();
  }

  handleStripeCardResult = (event) => {
  }

  handlePackageSelectClick = (pck) => {
    this.props.onPackageSelect(pck);
  }

  render() {
    const { modalOpen, packages} = this.props;

    return (
      <Dialog
        open={ modalOpen }
        onClose={ this.props.onModalClose }
        scroll={'paper'}
      >
        <DialogTitle>
            Choose Package
        </DialogTitle>
        <Divider style={{marginBottom: '15px'}}></Divider>
        <DialogContent>
            <table className={ classnames(cls.table, cls.packageTable) }>
              <thead>
                <tr>
                  <th>
                    Package
                  </th>
                  <th>
                    TLH
                  </th>
                  <th>
                    Storage
                  </th>
                  <th className={cls.rightAligned}>
                    Monthly Price
                  </th>
                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  packages.map(pck => (
                    <tr key={`pck-${pck.id}`}>
                      <td>{pck["attributes"].name}</td>
                      <td>{pck["attributes"].tlh}</td>
                      <td>{bytesToSize(pck["attributes"].bytes_available)}</td>
                      <td className={cls.rightAligned}>${(pck["attributes"].price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                      <td>
                        <UikButton primary onClick={this.handlePackageSelectClick.bind(this,pck)}>Select</UikButton>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <UikButton error onClick={this.props.onCancel}>Cancel</UikButton>
        </DialogActions>
      </Dialog>
    )
  }
}

function bytesToSize(bytes) {
   var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
   if (bytes === 0) return '0 Byte';
   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
   return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export default connect(
  store => ({
    packages: store.packages.packagesList.packages
  }),
  {
    fetchPackages: packagesActions.fetchPackages,
  },
)(ChoosePackageModal)


import * as React from 'react'
import classnames from 'classnames'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetTable,
  UikCheckbox,
  UikFormInputGroup,
  UikSelect,
  UikTag,
} from '@components'

import Select from 'react-select';
import cls from '../media.scss'
import tableCls from './multi-select-table.scss'
import { Link } from 'react-router-dom'

// @flow

class CategorizeTrackList extends React.Component<{}> {
  constructor(props) {
    super(props);

    let rowChecked = [];
    for(var i = 0; i < this.props.tracks.length; i++) {
      rowChecked[i] = true;
    }

    this.state = {
      stationId: this.props.stationId,
      rowChecked: rowChecked,
      checkAll: true,
      selectedType: '',
      trackFiles: this.props.tracks,
      activeTrack: {}
    };

    this.checkAll = this.checkAll.bind(this);
    this.checkRow = this.checkRow.bind(this);
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    this.handleCategoriesSelect = this.handleCategoriesSelect.bind(this);
  }

  checkRow = (id) => {
    let rowChecked = this.state.rowChecked;
    rowChecked[id] = !this.state.rowChecked[id];
    this.setState({
      rowChecked: this.state.rowChecked,
      checkAll: this.state.rowChecked.reduce((sum, next) => sum && next, true)
    });
  }

  checkAll = () => {
    this.setState({
      rowChecked: this.state.rowChecked.map(r => { return !this.state.checkAll }),
      checkAll: !this.state.checkAll
    });
  }

  handleTypeSelect = (data) => {
    let trackFiles = this.state.trackFiles
    for (var i = this.state.rowChecked.length - 1; i >= 0; i--) {
      if (this.state.rowChecked[i]) {
        trackFiles[i]["file"]["media_type"] = data.value;
      }
    }
    this.setState({trackFiles})
    this.props.onCategorize(this.state.trackFiles);
  }

  handleCategoriesSelect(e){
    // populate categories...
    let newCats = (e||[]).map(selCat => {
      let newCat = (this.props.categories.find(cat => cat.id === selCat.value)||{})["attributes"] || {};
      newCat.id = selCat.value;
      return newCat;
    })

    let trackFiles = this.state.trackFiles
    for (var i = this.state.rowChecked.length - 1; i >= 0; i--) {
      if (this.state.rowChecked[i]) {
        trackFiles[i]["file"]["categories"] = newCats;
      }
    }
    this.setState({trackFiles})
    this.props.onCategorize(this.state.trackFiles);
  }

  render() {
    const { trackFiles } = this.state;
    const { categories } = this.props;
    const categoriesOptions = categories.map(cat => {return {"value": cat["id"], "label": cat["attributes"]["name"]}});

    const mediaTypeOptions = [{
        value: 'music',
        label: 'Music (music and copyright material)'
      },
      {
        value: 'talk',
        label: 'Talk (sermons, speeches, and live recordings)'
      },
      {
        value: 'id',
        label: 'ID (station identification such as sweepers and jingles)'
      },
      {
        value: 'promo',
        label: 'Promo (station promotion that is not considered an ID)'
      }
    ];

    return (
      <div>
        <p>Track type is required to comply with music copyright laws.</p>
        <p>Categories are an optional way to easily group your tracks to Playlists and ClockWheels.</p>
        <div className={ cls.categorizeForm }>
          <UikFormInputGroup>
            <span className="uik-content-title__wrapper">Type</span>
            <UikSelect
              placeholder="Check track then set Type"
              className={ cls.categorizeSelect }
              onChange={ this.handleTypeSelect }
              options={ mediaTypeOptions }
            />
          </UikFormInputGroup>
          <UikFormInputGroup>
            <span className="uik-content-title__wrapper">Categories</span>
            { categoriesOptions.length > 0 && (
              <Select
                isMulti
                name="categories"
                placeholder="Select 1 or more categories"
                options={ categoriesOptions }
                className={ classnames("multi-select", cls.multiSelect) }
                classNamePrefix="select"
                onChange={ this.handleCategoriesSelect }
              />
            )}
            { categoriesOptions.length === 0 && (
              <small>You have no categories set up, create one <Link to={`/stations/${this.state.stationId}/media/categories`}>here</Link></small>
            )}
          </UikFormInputGroup>
        </div>
        <UikWidget className={ tableCls.tableScroll }>
          <UikWidgetTable className={tableCls.multiSelectTable}>
            <thead>
              <tr>
                <th>
                  <UikCheckbox
                    name="selectAllUsers"
                    onChange={this.checkAll}
                    checked={this.state.checkAll}
                  />
                </th>
                <th>
                </th>
                <th>
                  Filename
                </th>
                <th>
                  Type
                </th>
                <th>
                  Category
                </th>
              </tr>
            </thead>
            <tbody>
              {
                trackFiles.map((track, index) => (
                  <tr key={ Math.random() /* pls don't do this in prod */ }>
                    <td key={ Math.random() }>
                      <UikCheckbox
                        name={`track-${track.id}`}
                        onChange={this.checkRow.bind(this, index)}
                        checked={this.state.rowChecked[index]}
                      />
                    </td>
                    <td>
                      <div className={ cls.trackImg }>
                        <img alt="" src={ track.imgUrl ? track.imgUrl : '/img/song_placeholder.png' } />
                        <i className="fas fa-play"></i>
                      </div>
                    </td>
                    <td>
                      { track.title }
                    </td>
                    <td>
                      { track["file"]["media_type"] ?
                         mediaTypeOptions.find((m) => m.value === track["file"]["media_type"])["label"].split(' ')[0]
                       : (<i className="fas fa-question" style={{"opacity": 0.3}}></i>)}
                    </td>
                    <td>
                      { track["file"].hasOwnProperty("categories") && track["file"]["categories"].length > 0 ?
                        track["file"]["categories"].map(cat => (
                          <UikTag
                            color={(cat||{}).color}
                            fill
                            key={`cat-tag-${((cat||{}).name||'').replace(/ /gi, '')}`}
                          >
                            {((cat||{}).name||'')}
                          </UikTag>
                        ))
                        : (<i className="fas fa-question" style={{"opacity": 0.3}}></i>)}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </UikWidgetTable>
        </UikWidget>
      </div>
    )
  }
}

export default CategorizeTrackList

import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { fetchServices } from '../services/actions'
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;
const MEDIA_ROOT_URL = `${configApp.MEDIAAPI_SERVER}/`;

export const requestStations = () => ({
  type: 'REQUEST_STATIONS',
})

export const receiveStations = (json, meta) => ({
  type: 'RECEIVE_STATIONS',
  payload: json,
  meta: meta
})

export const requestStation = () => ({
  type: 'REQUEST_STATION',
})

export const receiveStation = (json) => ({
  type: 'RECEIVE_STATION',
  payload: json,
})

export const setStationPolling = (payload) => ({
  type: 'SET_STATION_POLLING',
  payload: payload,
})

export const requestStationService = () => ({
  type: 'REQUEST_STATION_SERVICE',
})

export const receiveStationService = (json) => ({
  type: 'RECEIVE_STATION_SERVICE',
  payload: json,
})

export const requestUpdateStation = () => ({
  type: 'REQUEST_UPDATE_STATION',
})

export const stationUpdated = (json) => ({
  type: 'STATION_UPDATED',
  payload: json,
})

export const requestUploadImage = () => ({
  type: 'REQUEST_UPLOAD_IMAGE',
})

export const imageUploaded = (json) => ({
  type: 'IMAGE_UPLOADED',
  payload: json
})

export const resetGenres = () => ({
  type: 'RESET_GENRES'
})

export const requestGenres = () => ({
  type: 'REQUEST_GENRES',
})

export const receiveGenres = (json) => ({
  type: 'RECEIVE_GENRES',
  payload: json
})

export function fetchStations({perPage, page, query, mountId}) {

  perPage = perPage || 20;
  page = page || 1;

  return function(dispatch) {

    dispatch(requestStations())

    const bearerToken = ls.get('bearerToken');

    let url = `${BROADCASTER_ROOT_URL}stations/?page[size]=${perPage}&page[number]=${page}`;

    if (query){
      url += `&filter[search]=${query}`;
    } else if(mountId){
      url += `&filter[mount_id]=${mountId}`;
    }

    return fetch(url, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveStations(json.data, json.meta))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchStation(stationId) {

  return function(dispatch) {

    dispatch(requestStation())

    const bearerToken = ls.get('bearerToken');

    return fetch(`${BROADCASTER_ROOT_URL}stations/${stationId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receiveStation(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchStationService(stationId) {

  return function(dispatch) {

    dispatch(requestStationService())

    const bearerToken = ls.get('bearerToken');

    return fetch(`${BROADCASTER_ROOT_URL}stations/${stationId}/service/`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receiveStationService(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function createStation({stationAttributes, packageId, userId}) {

  return function(dispatch) {

    toast.success(`Creating ${stationAttributes.name} station...`);

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "services",
        "relationships": {
            "user": {
                "data": {
                    "type": "users",
                    "id": userId +""
                }
            },
            "package": {
                "data": {
                    "type": "packages",
                    "id": packageId +""
                }
            }
        }
      }
    };

    // first create SERVICE
    return fetch(`${BROADCASTER_ROOT_URL}services/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Service has been created !");
          ls.set('onboarding', false);

          // Because there are multiple fields that may fail,
          // we're making individual requests for each attribute
          let stationId = json.data.relationships.station.data.id;

          if (stationAttributes["name"]) {
            dispatch(updateStation({
              "id":  stationId,
              "attributes": {"name": stationAttributes["name"]}
            }));
          }

          if (stationAttributes["genres"]) {
            dispatch(updateStation({
              "id":  stationId,
              "attributes": {"genres": stationAttributes["genres"]}
            }));
          }

          if (stationAttributes["description"]) {
            dispatch(updateStation({
              "id":  stationId,
              "attributes": {"description": stationAttributes["description"]}
            }));
          }

          if (stationAttributes["website"]) {
            dispatch(updateStation({
              "id":  stationId,
              "attributes": {"website": stationAttributes["website"]}
            }));
          }

          if (stationAttributes["facebook_url"]) {
            dispatch(updateStation({
              "id":  stationId,
              "attributes": {"facebook_url": stationAttributes["facebook_url"]}
            }));
          }

          if (stationAttributes["twitter_url"]) {
            dispatch(updateStation({
              "id":  stationId,
              "attributes": {"twitter_url": stationAttributes["twitter_url"]}
            }));
          }

          if (stationAttributes["instagram_url"]) {
            dispatch(updateStation({
              "id":  stationId,
              "attributes": {"instagram_url": stationAttributes["instagram_url"]}
            }));
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateStation(station) {

  return function(dispatch) {

    dispatch(requestUpdateStation())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "stations",
        "id": station.id + "",
        "attributes": station.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}stations/${station.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Station updated !");
          dispatch(stationUpdated(json.data))
          // refetch services... improve this later... v1.1
          dispatch(fetchServices());
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function uploadImage({stationId, mediaServiceUUID, imageType, file}) {

  return function(dispatch) {

    const bearerToken = ls.get('bearerToken') || null;

    dispatch(requestUploadImage());

    const formData = new FormData();
    formData.append('file', file);

    return fetch(`${MEDIA_ROOT_URL}upload/${mediaServiceUUID}/${imageType}`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${bearerToken}`
      },
      body: formData,
    })
    .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
      return res.json();
    })
    .then(json =>{
      if (json["errors"] && json["errors"].length){
        let msg = json["errors"][0]["detail"];
        toast.error(msg);
      } else {
        toast.success("Image Uploaded");
        dispatch(imageUploaded(json.data))
        dispatch(fetchStation(stationId))
      }
    })
    .catch(err => {
      console.error(err);
    });
  }
}

function getGenres(dispatch, page) {
  let url = `${BROADCASTER_ROOT_URL}genres/?page[number]=${page}`;
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      }
    }).then(response => {
      if (response.status === 401) {
        dispatch(push('/'));
        return Promise.resolve();
      }

      return response.json();
    }).then(response => {
      dispatch(receiveGenres(response));

      if (response['links'] && response['links']['next']) {
        getGenres(dispatch, page + 1).then(() => Promise.resolve());
      }

      return true;
    })
}

export function fetchGenres() {

  return function(dispatch) {
    dispatch(resetGenres())
    getGenres(dispatch, 1).then(() => Promise.resolve());
  }
}

export function setPolling({stationId, intervalId}) {

  return function(dispatch) {

    dispatch(setStationPolling({stationId, intervalId}))

  }
}

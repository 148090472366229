export default {
  tracksList: {tracks: [], error:null, loading: false},
  playlistTracksList: {tracks: [], error:null, loading: false},
  newTrack:{track:null, error: null, loading: false},
  activeTrack:{track:null, error:null, loading: false},
  deletedTrack: {track: null, error:null, loading: false},
  playedQueue: {queue: null, error:null, loading: false},
  playing: {},
  upcomingQueue: {queue: null, error:null, loading: false},
  allCategories: {categories: [], error: null, loading: false},
}

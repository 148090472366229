import initialState from './initialState'

import {
  FETCH_EVENTS, FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE, RESET_EVENTS,
  UPDATE_EVENT, UPDATE_EVENT_SUCCESS,  UPDATE_EVENT_FAILURE,
  FETCH_EVENT, FETCH_EVENT_SUCCESS,  FETCH_EVENT_FAILURE, RESET_ACTIVE_EVENT,
  CREATE_EVENT, CREATE_EVENT_SUCCESS, CREATE_EVENT_FAILURE, RESET_NEW_EVENT,
  DELETE_EVENT, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE, RESET_DELETED_EVENT,
  FETCH_ACTIVE_EVENT_TRACKS, FETCH_ACTIVE_EVENT_TRACKS_SUCCESS, FETCH_ACTIVE_EVENT_TRACKS_FAILURE, RESET_ACTIVE_EVENT_TRACKS,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_EVENTS:// start fetching event and set loading = true
    return { ...state, eventsList: {events:[], error: null, loading: true} };
  case FETCH_EVENTS_SUCCESS:
    return { ...state, eventsList: {events: action.payload, error:null, loading: false} };
  case FETCH_EVENTS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.message};//2nd one is network or server down errors
    return { ...state, eventsList: {events: [], error: error, loading: false} };
  case RESET_EVENTS:// reset eventList to initial state
    return { ...state, eventsList: {events: [], error:null, loading: false} };

  case FETCH_EVENT:
    return { ...state, activeEvent:{...state.activeEvent, loading: true}};
  case FETCH_EVENT_SUCCESS:
    return { ...state, activeEvent: {event: action.payload, error:null, loading: false}};
  case FETCH_EVENT_FAILURE:
    error = action.payload || {message: action.message};//2nd one is network or server down errors
    return { ...state, activeEvent: {event: null, error:error, loading:false}};
  case RESET_ACTIVE_EVENT:
    return { ...state, activeEvent: {event: null, error:null, loading: false}};

  case UPDATE_EVENT:
    return { ...state, activeEvent:{...state.activeEvent, loading: true}, eventsList: {...state.eventsList, loading: true}};
  case UPDATE_EVENT_SUCCESS:
    let events = state.eventsList.events;
    events[events.findIndex(el => el.id === action.payload.id)] = action.payload;
    return { ...state, eventsList: {events: events, error: null, loading: false}, activeEvent: {event: action.payload, error:null, loading: false}};
  case UPDATE_EVENT_FAILURE:
    error = action.payload || {message: action.message};//2nd one is network or server down errors
    return { ...state, activeEvent: {event: null, error:error, loading:false}};

  case CREATE_EVENT:
    return {...state, newEvent: {...state.newEvent, loading: true}, eventsList: {...state.eventsList, loading: true}}
  case CREATE_EVENT_SUCCESS:
    let newEvents = state.eventsList.events.slice();
    newEvents.push(action.payload);
    return {...state,
      eventsList: {
        events: newEvents,
        loading: false
      },
      newEvent: {
        event:action.payload,
        error:null,
        loading: false
      }
    }
  case CREATE_EVENT_FAILURE:
    error = action.payload || {message: action.message};//2nd one is network or server down errors
    return {...state, newEvent: {event:null, error:error, loading: false}}
  case RESET_NEW_EVENT:
    return {...state,  newEvent:{event:null, error:null, loading: false}}

  case DELETE_EVENT:
     return {...state, deletedEvent: {...state.deletedEvent, loading: true}, eventsList: {...state.eventsList, loading: true}}
  case DELETE_EVENT_SUCCESS:
    newEvents = state.eventsList.events.filter(item => {
      return item.id !== action.event.id // return all the items not matching the action.id
    })
    return {...state, eventsList: { events: newEvents, loading: false}, deletedEvent: {event:action.payload, error:null, loading: false}}
  case DELETE_EVENT_FAILURE:
    error = action.payload || {message: action.message};//2nd one is network or server down errors
    return {...state, deletedEvent: {event:null, error:error, loading: false}}
  case RESET_DELETED_EVENT:
    return {...state,  deletedEvent:{event:null, error:null, loading: false}}

  case FETCH_ACTIVE_EVENT_TRACKS:
    return {...state, activeEventTracks: {tracksList: [], error: null, loading: true}}
  case FETCH_ACTIVE_EVENT_TRACKS_SUCCESS:
    return {...state, activeEventTracks: {tracksList: [...state.activeEventTracks.tracksList, ...action.payload], error: null, loading: false}}
  case FETCH_ACTIVE_EVENT_TRACKS_FAILURE:
    return {...state, activeEventTracks: {tracksList: [], error: action.payload, loading: false}}
  case RESET_ACTIVE_EVENT_TRACKS:
    return {...state, activeEventTracks: {tracksList: [], error: null, loading: false}}
  default:
    return state;
  }
}

import * as React from 'react'
import { connect } from 'react-redux'
import * as actions from '@redux/restrictions/actions'
import * as configApp from '@redux/constants'
import Select from 'react-select';
import classnames from 'classnames'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetContent,
  UikSelect,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidgetContainer,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './settings.scss'
import countriesOptions from './assets/countries'

// @flow

class RestrictionsPage extends React.Component<{
}> {
  constructor(props) {
    super(props);

    this.state = {
      restriction: {},
      stationId: this.props.match.params.stationId,
      banIp: '',
    };

    this.props.fetchRestrictions( this.state.stationId);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.restrictions && this.props.restrictions.length && prevProps.restrictions !== this.props.restrictions){
      this.setState({restriction: this.props.restrictions[0]});
    }
  }

  handleEditChange(event) {
    let newState = {};
    if (event.target.name.indexOf("|") > -1){
      let restriction = Object.assign({}, this.state.restriction);
      let attrs = event.target.name.split("|");
      restriction[attrs[0]][attrs[1]] = event.target.value;
      this.setState(restriction);
    } else {
      newState[event.target.name] = event.target.value;
      this.setState(newState);
    }
  }

  handleSelectChange(name, event) {
    let newState = {};
    if (name.indexOf("|") > -1){
      let restriction = Object.assign({}, this.state.restriction);
      let attrs = name.split("|");
      if (attrs[1] === "session_limit"){
        restriction[attrs[0]][attrs[1]] = event.value * configApp.MILLISECONDS_CONVERSION_HOURS;
      } else {
        restriction[attrs[0]][attrs[1]] = event.value;
      }

      // custom defaults
      if (attrs[1] === 'geoblock_action'){
        let geoblock_countries = [];
        console.log('event.value',event.value);
        if(event.value === 'allow'){
          geoblock_countries = [ 'US', 'GB', 'CA' ];
        }
        console.log('86',geoblock_countries)
        restriction[attrs[0]]["geoblock_countries"] = geoblock_countries;
        console.log(restriction);
      }
      this.setState(restriction);
    } else {
      newState[name] = event.value;
      this.setState(newState);
    }
  }

  handleAddToBanList() {
    if((((this.state.restriction||{})["attributes"]||{})["banned_ips"]||[]).indexOf(this.state.banIp) === -1){
      let restriction = Object.assign({}, this.state.restriction);
      (restriction["attributes"]||[])["banned_ips"].push(this.state.banIp);
      this.setState({banIp: '', restriction});
      this.props.updateRestriction(this.state.restriction);
    }
  }

  handleCountriesSelectChange(countries){
    let restriction = Object.assign({}, this.state.restriction);
    restriction["attributes"]["geoblock_countries"] = (countries||[]).map(cunt => cunt.value);
    this.setState(restriction);
  }

  handleSaveGeoBlocking() {
    this.props.updateRestriction(this.state.restriction);
  }

  handleUnbanIpClick(unbanIp){
    let restriction = Object.assign({}, this.state.restriction);
    (restriction["attributes"]||[])["banned_ips"].splice( restriction["attributes"]["banned_ips"].indexOf(unbanIp), 1);
    this.setState({banIp: '', restriction});
    this.props.updateRestriction(this.state.restriction);
  }

  handleSaveRestriction(){
    this.props.updateRestriction(this.state.restriction);
  }

  render() {
    const {
      banIp,
    } = this.state;

    let restriction = this.state.restriction || {};
    let banned_ips = (restriction["attributes"]||{})["banned_ips"] || [];

    let session_limit = (restriction["attributes"]||{})["session_limit"];

    let geoblock_countries = ((restriction["attributes"]||{})["geoblock_countries"]||[]).map(code => {
      for (var i = 0; i < countriesOptions.length; ++i) {
        if (countriesOptions[i].value === code){
          return countriesOptions[i];
        }
      }
    });

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar
          title={ "Settings > Restrictions"}
        />
        <UikLayoutMain>
          <div className={ cls.vertSplitContent }>
            <UikWidgetContainer className={ cls.rowWidget } style={{ 'width': '33%' }}>
              <UikWidget>
                <UikWidgetContent>
                  <h3>Geo-Blocking</h3>
                  <p>Geo-Blocking allows you to restrict access to your station in certain countries. With this feature, you can block specific countries, or allow from only certain countries. Note: There are no “safe” countries when you choose the “allow” method, you must specify all countries that you want to access your stream.</p>
                  <UikDivider />
                  <UikFormInputGroup>
                    { ((restriction||{})["attributes"]||{}).geoblock_action && (
                      <UikFormInputGroup horizontal>
                        <UikSelect
                          defaultValue={ [restriction["attributes"].geoblock_action] }
                          label="I want to"
                          options={ [
                            {
                              value: 'block',
                              label: (
                                <span>
                                  Block
                                </span>
                              ),
                            },
                            {
                              value: 'allow',
                              label: (
                                <span>
                                  Allow
                                </span>
                              ),
                            },
                          ] }
                          placeholder="Action"
                          position="bottomRight"
                          onChange={ this.handleSelectChange.bind(this, 'attributes|geoblock_action') }
                        />
                      </UikFormInputGroup>
                    )}
                    <div>
                      <UikFormInputGroup horizontal>
                        <label className="uik-content-title__wrapper">Countries</label>
                      </UikFormInputGroup>
                      <Select
                        isMulti
                        defaultValue={geoblock_countries}
                        value={geoblock_countries}
                        label="Countries"
                        name="categories"
                        options={countriesOptions}
                        className="multi-select"
                        classNamePrefix="select"
                        onChange={ this.handleCountriesSelectChange.bind(this) }
                      />
                    </div>
                    <UikDivider />
                    <UikButton primary className={ cls.sideButton2 } onClick={this.handleSaveGeoBlocking.bind(this)}>Save</UikButton>
                  </UikFormInputGroup>
                </UikWidgetContent>
              </UikWidget>
            </UikWidgetContainer>
            <UikWidgetContainer className={ cls.rowWidget } style={{ 'width': '33%' }}>
              <UikWidget>
                <UikWidgetContent>
                  <h3>IP Bans</h3>
                  <p><b>IP Bans will allow you to block a specific listener from connecting to a stream.</b></p>
                  <p>This ban will last forever, unless you remove it. Note that by banning a listener, it will not automatically remove them from the stream. The removal and ban will take place when their session expires, typically within a few hours depending on your configuration.</p>
                  <UikDivider />
                  <UikFormInputGroup direction="horizontal">
                    <UikInput
                      defaultValue="IP Address"
                      label="Ban IP"
                      name="banIp"
                      onChange={this.handleEditChange}
                      value={banIp}
                    />
                    <UikButton primary className={ cls.sideButton } onClick={this.handleAddToBanList.bind(this)}>Add to ban list</UikButton>
                  </UikFormInputGroup>
                </UikWidgetContent>
                { (banned_ips||[]).length > 0 &&
                  <UikWidgetContent>
                    <ul className={ cls.ipList }>
                      { (banned_ips||[]).map(ip => (
                        <li key={`ip-${ip}`}>
                          <span className={ cls.ip }>{ip}</span>
                          <i
                            className={ classnames(cls.unbanIpBtn, "fas fa-times")}
                            onClick={this.handleUnbanIpClick.bind(this, ip)}
                          >
                          </i>
                        </li>
                      ))}
                    </ul>
                  </UikWidgetContent>
                }
              </UikWidget>
            </UikWidgetContainer>
            <UikWidgetContainer className={ cls.rowWidget } style={{ 'width': '33%' }} >
              <UikWidget>
                <UikWidgetContent>
                  <h3>
                    Session duration
                  </h3>
                  <p><b>Session durations allow you to limit how long a listener can stay connected.</b></p>
                  <p>
                    For example, if you have 4 hours selected, then after 4 hours the user will be disconnected. The default value is 6, with a maximum of 24 hours. Having a lower value will help decrease your monthly TLH usage.
                  </p>
                  <UikDivider />
                  <UikFormInputGroup horizontal>
                    { session_limit &&
                        <UikSelect
                        defaultValue={ [session_limit / configApp.MILLISECONDS_CONVERSION_HOURS] }
                        label="Session Duration"
                        options={ [
                          {
                            value: 2,
                            label: '2 Hours'
                          },
                          {
                            value: 4,
                            label: '4 Hours'
                          },
                          {
                            value: 6,
                            label: '6 Hours'
                          },
                          {
                            value: 8,
                            label: '8 Hours'
                          },
                          {
                            value: 12,
                            label: '12 Hours'
                          },
                          {
                            value: 24,
                            label: '24 Hours'
                          }
                        ] }
                        placeholder="Session Duration"
                        position="bottomRight"
                        onChange={ this.handleSelectChange.bind(this, 'attributes|session_limit') }
                      />
                    }
                    <UikButton primary className={ cls.sideButton2 } onClick={this.handleSaveRestriction.bind(this)}>Save</UikButton>
                  </UikFormInputGroup>
                </UikWidgetContent>
              </UikWidget>
            </UikWidgetContainer>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    restrictions: store.restrictions.restrictions.restrictions,
  }),
  {
    fetchRestrictions: actions.fetchRestrictions,
    updateRestriction: actions.updateRestriction,
  },
)(RestrictionsPage)

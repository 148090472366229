import * as React from 'react'
import { connect } from 'react-redux'

import ls from 'local-storage'

/* eslint-disable max-len */

import * as stationActions from '@redux/stations/actions'

import NewStationModal from './NewStationModal'
import ChoosePackageModal from './ChoosePackageModal'
import StripeCardModal from '../../pages/Billing/components/StripeCardModal'

// @flow

class OnboardingModals extends React.Component<{
}> {
  constructor(props) {
    super(props);

    this.state = {
      choosePackageModalOpen: ls.get('onboarding') || false,
      stripeCardModalOpen: false,
      newStationModalOpen: false,
    }

    this.handlePackageSelect = this.handlePackageSelect.bind(this);
    this.handleStripeCardModalClose = this.handleStripeCardModalClose.bind(this);
    this.handleStripeCardModalSuccess = this.handleStripeCardModalSuccess.bind(this);
    this.handleNewStationSuccess = this.handleNewStationSuccess.bind(this);
    this.handleNewStationModalClose = this.handleNewStationModalClose.bind(this);
  }

  onCancel = () => {
    this.setState({
      choosePackageModalOpen: false,
      stripeCardModalOpen: false,
      newStationModalOpen: false
    });
  }

  handlePackageSelect = (pck) =>{
    let newState = {
      choosePackageModalOpen: false,
      selectedPackage: pck
    }
    if(pck["attributes"]["name"].toLowerCase().includes("trial")) {
      newState["newStationModalOpen"] = true;
    } else {
      newState["stripeCardModalOpen"] = true;
    }
    this.setState(newState);
  }

  handleStripeCardModalClose = () => {
    this.setState({stripeCardModalOpen: false})
  }

  handleStripeCardModalSuccess = () => {
    this.setState({stripeCardModalOpen: false, newStationModalOpen: true})
  }

  handleNewStationModalClose = () => {
    this.setState({newStationModalOpen: false})
  }

  handleNewStationSuccess = () => {
    this.setState({newStationModalOpen: false})
  }

  render() {
    const {
      choosePackageModalOpen,
      stripeCardModalOpen,
      newStationModalOpen,
      selectedPackage
    } = this.state;

    return (
      <div>
        <ChoosePackageModal
          modalOpen={choosePackageModalOpen}
          onPackageSelect={this.handlePackageSelect}
          onCancel={this.onCancel}
        />
        <StripeCardModal
          addMode={true}
          modalOpen={stripeCardModalOpen}
          onModalClose={this.handleStripeCardModalClose}
          onSuccess={this.handleStripeCardModalSuccess}
        />
        <NewStationModal
          modalOpen={newStationModalOpen}
          onSuccess={this.handleNewStationSuccess}
          package={selectedPackage}
          onModalClose={this.handleNewStationModalClose}
          onCancel={this.onCancel}
        />
      </div>
    )
  }
}

export default connect(
  store => ({
  }),
  {
    createStation: stationActions.createStation,
  },
)(OnboardingModals)

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames'
import * as actions from '@redux/playlists/actions'
import * as trackActions from '@redux/tracks/actions'
import Pagination from '../../../../@components/Pagination'
import * as configApp from '@redux/constants'
import * as categoriesActions from '@redux/categories/actions'
import PacmanLoader from '../../../../@components/PacmanLoader'

/* eslint-disable max-len */

import { connect } from 'react-redux'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikButton,
  UikWidgetContainer,
  UikSelect,
  UikWidget,
  UikAvatar,
  UikWidgetTable,
  UikTagContainer,
  UikTag,
} from '@components'

import TopBar from '../../../../@components/TopBar'
import SearchInput from '../../../../@components/SearchInput'

import cls from './playlists.scss'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = list.slice();
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: "white",
    border: isDragging ? "2px solid orange" : "none",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(246, 171, 47, 0.1)' : 'f'
});


// @flow
type PlaylistProps = {
  playlists: Array,
  tracks: Array,
  fetchPlaylist: Function,
  fetchTracks: Function,
}

class PlaylistPage extends React.Component<PlaylistProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      playlistId: this.props.match.params.playlistId,
      addMode: true,
      editPlaylistModalOpen: false,
      deletePlaylistModalOpen: false,
      activePlaylist: this.props.playlist || {},
      playlistTrackIds: [],
      playlistTracks: [],
      searchQuery: '',
      perPage: 30,
      page: 1,
      selectedTrack: null,
      playerState: "stopped",
      showFilters: false,
      sortBy: 'title',
      filters: {"media_type": '', "category": ''},
    };

    this.handleFiltersClick = this.handleFiltersClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleperPageChange = this.handleperPageChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.props.fetchTracks({stationId: this.state.stationId});
    this.props.fetchPlaylistTracks({stationId: this.state.stationId, playlistId: this.state.playlistId});
    this.props.fetchCategories(this.state.stationId);
    this.props.fetchPlaylist(this.state.playlistId);
  };

  onDragEnd = result => {
      const { source, destination } = result;

      // dropped outside the list or dropped on all tracks - exit
      if (!destination || destination.droppableId === 'droppable') {
          return;
      }

      // reorder playlist, not all tracks...
      if (source.droppableId === destination.droppableId && source.droppableId === 'droppable2') {
          const items = reorder(
              this.state.playlistTracks,
              source.index,
              destination.index
          );

          this.setState({ playlistTracks: items });
      }

      if (source.droppableId !== destination.droppableId && source.droppableId === 'droppable') {
          const destClone = this.state.playlistTracks.slice();

          destClone.splice(destination.index, 0, this.props.tracks[source.index]);

          this.setState({
              playlistTracks: destClone
          });
      }
  };

  playTrack(fileName){
    if ( this.state.playerState === "playing" && this.state.selectedTrack === fileName) {
      this.player.pause()
      this.setState({playerState: "stopped"})
      this.player.src = '';
    } else {
      this.player.src = mediaStageBaseUrl + "/download/" + fileName;
      this.player.play()
      this.setState({playerState: "playing", selectedTrack: fileName})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const newState = {
      activePlaylist: this.state.activePlaylist,
      playlistTracks: this.state.playlistTracks
    }

    // console.log('*************************************')

    // console.log('prevProps.playlist', prevProps.playlist);
    // console.log('this.props.playlist', this.props.playlist);
    // console.log('prevProps.playlistTracks', prevProps.playlistTracks);
    // console.log('this.props.playlistTracks', this.props.playlistTracks);

    // This whole tangled thing is supposed to avoid a race condition.
    // We can't rely on activePlaylist and playlistTracks to be available at the same time.
    // But when we have both of them (received via props), we need to preserve the tracks' order
    // described by activePlaylist.tracks, and make sure we duplicate tracks if necessary.

    let shouldUpdateActivePlaylist = false;
    if (prevProps.playlist !== this.props.playlist) {
      if (!prevProps.playlist) {
        shouldUpdateActivePlaylist = true;
      } else if (prevProps.playlist && this.props.playlist && prevProps.playlist.id !== this.props.playlist.id) {
        shouldUpdateActivePlaylist = true;
      }
    }

    if (shouldUpdateActivePlaylist) {
      newState.activePlaylist = this.props.playlist;
    }

    let shouldUpdatePlaylistTracks = false;
    if (prevProps.playlistTracks !== this.props.playlistTracks) {
      if (!prevProps.playlistTracks) {
        shouldUpdatePlaylistTracks = true;
      } else if (prevProps.playlistTracks && this.props.playlistTracks && prevProps.playlistTracks.length !== this.props.playlistTracks.length) {
        shouldUpdatePlaylistTracks = true;
      }
    }

    if (shouldUpdatePlaylistTracks) {
      newState.playlistTracks = this.props.playlistTracks;
    }

    // State is updated only when new props are received.
    // This is how we know the active playlist and the tracks that make up that playlist.
    let shouldUpdateState = shouldUpdateActivePlaylist || shouldUpdatePlaylistTracks;

    if (shouldUpdateState) {
      const hasActivePlaylist = newState.activePlaylist && newState.activePlaylist.id;
      const hasPlaylistTracks = newState.playlistTracks && newState.playlistTracks.length !== 0;

      if (hasActivePlaylist && hasPlaylistTracks) {
        const playlistTracks = [];

        newState.activePlaylist.attributes.tracks.forEach(trackId => {
          const track = this.props.playlistTracks.find(track => track.id === trackId);

          if (track) {
            playlistTracks.push(track);
          }
        });

        newState.playlistTracks = playlistTracks;
      }

      this.setState(newState);
    }
  }

  handleSavePlaylistClick = () => {
    let playlist = Object.assign({},this.state.activePlaylist);
    playlist.attributes.tracks = this.state.playlistTracks.map(t => t.id);
    this.props.updatePlaylist(playlist);
  };

  handleAddTrackToPlaylist = (track) => {
    let playlistTracks = this.state.playlistTracks.slice();
    playlistTracks.push(track);
    this.setState({playlistTracks});
  }

  handleRemoveTrackFromPlaylist = (trackIndex) => {
    let playlistTracks = this.state.playlistTracks.slice();
    playlistTracks.splice(trackIndex, 1);
    this.setState({playlistTracks});
  }

  handleSearch({query}) {
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: 1,
      perPage: this.state.perPage,
      sortBy: this.state.sortBy,
      filterBy: this.state.filters,
      query: query
    })
    this.setState({"searchQuery": query});
  }

  handleperPageChange(event) {
    this.setState({perPage: event.value, page: 1});
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: 1,
      perPage: event.value,
      sortBy: this.state.sortBy,
      filterBy: this.state.filters,
      query: this.state.searchQuery
    });
  }

  handleFilterChange(fieldName, event) {
    let filters = Object.assign({},this.state.filters);
    filters[fieldName] = event.value;
    this.setState(filters);
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: 1,
      perPage: this.state.perPage,
      sortBy: this.state.sortBy,
      filterBy: filters,
      query: this.state.searchQuery
    });
  }

  handlePageChange(page) {
    this.setState({page});
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: page,
      perPage: this.state.perPage,
      sortBy: this.state.sortBy,
      filterBy: this.state.filters,
      query: this.state.searchQuery
    });
  }

  handleFiltersClick = () => {
    this.setState({showFilters: true});
  }

  render() {
    const {
      activePlaylist,
      page,
      perPage,
      playlistTracks,
      selectedTrack,
      playerState,
      showFilters,
    } = this.state;

    let {
      categories,
      tracks,
      totalTracks,
      tracksLoading
    } = this.props;

    let runtime = playlistTracks.reduce((sum, next) => sum + ((next["attributes"] && next["attributes"]["length"]) || 0), true)
    const categoriesOptions = categories.map(cat => {return {"value": cat["id"], "label": cat["attributes"]["name"]}});
    let playlistAttrs = activePlaylist["attributes"] || {};

    tracks.forEach(track => {
      track["categories"] = (((track["relationships"]||[])["categories"]||[])["data"]||[]).forEach(trackCat => {
        let cat = (categories.find((c) => c.id === trackCat.id)||{})["attributes"];
        if (cat){
          cat["id"] = trackCat.id;
          return cat;
        }
      })
    });

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title={`Playlists > ${playlistAttrs["title"]}`}/>
        <UikLayoutMain>
          <DragDropContext onDragEnd={this.onDragEnd}>
          <div className={ classnames(cls.splitContent, cls.splitContent50)}>
            <UikWidgetContainer className={ cls.tracklistContainer } >
              <div className={cls.widgetHeaderRow}>
                <h4 className={ cls.playlistTitle} >All Tracks</h4>
                <SearchInput
                  onSearch={ this.handleSearch }
                  collectionName="Tracks"
                />
                { showFilters && (
                  <div className={ cls.filters }>
                    <label className="uik-content-title__wrapper">
                      Filters
                    </label>
                    <UikSelect
                      placeholder="Category"
                      name="category"
                      options={[{label: 'Show all', value: ''}, ...categoriesOptions]}
                      onChange={ this.handleFilterChange.bind(this, "category") }
                    />
                    <UikSelect
                      placeholder="Media Type"
                      name="mediaType"
                      position="bottomRight"
                      options={[{label: 'Show all', value: ''}, ...mediaTypeOptionsShort]}
                      onChange={ this.handleFilterChange.bind(this, "media_type") }
                    />
                  </div>
                )}
                { !showFilters && (
                  <UikButton className={ cls.btnFilters } onClick={this.handleFiltersClick}>
                    <i className="fas fa-filter"></i>
                    Filters
                   </UikButton>
                )}
              </div>
              <UikWidget
                className={cls.vertScrollable}
              >
                <UikWidgetTable className={cls.multiSelectTable}>
                  <thead>
                    <tr>
                      <th>
                      </th>
                      <th>
                        Track
                      </th>
                      <th>
                        Categories
                      </th>
                      <th>
                        Type
                      </th>
                      <th>
                        Duration
                      </th>
                      <th>
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <tbody
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        { tracks && tracks.map((track, index) => (
                          <Draggable
                            key={`draggabletrack${index}`}
                            draggableId={`track${index}`}
                            index={index}>
                            {(provided, snapshot) => (
                              <tr
                                key={ `trackrow${track.id}` }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}>
                                  <td>
                                    <div
                                      className={ classnames(cls.trackImg, {
                                        [cls.playing]: selectedTrack === `${track["id"]}.${(track["attributes"]||{})["extension"]}` && playerState === "playing"
                                      }) }
                                      onClick={this.playTrack.bind(this, `${track["id"]}.${(track["attributes"]||{})["extension"]}`)}
                                    >
                                      <img alt="" src={ track.imgUrl ? track.imgUrl : '/img/song_placeholder.png' }/>
                                      <i className={ selectedTrack === `${track["id"]}.${(track["attributes"]||{})["extension"]}` && playerState === "playing" ? 'fas fa-pause' : 'fas fa-play' }></i>
                                    </div>
                                  </td>
                                  <td>
                                    <UikAvatar
                                      className={cls.trackTitleMeta}
                                      highlighted
                                      name={ `${(track["attributes"]||{}).title}` }
                                      textBottom={ `${(track["attributes"]||{}).artist} - ${(track["attributes"]||{}).album} - ${(track["attributes"]||{}).year}` }
                                    />
                                  </td>
                                  <td>
                                    <UikTagContainer className={ cls.tags }>
                                      { track["categories"] && track["categories"].length
                                        ? (track["categories"]||[]).map(cat => (
                                          <UikTag
                                            color={(cat||{}).color}
                                            fill
                                            key={`cat-tag-${cat ? cat["id"] : Math.random()}`}
                                          >
                                            {(cat||{}).name}
                                          </UikTag>
                                      )) : null }
                                    </UikTagContainer>
                                  </td>
                                  <td>
                                    { track["attributes"] && track["attributes"]["media_type"] }
                                  </td>
                                  <td>
                                    { formatMicroseconds((track["attributes"]||{}).length) || "0:00"}
                                  </td>
                                  <td>
                                    <UikButton
                                      clear
                                      icon={ <i className={`fas fa-plus`}></i> }
                                      onClick={this.handleAddTrackToPlaylist.bind(this, track)}
                                    >
                                    </UikButton>
                                  </td>
                                </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                  { tracksLoading && (
                    <tr><td colSpan="10" className={ cls.noResults }>
                      <PacmanLoader
                        loading={true}
                      />
                    </td></tr>
                  )}
                  { tracks && tracks.length === 0 && !tracksLoading && (
                    <tbody>
                        <tr><td colSpan="10" className={ cls.noResults }>No tracks found.</td></tr>
                    </tbody>
                  )}
                </UikWidgetTable>
              </UikWidget>
              <Pagination
                total={ totalTracks }
                page={ page }
                perPage={ perPage }
                changePage={ this.handlePageChange }
                changePerPage={ this.handleperPageChange }
                collectionType="tracks"
                aroundPage = { 3 }
              />
            </UikWidgetContainer>
            <UikWidgetContainer className={ cls.tracklistContainer } >
              <div className={cls.widgetHeaderRow}>
                <h4 className={ cls.playlistTitle} >Playlist ({playlistTracks.length}) <small>- Runtime {formatMicroseconds(runtime)}</small></h4>
                <UikButton primary onClick={this.handleSavePlaylistClick.bind(this)} style={{"float":"right"}}>Save Playlist</UikButton>
              </div>
              <UikWidget
                className={cls.vertScrollable}
              >
                <UikWidgetTable className={cls.multiSelectTable}>
                  <thead>
                    <tr>
                      <th>
                      </th>
                      <th>
                        Track
                      </th>
                      <th>
                        Duration
                      </th>
                      <th>
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="droppable2">
                      {(provided, snapshot) => (
                          <tbody
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}>
                              {playlistTracks.map((track, index) => (
                                  <Draggable
                                      key={`draggableplaylisttrack${index}`}
                                      draggableId={`playlisttrack${index}`}
                                      index={index}>
                                      {(provided, snapshot) => (
                                          <tr
                                              key={ `playlisttrackrow${track.id}` }
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                              )}>
                                                <td>
                                                  <div
                                                    className={ classnames(cls.trackImg, {
                                                      [cls.playing]: selectedTrack === `${track["id"]}.${(track["attributes"]||{})["extension"]}` && playerState === "playing"
                                                    }) }
                                                    onClick={this.playTrack.bind(this, `${track["id"]}.${(track["attributes"]||{})["extension"]}`)}
                                                  >
                                                    <img alt="" src={ track.imgUrl ? track.imgUrl : '/img/song_placeholder.png' }/>
                                                    <i className={ selectedTrack === `${track["id"]}.${(track["attributes"]||{})["extension"]}` && playerState === "playing" ? 'fas fa-pause' : 'fas fa-play' }></i>
                                                  </div>
                                                </td>
                                                <td>
                                                  <UikAvatar
                                                    className={cls.trackTitleMeta}
                                                    highlighted
                                                    name={ `${(track["attributes"]||{}).title}` }
                                                    textBottom={ `${(track["attributes"]||{}).artist} - ${(track["attributes"]||{}).album} - ${(track["attributes"]||{}).year}` }
                                                  />
                                                </td>
                                                <td>
                                                  { formatMicroseconds((track["attributes"]||{}).length) || "0:00"}
                                                </td>
                                                <td>
                                                  <UikButton
                                                    clear
                                                    icon={ <i className={`fas fa-trash`}></i> }
                                                    onClick={this.handleRemoveTrackFromPlaylist.bind(this, index)}
                                                  >
                                                  </UikButton>
                                                </td>
                                          </tr>
                                      )}
                                  </Draggable>
                              ))}
                              { playlistTracks.length === 0 && (
                                <Draggable
                                    key={`draggableplaylisttrack0`}
                                    draggableId={`playlisttrack0`}
                                    index={0}>
                                    {(provided, snapshot) => (
                                        <tr
                                            key={ `playlisttrackrow0` }
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                              <td colSpan="10" style={{"textAlign": "center"}}>
                                                Playlist is empty
                                              </td>
                                        </tr>
                                    )}
                                </Draggable>
                              )}
                          </tbody>
                      )}
                  </Droppable>
                </UikWidgetTable>
              </UikWidget>
            </UikWidgetContainer>
          </div>
          </DragDropContext>
        </UikLayoutMain>
        <audio ref={ref => this.player = ref} />
      </UikContainerVertical>
    )
  }
}

function formatMicroseconds(s){
  s = s / 1000;
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  mins = mins + "";
  secs = secs + "";

  return ((hrs > 0) ? hrs + ':' + mins.padStart(2,'0') : mins) + ':' + secs.padStart(2,'0');
}

export default connect(
  store => ({
    playlists: store.playlists.playlistsList.playlists,
    playlist: store.playlists.activePlaylist.playlist,
    tracks: store.tracks.tracksList.tracks,
    tracksLoading: store.tracks.tracksList.loading,
    totalTracks: store.tracks.tracksList.total,
    categories: store.categories.categoriesList.categories,
    playlistTracks: store.tracks.playlistTracksList.tracks,
  }),
  {
    fetchCategories: categoriesActions.fetchCategories,
    fetchTracks: trackActions.fetchTracks,
    fetchPlaylistTracks: trackActions.fetchPlaylistTracks,
    fetchPlaylist: actions.fetchPlaylist,
    updatePlaylist: actions.updatePlaylist
  },
)(PlaylistPage)

const mediaTypeOptionsShort = [{
    value: 'music',
    label: 'Music'
  },
  {
    value: 'talk',
    label: 'Talk'
  },
  {
    value: 'id',
    label: 'ID'
  },
  {
    value: 'promo',
    label: 'Promo'
  },
  {
    value: 'advert',
    label: 'Ad'
  }
];

import * as React from 'react'
import * as actions from '@redux/auth/actions'
import * as userActions from '@redux/users/actions'
import * as configApp from '@redux/constants'

import { connect } from 'react-redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'

var jwtDecode = require('jwt-decode');

// @flow
type AuthProps = {
  receiveLogin: Function,
  bearerToken: String,
}

@withRouter
class Auth extends React.Component<AuthProps> {

  componentDidMount() {
    const hashParams = getHashParams(this.props.location);
    let bearerToken = hashParams["access_token"];
    if (bearerToken){
      var decoded = jwtDecode(bearerToken);
      localStorage.setItem('bearerToken', bearerToken);
      localStorage.setItem('u', decoded.sub);
      this.props.receiveLogin({"bearerToken": bearerToken, "userId": decoded.sub});
      this.props.fetchUser(decoded.sub)
      this.redirectTo = '/account/stations';
    } else {
      window.location = `${configApp.USERAPI_SERVER}/oauth2/authorize?client_id=broadcaster-frontend&response_type=token&scope=profile`;
    }
  }

  componentDidUpdate() {
  }

  render() {
    if ( !this.redirectTo ){
      return (<div/>)
    }
    return (
      <Redirect to={this.redirectTo} />
    )
  }
}

export default connect(
  store => ({
    bearerToken: store.auth.bearerToken,
  }),
  {
    receiveLogin: actions.receiveLogin,
    fetchUser: userActions.fetchUser,
  },
)(Auth)

function getHashParams(url) {
    var hashParams = {};
    var e,
        a = /\+/g,  // Regex for replacing addition symbol with a space
        r = /([^&;=]+)=?([^&;]*)/g,
        d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
        q = url.hash.substring(1);

    while ((e = r.exec(q))){
      hashParams[d(e[1])] = d(e[2]);
    }

    return hashParams;
}
import * as React from 'react'
import classnames from 'classnames'

import Modal from '@material-ui/core/Modal';

/* eslint-disable max-len */

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikInput,
  UikSelect,
  UikFormInputGroup,
  UikWidget,
  UikWidgetContent,
  UikTabContainer,
  UikTabItem
} from '@components'

import TopBar from '../../../../@components/TopBar'
import UserList from './components/UserList'
import InvitationsList from './components/InvitationsList'
import SearchInput from '../../../../@components/SearchInput'

import cls from './stations-users.scss'

import { connect } from 'react-redux'
import * as actions from '@redux/users/actions'
import * as broadcasterUserActions from '@redux/broadcasterUsers/actions'

// @flow

class StationUsers extends React.Component<{
  match: Object,
}> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      activeTab: "users",
      invitationEmail: "",
      invitationRole: "",
      searchQuery: '',
      page: 1,
      perPage: 30,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.props.fetchUsers();
    // this.props.fetchBroadcasterUsers({stationId: this.state.stationId});
    this.props.fetchStationUserRoles({stationId: this.state.stationId});
    this.props.fetchInvitations({stationId: this.state.stationId});
  }

  componentDidMount() {
  }

  handleTabClick = (activeTab) => {
    this.setState({activeTab: activeTab});
  }

  handleSelectUser = (userId) => {
    // this.setState({ deleteUserModalOpen: true });
  };

  handleSelectAllUsers = () => {
    // this.setState({ deleteUserModalOpen: true });
  };

  handleAddUserModalOpen = () => {
    this.setState({ addMode: true, editUserModalOpen: true });
  };

  handleEditUserModalOpen = (activeUser) => {
    this.setState({ addMode: false, editUserModalOpen: true, activeUser});
  };

  handleEditUserModalClose = () => {
    this.setState({ editUserModalOpen: false });
  };

  handleEditUserModalSave = () => {
    if (this.state.addMode) {
      this.props.sendInvitation({stationId: this.state.stationId, invitationEmail: this.state.invitationEmail, invitationRole: this.state.invitationRole})
    } else {
      this.props.updateUserRole({userId: this.state.activeUser.role.id, accessLevel: this.state.invitationRole})
    }
    this.setState({ editUserModalOpen: false });
  };

  handleDeleteUserModalOpen = (activeUser) => {
    this.setState({ deleteUserModalOpen: true, activeUser});
  };

  handleDeleteUserModalClose = () => {
    this.setState({ deleteUserModalOpen: false });
  };

  handleDeleteServiceClick = () => {
    alert('Sadly, cannot delete through API :(');
    this.setState({ deleteUserModalOpen: false });
  };

  handleBulkActionChange = (event) => {
    this.setState({bulkAction: event.value});
    if ( event.value === 'delete') {
      this.handleDeleteUserModalOpen();
    }
  };

  applyBulkActions = () => {
    if (this.state.bulkAction === 'edit' ) {
    }
  };

  handleSelectChange(name, event) {
    let newState = {};
    newState[name] = event.value;
    this.setState(newState);
  }

  handleEditChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleSearchChange({query}) {
    this.setState({'searchQuery': query});
  }

  render() {
    const {
      addMode,
      bulkAction,
      activeTab,
      searchQuery,
      invitationEmail,
      invitationRole
    } = this.state;

    const {
      users,
      userRoles,
      broadcasterUsers,
      invitations,
      invitationsLoading,
      usersLoading
    } = this.props;

    let bulkEditWidget;


    // let stationUsers = userRoles.map(usrRole => {
    //   return usrRole
    // });

    // if (searchQuery && searchQuery.length) {
    //   stationUsers = stationUsers.filter(user => {
    //     return (user["attributes"]["first_name"] && user["attributes"]["first_name"].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
    //     || (user["attributes"]["last_name"] && user["attributes"]["last_name"].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
    //     || (user["attributes"]["email"] && user["attributes"]["email"].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
    //   });
    // }

    if ( bulkAction === 'edit') {
      bulkEditWidget = <UikWidget margin>
        <UikWidgetContent>
          <h3>Bulk Edit</h3>
          <div className={cls.bulkEditForm}>
            <UikFormInputGroup direction="horizontal" className={cls.formHorizontal}>
              <label>Station 1</label>
              <UikSelect placeholder="Role" options={[]} />
            </UikFormInputGroup>
          </div>
          <UikButton success>Save</UikButton>
        </UikWidgetContent>
      </UikWidget>;
    }

    return (
      <UikContainerVertical className={cls.page}>
        <TopBar title="Users"/>
        <UikLayoutMain className={cls.wrapper}>
          <UikContentItem className={cls.header}>
            <div className={cls.bulkActionsWrapper}>
              {/*<UikSelect
                      className={ cls.selectRange }
                      onChange={ this.handleBulkActionChange.bind(this) }
                      options={ [
                        {
                          value: 'edit',
                          label: (
                            <span>
                              {'Edit'}
                            </span>
                          ),
                        },
                        {
                          value: 'delete',
                          label: (
                            <span>
                              {'Delete'}
                            </span>
                          ),
                        },
                      ] }
                      placeholder="Bulk Actions"
                    />*/}
            </div>
            <SearchInput
              onSearch={ this.handleSearchChange }
              collectionName="Users"
            />
            <UikButton primary onClick={this.handleAddUserModalOpen} disabled>
              Invite User
            </UikButton>
          </UikContentItem>

          <Modal
            open={this.state.editUserModalOpen}
            onClose={this.handleEditUserModalClose}
          >
            <div className={cls.modal}>
              <div className={cls.modalHeader}>
                {addMode ? "Invite" : "Edit"} User
              </div>
              <div className={cls.modalBody}>
                {addMode && (
                  <UikFormInputGroup>
                    <UikInput
                      label="Email Address"
                      placeholder=""
                      name="invitationEmail"
                      onChange={this.handleEditChange.bind(this)}
                    />
                  </UikFormInputGroup>
                )}
                <UikFormInputGroup>
                  <UikSelect
                    label="Access Level"
                    placeholder="Role"
                    onChange={this.handleSelectChange.bind(this, "invitationRole")}
                    options={[
                      {
                        value: "editor",
                        label: "Editor"
                      },
                      {
                        value: "manager",
                        label: "Manager"
                      },
                      {
                        value: "owner",
                        label: "Owner"
                      }
                    ]}
                  />
                </UikFormInputGroup>
              </div>
              <div className={cls.modalFooter}>
                <UikButton
                  isExpanded
                  success
                  disabled={invitationEmail.length === 0 || invitationRole.length === 0}
                  onClick={this.handleEditUserModalSave.bind(this)}
                >
                  {addMode ? 'Send Invitation' : 'Save'}
                </UikButton>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deleteUserModalOpen}
            onClose={this.handleDeleteUserModalClose}
          >
            <div className={cls.modal}>
              <div className={cls.modalHeader}>Delete User</div>
              <div className={cls.modalBody}>
                <p>Are you sure you wish to delete XYZ ?</p>
              </div>
              <div
                className={classnames(cls.modalFooter, cls.modalFooterMultiButton)}
              >
                <UikButton onClick={this.handleDeleteUserModalClose}>
                  Cancel
                </UikButton>
                <UikButton error onClick={this.handleDeleteServiceClick}>
                  Delete
                </UikButton>
              </div>
            </div>
          </Modal>
          {bulkEditWidget}

          <UikTabContainer>
            <UikTabItem
              key="users"
              className={activeTab === "users" && "active"}
              onClick={this.handleTabClick.bind(this, "users")}
            >
              Users ({userRoles.length})
            </UikTabItem>
            <UikTabItem
              key="invitations"
              className={activeTab === "invitations" && "active"}
              onClick={this.handleTabClick.bind(this, "invitations")}
            >
              Invitations ({invitations.length})
            </UikTabItem>
          </UikTabContainer>
          {activeTab === "users" && (
            <UserList
              users={userRoles}
              loading={usersLoading}
              editUser={this.handleEditUserModalOpen.bind(this)}
              deleteUser={this.handleDeleteUserModalOpen.bind(this)}
              selectUser={this.handleSelectUser}
              selectAllUsers={this.handleSelectAllUsers}
              showAccessLevel={false}
            />
          )}
          {activeTab === "invitations" && (
            <InvitationsList
              invitations={invitations}
              loading={invitationsLoading}
              editInvitation={this.handleEditInvitationModalOpen}
              deleteInvitation={this.handleDeleteInvitationModalOpen}
              selectInvitation={this.handleSelectInvitation}
              selectAllInvitations={this.handleSelectAllInvitations}
            />
          )}
        </UikLayoutMain>
      </UikContainerVertical>
    );
  }
}

export default connect(
  store => ({
    users: store.users.users,
    usersLoading: store.users.usersLoading,
    userRoles: store.users.userRoles,
    broadcasterUsers: store.broadcasterUsers.broadcasterUsersList.broadcasterUsers,
    invitations: store.users.invitations,
    invitationsLoading: store.users.invitationsLoading
  }),
  {
    fetchUsers: actions.fetchUsers,
    fetchInvitations: actions.fetchInvitations,
    fetchStationUserRoles: actions.fetchStationUserRoles,
    fetchBroadcasterUsers: broadcasterUserActions.fetchBroadcasterUsers,
    sendInvitation: actions.sendInvitation,
    updateUserRole: actions.updateUserRole,
  },
  )(StationUsers)

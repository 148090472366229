import initialState from './initialState'

import {
  FETCH_NOTIFICATIONS, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILURE, RESET_NOTIFICATIONS,
  UPDATE_NOTIFICATION, UPDATE_NOTIFICATION_SUCCESS,  UPDATE_NOTIFICATION_FAILURE,
  READ_NOTIFICATION, READ_NOTIFICATION_SUCCESS
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_NOTIFICATIONS:// start fetching notification and set loading = true
    return { ...state, notificationsList: {notifications:[], error: null, loading: true} };
  case FETCH_NOTIFICATIONS_SUCCESS:
    return { ...state, notificationsList: {notifications: action.payload, error:null, loading: false} };
  case FETCH_NOTIFICATIONS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, notificationsList: {notifications: [], error: error, loading: false} };
  case RESET_NOTIFICATIONS:// reset notificationList to initial state
    return { ...state, notificationsList: {notifications: [], error:null, loading: false} };

  case UPDATE_NOTIFICATION:
    return { ...state, activenotification:{...state.activenotification, loading: true}};
  case UPDATE_NOTIFICATION_SUCCESS:
    return { ...state, activenotification: {notification: action.payload, error:null, loading: false}};
  case UPDATE_NOTIFICATION_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activenotification: {notification: null, error:error, loading:false}};

  case READ_NOTIFICATION:
    return { ...state, activenotification:{...state.activenotification, loading: true}};
  case READ_NOTIFICATION_SUCCESS:
    return { ...state, activenotification: {notification: action.payload, error:null, loading: false}};
  default:
    return state;
  }
}


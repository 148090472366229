import * as React from 'react'
import FreshdeskWidget from '@personare/react-freshdesk-widget';

/* eslint-disable max-len */

import {
  UikWidget,
  UikLayoutMain,
  UikWidgetContainer,
  UikContainerVertical,
} from '@components'

import TopBar from '../../../../@components/TopBar'

// @flow

class ContactUsPage extends React.Component<{
}> {
  render() {
    return (
      <UikContainerVertical >
        <TopBar title="Contact us"/>
        <UikLayoutMain>
          <UikWidgetContainer>
            <UikWidget style={{"maxWidth": "1200px", "padding": "30px 30px"}}>
              <FreshdeskWidget url="https://mediacreek.freshdesk.com" />
            </UikWidget>
          </UikWidgetContainer>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default ContactUsPage

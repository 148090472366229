import * as React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import * as actions from '@redux/stations/actions'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetContent,
  UikDivider,
  UikInput,
  UikFormInputGroup,
  UikButton,
  UikWidgetContainer,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './listen.scss'

class CopyToClipboardLabel extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div style={{textAlign: 'space-between'}}>
        {this.props.labelText} &nbsp;
        <CopyToClipboard text={this.props.copyValue}>
          <button onClick={function(e) {e.preventDefault(); toast.success("Copied to clipboard")}}>
            <i className="fas fa-clipboard"></i>
          </button>
        </CopyToClipboard>
      </div>
    )
  }
}

// @flow
type StationProps = {
  station: Object,
  activeStation: {}
}

class ListenPage extends React.Component<StationProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
    }

    this.props.fetchStation(this.state.stationId);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.station !== this.props.station){
      this.setState({ activeStation: this.props.station });
    }
  }

  render() {
    let activeStation = this.state.activeStation || {};
    const mountId = (activeStation["attributes"]||{})["mount_id"] || null;
    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar
          title={ "Listen"}
        />
        <UikLayoutMain>
          <div className={ cls.splitContent }>
            <UikWidgetContainer className={ cls.basicProfileWidgetContainer } >
              <UikWidget>
                <UikWidgetContent>
                  <h3>Listen Links</h3>
                </UikWidgetContent>
                <UikDivider />
                <form onSubmit={ () => {} }>
                  <UikWidgetContent>
                    <UikFormInputGroup>
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          value={ mountId ? `http://streaming.live365.com/${mountId}` : ''}
                          label={<CopyToClipboardLabel labelText="Quick Link" copyValue={mountId ? `http://streaming.live365.com/${mountId}` : ''} />}
                          name="quicklink"
                          readOnly
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          value={ mountId ? `http://player.live365.com/${mountId}` : ''}
                          label={<CopyToClipboardLabel labelText="Licensed Player" copyValue={mountId ? `http://player.live365.com/${mountId}` : ''} />}
                          name="licensedplayer"
                          readOnly
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup>
                        <label className="uik-content-title__wrapper">Listen Links</label>
                        <div className={cls.listenLinks}>
                          { mountId && (
                            <React.Fragment>
                              <a href={`/listen-link/${mountId}.pls`}>PLS</a>
                              <a href={`/listen-link/${mountId}.m3u`}>M3U</a>
                              <a href={`/listen-link/${mountId}.asx`}>ASX</a>
                            </React.Fragment>
                          )}
                         </div>
                      </UikFormInputGroup>
                      <UikFormInputGroup>
                      <label className="uik-content-title__wrapper">Licensed Player Button</label>
                        { mountId && (
                          <textarea
                            name="licensedplayerbutton"
                            readOnly
                            className={cls.textarea}
                            value={mountId ? `<button onclick="window.open('http://player.live365.com/${mountId}','_blank');" style="background-image: url('https://live365.com/assets/listen-now-live365logo.png');height:60px;width:250px;border:0px;"></button>` : '' }/>
                        )}
                      </UikFormInputGroup>
                      <UikFormInputGroup horizontal>
                        { ((activeStation||{})["attributes"]||{})["slug"] && (
                          <UikInput
                            value={`https://live365.com/station/${((activeStation||{})["attributes"]||{})["slug"]}-${mountId}`}
                            label={<CopyToClipboardLabel labelText="Station Profile" copyValue={`https://live365.com/station/${((activeStation||{})["attributes"]||{})["slug"]}-${mountId}`} />}
                            name="stationprofile"
                            readOnly
                          />
                        )}
                      </UikFormInputGroup>
                    </UikFormInputGroup>
                  </UikWidgetContent>
                </form>
              </UikWidget>
            </UikWidgetContainer>
            <UikWidgetContainer className={ cls.basicProfileWidgetContainer } >
              <UikWidget>
                <UikWidgetContent>
                 <h3>Now Playing Widgets</h3>
                 <p>Feature your station's currently playing song, and easily open the licensed player with this simple to embed widget.</p>
                   { mountId && (
                    <UikFormInputGroup>
                      <iframe title="iframe1" src={`https://broadcaster.live365.com/v1/now-playing/large/${mountId}`} width="440px" height="240px" frameBorder="0"></iframe>
                      <textarea
                        className={cls.textarea}
                        defaultValue={`<iframe src="https://broadcaster.live365.com/v1/now-playing/large/${mountId}" width='440px' height='240px' frameborder="0"/>`}
                       />
                      <iframe title="iframe2" src={`https://broadcaster.live365.com/v1/now-playing/small/${mountId}`} width="220px" height="380px" frameBorder="0"></iframe>
                      <textarea
                        className={cls.textarea}
                        defaultValue={`<iframe src="https://broadcaster.live365.com/v1/now-playing/small/${mountId}" width='220px' height='380px' frameborder="0"/>`}
                       />
                    </UikFormInputGroup>
                   )}
                </UikWidgetContent>
              </UikWidget>
            </UikWidgetContainer>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    station: store.stations.activeStation,
  }),
  {
    fetchStation: actions.fetchStation,
    updateStation: actions.updateStation,
  },
)(ListenPage)


import * as React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { toast } from "react-toastify";
import Modal from '@material-ui/core/Modal';
import DatePicker from "react-datepicker";
import Tooltip from '@material-ui/core/Tooltip';
import mom from 'moment';
import moment from 'moment-timezone';

import "react-datepicker/dist/react-datepicker.css";

import * as actions from '@redux/events/actions'
import * as playlistActions from '@redux/playlists/actions'
import * as clockwheelActions from '@redux/clockwheels/actions'
import * as fadeActions from '@redux/fades/actions'
/* eslint-disable max-len */

import {
  UikContainerVertical,
  UikScrollArea,
  UikWidget,
  UikWidgetHeader,
  UikButton,
  Uikon,
  UikRadio,
  UikCheckbox,
  UikInput,
  UikSelect,
  UikFormInputGroup,
  UikTabContainer,
  UikTabItem,
  UikWidgetTable
} from '@components'

import TopBar from '../../../../@components/TopBar'
import PacmanLoader from "../../../../@components/PacmanLoader";

import cls from './stations-schedule.scss'

import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";

const localizer = momentLocalizer(mom);
const DragAndDropCalendar = withDragAndDrop(Calendar)

// @flow
type ScheduleProps = {
  deletedEvent: Object,
  newEvent: Object,
  events: Array,
  playlists: Array,
  clockwheels: Array,
}

class SchedulePage extends React.Component<ScheduleProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      events: [],
      calendarEvents: [],
      allEvents: this.props.events || [],
      playlists: [],
      editEventModalOpen: false,
      applyToAll: false,
      eventWasRecurring: false,
      addMode: false,
      activeEvent: {},
      addEventType: "playlist",
      durationHours: 0,
      durationMinutes: 30,
      playlistId: null,
      clockwheelId: null,
      calendarView: "week",
      defaultFadeId: 0,
      defaultSeriesStartDate: moment().add(30 - (moment().minute() % 30), 'm').toDate(),
      defaultSeriesEndDate: moment().add(30 - (moment().minute() % 30), 'm').add(1,'w').toDate(),
      calendarStart: moment().startOf('week'),
      calendarEnd: moment().endOf('week').add(1,'d'),
      title: "",
      start_date: moment().add(30 - (moment().minute() % 30), 'm').toDate(),
      series_end: null,
      errors: {"duration":"","playlist":"","clockwheel":""},
      activeTab: "setup",
      timezone: this.props.loggedInUser && this.props.loggedInUser.timezone,
      count: 0,
      fetchedPlaylists: false,
      fetchedClockwheels: false,
      fetchedEventSources: false,
      fetchedActiveStation: false,
      fetchedEvents: false,
      finishedProcessingEvents: false,
    };

    this.handleEditChange = this.handleEditChange.bind(this)
    this.handleCheckChange = this.handleCheckChange.bind(this)
    this.handleCrossfadeCheckChange = this.handleCrossfadeCheckChange.bind(this)
    this.handleDayCheckChange = this.handleDayCheckChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleCreateEventModalOpen = this.handleCreateEventModalOpen.bind(this);
    this.handleEditEventModalClick = this.handleEditEventModalClick.bind(this);
    this.handleEditEventModalClose = this.handleEditEventModalClose.bind(this);
    this.handleDeleteEventClick = this.handleDeleteEventClick.bind(this);
    this.handleDeleteInstanceEventClick = this.handleDeleteInstanceEventClick.bind(this);

    this.moveEvent = this.moveEvent.bind(this);
    this.resizeEvent = this.resizeEvent.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.dragEventStart = this.dragEventStart.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleView = this.handleView.bind(this);
    this.eventPropGetter = this.eventPropGetter.bind(this);

    this.props.fetchPlaylists(this.state.stationId);
    this.props.fetchClockwheels(this.state.stationId);
    this.props.fetchFades(this.state.stationId);
  }

  shouldComponentUpdate(nextProps, nextState){
    return true;
  }

  buildEventsList = (events) => {
    let calendarEvents = [];  // This is what will eventually be passed to react-big-calendar's Calendar

    // First things first: devirtualize recurring events.

    const { calendarStart, calendarEnd } = this.state;
    const recurringEvents = events.filter(e => e.attributes.is_recurring && !e.attributes.is_instance && !e.attributes.is_canceled);

    const numRecurringEvents = recurringEvents.length;
    // console.log(`Found ${numRecurringEvents} recurring events.`)

    recurringEvents.forEach(event => {
      let currentTimestamp = moment(calendarStart);
      const seriesEnd = moment(event.attributes.series_end);

      const recurringDays = event.attributes.recurring_days;
      while (currentTimestamp < calendarEnd) {
        if (seriesEnd < currentTimestamp) {
          return;
        }
      
        // const instanceStart = moment(event.attributes.start_time);
        // instanceStart.year(currentTimestamp.year()).month(currentTimestamp.month()).date(currentTimestamp.date());

        const instanceStartTime = moment(event.attributes.start_time);
        instanceStartTime.year(currentTimestamp.year()).month(currentTimestamp.month()).date(currentTimestamp.date());
        if (shouldScheduleRecurring(instanceStartTime, recurringDays, this.props.activeStation["attributes"]["timezone"])) {
          const instance = JSON.parse(JSON.stringify(event));
          instance.attributes.is_instance = true;
          instance.attributes.start_time = instanceStartTime.format();

          calendarEvents.push(instance);
        }

        currentTimestamp.add(1, 'd')
      }
    })

    // console.log(`Created ${calendarEvents.length} instances`)

    // Second things second: replace the instances we created with the ones that came from the API.
    // Then add these instances to our events list.

    let recurringEventInstances = events.filter(e => e.attributes.is_recurring && e.attributes.is_instance);
    // Stop the crashing until figuring out what's wrong with Events.
    recurringEventInstances = recurringEventInstances.filter(e => e.relationships.parent_event.data);
    recurringEventInstances = recurringEventInstances.map(e => {
      const instance = JSON.parse(JSON.stringify(e));
      // We need to add some parent event information
      const parentEvent = recurringEvents.find(ev => ev.id === instance.relationships.parent_event.data.id);
      if (parentEvent) {
        instance.attributes.recurring_days = parentEvent.attributes.recurring_days;
        instance.attributes.series_end = parentEvent.attributes.series_end;
      }

      return instance;
    });

    recurringEventInstances.forEach(event => {
      const existing = calendarEvents.find(e => {
        const eventStartTime = moment(event.attributes.start_time);
        const instanceStartTime = moment(e.attributes.start_time);
        return eventStartTime.isSame(instanceStartTime);
      })
      if (existing) {
        // console.log('Found existing instance, will remove from list')
        calendarEvents = calendarEvents.filter(ce => ce !== existing);
      }
    })

    calendarEvents.push(...recurringEventInstances);

    // Third things third: add single event instances.

    calendarEvents.push(...events.filter(e => !e.attributes.is_recurring && e.attributes.is_instance));

    // Fourth things fourth: remove all canceled instances

    calendarEvents = calendarEvents.filter(e => !e.attributes.is_canceled);

    // Fith things fifth: add the attributes needed by Calendar.

    calendarEvents = calendarEvents.map(ev => {
      const e = JSON.parse(JSON.stringify(ev))

      e.start = moment(e.attributes.start_time).toDate(),
      e.end = moment(e.attributes.start_time).add(e.attributes.duration, 's').toDate()

      e.clockwheelId = ((e.relationships.clockwheel||{}).data||{}).id;
      e.clockwheel = e.clockwheelId ? this.props.clockwheels.find(c => c.id === e.clockwheelId) : null;

      e.playlistId = ((e.relationships.playlist||{}).data||{}).id;
      e.playlist = e.playlistId ? this.props.playlists.find(p => p.id === e.playlistId) : null

      e.title = ev.attributes.title;
      // If people delete their playlist or clockwheel, the event might have no source.
      e.color = e.playlist ? ((e.playlist || {})["attributes"] || {})["color"] : ((e.clockwheel || {})["attributes"] || {})["color"]

      return e;
    })

    // console.log('Number of events returned', calendarEvents.length);
    return calendarEvents;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.fades !== this.props.fades && this.props.fades.length){
    }

    // ----------------------------------------------------------------------
    // TODO: Figure out when both playlists and clockwheels have been fetched,
    // and then fetch events.
    // Update: this looks good for now, look out for any crashes/problems caused by this.
    if (prevProps.clockwheelsLoading && !this.props.clockwheelsLoading) {
      this.setState({fetchedClockwheels: true});
    }
    if (prevProps.playlistsLoading && !this.props.playlistsLoading){
      this.setState({fetchedPlaylists: true});
    }

    if (this.state.fetchedClockwheels && this.state.fetchedPlaylists && !this.state.fetchedEventSources) {
      this.setState({fetchedEventSources: true});
    }
    // ----------------------------------------------------------------------

    if (!prevProps.activeStation && this.props.activeStation) {
      this.setState({fetchedActiveStation: true});
    }

    if (this.state.fetchedEventSources && this.props.activeStation && !this.state.fetchedEvents) {
      this.props.fetchEvents(this.state.stationId, `${this.state.calendarStart.add(-1, 'days').format("YYYY-MM-DD")}:${this.state.calendarEnd.add(1, 'days').format("YYYY-MM-DD")}`);
      this.setState({fetchedEvents: true});
    }

    if (prevProps.eventsLoading && !this.props.eventsLoading || (prevProps.events !== this.props.events)) {

      let calendarEvents = this.buildEventsList(this.props.events)

      // events.forEach((event) => {
      //   event.clockwheelId = ((event.relationships.clockwheel||{}).data||{}).id;
      //   event.playlistId = ((event.relationships.playlist||{}).data||{}).id;
      //   event.playlist = this.props.playlists.find(p => p.id === event.playlistId);
      //   event.color = ((event.playlist||{})["attributes"]||{})["color"];
      //   event.title = event.attributes.title;

      //   let eventStartTime = moment(event.attributes.start_time);
      //   event.start = eventStartTime.toDate();
      //   event.end = moment(eventStartTime).clone().add(event.attributes.duration, 's').toDate();

      //   if ( event.attributes.is_recurring && !event.attributes.is_instance && !event.attributes.is_canceled) {
      //     let parentEventStartDate = moment(event["attributes"]["start_time"]);
      //     let parentEventSeriesEndDate = moment(event["attributes"]["series_end"]);
      //     let parentEventSeriesStartDate = moment(parentEventStartDate);

      //     for (var i = 0; i < event.attributes.recurring_days.length; ++i) {
      //       let weekday = event.attributes.recurring_days[i]; // adjust 0 mon 6 sun => 0 sun 6 sat
      //       weekday = weekday === 6 ? 0 : weekday + 1;
      //       let newStartTime = this.state.calendarStart.clone().hour(eventStartTime.hour()).minute(eventStartTime.minute()).day(weekday);
      //       // check new start time is within the start / end of the series...
      //       if ((newStartTime.isBefore(parentEventSeriesEndDate)
      //         && newStartTime.isAfter(parentEventSeriesStartDate))
      //         || (newStartTime.isSame(parentEventSeriesStartDate)
      //          || newStartTime.isSame(parentEventSeriesEndDate))){
      //           // check there isn't a canceled event in same slot before adding this event
      //           if (!events.find(ev => ev.attributes.is_canceled
      //               && (ev.relationships.parent_event.data||{})["id"] === event["id"]
      //               && newStartTime.isSame(moment(ev.attributes.start_time)))) {
      //             let recEvent = JSON.parse(JSON.stringify(event));
      //             recEvent["attributes"]["start_time"] = newStartTime.format();
      //             recEvent["attributes"]["is_instance"] = true;
      //             recEvent["relationships"]["parent_event"] = {"data": {"type": "events", "id": event.id}}
      //             recEvent.start = moment(newStartTime).toDate();
      //             recEvent.end = moment(newStartTime).add(recEvent.attributes.duration, 's').toDate();
      //             calendarEvents.push(recEvent);
      //         }
      //       }
      //     }
      //   }

      //   // don't push recurring parent event if its virtualized instance event is present
      //   if (event.attributes.is_recurring && !event.attributes.is_instance) {
      //     let evStartTime = moment(event.attributes.start_time);
      //     // only keep event if, you don't find a virtualized event in its place
      //     // AND
      //     // you don't find a canceled event in its place
      //     // AND
      //     // it's not canceled
      //     // AND
      //     // hasn't got a recurring instance in its place too
      //     if(!events.find(ev => ev.attributes.is_instance
      //         && ev.attributes.is_recurring
      //         && ev.attributes.recurring_days === null
      //         && evStartTime.isSame(moment(ev.attributes.start_time)))
      //       &&
      //         !events.find(ev => ev.attributes.is_canceled
      //         && (ev.relationships.parent_event.data||{})["id"] === event["id"]
      //         && evStartTime.isSame(moment(ev.attributes.start_time)))
      //       &&
      //         !event.attributes.is_canceled
      //       &&
      //         !calendarEvents.find(ev => (ev.relationships.parent_event["data"]||{})["id"] === event.id
      //         && evStartTime.isSame(moment(ev.attributes.start_time)))
      //       ){
      //       calendarEvents.push(event);
      //     }
      //   } else if(!event.attributes.is_canceled){
      //     calendarEvents.push(event);
      //   }
      // })

      // close modal if no errors
      this.setState({
        allEvents: this.props.events,
        calendarEvents,
        finishedProcessingEvents: true,
        editEventModalOpen: this.state.editEventModalOpen && ((this.props.newEvent||{}).error || (this.props.deletedEvent||{}).error || this.props.eventsError) != null
      });
    }
  }

  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
    const { events } = this.state

    const idx = events.indexOf(event);

    // if (event.start === events[idx].start && event.end === events[idx].end) {
    //   // event haven't moved, open edit modal
    //   this.setState({
    //     editEventModalOpen: true,
    //     activeEvent: event
    //   })
    // } else {

      const updatedEvent = { ...event, start, end }

      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)

      this.setState({
        events: nextEvents,
      })
    // }

    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  resizeEvent = ({ event, start, end }) => {
    const { events } = this.state

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    this.setState({
      events: nextEvents,
    })

    //alert(`${event.title} was resized to ${start}-${end}`)
  }

  createEvent(event) {
    if (moment(event.start).isBefore(moment())) {
      toast.error("Can't schedule an Event in the past")
      return;
    }

    let activeEvent = { "attributes": {
      "start_time": event.start,
      "series_end": this.state.defaultSeriesEndDate,
      "duration": moment(event.end).diff(moment(event.start), 's'),
      "strict_start": false,
      "recurring_days": [],
      "is_recurring": false,
    }, "relationships": {}};

    this.setState({
      editEventModalOpen: true,
      activeEvent,
      playlistId: null,
      clockwheelId: null,
      addMode: true,
      addEventType: "playlist",
    });
  }

  onEventResize = (type, { event, start, end, allDay }) => {
    this.setState(state => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };

  onEventDrop = ({ event, start, end, allDay }) => {
  };


  dragEventStart = (dragEvent) => {
    // This gets called on event click
    const { events } = this.props
    let event = dragEvent.event;
    const prevEvent = events.find((ev) => ev.id === event.id);
    if (event["attributes"]["is_instance"] || (event.start === prevEvent.start && event.end === prevEvent.end)) {
      // event haven't moved, open edit modal
      let durationMinutes = Math.floor((event.attributes.duration || 0) / 60 % 60);
      let durationHours = Math.floor((event.attributes.duration || 0) / 60 / 60 );
      event.clockwheelId = ((event.relationships.clockwheel||{}).data||{}).id;
      event.playlistId = ((event.relationships.playlist||{}).data||{}).id;
      let addEventType = event.playlistId !== undefined ? 'playlist' : 'clockwheel';

      this.setState({
        addMode: false,
        addEventType,
        editEventModalOpen: true,
        activeEvent: event,
        playlistId: event["playlistId"],
        clockwheelId: event["clockwheelId"],
        durationMinutes,
        durationHours
      });

      this.props.getActiveEventTracks(event);
    }
  };

  eventPropGetter = (obj) => {
    let bgColor = ((obj["playlist"]||{})["attributes"]||{})["color"] || ((obj["clockwheel"]||{})["attributes"]||{})["color"] || "rgba(22, 101, 216, 0.1)";
    const color = getContrastYIQ(RGBAToHexA(bgColor));
    // bgColor = obj["attributes"]["is_canceled"] ? 'black' : bgColor;
    return {"className": "calendarEvent", "style": {"backgroundColor": bgColor, "borderColor": bgColor, "color": color}};
  }

  handleCreateEventModalOpen = () => {
    // let activeEvent = { "attributes": {"duration": 30 * 60}, "relationships": {}};

    let activeEvent = { "attributes": {
      "start_time": new Date(moment().add(30 - (moment().minute() % 30), 'm').format('YYYY-MM-DDTHH:mm:ss')),
      "series_end": this.state.defaultSeriesEndDate,
      "duration": 30 * 60,
      "strict_start": false,
      "recurring_days": [],
      "is_recurring": false,
    }, "relationships": {}};

    this.setState({
      activeEvent: activeEvent,
      eventWasRecurring: activeEvent["attributes"]["is_recurring"],
      addMode: true,
      editEventModalOpen: true,
      playlistId: undefined,
      clockwheelId: undefined,
      activeTab: "setup"
    });
  }

  handleEditEventModalClose = () => {
    this.setState({ editEventModalOpen: false, activeTab: "setup" });
    if (!this.state.addMode) {
      this.props.resetActiveEventTracks();
    }
  };

  handleEditEventModalClick = () => {
    let activeEvent = JSON.parse(JSON.stringify(this.state.activeEvent || {}));
    let aeAttrs = activeEvent["attributes"] || {};

    const { addEventType, stationId, playlistId, clockwheelId, durationHours, durationMinutes, addMode } = this.state;

    let errors = this.state.errors;
    errors["duration"] = (durationHours + durationMinutes) === 0 ? "Duration cannot be 0" : '';
    errors["playlist"] = addEventType === "playlist" && !playlistId ? "Please select a playlist" : '';
    errors["clockwheel"] = addEventType === "clockwheel" && !clockwheelId ? "Please select a clockwheel" : '';

    let hasError = Object.values(errors).reduce((sum, next) => sum || next.length > 0, false);
    if(hasError) {
      this.setState(errors);
      return;
    }

    aeAttrs.duration = durationHours * 60 * 60 + durationMinutes * 60;

    if (addEventType === "playlist"){
      if (!playlistId){
        toast.error('You must select a playlist')
        return;
      }
      // 8 - if switching from playlist to clockwheel... then you delete the playlist relationship
      activeEvent["relationships"]["playlist"] = {"data":{"type":"playlists","id":playlistId}}
      delete activeEvent["relationships"]["clockwheel"];
    } else {
      if (!clockwheelId){
        toast.error('You must select a clockwheel')
        return;
      }
      // 8 - if switching from playlist to clockwheel... then you delete the playlist relationship
      activeEvent["relationships"]["clockwheel"] = {"data":{"type":"clockwheels","id":clockwheelId}}
      delete activeEvent["relationships"]["playlist"];
    }

    let newEvent = JSON.parse(JSON.stringify(activeEvent));
    newEvent["relationships"]["station"] = {"data":{"type":"stations","id":stationId}}

    // if title doesnt exist or is empty, use the name of the playlist / clockwheel
    if (typeof aeAttrs["title"] === "undefined" ||
        !(aeAttrs).hasOwnProperty('title') ||
        (aeAttrs["title"]||{}).length === 0) {
      aeAttrs["title"] = playlistId ?
        this.props.playlists.find(p => p.id === playlistId)["attributes"]["title"] :
        this.props.clockwheels.find(c => c.id === clockwheelId)["attributes"]["name"];
      newEvent["attributes"]["title"] = aeAttrs["title"]
    }

    if (!newEvent["attributes"]["is_recurring"]) {
      delete newEvent["attributes"]["recurring_days"];
    }

    // cause we're editing foreign keys on the front end o// \\o \o/
    delete newEvent["relationships"]["parent_event"];
    delete activeEvent["relationships"]["parent_event"];

    if (addMode) {
      this.props.createEvent({newEvent});
    } else {
      if (!this.state.applyToAll && aeAttrs["is_recurring"]) {
        // if only updating this event, you have create a canceled event ( deleting this 1 instance... )
        // then creating a brand new single event...
        // to delete an instance, create a new event, at the same time, that is canceled
        let newCanceledEvent = this.prepareCanceledEvent(activeEvent);
        newEvent["attributes"]["is_recurring"] = false;
        delete newEvent["attributes"]["recurring_days"];
        this.props.createTwoEvents({newEvent: newCanceledEvent, newEvent2: newEvent});
      } else if(!this.state.eventWasRecurring && aeAttrs["is_recurring"]) {
        // 7 - if this previously was a single event but now a recurring event...
        // cancel event
        newEvent["attributes"]["is_recurring"] = true;
        newEvent["attributes"]["is_instance"] = false;
        newEvent["attributes"]["series_end"] = newEvent["attributes"]["series_end"] || this.state.defaultSeriesEndDate;
        this.props.deleteEvent({
          activeEvent,
          "secondAction": {
            "name": "createEvent", payload: newEvent
          }
        });
      } else {
        delete activeEvent["attributes"]["is_instance"];
        this.props.updateEvent({activeEvent});
      }
    }
  };

  prepareCanceledEvent = (calEvent) => {
    let aeAttrs = calEvent["attributes"] || {};
    // to delete an instance, create a new event, at the same time, that is canceled
    let newCanceledEvent = {
      "attributes": {
        "start_time": aeAttrs["start_time"],
        "duration": aeAttrs["duration"],
        "title": aeAttrs["title"],
        "is_instance": aeAttrs["is_instance"],
        "is_recurring": aeAttrs["is_recurring"],
        "recurring_days": [],
        "strict_start": false,
        "is_canceled": true
      },
      "relationships": {
        "parent_event": {"data": {"type": "events", "id": calEvent.id}},
        "station": {"data": {"type": "stations", "id": (calEvent["relationships"]["station"]["data"]||{})["id"]}}
      }
    };
    if (calEvent["relationships"]["playlist"] && calEvent["relationships"]["playlist"]["data"]){
      newCanceledEvent["relationships"]["playlist"] = calEvent["relationships"]["playlist"];
    }
    if (calEvent["relationships"]["clockwheel"] && calEvent["relationships"]["clockwheel"]["data"]){
      newCanceledEvent["relationships"]["clockwheel"] = calEvent["relationships"]["clockwheel"];
    }
    return newCanceledEvent;
  };

  handleDeleteEventClick = () => {
    let activeEvent = this.state.activeEvent || {};
    this.props.deleteEvent({ activeEvent });
  };

  handleDeleteInstanceEventClick = () => {
    let newCanceledEvent = this.prepareCanceledEvent(this.state.activeEvent);
    this.props.createEvent({
      newEvent: newCanceledEvent
    });
  };

  handleEditChange(event) {
    let activeEvent = this.state.activeEvent || {};
    activeEvent["attributes"][event.target.name] = event.target.value;
    this.setState({activeEvent});
  }

  handleSelectChange(name, event) {
    let errors = this.state.errors;
    errors["playlist"] = name === 'playlistId' ? '' : errors["playlist"];
    errors["clockwheel"] = name === 'clockwheelId' ? '' : errors["clockwheel"];
    errors["duration"] = (name === 'durationMinutes' || name === 'durationHours') ? '' : errors["duration"];
    this.setState({
      [name]: event.value,
      "errors": errors
    });
  }

  handleCheckChange(event) {
    let newState = {};
    if (event.target.name.indexOf("|") > -1){
      let activeEvent = Object.assign({}, this.state.activeEvent);
      let attrs = event.target.name.split("|");
      // set current day default recurring...
      if (attrs[1] === "is_recurring") {
        let recDays = (activeEvent[attrs[0]]["recurring_days"]||[]).slice();
        let currentDayIndex = moment(activeEvent.attributes.start_time).day() - 1;
        recDays = recDays.filter(recDay => recDay !== currentDayIndex);
        recDays.push(currentDayIndex);
        activeEvent[attrs[0]]["recurring_days"] = recDays;
        newState["applyToAll"] = event.target.checked;
      }
      activeEvent[attrs[0]][attrs[1]] = event.target.checked;
    } else {
      newState[event.target.name] = event.target.value === "boolean" ? event.target.checked : event.target.value;
    }
    this.setState(newState);
  }

  handleCrossfadeCheckChange(event) {
    let activeEvent = Object.assign({}, this.state.activeEvent);
    if(!activeEvent["relationships"].hasOwnProperty("fade_option")) {
      activeEvent["relationships"] = { "fade_option": { "data": null } };
    }
    activeEvent["relationships"]["fade_option"]["data"] = event.target.value === "on" ? {"id": this.props.fades[0]["id"], "type": "fade_options"} : null;
    this.setState({activeEvent});
  }

  handleDayCheckChange(event) {
    let activeEvent = Object.assign({}, this.state.activeEvent);
    let recDays = activeEvent["attributes"]["recurring_days"].slice();
    const checkedValue = parseInt(event.target.value, 10);
    if (event.target.checked){
      recDays.push(checkedValue);
    } else {
      // remove weekday ID from list of recurring days
      recDays = recDays.filter(recDay => recDay !== checkedValue);
    }
    activeEvent["attributes"]["recurring_days"] = recDays;
    this.setState({activeEvent});
  }

  handleDateChange(dateName, date) {
    let activeEvent = Object.assign({}, this.state.activeEvent);
    activeEvent.attributes[dateName] = moment([date.getFullYear(), date.getMonth(), date.getDate(),
 date.getHours(), date.getMinutes(), date.getSeconds()]).toDate();
    this.setState({activeEvent});
  }

  eventWrapper(eventWrapperProps) {
    eventWrapperProps.children.props.style.borderColor = "red";
    return eventWrapperProps.children
  }

  handleNavigate(e) {
  }

  handleRangeChange(e) {
    let {
      calendarStart,
      calendarEnd
    } = this.state;

    if(e.hasOwnProperty('start') && e.hasOwnProperty('end')){
      calendarStart = moment(e.start);
      calendarEnd = moment(e.end);
    } else if ( Array.isArray(e) && e.length > 1 ) {
      calendarStart = moment(e[0]);
      calendarEnd = moment(e[e.length - 1]);
    } else if ( Array.isArray(e) && e.length === 1 ) {
      calendarStart = moment(e[0]);
      calendarEnd = calendarStart.endOf('day')
    }
    this.props.fetchEvents(this.state.stationId, `${calendarStart.add(-1, 'days').format("YYYY-MM-DD")}:${calendarEnd.add(1, 'days').format("YYYY-MM-DD")}`);
    this.setState({calendarStart, calendarEnd})
  }

  handleView(e) {
    this.setState({calendarView: e})
  }

  handleTabClick(tabName) {
    this.setState({ activeTab: tabName });
  }

  render() {
    const {
      addMode,
      addEventType,
      activeEvent,
      errors,
      playlistId,
      clockwheelId,
      activeTab,
      calendarEvents
    } = this.state;

    // console.log(calendarEvents);
    // const calendarEvents = this.buildEventsList();

    const { playlists, clockwheels} = this.props;
    const activeEventAttrs = (activeEvent && activeEvent.attributes) || {};
    const activeEventReadOnly = moment(activeEventAttrs.start_time).diff(moment()) < 0;
    const crossfadeEnabled = (activeEvent["relationships"]||{}).hasOwnProperty("fade_option") && activeEvent["relationships"]["fade_option"]["data"] !== null;

    let durationMinutes = Math.floor((activeEventAttrs.duration || 0) / 60 % 60);
    let durationHours = Math.floor((activeEventAttrs.duration || 0) / 60 / 60 );

    let initDate = moment().startOf('week').startOf('day').toDate();

    let playlistOptions = playlists.map((pl) => {
      return { value: pl.id, label: pl.attributes.title}
    });

    let clockwheelOptions = clockwheels.map((pl) => {
      return { value: pl.id, label: pl.attributes.name}
    });

    let startDate = activeEventAttrs.start_time ? new Date(moment(activeEventAttrs.start_time).format('YYYY-MM-DDTHH:mm:ss')) : new Date(moment().add(30 - (moment().minute() % 30), 'm').format('YYYY-MM-DDTHH:mm:ss'));
    let untilDate = activeEventAttrs.series_end ? new Date(moment(activeEventAttrs.series_end).format('YYYY-MM-DDTHH:mm:ss')) : this.state.defaultSeriesEndDate;
    let startDateString = ("0" + startDate.getDate()).slice(-2) + "-" + ("0"+(startDate.getMonth()+1)).slice(-2) + "-" +
    startDate.getFullYear() + " " + ("0" + startDate.getHours()).slice(-2) + ":" + ("0" + startDate.getMinutes()).slice(-2);

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Schedule > Events"/>
        <UikScrollArea className={ cls.mainContent }>
          <UikWidget className={ cls.widgetCalendar }>
            <UikWidgetHeader className={ cls.widgetCalendarHeader }
              style={{"paddingLeft": 0, "borderBottom": "none"}}
              rightEl={ (
              <div>
                <UikButton
                  className={ cls.addLg }
                  primary
                  style={ {
                    marginLeft: '10px',
                  } }
                  onClick={this.handleCreateEventModalOpen}
                >
                  Create Event
                </UikButton>
                <UikButton
                  className={ cls.addSm }
                  icon={ <Uikon>add</Uikon> }
                  iconOnly
                  primary
                  style={ {
                    marginLeft: '10px',
                  } }
                />
              </div>
              ) }
            >
              <small>The schedule is displayed in your local timezone, which is {moment.tz.guess()}</small>
            </UikWidgetHeader>
            {
              this.state.finishedProcessingEvents
              ? (
                  <DragAndDropCalendar
                    // selectable
                    step={15}
                    localizer={localizer}
                    events={calendarEvents}
                    onEventDrop={this.moveEvent}
                    defaultDate={initDate}
                    resizable
                    onEventResize={this.resizeEvent}
                    onSelectSlot={this.createEvent}
                    onDragStart={this.dragEventStart}
                    defaultView={Views.WEEK}
                    onNavigate={this.handleNavigate}
                    onRangeChange={this.handleRangeChange}
                    onView={this.handleView}
                    eventPropGetter={this.eventPropGetter}
                    components={{
                      event: EventComponent
                    }}
                    showMultiDayTimes
                  />
              )
              : (
                  <PacmanLoader loading />
              )
            }
          </UikWidget>
        </UikScrollArea>

        <Modal
            open={this.state.editEventModalOpen}
            onClose={this.handleEditEventModalClose}
          >
            <div className={ classnames(cls.modal, cls.large) }>
              <UikTabContainer className={ cls.modalTabHeader }>
                <UikTabItem
                  key="setup"
                  className={activeTab === "setup" ? "active" : ""}
                  onClick={() => this.handleTabClick("setup")}
                >
                  { addMode ? 'Add' : 'Edit' } Event
                </UikTabItem>
                {!addMode && this.props.activeEventTracks && (
                  <UikTabItem
                    key="tracks"
                    className={activeTab === "tracks" ? "active" : ""}
                    onClick={() => this.handleTabClick("tracks")}
                  >
                    Tracks
                  </UikTabItem>
                )}
              </UikTabContainer>

              {activeTab === "setup" && (
                <div>
                  <div className={ classnames(cls.modalBody, cls.vertSplitContent) } style={{"paddingBottom": 0}}>
                    <div>
                      <UikFormInputGroup>
                        <UikInput
                          label="Title"
                          name="title"
                          defaultValue={ activeEventAttrs.title }
                          onChange={ this.handleEditChange }
                          maxLength={40}
                          readOnly={activeEventReadOnly}
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup>
                        <label className="uik-content-title__wrapper">Start</label>
                        { activeEventAttrs["is_recurring"] ? (
                          <label className={cls.readOnlyLabel}>{ startDateString }</label>
                          ) : (
                          <DatePicker
                            selected={startDate}
                            onChange={this.handleDateChange.bind(this, 'start_time')}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            readOnly={activeEventReadOnly && !addMode}
                          />
                        )}
                      </UikFormInputGroup>
                      <UikFormInputGroup>
                        <label className="uik-content-title__wrapper">Duration</label>
                        <div className={ cls.durationSelects }>
                          {durationHours !== undefined && (
                            <UikSelect
                              name="duration_hour"
                              className={ cls.select }
                              defaultValue={ [durationHours] }
                              onChange={ this.handleSelectChange.bind(this, 'durationHours') }
                              options={ [{value: 0,label: "00"},{value: 1,label: "01"},{value: 2,label: "02"},{value: 3,label: "03"},{value: 4,label: "04"},{value: 5,label: "05"},{value: 6,label: "06"},{value: 7,label: "07"},{value: 8,label: "08"},{value: 9,label: "09"},{value: 10,label: "10"},{value: 11,label: "11"},{value: 12,label: "12"},{value: 13,label: "13"},{value: 14,label: "14"},{value: 15,label: "15"},{value: 16,label: "16"},{value: 17,label: "17"},{value: 18,label: "18"},{value: 19,label: "19"},{value: 20,label: "20"},{value: 21,label: "21"},{value: 22,label: "22"},{value: 23,label: "23"}] }
                              readOnly={activeEventReadOnly}
                            />
                          )}
                          <span>:</span>
                          {durationMinutes !== undefined && (
                            <UikSelect
                              name="duration_minute"
                              className={ cls.select }
                              defaultValue={ [durationMinutes] }
                              onChange={ this.handleSelectChange.bind(this, 'durationMinutes') }
                              options={ [{value:0, label: "00"},{value:1, label: "01"},{value:2, label: "02"},{value:3, label: "03"},{value:4, label: "04"},{value:5, label: "05"},{value:6, label: "06"},{value:7, label: "07"},{value:8, label: "08"},{value:9, label: "09"},{value:10, label: "10"},{value:11, label: "11"},{value:12, label: "12"},{value:13, label: "13"},{value:14, label: "14"},{value:15, label: "15"},{value:16, label: "16"},{value:17, label: "17"},{value:18, label: "18"},{value:19, label: "19"},{value:20, label: "20"},{value:21, label: "21"},{value:22, label: "22"},{value:23, label: "23"},{value:24, label: "24"},{value:25, label: "25"},{value:26, label: "26"},{value:27, label: "27"},{value:28, label: "28"},{value:29, label: "29"},{value:30, label: "30"},{value:31, label: "31"},{value:32, label: "32"},{value:33, label: "33"},{value:34, label: "34"},{value:35, label: "35"},{value:36, label: "36"},{value:37, label: "37"},{value:38, label: "38"},{value:39, label: "39"},{value:40, label: "40"},{value:41, label: "41"},{value:42, label: "42"},{value:43, label: "43"},{value:44, label: "44"},{value:45, label: "45"},{value:46, label: "46"},{value:47, label: "47"},{value:48, label: "48"},{value:49, label: "49"},{value:50, label: "50"},{value:51, label: "51"},{value:52, label: "52"},{value:53, label: "53"},{value:54, label: "54"},{value:55, label: "55"},{value:56, label: "56"},{value:57, label: "57"},{value:58, label: "58"},{value:59, label: "59"}] }
                              readOnly={activeEventReadOnly}
                            />
                          )}
                        </div>
                        { errors["duration"] &&
                          <div className={cls.error}>{errors["duration"]}</div>
                        }
                      </UikFormInputGroup>
                      { activeEventAttrs["is_recurring"] ?
                        <label className={ classnames("uik-content-title__wrapper", cls.checkboxLabel) }>Recurring</label>
                        :
                        <UikFormInputGroup className={ cls.marginTop }>
                          <div className={ classnames(cls.marginTop) }>
                            <div>
                              <UikCheckbox
                                label="Recurring"
                                name="attributes|is_recurring"
                                onChange={ this.handleCheckChange }
                                defaultChecked={activeEventAttrs["is_recurring"]}
                                readOnly={activeEventReadOnly}
                              />
                            </div>
                          </div>
                        </UikFormInputGroup>
                      }
                    </div>
                    <div>
                      <UikFormInputGroup>
                        <label className={ classnames("uik-content-title__wrapper", cls.checkboxLabel) }>Source</label>
                        <div className={ cls.horizontalInputs }>
                          <UikRadio
                            label="Playlist"
                            name="addEventType"
                            value="playlist"
                            onChange={ this.handleCheckChange }
                            readOnly={ activeEventReadOnly }
                            defaultChecked={addEventType === "playlist"}
                          />
                          <UikRadio
                            label="Clockwheel"
                            name="addEventType"
                            value="clockwheel"
                            onChange={ this.handleCheckChange }
                            readOnly={ activeEventReadOnly }
                            defaultChecked={addEventType === "clockwheel"}
                          />
                        </div>
                      </UikFormInputGroup>
                      { addEventType === "playlist" && (
                        <UikFormInputGroup className={ cls.marginTop }>
                          <UikSelect
                            name="playlist"
                            label="Playlist"
                            onChange={ this.handleSelectChange.bind(this, 'playlistId') }
                            defaultValue={ [playlistId] }
                            options={ [...playlistOptions] }
                            readOnly={activeEventReadOnly}
                          />
                          { errors["playlist"] &&
                            <div className={cls.error}>{errors["playlist"]}</div>
                          }
                        </UikFormInputGroup>
                      )}
                      { addEventType === "clockwheel" && (
                        <UikFormInputGroup className={ cls.marginTop }>
                          <UikSelect
                            name="clockwheel"
                            label="Clockwheel"
                            onChange={ this.handleSelectChange.bind(this, 'clockwheelId') }
                            defaultValue={ [clockwheelId] }
                            options={ [...clockwheelOptions] }
                            readOnly={activeEventReadOnly}
                          />
                          { errors["clockwheel"] &&
                            <div className={cls.error}>{errors["clockwheel"]}</div>
                          }
                        </UikFormInputGroup>
                      )}
                      <UikFormInputGroup>
                        <label className={ classnames("uik-content-title__wrapper", cls.checkboxLabel) }>
                          Scheduling
                        </label>
                        <div className={ cls.horizontalInputs }>
                          <label>
                            <UikRadio
                              label="Flexible"
                              name="attributes|strict_start"
                              defaultChecked={!activeEventAttrs["strict_start"]}
                              onChange={ this.handleCheckChange }
                              readOnly={activeEventReadOnly}
                            />
                            <Tooltip
                              title="Flexible: Let the current track finish before starting this event. The start of this event may be delayed."
                              placement="top"
                              className={ cls.tooltip }>
                              <i className="fas fa-question-circle"></i>
                            </Tooltip>
                          </label>
                          <label>
                            <UikRadio
                              label="Strict"
                              name="attributes|strict_start"
                              defaultChecked={activeEventAttrs["strict_start"]}
                              onChange={ this.handleCheckChange }
                              readOnly={activeEventReadOnly}
                            />
                            <Tooltip
                              title="Strict: Start this event at the scheduled time, cutting off the current track."
                              placement="top"
                              className={ cls.tooltip }>
                              <i className="fas fa-question-circle"></i>
                            </Tooltip>
                          </label>
                        </div>
                      </UikFormInputGroup>

                      <UikFormInputGroup className={ cls.marginTop }>
                        <div className={ classnames(cls.marginTop) }>
                          <div>
                            <UikCheckbox
                              label="Crossfade"
                              name="crossfade"
                              onChange={ this.handleCrossfadeCheckChange }
                              defaultChecked={ crossfadeEnabled }
                              readOnly={ activeEventReadOnly }
                            />
                          </div>
                        </div>
                      </UikFormInputGroup>
                    </div>
                  </div>
                  <div className={ classnames(cls.recurringRow) } style={{"paddingTop": 0}}>
                    { activeEventAttrs["is_recurring"] && (
                      <UikFormInputGroup className={ cls.marginTop }>
                        <div className={ classnames(cls.horizontalInputs,cls.marginTop) }>
                          { weekDays.map((item, index) => (
                            <UikCheckbox
                              label={item}
                              key={`recurringDaysCB${index}`}
                              name="recurring_days"
                              value={index}
                              onChange={ this.handleDayCheckChange }
                              defaultChecked={(activeEventAttrs["recurring_days"]||[]).indexOf(index) > -1}
                              readOnly={activeEventReadOnly}
                            />
                          ))}
                        </div>

                      </UikFormInputGroup>
                    ) }
                      <div className={ classnames(cls.vertSplitContent) }>
                        { activeEventAttrs["is_recurring"] && (
                          <UikFormInputGroup>
                            <label className="uik-content-title__wrapper">Until</label>
                            <DatePicker
                              selected={untilDate}
                              onChange={this.handleDateChange.bind(this, 'series_end')}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="MMMM d, yyyy h:mm aa"
                              timeCaption="time"
                              readOnly={activeEventReadOnly}
                            />
                          </UikFormInputGroup>
                        ) }
                        { !addMode && activeEventAttrs["is_recurring"] && (
                          <UikFormInputGroup className={ cls.applyToAll }>
                            <div>
                              <UikCheckbox
                                label="Apply to all events"
                                name="applyToAll"
                                value={ "boolean" }
                                onChange={ this.handleCheckChange }
                                readOnly={ activeEventReadOnly }
                                defaultChecked={ this.state.applyToAll }
                              />
                              <Tooltip
                                title="Apply changes to all events in this recurring event series"
                                placement="top"
                                className={ cls.tooltip }>
                                <i className="fas fa-question-circle"></i>
                              </Tooltip>
                            </div>
                          </UikFormInputGroup>
                        )}
                      </div>
                  </div>
                </div>
              )}

              {activeTab === "tracks" && (
                <div className={ classnames(cls.modalBody, cls.vertSplitContent, cls.modalBodyScroll) }>
                  <UikWidgetTable className={ cls.tracksTable }>
                    <thead>
                      <tr><th>Start Time</th><th>Title</th><th>Artist</th><th>Album</th></tr>
                    </thead>
                    <tbody>
                      { this.props.activeEventTracks.map((track, index) => (
                        <tr key={`event-track-${index}`}>
                          <td>{computeStartTime(track, index, this.props.activeEventTracks, activeEvent)}</td>
                          <td>{track.attributes.title}</td>
                          <td>{track.attributes.artist}</td>
                          <td>{track.attributes.album}</td>
                        </tr>
                      ))}
                    </tbody>
                  </UikWidgetTable>
                </div>
              )}
              <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton) }>
                { !activeEventReadOnly && !addMode && !activeEventAttrs["is_recurring"] &&
                  <div style={{"flexGrow":"2"}}><UikButton error onClick={this.handleDeleteEventClick}>Delete</UikButton></div>
                }
                { !activeEventReadOnly && !addMode && activeEventAttrs["is_recurring"] &&
                  <div style={{"flexGrow":"2"}}><UikButton error onClick={this.handleDeleteInstanceEventClick}>Delete Single Event</UikButton>
                  &nbsp;
                  <UikButton error onClick={this.handleDeleteEventClick}>Delete All</UikButton></div>
                }
                { activeEventReadOnly && addMode &&
                  <div className={cls.warningMessage}>Events can't be scheduled in the past.</div>
                }
                { activeEventReadOnly && !addMode &&
                  <div className={cls.warningMessage}>The event is in the past and cannot be edited.</div>
                }
                <UikButton onClick={this.handleEditEventModalClose}>{ activeEventReadOnly ? "Close" : "Cancel" }</UikButton>
                { !activeEventReadOnly &&
                  <div><UikButton success onClick={this.handleEditEventModalClick}>Save</UikButton></div>
                }
              </div>
            </div>
          </Modal>
      </UikContainerVertical>
    )
  }
}

class EventComponent extends React.Component {
  render() {
    const ev = this.props.event;
    return <div><span className="time">{moment(ev.start).format("HH:mm") }</span> <span className="title">{ ev.title }</span></div>;
  }
}

export default connect(
  store => ({
    events: store.events.eventsList.events,
    eventsError: store.events.eventsList.error,
    eventsLoading: store.events.eventsList.loading,
    fades: store.fades.fadesList.fades,
    newEvent: store.events.newEvent,
    deletedEvent: store.events.deletedEvent,
    playlists: store.playlists.playlistsList.playlists,
    playlistsLoading: store.playlists.playlistsList.loading,
    clockwheels: store.clockwheels.clockwheelsList.clockwheels,
    clockwheelsLoading: store.clockwheels.clockwheelsList.loading,
    loggedInUser: store.users.loggedInUser,
    activeEventTracks: store.events.activeEventTracks.tracksList,
    activeStation: store.stations.activeStation
  }),
  {
    fetchClockwheels: clockwheelActions.fetchClockwheels,
    fetchPlaylists: playlistActions.fetchPlaylists,
    fetchEvents: actions.fetchEvents,
    updateEvent: actions.updateEvent,
    deleteEvent: actions.deleteEvent,
    createEvent: actions.createEvent,
    createTwoEvents: actions.createTwoEvents,
    fetchFades: fadeActions.fetchFades,
    getActiveEventTracks: actions.getActiveEventTracks,
    resetActiveEventTracks: actions.resetActiveEventTracks
  },
)(SchedulePage)

const computeStartTime = (track, index, tracksList, event) => {
  var date = new Date(event.attributes.start_time);

  if (index) {
    const elapsedSeconds = tracksList.slice(0, index).reduce((acc, current) => acc + parseInt(current.attributes.length, 10) / 1e6, 0);
    date.setSeconds(date.getSeconds() + elapsedSeconds);
  }

  return date.toLocaleString();
}

function getContrastYIQ(hexcolor){
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return isNaN(yiq) || (yiq >= 90) ? 'black' : 'white';
}

function RGBAToHexA(rgba) {
  rgba = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return (rgba && rgba.length === 4) ? "#" +
    ("0" + parseInt(rgba[1],10).toString(16)).slice(-2) +
    ("0" + parseInt(rgba[2],10).toString(16)).slice(-2) +
    ("0" + parseInt(rgba[3],10).toString(16)).slice(-2) : '';
}

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

function isSameWeekDay(momentDay, apiDay) {
  // moment's `day()` returns 0 for Sunday and 6 for Saturday
  // API stores days as 0 for Monday and 6 for Sunday

  return momentDay - apiDay === 1 || momentDay - apiDay === -6
}

function shouldScheduleRecurring(momentTimestamp, eventRecurringDays, stationTimezone) {
  for (let day of eventRecurringDays) {
    if (isSameWeekDay(momentTimestamp.tz(stationTimezone).day(), day)) {
      return true;
    }
  }
  return false;
}

// Libraries
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// Containers

import ServiceDashboard from './ServiceDashboard'
import AccountDashboard from './AccountDashboard'
import SignUp from './SignIn/signup'
import SignIn from './SignIn'
import ForgotPassword from './SignIn/forgot-password'
import ResetPassword from './SignIn/reset-password'


const Dashboards = () => (
  <Switch>
    <Route
      component={ SignIn }
      exact
      path="/signin"
      strict
    />
    <Route
      component={ SignUp }
      exact
      path="/signup"
      strict
    />
    <Route
      component={ AccountDashboard }
      path="/account"
    />
    <Route
      component={ ServiceDashboard }
      path="/stations"
    />
    <Route
      component={ ForgotPassword }
      exact
      path="/forgot-password"
      strict
    />
    <Route
      component={ ResetPassword }
      exact
      path="/reset-password"
      strict
    />
    <Route
      component={ SignIn }
      path="/"
    />
    <Redirect to="/" />
  </Switch>
)

export default Dashboards

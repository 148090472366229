import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import * as configApp from '@redux/constants'
import { push } from 'react-router-redux'

export const FETCH_REALTIME_SESSIONS = 'FETCH_REALTIME_SESSIONS';
export const FETCH_REALTIME_SESSIONS_PAGE_SUCCESS = 'FETCH_REALTIME_SESSIONS_PAGE_SUCCESS';
export const FETCH_REALTIME_SESSIONS_SUCCESS = 'FETCH_REALTIME_SESSIONS_SUCCESS';
export const FETCH_REALTIME_SESSIONS_FAILURE = 'FETCH_REALTIME_SESSIONS_FAILURE';

export const FETCH_REALTIME_STATIONS = 'FETCH_REALTIME_STATIONS';
export const FETCH_REALTIME_STATIONS_SUCCESS = 'FETCH_REALTIME_STATIONS_SUCCESS';
export const FETCH_REALTIME_STATIONS_FAILURE = 'FETCH_REALTIME_STATIONS_FAILURE';

export const FETCH_REALTIME_COUNTRIES = 'FETCH_REALTIME_COUNTRIES';
export const FETCH_REALTIME_COUNTRIES_SUCCESS = 'FETCH_REALTIME_COUNTRIES_SUCCESS';
export const FETCH_REALTIME_COUNTRIES_FAILURE = 'FETCH_REALTIME_COUNTRIES_FAILURE';

export const FETCH_REALTIME_PLATFORMS = 'FETCH_REALTIME_PLATFORMS';
export const FETCH_REALTIME_PLATFORMS_SUCCESS = 'FETCH_REALTIME_PLATFORMS_SUCCESS';
export const FETCH_REALTIME_PLATFORMS_FAILURE = 'FETCH_REALTIME_PLATFORMS_FAILURE';

export const FETCH_HISTORICAL_LISTENERS = 'FETCH_HISTORICAL_LISTENERS';
export const FETCH_HISTORICAL_LISTENERS_SUCCESS = 'FETCH_HISTORICAL_LISTENERS_SUCCESS';
export const FETCH_HISTORICAL_LISTENERS_FAILURE = 'FETCH_HISTORICAL_LISTENERS_FAILURE';

export const FETCH_HISTORICAL_STATIONS = 'FETCH_HISTORICAL_STATIONS';
export const FETCH_HISTORICAL_STATIONS_SUCCESS = 'FETCH_HISTORICAL_STATIONS_SUCCESS';
export const FETCH_HISTORICAL_STATIONS_FAILURE = 'FETCH_HISTORICAL_STATIONS_FAILURE';

export const FETCH_HISTORICAL_COUNTRIES = 'FETCH_HISTORICAL_COUNTRIES';
export const FETCH_HISTORICAL_COUNTRIES_SUCCESS = 'FETCH_HISTORICAL_COUNTRIES_SUCCESS';
export const FETCH_HISTORICAL_COUNTRIES_FAILURE = 'FETCH_HISTORICAL_COUNTRIES_FAILURE';

export const FETCH_HISTORICAL_PLATFORMS = 'FETCH_HISTORICAL_PLATFORMS';
export const FETCH_HISTORICAL_PLATFORMS_SUCCESS = 'FETCH_HISTORICAL_PLATFORMS_SUCCESS';
export const FETCH_HISTORICAL_PLATFORMS_FAILURE = 'FETCH_HISTORICAL_PLATFORMS_FAILURE';

export const FETCH_REVENUE_STATS = 'FETCH_REVENUE_STATS';
export const FETCH_REVENUE_STATS_SUCCESS = 'FETCH_REVENUE_STATS_SUCCESS';
export const FETCH_REVENUE_STATS_ERROR = 'FETCH_REVENUE_STATUS_ERROR';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestRealtimeSessions = () => ({
  type: FETCH_REALTIME_SESSIONS,
})

export const receiveRealtimeSessionsSuccess = () => ({
  type: FETCH_REALTIME_SESSIONS_SUCCESS
})

export const receiveRealtimeSessions = (json) => ({
  type: FETCH_REALTIME_SESSIONS_PAGE_SUCCESS,
  payload: json
})

export function fetchRealtimeSessionsFailure(error) {
  return {
    type: FETCH_REALTIME_SESSIONS_FAILURE,
    payload: error
  };
}

export const requestRealtimeStations = () => ({
  type: FETCH_REALTIME_STATIONS,
})

export const receiveRealtimeStations = (json) => ({
  type: FETCH_REALTIME_STATIONS_SUCCESS,
  payload: json
})

export function fetchRealtimeStationsFailure(error) {
  return {
    type: FETCH_REALTIME_STATIONS_FAILURE,
    payload: error
  };
}

export const requestRealtimeCountries = () => ({
  type: FETCH_REALTIME_COUNTRIES,
})

export const receiveRealtimeCountries = (json) => ({
  type: FETCH_REALTIME_COUNTRIES_SUCCESS,
  payload: json
})

export function fetchRealtimeCountriesFailure(error) {
  return {
    type: FETCH_REALTIME_COUNTRIES_FAILURE,
    payload: error
  };
}


export const requestRealtimePlatforms = () => ({
  type: FETCH_REALTIME_PLATFORMS,
})

export const receiveRealtimePlatforms = (json) => ({
  type: FETCH_REALTIME_PLATFORMS_SUCCESS,
  payload: json
})

export function fetchRealtimePlatformsFailure(error) {
  return {
    type: FETCH_REALTIME_PLATFORMS_FAILURE,
    payload: error
  };
}

export const requestRevenueStats = () => ({
  type: FETCH_REVENUE_STATS
})

export const fetchRevenueStatsSuccess = (json) => ({
  type: FETCH_REVENUE_STATS_SUCCESS,
  payload: json
})

export const fetchRevenueStatsError = (error) => ({
  type: FETCH_REVENUE_STATS_SUCCESS,
  payload: error
})


export const requestHistoricalListeners = () => ({
  type: FETCH_HISTORICAL_LISTENERS,
})

export const receiveHistoricalListeners = (json) => ({
  type: FETCH_HISTORICAL_LISTENERS_SUCCESS,
  payload: json
})

export function fetchHistoricalListenersFailure(error) {
  return {
    type: FETCH_HISTORICAL_LISTENERS_FAILURE,
    payload: error
  };
}

export const requestHistoricalStations = () => ({
  type: FETCH_HISTORICAL_STATIONS,
})

export const receiveHistoricalStations = (json) => ({
  type: FETCH_HISTORICAL_STATIONS_SUCCESS,
  payload: json
})

export function fetchHistoricalStationsFailure(error) {
  return {
    type: FETCH_HISTORICAL_STATIONS_FAILURE,
    payload: error
  };
}

export const requestHistoricalCountries = () => ({
  type: FETCH_HISTORICAL_COUNTRIES,
})

export const receiveHistoricalCountries = (json) => ({
  type: FETCH_HISTORICAL_COUNTRIES_SUCCESS,
  payload: json
})

export function fetchHistoricalCountriesFailure(error) {
  return {
    type: FETCH_HISTORICAL_COUNTRIES_FAILURE,
    payload: error
  };
}


export const requestHistoricalPlatforms = () => ({
  type: FETCH_HISTORICAL_PLATFORMS,
})

export const receiveHistoricalPlatforms = (json) => ({
  type: FETCH_HISTORICAL_PLATFORMS_SUCCESS,
  payload: json
})

export function fetchHistoricalPlatformsFailure(error) {
  return {
    type: FETCH_HISTORICAL_PLATFORMS_FAILURE,
    payload: error
  };
}

export function fetchHistoricalListeners({stationIds, startDate, endDate, granularity}) {

  return function(dispatch) {

    dispatch(requestHistoricalListeners())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "stations": stationIds,
      "start_date": startDate || "2019-06-01T00:00:00Z",
      "end_date": endDate || "2019-07-01T00:00:00Z",
      "granularity": granularity || "day"
    };

    return fetch(`${BROADCASTER_ROOT_URL}stats/historical/listeners/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchHistoricalListenersFailure(msg))
        } else {
          dispatch(receiveHistoricalListeners(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchHistoricalStations({stationIds, startDate, endDate}) {

  return function(dispatch) {

    dispatch(requestHistoricalStations())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "stations": stationIds,
      "start_date": startDate || "2019-06-01T00:00:00Z",
      "end_date": endDate || "2019-07-01T00:00:00Z",
    };

    return fetch(`${BROADCASTER_ROOT_URL}stats/historical/stations/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchHistoricalStationsFailure(msg))
        } else {
          dispatch(receiveHistoricalStations(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}



export function fetchHistoricalCountries({stationIds, startDate, endDate, countries}) {

  return function(dispatch) {

    dispatch(requestHistoricalCountries())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "stations": stationIds,
      "start_date": startDate || "2019-06-01T00:00:00Z",
      "end_date": endDate || "2019-07-01T00:00:00Z",
      "countries": countries
    };

    return fetch(`${BROADCASTER_ROOT_URL}stats/historical/countries/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchHistoricalCountriesFailure(msg))
        } else {
          dispatch(receiveHistoricalCountries(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}



export function fetchHistoricalPlatforms({stationIds, startDate, endDate, platforms}) {

  return function(dispatch) {

    dispatch(requestHistoricalPlatforms())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "stations": stationIds,
      "start_date": startDate || "2019-06-01T00:00:00Z",
      "end_date": endDate || "2019-07-01T00:00:00Z"
    };

    return fetch(`${BROADCASTER_ROOT_URL}stats/historical/platforms/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchHistoricalPlatformsFailure(msg))
        } else {
          dispatch(receiveHistoricalPlatforms(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}


function fetchAllSessions(dispatch, stationId, page) {
  let url = `${BROADCASTER_ROOT_URL}stats/realtime/sessions/?page=${page}`;
  const bearerToken = ls.get('bearerToken') || null;

  let stationIds = new Array();
  if (Array.isArray(stationId) && stationId.length > 1) {
    console.log('Danger!  Array of stations passed to an auto unpaginating method.  Multiple station results cannot be unpaginated')
    stationIds[0] = stationId[0].toString();
  }
  else
    stationIds[0] = stationId.toString();

  let data = {
    "stations": stationIds
  };

  return fetch(url, {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status === 401) {
      dispatch(push('/'));
      return Promise.resolve();
    }

    return response.json();
  }).then(response => {
    if (response["errors"] && response["errors"].length){
      let msg = response["errors"][0]["detail"];
      toast.error('Please contact support: ' + msg);
      return;
    }
    if (Object.keys(response.data.sessions) && stationIds[0] in response.data.sessions)
      dispatch(receiveRealtimeSessions(response.data.sessions[stationIds[0]]));

    if (response['links'] && response['links']['next']) {
      fetchAllSessions(dispatch, stationId, page + 1).then(() => Promise.resolve());
    } else {
      dispatch(receiveRealtimeSessionsSuccess())
    }

    return true;
  })
}


export function fetchRealtimeSessions({stationIds}) {

  return function(dispatch) {

    dispatch(requestRealtimeSessions())

    fetchAllSessions(dispatch, stationIds, 1).then(() => Promise.resolve());
  }
}

export function fetchRealtimeStations({stationIds}) {

  return function(dispatch) {

    dispatch(requestRealtimeStations())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "stations": stationIds
    };

    return fetch(`${BROADCASTER_ROOT_URL}stats/realtime/stations/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchRealtimeStationsFailure(msg))
        } else {
          dispatch(receiveRealtimeStations(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}



export function fetchRealtimeCountries({stationIds, countries}) {

  return function(dispatch) {

    dispatch(requestRealtimeCountries())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "stations": stationIds,
      "countries": []
    };

    return fetch(`${BROADCASTER_ROOT_URL}stats/realtime/countries/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchRealtimeCountriesFailure(msg))
        } else {
          dispatch(receiveRealtimeCountries(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}



export function fetchRealtimePlatforms({stationIds, platforms}) {

  return function(dispatch) {

    dispatch(requestRealtimePlatforms())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "stations": stationIds
    };

    return fetch(`${BROADCASTER_ROOT_URL}stats/realtime/platforms/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchRealtimePlatformsFailure(msg))
        } else {
          dispatch(receiveRealtimePlatforms(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchRevenueStats(stationId, startDate, endDate) {
  return function(dispatch) {
    console.log(stationId, startDate, endDate)
    dispatch(requestRevenueStats())

    const bearerToken = ls.get('bearerToken') || null;
    const startDateParam = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() + 1}-${startDate.getUTCDate()}`;
    const endDateParam = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()}`;
    const url = `${BROADCASTER_ROOT_URL}stats/revenue/${stationId}?start_date=${startDateParam}&end_date=${endDateParam}`;

    return fetch(url, {
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
          dispatch(fetchRevenueStatsError(msg))
        } else {
          dispatch(fetchRevenueStatsSuccess(json))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'
import { updateAutoDJSettings } from '../autoDJSettings/actions'

//Separation list
export const FETCH_SEPARATIONS = 'FETCH_SEPARATIONS';
export const FETCH_SEPARATIONS_SUCCESS = 'FETCH_SEPARATIONS_SUCCESS';
export const FETCH_SEPARATIONS_FAILURE = 'FETCH_SEPARATIONS_FAILURE';
export const RESET_SEPARATIONS = 'RESET_SEPARATIONS';

//Create new SEPARATION
export const CREATE_SEPARATION = 'CREATE_SEPARATION';
export const CREATE_SEPARATION_SUCCESS = 'CREATE_SEPARATION_SUCCESS';
export const CREATE_SEPARATION_FAILURE = 'CREATE_SEPARATION_FAILURE';
export const RESET_NEW_SEPARATION = 'RESET_NEW_SEPARATION';

//Update SEPARATION
export const UPDATE_SEPARATION = 'UPDATE_SEPARATION';
export const UPDATE_SEPARATION_SUCCESS = 'UPDATE_SEPARATION_SUCCESS';
export const UPDATE_SEPARATION_FAILURE = 'UPDATE_SEPARATION_FAILURE';

//Fetch SEPARATION
export const FETCH_SEPARATION = 'FETCH_SEPARATION';
export const FETCH_SEPARATION_SUCCESS = 'FETCH_SEPARATION_SUCCESS';
export const FETCH_SEPARATION_FAILURE = 'FETCH_SEPARATION_FAILURE';
export const RESET_ACTIVE_SEPARATION = 'RESET_ACTIVE_SEPARATION';

//Delete SEPARATION
export const DELETE_SEPARATION = 'DELETE_SEPARATION';
export const DELETE_SEPARATION_SUCCESS = 'DELETE_SEPARATION_SUCCESS';
export const DELETE_SEPARATION_FAILURE = 'DELETE_SEPARATION_FAILURE';
export const RESET_DELETED_SEPARATION = 'RESET_DELETED_SEPARATION';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestSeparations = () => ({
  type: FETCH_SEPARATIONS,
})

export const receiveSeparations = (json) => ({
  type: FETCH_SEPARATIONS_SUCCESS,
  payload: json
})

export const requestSeparation = () => ({
  type: FETCH_SEPARATION,
})

export const receiveSeparation = (json) => ({
  type: FETCH_SEPARATION_SUCCESS,
  payload: json
})

export const requestDeleteSeparation = () => ({
  type: DELETE_SEPARATION,
})

export const separationDeleted = (cwId) => ({
  type: DELETE_SEPARATION_SUCCESS,
  id: cwId
})

export const requestCreateSeparation = () => ({
  type: CREATE_SEPARATION,
})

export const requestUpdateSeparation = () => ({
  type: UPDATE_SEPARATION,
})

export const separationUpdated = (json) => ({
  type: UPDATE_SEPARATION_SUCCESS,
  payload: json
})

export function fetchSeparations(stationId) {

  return function(dispatch) {

    dispatch(requestSeparations())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}separation-rules/?filter[station]=${stationId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveSeparations(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchSeparation(fId) {

  return function(dispatch) {

    dispatch(requestSeparation())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}separation-rules/${fId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveSeparation(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createSeparation({separation, autoDJId, stationId}) {

  return function(dispatch) {

    dispatch(requestCreateSeparation())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "separation_rules",
        "attributes": separation.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}separation-rules/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          console.log("------------")
          console.log("------------")
          console.log("------------")
          console.log("------------")
          console.log("json", json)
          // update autodj setting with relationship link
          let newAutoDJ = {
            "id": autoDJId,
            "relationships": {
              "separation_rule": {
                "data": {
                  "id": json.data.id,
                  "type": "separation_rules"
                }
              }
            }
          }
          dispatch(updateAutoDJSettings(newAutoDJ));
          toast.success("Separation has been created !");
          // dispatch(createSeparationSuccess(json.data))
        };
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateSeparation(separation) {

  return function(dispatch) {

    dispatch(requestUpdateSeparation())

    const bearerToken = ls.get('bearerToken') || null;

    Object.keys(separation.attributes).forEach((key) => (separation.attributes[key] === null) && delete separation.attributes[key]);

    let data = {
      "data": {
        "type": "separation_rules",
        "id": separation.id + "",
        "attributes": separation.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}separation-rules/${separation.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Separation updated !");
          dispatch(separationUpdated(json.data))
        };
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deleteSeparation(cwId) {

  return function(dispatch) {

    dispatch(requestDeleteSeparation())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}separation-rules/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Separation has been deleted !");
        dispatch(separationDeleted(cwId))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function fetchSeparationSuccess(separations) {
  return {
    type: FETCH_SEPARATIONS_SUCCESS,
    payload: separations
  };
}

export function fetchSeparationFailure(error) {
  return {
    type: FETCH_SEPARATIONS_FAILURE,
    payload: error
  };
}

export function createSeparationSuccess(newSeparation) {
  return {
    type: CREATE_SEPARATION_SUCCESS,
    payload: newSeparation
  };
}

export function createSeparationFailure(error) {
  return {
    type: CREATE_SEPARATION_FAILURE,
    payload: error
  };
}

export function resetNewSeparation() {
  return {
    type: RESET_NEW_SEPARATION
  }
}
;

export function resetDeletedSeparation() {
  return {
    type: RESET_DELETED_SEPARATION
  }
}


export function resetActiveSeparation() {
  return {
    type: RESET_ACTIVE_SEPARATION
  }
}

export function deleteSeparationFailure(response) {
  return {
    type: DELETE_SEPARATION_FAILURE,
    payload: response
  };
}

import initialState from './initialState'

import {
  FETCH_TRACKS_CATEGORIES, FETCH_TRACKS_CATEGORIES_SUCCESS, FETCH_TRACKS_CATEGORIES_FAILURE, RESET_TRACKS_CATEGORIES, FETCH_TRACKS_CATEGORIES_PAGE_SUCCESS,
  UPDATE_TRACKS_CATEGORY, UPDATE_TRACKS_CATEGORY_SUCCESS,  UPDATE_TRACKS_CATEGORY_FAILURE,
  FETCH_TRACKS_CATEGORY, FETCH_TRACKS_CATEGORY_SUCCESS,  FETCH_TRACKS_CATEGORY_FAILURE, RESET_ACTIVE_TRACKS_CATEGORY,
  CREATE_TRACKS_CATEGORY, CREATE_TRACKS_CATEGORY_SUCCESS, CREATE_TRACKS_CATEGORY_FAILURE, RESET_NEW_TRACKS_CATEGORY,
  DELETE_TRACKS_CATEGORY, DELETE_TRACKS_CATEGORY_SUCCESS, DELETE_TRACKS_CATEGORY_FAILURE, RESET_DELETED_TRACKS_CATEGORY,
} from './actions';

export default function(state = initialState, action) {
  let error, newCats;
  switch(action.type) {

  case FETCH_TRACKS_CATEGORIES:// start fetching tracksCategories and set loading = true
    return { ...state, tracksCategoriesList: {tracksCategories:[], error: null, loading: true} };
  case FETCH_TRACKS_CATEGORIES_PAGE_SUCCESS:
    return { ...state, tracksCategoriesList: {tracksCategories: [...state.tracksCategoriesList.tracksCategories, ...action.payload], error: null, loading: true}};
  case FETCH_TRACKS_CATEGORIES_SUCCESS:
    return { ...state, tracksCategoriesList: {tracksCategories: state.tracksCategoriesList.tracksCategories, error:null, loading: false} };
  case FETCH_TRACKS_CATEGORIES_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, tracksCategoriesList: {tracksCategories: [], error: error, loading: false} };
  case RESET_TRACKS_CATEGORIES:// reset categoryList to initial state
    return { ...state, tracksCategoriesList: {tracksCategories: [], error:null, loading: false} };

  case FETCH_TRACKS_CATEGORY:
    return { ...state, activeTracksCategory:{...state.activeTracksCategory, loading: true}};
  case FETCH_TRACKS_CATEGORY_SUCCESS:
    return { ...state, activeTracksCategory: {category: action.payload, error:null, loading: false}};
  case FETCH_TRACKS_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeTracksCategory: {category: null, error:error, loading:false}};
  case RESET_ACTIVE_TRACKS_CATEGORY:
    return { ...state, activeTracksCategory: {category: null, error:null, loading: false}};

  case UPDATE_TRACKS_CATEGORY:
    return { ...state, activeTracksCategory:{...state.activeTracksCategory, loading: true}};
  case UPDATE_TRACKS_CATEGORY_SUCCESS:
    return { ...state, activeTracksCategory: {category: action.payload, error:null, loading: false}};
  case UPDATE_TRACKS_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeTracksCategory: {category: null, error:error, loading:false}};

  case CREATE_TRACKS_CATEGORY:
    return {...state, newTracksCategory: {...state.newTracksCategory, loading: true}}
  case CREATE_TRACKS_CATEGORY_SUCCESS:
    newCats = state.tracksCategoriesList.tracksCategories.slice();
    newCats.push(action.payload);
    return {...state,
      tracksCategoriesList: { tracksCategories: newCats },
      newTracksCategory: {category:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_TRACKS_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newTracksCategory: {category:null, error:error, loading: false}}
  case RESET_NEW_TRACKS_CATEGORY:
    return {...state,  newTracksCategory:{category:null, error:null, loading: false}}

  case DELETE_TRACKS_CATEGORY:
     return {...state, deletedTracksCategory: {...state.deletedTracksCategory, loading: true}}
  case DELETE_TRACKS_CATEGORY_SUCCESS:
    newCats = state.tracksCategoriesList.tracksCategories.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, tracksCategoriesList: { tracksCategories: newCats }, deletedTracksCategory: {category:action.payload, error:null, loading: false}}
  case DELETE_TRACKS_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedTracksCategory: {category:null, error:error, loading: false}}
  case RESET_DELETED_TRACKS_CATEGORY:
    return {...state,  deletedTracksCategory:{category:null, error:null, loading: false}}
  default:
    return state;
  }
}

/* eslint-disable max-len */
import * as React from 'react'
import { Link } from 'react-router-dom'

import {
  UikWidgetContent,
  UikWidget,
  UikInput,
  UikFormInputGroup,
  UikHeadline,
  UikButton,
  UikAvatar,
} from '@components'


import * as images from '@eAssets'

import cls from './stations-signin.scss'

const StationsSignIn = () => (
  <div className={ cls.pageWrapper }>
    <div
      className={ cls.logoBlack }
      alt="logoBlack"
    ></div>
    <UikWidget
      className={ cls.widgetWrapper }
    >
      <div className={ cls.content }>
        <UikWidgetContent className={ cls.left }>
          <UikHeadline>
            Forgot Password
          </UikHeadline>
          <p>Enter your email, we'll email you a reset link</p>
          <UikFormInputGroup className={ cls.signInForm }>
            <UikInput
              label="E-mail address"
              name="email"
              placeholder="your@email.com"
            />
          </UikFormInputGroup>
          <UikButton
            className={ cls.btnAction }
            Component={ Link }
            primary
            to="/account"
          >
            Submit
          </UikButton>
        </UikWidgetContent>
        <div className={ cls.right }>
          <h3 className={ cls.headline } style={{ "display": "none" }}>
            Stations
          </h3>
          <p className={ cls.desc } style={{ "display": "none" }}>
            Architecture begins when you place two bricks carefully together.
          </p>
          <UikAvatar
            style={{ "display": "none" }}
            imgUrl={ images.a11 }
            name="Ludwig Mies van der Rohe"
            size="small"
          />
        </div>
      </div>
    </UikWidget>
    <p>
      Not yet on Live365? Create an account
      {' '}
      <a href="/signup">
        here!
      </a>
    </p>
    <p>
      <a href="/">
        Sign in
      </a>
    </p>
  </div>
)

export default StationsSignIn

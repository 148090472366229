import * as React from 'react'
import classnames from 'classnames'

import Tooltip from '@material-ui/core/Tooltip';

import cls from './widget-chart-summary.scss'

import UikChartDoughnut from '../UikChartDoughnut'
import UikWidget from '../../components/UikWidget'

// @flow
type UikWidgetChartSummarySmallProps2 = {
  className?: ?String,
  data: Object,
  label?: React.Node,
  iconClass?: React.Node,
  value?: React.Node,
  max?: React.Node,
  maxUnit?: React.Node,
  subtitle?: React.Node,
  diff?: Number
}

const UikWidgetChartSummarySmall2 = ({
  className,
  data,
  label,
  tooltip,
  iconClass,
  value,
  max,
  maxUnit,
  subtitle,
  diff,
  infinity,
  ...rest
}: UikWidgetChartSummarySmallProps2) => {
  value = Number(value)
  max = Number(max)
  if (isNaN(value) || isNaN(max))
    value, max = 0;
  let graphValue = ((value > max) ? max: value);
  data.datasets[0].data = [graphValue,(max - graphValue)];
  return (
    <UikWidget
      className={ classnames(cls.wrapper, className) }
      padding
      { ...rest }
    >
      { label && (
      <h2 className={ cls.label }>
        {label}
        { tooltip && (
          <Tooltip title={tooltip} placement="top" className={ cls.tooltip }>
            <i className="fas fa-question-circle"></i>
          </Tooltip>
        ) }
      </h2>
      ) }
      <div className={ cls.doughtnutWrapper }>
        <UikChartDoughnut
          className={ cls.chart }
          data={ data }
          maxHeight={ 150 }
        />
        { iconClass && (
          <div className={ cls.icon }>
            <i className={ iconClass }/>
          </div>
        ) }
      </div>
      { value !== undefined && (
      <span className={ cls.value }>
        {value} / {max} {maxUnit}
      </span>
      ) }
      { subtitle && (
        <span className={ cls.subtitle }>
          {subtitle}
        </span>
      ) }
    </UikWidget>
  )
}

UikWidgetChartSummarySmall2.defaultProps = {
  className: null,
  value: null,
  max: null,
  label: null,
  iconClass: null,
  diff: null,
}

export default UikWidgetChartSummarySmall2

import initialState from './initialState'

import {
  FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE, RESET_CATEGORIES, FETCH_CATEGORIES_PAGE_SUCCESS,
  UPDATE_CATEGORY, UPDATE_CATEGORY_SUCCESS,  UPDATE_CATEGORY_FAILURE,
  FETCH_CATEGORY, FETCH_CATEGORY_SUCCESS,  FETCH_CATEGORY_FAILURE, RESET_ACTIVE_CATEGORY,
  CREATE_CATEGORY, CREATE_CATEGORY_SUCCESS, CREATE_CATEGORY_FAILURE, RESET_NEW_CATEGORY,
  DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAILURE, RESET_DELETED_CATEGORY,
} from './actions';

export default function(state = initialState, action) {
  let error, newCats;
  switch(action.type) {

  case FETCH_CATEGORIES:// start fetching categories and set loading = true
    return { ...state, categoriesList: {categories:[], error: null, loading: true} };
  case FETCH_CATEGORIES_PAGE_SUCCESS:
    return { ...state, categoriesList: {categories: [...state.categoriesList.categories, ...action.payload], error: null, loading: true}};
  case FETCH_CATEGORIES_SUCCESS:
    return { ...state, categoriesList: {categories: state.categoriesList.categories, error:null, loading: false} };
  case FETCH_CATEGORIES_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, categoriesList: {categories: [], error: error, loading: false} };
  case RESET_CATEGORIES:// reset categoryList to initial state
    return { ...state, categoriesList: {categories: [], error:null, loading: false} };

  case FETCH_CATEGORY:
    return { ...state, activeCategory:{...state.activeCategory, loading: true}};
  case FETCH_CATEGORY_SUCCESS:
    return { ...state, activeCategory: {category: action.payload, error:null, loading: false}};
  case FETCH_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeCategory: {category: null, error:error, loading:false}};
  case RESET_ACTIVE_CATEGORY:
    return { ...state, activeCategory: {category: null, error:null, loading: false}};

  case UPDATE_CATEGORY:
    return { ...state, activeCategory:{...state.activeCategory, loading: true}};
  case UPDATE_CATEGORY_SUCCESS:
    return { ...state, activeCategory: {category: action.payload, error:null, loading: false}};
  case UPDATE_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeCategory: {category: null, error:error, loading:false}};

  case CREATE_CATEGORY:
    return {...state, newCategory: {...state.newCategory, loading: true}}
  case CREATE_CATEGORY_SUCCESS:
    newCats = state.categoriesList.categories.slice();
    newCats.push(action.payload);
    return {...state,
      categoriesList: { categories: newCats },
      newCategory: {category:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newCategory: {category:null, error:error, loading: false}}
  case RESET_NEW_CATEGORY:
    return {...state,  newCategory:{category:null, error:null, loading: false}}

  case DELETE_CATEGORY:
     return {...state, deletedCategory: {...state.deletedCategory, loading: true}}
  case DELETE_CATEGORY_SUCCESS:
    newCats = state.categoriesList.categories.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, categoriesList: { categories: newCats }, deletedCategory: {category:action.payload, error:null, loading: false}}
  case DELETE_CATEGORY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedCategory: {category:null, error:error, loading: false}}
  case RESET_DELETED_CATEGORY:
    return {...state,  deletedCategory:{category:null, error:null, loading: false}}

  default:
    return state;
  }
}

export default {
  realtimeSessions: { stats: [], error: null, loading: false },
  realtimeStations: { stats: [], error: null, loading: false },
  realtimeCountries: { stats: [], error: null, loading: false },
  realtimePlatforms: { stats: [], error: null, loading: false },
  historicalListeners: { stats: [], error: null, loading: false },
  historicalStations: { stats: [], error: null, loading: false },
  historicalCountries: { stats: [], error: null, loading: false },
  historicalPlatforms: { stats: [], error: null, loading: false },
  revenue: { stats: {}, error: null, loading: false}
}

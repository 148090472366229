import * as React from 'react'
import * as configApp from '@redux/constants'

/* eslint-disable max-len */

import {
  UikInput,
  UikWidget,
  UikWidgetTable,
} from '@components'

import cls from '../media.scss'
import tableCls from './multi-select-table.scss'


// @flow

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

class VerifyTrackList extends React.Component<{
}> {
  constructor(props) {
    super(props);

    this.state = {
      tracks: this.props.tracks
    };

    this.handleMetadataChange = this.handleMetadataChange.bind(this);
  }

  handleMetadataChange(index, event) {
    let tracks = this.state.tracks.slice();
    tracks[index]["attributes"][event.target.name] = event.target.value;
    // this.setState({tracks});
  }

  render() {
    const { tracks }  = this.props;

    return (
      <UikWidget className={ tableCls.tableScroll }>
        <UikWidgetTable className={ tableCls.metaDataTable }>
          <thead>
            <tr>
              <th>
                Art
              </th>
              <th>
                Title
              </th>
              <th>
                Artist
              </th>
              <th>
                Album
              </th>
              <th>
                Year
              </th>
            </tr>
          </thead>
          <tbody>
            {
              tracks.map((track, index) => (
                <tr key={ Math.random() /* pls don't do this in prod */ }>
                  <td>
                    <div className={ cls.trackImg }>
                      <img alt="" src={ track["attributes"]["album_art_uuid"] ? `${mediaStageBaseUrl}/download/${track["attributes"]["album_art_uuid"]}.${track["attributes"]["album_art_extension"]}` : '/img/song_placeholder.png'}/>
                    </div>
                  </td>
                  <td>
                    <UikInput
                      className={ cls.verifyInput }
                      name="title"
                      defaultValue={ track["attributes"].title }
                      onChange={ this.handleMetadataChange.bind(this, index) }
                      maxLength={40}
                    />
                  </td>
                  <td>
                    <UikInput
                      className={ cls.verifyInput }
                      name="artist"
                      defaultValue={ track["attributes"].artist }
                      onChange={ this.handleMetadataChange.bind(this, index) }
                      maxLength={40}
                    />
                  </td>
                  <td>
                    <UikInput
                      className={ cls.verifyInput }
                      name="album"
                      defaultValue={ track["attributes"].album }
                      onChange={ this.handleMetadataChange.bind(this, index) }
                      maxLength={40}
                    />
                  </td>
                  <td>
                    <UikInput
                      className={ cls.verifyInput }
                      name="year"
                      defaultValue={ track["attributes"].year }
                      onChange={ this.handleMetadataChange.bind(this, index) }
                      maxLength={40}
                      max={2050}
                      min={1000}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default VerifyTrackList

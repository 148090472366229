import * as React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import * as actions from '@redux/autoDJSettings/actions'
import * as configApp from '@redux/constants'
import * as separationActions from '@redux/separations/actions'
import * as fadeActions from '@redux/fades/actions'
import * as clockwheelActions from '@redux/clockwheels/actions'
import * as stationActions from '@redux/stations/actions'
import * as packageActions from '@redux/packages/actions'
import { Link } from 'react-router-dom'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetContent,
  UikSelect,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidgetContainer,
  UikContainerVertical,
  UikLayoutMain,
  UikCheckbox,
  UikTabContainer,
  UikTabItem,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './sources.scss'

import CrossfadeGraph from './components/CrossfadeGraph'
import {MILLISECONDS_CONVERSION_SECONDS} from "../../../../../redux/constants";

// @flow
type AutoDJProps = {
  autoDJSettings: Array,
}

class AutoDJ extends React.Component<AutoDJProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      activeTab: 'Global',
      autoDJSettings: {"attributes":{}},
      separation: {},
      fades: [],
      fade: {}
    };

    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleTrackTypeCheckChange = this.handleTrackTypeCheckChange.bind(this);

    this.props.fetchAutoDJSettings(this.state.stationId);
    this.props.fetchFades(this.state.stationId);
    this.props.fetchClockwheels(this.state.stationId);
    this.props.fetchStation(this.state.stationId);
    this.props.fetchStationService(this.state.stationId);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.autoDJSettings !== this.props.autoDJSettings){
      this.setState({ autoDJSettings: this.props.autoDJSettings });
      if(this.props.autoDJSettings && Object.keys(this.props.autoDJSettings).length){
        let separation_rule = this.props.autoDJSettings["relationships"]["separation_rule"]["data"]
        if(separation_rule){
          this.props.fetchSeparation(separation_rule.id);
        } else {
          this.setState({ separation: {"attributes":{}} });
        }
      }
    }
    if(prevProps.separation !== this.props.separation){
      this.setState({ separation: this.props.separation });
    }
    if(prevProps.fade !== this.props.fade){
      this.setState({ fade: this.props.fade });
    }
    if(prevProps.stationService !== this.props.stationService){
      let packageId = this.props.stationService["relationships"]["package"]["data"]["id"];
      if(packageId){
        this.props.fetchPackage(packageId);
      }
    }
    if(prevProps.fades !== this.props.fades){
      let fade;
      if (this.props.fades.length === 0) {
        fade = {
          "attributes": {
              "name": null,
              "crossfade_duration": null,
              "fade_in": null,
              "fade_out": null,
              "type": "lin"
          }
        };
      } else {
        fade = this.props.fades[0];
      }
      this.setState({fade});
    }
  }

  handleSaveSettingsClick() {
    let autoDJSettings = Object.assign({}, this.state.autoDJSettings);
    delete autoDJSettings["attributes"]["ads_enabled"];
    this.props.updateAutoDJSettings(autoDJSettings);
  }

  handleSaveSeparationClick() {
    this.handleSaveSettingsClick();
    let autoDJSettings = Object.assign({}, this.state.autoDJSettings);
    if (Object.keys((this.state.separation||{})["attributes"]).length) {
      let separation = Object.assign({}, this.state.separation);
      if (autoDJSettings["relationships"]["separation_rule"]["data"] === null) {
        separation["name"] = "";
        this.props.createSeparation({separation, stationId:this.state.stationId, autoDJId: autoDJSettings.id});
      } else {
        this.props.updateSeparation(separation);
      }
    }
  }

  handleSaveFadeClick() {
    let fade = Object.assign({}, this.state.fade);
    if (!fade.hasOwnProperty("id")) {
      this.props.createFade(fade, this.state.stationId);
    } else {
      this.props.updateFade(fade);
    }
  }

  handleCheckChange(event) {
    let newState = {};
    if (event.target.name.indexOf("|") > -1){
      let autoDJSettings = Object.assign({}, this.state.autoDJSettings);
      let attrs = event.target.name.split("|");
      autoDJSettings[attrs[0]][attrs[1]] = event.target.checked;
      newState["autoDJSettings"] = autoDJSettings;
    } else {
      newState[event.target.name] = event.target.value;
    }
    this.setState(newState);
  }

  handleTrackTypeCheckChange(event) {
    let autoDJSettings = Object.assign({}, this.state.autoDJSettings);
    let track_types = autoDJSettings["attributes"]["track_types"] || [];
    if (event.target.checked) {
      if(track_types.indexOf(event.target.name) === -1){
        track_types.push(event.target.name);
      }
    } else {
      track_types.splice(track_types.indexOf(event.target.name),1)
    }
    this.setState({autoDJSettings});
  }

  handleSelectChange(name, event) {
    let newState = {};
    if (name.indexOf("|") > -1){
      let autoDJSettings = Object.assign({}, this.state.autoDJSettings);
      let attrs = name.split("|");
      if (attrs.length === 4) {
        let link = {};
        link[attrs[3]] = event.value;
        link["type"] = attrs[1] + 's';
        autoDJSettings[attrs[0]][attrs[1]][attrs[2]] = link;
      } else {
        if (attrs[1] === "ad_break_duration"){
          autoDJSettings[attrs[0]][attrs[1]] = event.value * configApp.MILLISECONDS_CONVERSION_SECONDS;
        } else {
          autoDJSettings[attrs[0]][attrs[1]] = event.value;
        }
      }
    } else {
      newState[name] =  event.value;
    }
    this.setState(newState);
  }

  handleSeparationSelectChange(name, event) {
    let newState = {};
    if (name.indexOf("|") > -1){
      let separation = Object.assign({}, this.state.separation);
      let attrs = name.split("|");
      if (["artist", "album", "title", "track"].indexOf(attrs[1]) > -1){
        separation[attrs[0]][attrs[1]] = event.value * configApp.MILLISECONDS_CONVERSION_MINUTES;
      } else {
        separation[attrs[0]][attrs[1]] = event.value;
      }
    } else {
      newState[name] = event.value;
    }
    this.setState(newState);
  }

  handleFadeSelectChange(name, event) {
    let newState = {};
    if (name.indexOf("|") > -1){
      let fade = Object.assign({}, this.state.fade);
      let attrs = name.split("|");
      fade[attrs[0]][attrs[1]] = event.value;
    } else {
      newState[name] =  event.value;
    }
    this.setState(newState);
  }

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    })
  }

  handleEditChange(event) {
    let fade = this.state.fade || {};
    if (/^\d+(\.\d{1,2})?$/.test(event.target.value)){
      fade["attributes"][event.target.name] = event.target.value * configApp.MILLISECONDS_CONVERSION_SECONDS;
      this.setState({fade});
    }
  }

  render() {
    const {
      activeTab,
      autoDJSettings,
      separation,
      fade
    } = this.state;

    const { clockwheels, activePackage } = this.props;

    let attrs = autoDJSettings["attributes"] || {};

    let separationAttrs = separation["attributes"] || {};

    let fadeAttrs = fade["attributes"] || {};

    let clockwheelOptions = clockwheels.map((pl) => {
      return { value: pl.id, label: pl.attributes.name}
    });

    const adsEnabled = ((activePackage||{})["attributes"]||{})["ads_enabled"];
    const defaultClockwheelId = (((autoDJSettings["relationships"]||[])["clockwheel"]||[])["data"]||[])["id"] || null;

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar
          title={ "Sources > AutoDJ Settings"}
        />
        <UikLayoutMain>
          <div className={ cls.vertSplitContent }>
            <UikWidgetContainer className={ classnames(cls.rowWidget, cls.mediumWidget) } style={{ 'width': '100%' }}>
              <UikWidget margin >
                <UikTabContainer>
                  {
                    [
                      {
                        text: 'Global',
                      },
                      {
                        text: 'Advertisements',
                      },
                      {
                        text: 'Track Mix',
                      },
                      {
                        text: 'Crossfade',
                      },
                    ].map(item => (
                      <UikTabItem
                        key={ item.text }
                        onClick={ this.setActiveTab.bind(null, item.text) }
                        className={ item.text === activeTab && 'active' }
                        { ...item }
                      />
                    ))
                  }
                </UikTabContainer>
                <div className={ classnames(cls.mainTab, {
                  [cls.activeTab]: activeTab === 'Global',
                }) }
                >
                  <form onSubmit={ () => {} }>
                    <UikWidgetContent>
                      <UikFormInputGroup horizontal>
                        <UikFormInputGroup>
                          <UikCheckbox
                            description="Automatically play music from your library when no event is scheduled."
                            label="Enable"
                            name="attributes|enabled"
                            onClick={this.handleCheckChange}
                            defaultChecked={ attrs.enabled  }
                          />
                        </UikFormInputGroup>
                      </UikFormInputGroup>
                    </UikWidgetContent>
                    <UikWidgetContent>
                      <UikButton primary onClick={ this.handleSaveSettingsClick.bind(this) }>Save Settings</UikButton>
                    </UikWidgetContent>
                  </form>
                </div>
                <div className={ classnames(cls.mainTab, {
                  [cls.activeTab]: activeTab === 'Advertisements',
                }) }
                >
                  <form onSubmit={ () => {} }>
                    <UikDivider />
                    <UikWidgetContent>
                      <UikFormInputGroup horizontal>
                        <UikFormInputGroup>
                          <UikCheckbox
                            label="Play audio ads"
                            name="readonly"
                            defaultChecked={ adsEnabled }
                            readOnly
                            disabled
                          />
                          { adsEnabled && (<div className={ cls.helpNote }><a href="/account/billing/services">Upgrade</a> your plan to disable ads.</div>) }
                        </UikFormInputGroup>
                        <UikFormInputGroup>
                          { attrs.ad_break_duration &&
                            <UikSelect
                              defaultValue={ [attrs.ad_break_duration / configApp.MILLISECONDS_CONVERSION_SECONDS] }
                              label="Preferred Ad Break Duration"
                              onChange={this.handleSelectChange.bind(this, "attributes|ad_break_duration")}
                              options={ [
                                {
                                  value: 30,
                                  label: (
                                    <span>
                                      30 Seconds
                                    </span>
                                  ),
                                },
                                {
                                  value: 60,
                                  label: (
                                    <span>
                                      60 Seconds
                                    </span>
                                  ),
                                },
                                {
                                  value: 120,
                                  label: (
                                    <span>
                                      120 Seconds
                                    </span>
                                  ),
                                },
                              ] }
                              position="bottomRight"
                            />
                          }
                          <div className={ cls.helpNote }>
                            AutoDJ will automatically play up to 4 minutes of ads per hour.
                          </div>
                          <br/>
                          <br/>
                        </UikFormInputGroup>
                      </UikFormInputGroup>
                    </UikWidgetContent>
                    <UikWidgetContent>
                      <UikButton primary onClick={ this.handleSaveSettingsClick.bind(this) }>Save Settings</UikButton>
                    </UikWidgetContent>
                  </form>
                </div>
                <div className={ classnames(cls.mainTab, {
                  [cls.activeTab]: activeTab === 'Track Mix',
                }) }
                >
                  <form onSubmit={ () => {} }>
                    <UikDivider />
                    <UikWidgetContent>
                      <UikFormInputGroup horizontal>
                        <UikFormInputGroup>
                          { attrs.selection_method &&
                            <UikSelect
                              defaultValue={ [attrs.selection_method] }
                              onChange={this.handleSelectChange.bind(this, "attributes|selection_method")}
                              label="Selection Method"
                              options={ [
                                {
                                  value: 'random',
                                  label: (
                                    <span>
                                      Random
                                    </span>
                                  ),
                                },
                                {
                                  value: 'clockwheel',
                                  label: (
                                    <span>
                                      Clockwheel
                                    </span>
                                  ),
                                },
                              ] }
                              placeholder="Rule"
                              position="bottomRight"
                            />
                          }
                          { attrs.selection_method === 'random' && (
                            <React.Fragment>
                            <div className={ cls.helpNote }>TYPE</div>
                            <UikCheckbox
                              description=""
                              label="Music"
                              name="music"
                              onChange={this.handleTrackTypeCheckChange}
                              defaultChecked={(attrs.track_types||[]).indexOf("music") > -1}
                            />
                            <UikCheckbox
                              description=""
                              label="Talk"
                              name="talk"
                              onChange={this.handleTrackTypeCheckChange}
                              defaultChecked={(attrs.track_types||[]).indexOf("talk") > -1}
                            />
                            <UikCheckbox
                              description=""
                              label="Promos"
                              name="promo"
                              onChange={this.handleTrackTypeCheckChange}
                              defaultChecked={(attrs.track_types||[]).indexOf("promo") > -1}
                            />
                            <UikCheckbox
                              description=""
                              label="IDs"
                              name="id"
                              onChange={this.handleTrackTypeCheckChange}
                              defaultChecked={(attrs.track_types||[]).indexOf("id") > -1}
                            />
                            <div className={ cls.helpNote }>AutoDJ will choose to play tracks of selected types.</div>
                            </React.Fragment>
                          )}
                          { attrs.selection_method === 'random' && attrs.station_ids_per_hour &&
                            <React.Fragment>
                              <UikSelect
                                label="Station IDs per hour"
                                defaultValue={ [attrs.station_ids_per_hour] }
                                onChange={this.handleSelectChange.bind(this, "attributes|station_ids_per_hour")}
                                options={ [
                                  {
                                    value: 1,
                                    label: (
                                      <span>
                                        1
                                      </span>
                                    ),
                                  },
                                  {
                                    value: 2,
                                    label: (
                                      <span>
                                        2
                                      </span>
                                    ),
                                  },
                                  {
                                    value: 4,
                                    label: (
                                      <span>
                                        4
                                      </span>
                                    ),
                                  },
                                  {
                                    value: 6,
                                    label: (
                                      <span>
                                        6
                                      </span>
                                    ),
                                  },
                                ] }
                                placeholder="Count"
                                position="bottomRight"
                              />
                            <div className={ cls.helpNote }>Play this many station IDs per hour when on AutoDJ. Make sure you categorize your Station ID tracks in the library.</div>
                            </React.Fragment>
                          }
                          { attrs.selection_method === 'clockwheel' && clockwheelOptions.length === 0 && (
                            <div style={{paddingTop: "30px"}}>You have no clockwheels, please create one&nbsp;
                              <Link to={`/stations/${this.state.stationId}/schedule/clockwheels`}>
                                here
                              </Link>.
                            </div>
                          )}
                          { attrs.selection_method === 'clockwheel' && clockwheelOptions.length > 0 && (
                            <UikFormInputGroup className={ cls.marginTop }>
                              <UikSelect
                                name="clockwheel"
                                label="Clockwheel"
                                defaultValue={[defaultClockwheelId + ""]}
                                onChange={ this.handleSelectChange.bind(this, 'relationships|clockwheel|data|id') }
                                options={ [...clockwheelOptions] }
                              />
                            </UikFormInputGroup>
                          )}
                        </UikFormInputGroup>
                        <UikFormInputGroup>
                          { Object.keys(separation).length > 0  &&
                          <UikSelect
                            defaultValue={ [ separationAttrs.artist / configApp.MILLISECONDS_CONVERSION_MINUTES ] }
                            label="Do not play same artist within"
                            onChange={this.handleSeparationSelectChange.bind(this, "attributes|artist")}
                            options={ [
                              {
                                value: 45,
                                label: (
                                  <span>
                                    45 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 60,
                                label: (
                                  <span>
                                    60 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 90,
                                label: (
                                  <span>
                                    90 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 120,
                                label: (
                                  <span>
                                    120 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 150,
                                label: (
                                  <span>
                                    150 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 180,
                                label: (
                                  <span>
                                    180 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 210,
                                label: (
                                  <span>
                                    210 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 240,
                                label: (
                                  <span>
                                    240 minutes
                                  </span>
                                ),
                              },
                            ] }
                            placeholder="delay"
                            position="bottomRight"
                          />
                          }
                          { Object.keys(separation).length > 0  &&
                          <UikSelect
                            defaultValue={ [ separationAttrs.album / configApp.MILLISECONDS_CONVERSION_MINUTES ] }
                            label="Do not play same album within"
                            onChange={this.handleSeparationSelectChange.bind(this, "attributes|album")}
                            options={ [
                              {
                                value: 60,
                                label: (
                                  <span>
                                  60 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 90,
                                label: (
                                  <span>
                                  90 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 120,
                                label: (
                                  <span>
                                  120 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 150,
                                label: (
                                  <span>
                                  150 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 180,
                                label: (
                                  <span>
                                  180 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 210,
                                label: (
                                  <span>
                                  210 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 240,
                                label: (
                                  <span>
                                  240 minutes
                                  </span>
                                ),
                              },
                            ] }
                            placeholder="delay"
                            position="bottomRight"
                          />
                          }
                          { Object.keys(separation).length > 0  &&
                          <UikSelect
                            defaultValue={ [ separationAttrs.title / configApp.MILLISECONDS_CONVERSION_MINUTES ] }
                            label="Do not play same title within"
                            onChange={this.handleSeparationSelectChange.bind(this, "attributes|title")}
                            options={ [
                              {
                                value: 'disable',
                                label: (
                                  <span>
                                  Disable
                                  </span>
                                ),
                              },
                              {
                                value: 30,
                                label: (
                                  <span>
                                  30 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 60,
                                label: (
                                  <span>
                                  60 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 90,
                                label: (
                                  <span>
                                  90 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 120,
                                label: (
                                  <span>
                                  120 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 150,
                                label: (
                                  <span>
                                  150 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 180,
                                label: (
                                  <span>
                                  180 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 210,
                                label: (
                                  <span>
                                  210 minutes
                                  </span>
                                ),
                              },
                              {
                                value: 240,
                                label: (
                                  <span>
                                  240 minutes
                                  </span>
                                ),
                              },
                            ] }
                            placeholder="delay"
                            position="bottomRight"
                          />
                          }
                          { Object.keys(separation).length > 0 &&
                            <UikSelect
                              defaultValue={ [ separationAttrs.track / configApp.MILLISECONDS_CONVERSION_MINUTES] }
                              label="Do not play same track within"
                              onChange={this.handleSeparationSelectChange.bind(this, "attributes|track")}
                              options={ [
                                {
                                  value: 60,
                                  label: (
                                    <span>
                                    60 minutes
                                    </span>
                                  ),
                                },
                                {
                                  value: 90,
                                  label: (
                                    <span>
                                    90 minutes
                                    </span>
                                  ),
                                },
                                {
                                  value: 120,
                                  label: (
                                    <span>
                                    120 minutes
                                    </span>
                                  ),
                                },
                                {
                                  value: 150,
                                  label: (
                                    <span>
                                    150 minutes
                                    </span>
                                  ),
                                },
                                {
                                  value: 180,
                                  label: (
                                    <span>
                                    180 minutes
                                    </span>
                                  ),
                                },
                                {
                                  value: 210,
                                  label: (
                                    <span>
                                    210 minutes
                                    </span>
                                  ),
                                },
                                {
                                  value: 240,
                                  label: (
                                    <span>
                                    240 minutes
                                    </span>
                                  ),
                                },
                              ] }
                              placeholder="delay"
                              position="bottomRight"
                            />
                          }
                        </UikFormInputGroup>
                      </UikFormInputGroup>
                    </UikWidgetContent>
                    <UikWidgetContent>
                      <UikButton primary onClick={ this.handleSaveSeparationClick.bind(this) }>Save Settings</UikButton>
                    </UikWidgetContent>
                  </form>
                </div>

                <div className={ classnames(cls.mainTab, {
                  [cls.activeTab]: activeTab === 'Crossfade',
                }) }
                >
                  <form onSubmit={ () => {} }>
                    <UikDivider />
                    <UikWidgetContent>
                      <UikFormInputGroup direction="horizontal">
                        <div>
                          <UikInput
                            label="Crossfade Duration"
                            name="crossfade_duration"
                            type="number"
                            step="0.01"
                            min="0"
                            max="20"
                            value={ [fadeAttrs.crossfade_duration / configApp.MILLISECONDS_CONVERSION_SECONDS] || 0 }
                            onChange={ this.handleEditChange.bind(this) }
                          />
                          <p>The amount of time (in seconds) 2 consecutive tracks will overlap (0.0 - 20.0 seconds)</p>
                        </div>
                        <div>
                          { Object.keys(fade).length > 0 && fadeAttrs.fade_out !== null  &&
                            <UikSelect
                              defaultValue={ [fadeAttrs.type]}
                              label="Crossfade Type"
                              onChange={this.handleFadeSelectChange.bind(this, "attributes|type")}
                              options={ [
                                {
                                  value: "lin",
                                  label: (
                                    <span>
                                      Linear
                                    </span>
                                  ),
                                },
                                {
                                  value: "sin",
                                  label: (
                                    <span>
                                      Sine
                                    </span>
                                  ),
                                },
                                {
                                  value: "log",
                                  label: (
                                    <span>
                                      Logarithmic
                                    </span>
                                  ),
                                },
                                {
                                  value: "exp",
                                  label: (
                                    <span>
                                      Exponential
                                    </span>
                                  ),
                                },
                              ] }
                            />
                          }
                          <p>Volume will follow one of these function's curve"</p>
                        </div>
                      </UikFormInputGroup>
                      <UikFormInputGroup horizontal>
                        <div>
                          <UikInput
                            label="Fade In"
                            name="fade_in"
                            type="number"
                            step="0.01"
                            min="0"
                            max="20"
                            value={ [fadeAttrs.fade_in / configApp.MILLISECONDS_CONVERSION_SECONDS] || 0 }
                            onChange={ this.handleEditChange.bind(this) }
                          />
                          <p>The amount of time (in seconds) it will take for the beginning track to reach desired volume (0.0 - 20.0 seconds)"</p>
                        </div>
                        <div>
                          <UikInput
                            label="Fade Out"
                            name="fade_out"
                            type="number"
                            step="0.01"
                            min="0"
                            max="20"
                            value={ [fadeAttrs.fade_out / configApp.MILLISECONDS_CONVERSION_SECONDS] || 0 }
                            onChange={ this.handleEditChange.bind(this) }
                          />
                          <p>The amount of time (in seconds) it will take for the ending track to become silent (0.0 - 20.0 seconds)"</p>
                        </div>
                      </UikFormInputGroup>
                    </UikWidgetContent>
                    <UikWidgetContent>
                      <CrossfadeGraph
                        fadeIn={fadeAttrs.fade_in}
                        fadeOut={fadeAttrs.fade_out}
                        crossFade={fadeAttrs.crossfade_duration}
                        crossFadeType={fadeAttrs.type}
                      />
                    </UikWidgetContent>
                    <UikWidgetContent>
                      <UikButton primary onClick={ this.handleSaveFadeClick.bind(this) }>Save Crossfade</UikButton>
                    </UikWidgetContent>
                  </form>
                </div>
              </UikWidget>
            </UikWidgetContainer>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    autoDJSettings: store.autoDJSettings.autoDJSettings.autoDJSettings,
    fades: store.fades.fadesList.fades,
    fade: store.fades.activeFade.fade,
    separation: store.separations.activeSeparation.separation,
    clockwheels: store.clockwheels.clockwheelsList.clockwheels,
    activeStation: store.stations.activeStation,
    activePackage: store.packages.activePackage.package,
    stationService: store.stations.stationService,
  }),
  {
    fetchStationService: stationActions.fetchStationService,
    fetchStation: stationActions.fetchStation,
    fetchSeparation: separationActions.fetchSeparation,
    createSeparation: separationActions.createSeparation,
    updateSeparation: separationActions.updateSeparation,
    createFade: fadeActions.createFade,
    updateFade: fadeActions.updateFade,
    fetchFades: fadeActions.fetchFades,
    fetchAutoDJSettings: actions.fetchAutoDJSettings,
    updateAutoDJSettings: actions.updateAutoDJSettings,
    fetchClockwheels: clockwheelActions.fetchClockwheels,
    fetchPackage: packageActions.fetchPackage,
  },
)(AutoDJ)

import initialState from './initialState'

import {
  FETCH_BROADCASTER_USERS, FETCH_BROADCASTER_USERS_SUCCESS, FETCH_BROADCASTER_USERS_FAILURE, RESET_BROADCASTER_USERS,
  UPDATE_BROADCASTER_USER, UPDATE_BROADCASTER_USER_SUCCESS,  UPDATE_BROADCASTER_USER_FAILURE,
  FETCH_BROADCASTER_USER, FETCH_BROADCASTER_USER_SUCCESS,  FETCH_BROADCASTER_USER_FAILURE, RESET_ACTIVE_BROADCASTER_USER,
  CREATE_BROADCASTER_USER, CREATE_BROADCASTER_USER_SUCCESS, CREATE_BROADCASTER_USER_FAILURE, RESET_NEW_BROADCASTER_USER,
  DELETE_BROADCASTER_USER, DELETE_BROADCASTER_USER_SUCCESS, DELETE_BROADCASTER_USER_FAILURE,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_BROADCASTER_USERS:// start fetching broadcasterUsers and set loading = true
    return { ...state, broadcasterUsersList: {broadcasterUsers:[], error: null, loading: true} };
  case FETCH_BROADCASTER_USERS_SUCCESS:
    return { ...state, broadcasterUsersList: {broadcasterUsers: action.payload, error:null, loading: false} };
  case FETCH_BROADCASTER_USERS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, broadcasterUsersList: {broadcasterUsers: [], error: error, loading: false} };
  case RESET_BROADCASTER_USERS:// reset broadcasterUserList to initial state
    return { ...state, broadcasterUsersList: {broadcasterUsers: [], error:null, loading: false} };

  case FETCH_BROADCASTER_USER:
    return { ...state, activeBroadcasterUser:{...state.activeBroadcasterUser, loading: true}};
  case FETCH_BROADCASTER_USER_SUCCESS:
    return { ...state, activeBroadcasterUser: {broadcasterUser: action.payload, error:null, loading: false}};
  case FETCH_BROADCASTER_USER_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeBroadcasterUser: {broadcasterUser: null, error:error, loading:false}};
  case RESET_ACTIVE_BROADCASTER_USER:
    return { ...state, activeBroadcasterUser: {broadcasterUser: null, error:null, loading: false}};

  case UPDATE_BROADCASTER_USER:
    return { ...state, activeBroadcasterUser:{...state.activeBroadcasterUser, loading: true}};
  case UPDATE_BROADCASTER_USER_SUCCESS:
    return { ...state, activeBroadcasterUser: {broadcasterUser: action.payload, error:null, loading: false}};
  case UPDATE_BROADCASTER_USER_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeBroadcasterUser: {broadcasterUser: null, error:error, loading:false}};

  case CREATE_BROADCASTER_USER:
    return {...state, newClockwheel: {...state.newClockwheel, loading: true}}
  case CREATE_BROADCASTER_USER_SUCCESS:
    let newClockwheels = state.broadcasterUsersList.broadcasterUsers.slice();
    newClockwheels.push(action.payload);
    return {...state,
      broadcasterUsersList: { broadcasterUsers: newClockwheels },
      newClockwheel: {broadcasterUser:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_BROADCASTER_USER_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newClockwheel: {broadcasterUser:null, error:error, loading: false}}
  case RESET_NEW_BROADCASTER_USER:
    return {...state,  newClockwheel:{broadcasterUser:null, error:null, loading: false}}

  case DELETE_BROADCASTER_USER:
     return {...state, deletedClockwheel: {...state.deletedClockwheel, loading: true}}
  case DELETE_BROADCASTER_USER_SUCCESS:
    let newUsers = state.broadcasterUsersList.broadcasterUsers.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, broadcasterUsersList: { broadcasterUsers: newUsers }, deletedClockwheel: {broadcasterUser:action.payload, error:null, loading: false}}
  case DELETE_BROADCASTER_USER_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedClockwheel: {broadcasterUser:null, error:error, loading: false}}
  default:
    return state;
  }
}


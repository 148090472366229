import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//ClockwheelEntry list
export const FETCH_CLOCKWHEEL_ENTRIES = 'FETCH_CLOCKWHEEL_ENTRIES';
export const FETCH_CLOCKWHEEL_ENTRIES_SUCCESS = 'FETCH_CLOCKWHEEL_ENTRIES_SUCCESS';
export const FETCH_CLOCKWHEEL_ENTRIES_FAILURE = 'FETCH_CLOCKWHEEL_ENTRIES_FAILURE';
export const RESET_CLOCKWHEEL_ENTRIES = 'RESET_CLOCKWHEEL_ENTRIES';

//Create new CLOCKWHEEL_ENTRY
export const CREATE_CLOCKWHEEL_ENTRY = 'CREATE_CLOCKWHEEL_ENTRY';
export const CREATE_CLOCKWHEEL_ENTRY_SUCCESS = 'CREATE_CLOCKWHEEL_ENTRY_SUCCESS';
export const CREATE_CLOCKWHEEL_ENTRY_FAILURE = 'CREATE_CLOCKWHEEL_ENTRY_FAILURE';
export const RESET_NEW_CLOCKWHEEL_ENTRY = 'RESET_NEW_CLOCKWHEEL_ENTRY';

//Update CLOCKWHEEL_ENTRY
export const UPDATE_CLOCKWHEEL_ENTRY = 'UPDATE_CLOCKWHEEL_ENTRY';
export const UPDATE_CLOCKWHEEL_ENTRY_SUCCESS = 'UPDATE_CLOCKWHEEL_ENTRY_SUCCESS';
export const UPDATE_CLOCKWHEEL_ENTRY_FAILURE = 'UPDATE_CLOCKWHEEL_ENTRY_FAILURE';

//Fetch CLOCKWHEEL_ENTRY
export const FETCH_CLOCKWHEEL_ENTRY = 'FETCH_CLOCKWHEEL_ENTRY';
export const FETCH_CLOCKWHEEL_ENTRY_SUCCESS = 'FETCH_CLOCKWHEEL_ENTRY_SUCCESS';
export const FETCH_CLOCKWHEEL_ENTRY_FAILURE = 'FETCH_CLOCKWHEEL_ENTRY_FAILURE';
export const RESET_ACTIVE_CLOCKWHEEL_ENTRY = 'RESET_ACTIVE_CLOCKWHEEL_ENTRY';

//Delete CLOCKWHEEL_ENTRY
export const DELETE_CLOCKWHEEL_ENTRY = 'DELETE_CLOCKWHEEL_ENTRY';
export const DELETE_CLOCKWHEEL_ENTRY_SUCCESS = 'DELETE_CLOCKWHEEL_ENTRY_SUCCESS';
export const DELETE_CLOCKWHEEL_ENTRY_FAILURE = 'DELETE_CLOCKWHEEL_ENTRY_FAILURE';
export const RESET_DELETED_CLOCKWHEEL_ENTRY = 'RESET_DELETED_CLOCKWHEEL_ENTRY';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestClockwheelEntries = () => ({
  type: FETCH_CLOCKWHEEL_ENTRIES,
})

export const receiveClockwheelEntries = (json) => ({
  type: FETCH_CLOCKWHEEL_ENTRIES_SUCCESS,
  payload: json
})

export const requestDeleteClockwheelEntry = () => ({
  type: DELETE_CLOCKWHEEL_ENTRY,
})

export const clockwheelDeleted = (id) => ({
  type: DELETE_CLOCKWHEEL_ENTRY_SUCCESS,
  id: id
})

export const requestCreateClockwheelEntry = () => ({
  type: CREATE_CLOCKWHEEL_ENTRY,
})

export const requestUpdateClockwheelEntry = () => ({
  type: UPDATE_CLOCKWHEEL_ENTRY,
})

export const clockwheelUpdated = (json) => ({
  type: UPDATE_CLOCKWHEEL_ENTRY_SUCCESS,
  payload: json
})

export function fetchClockwheelEntries(cwId) {

  return function(dispatch) {

    dispatch(requestClockwheelEntries())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}clockwheel-entries/?filter[clockwheel]=${cwId}&sort=position`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }


        return res.json();
      })
      .then(json => {
        dispatch(receiveClockwheelEntries(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchClockwheelEntriesSuccess(clockwheels) {
  return {
    type: FETCH_CLOCKWHEEL_ENTRIES_SUCCESS,
    payload: clockwheels
  };
}

export function fetchClockwheelEntriesFailure(error) {
  return {
    type: FETCH_CLOCKWHEEL_ENTRIES_FAILURE,
    payload: error
  };
}

export function createClockwheelEntry(cwEntry, clockwheelId) {

  return function(dispatch) {

    dispatch(requestCreateClockwheelEntry())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "clockwheel_entries",
        "attributes": cwEntry.attributes,
        "relationships": {
            "clockwheel": {
                "data": {
                    "type": "clockwheels",
                    "id": clockwheelId + ""
                }
            }
        }
      }
    };

    if((cwEntry["relationships"]||{}).hasOwnProperty("category")){
      data["data"]["relationships"]["category"] = cwEntry["relationships"]["category"];
    }

    return fetch(`${BROADCASTER_ROOT_URL}clockwheel-entries/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Clockwheel entry created.");
          dispatch(createClockwheelEntrySuccess(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createClockwheelEntrySuccess(newClockwheelEntry) {
  return {
    type: CREATE_CLOCKWHEEL_ENTRY_SUCCESS,
    payload: newClockwheelEntry
  };
}

export function createClockwheelEntryFailure(error) {
  return {
    type: CREATE_CLOCKWHEEL_ENTRY_FAILURE,
    payload: error
  };
}

export function resetNewClockwheelEntry() {
  return {
    type: RESET_NEW_CLOCKWHEEL_ENTRY
  }
}
;

export function resetDeletedClockwheelEntry() {
  return {
    type: RESET_DELETED_CLOCKWHEEL_ENTRY
  }
}

export function fetchClockwheelEntrySuccess(activeClockwheelEntry) {
  return {
    type: FETCH_CLOCKWHEEL_ENTRY_SUCCESS,
    payload: activeClockwheelEntry
  };
}

export function fetchClockwheelEntryFailure(error) {
  return {
    type: FETCH_CLOCKWHEEL_ENTRY_FAILURE,
    payload: error
  };
}

export function resetActiveClockwheelEntry() {
  return {
    type: RESET_ACTIVE_CLOCKWHEEL_ENTRY
  }
}


export function deleteClockwheelEntry(cwId) {

  return function(dispatch) {

    dispatch(requestDeleteClockwheelEntry())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}clockwheel-entries/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Clockwheel entry deleted");
        dispatch(clockwheelDeleted(cwId))
      })
      .catch(err => {
        console.error(err);
      });
  }
}



export function updateClockwheelEntry(cw) {

  return function(dispatch) {

    dispatch(requestUpdateClockwheelEntry())

    const bearerToken = ls.get('bearerToken') || null;

    if (cw.attributes.media_type === 'advert'){
      delete cw.attributes.select_type;
    }

    // only position can change...
    let data = {
      "data": {
        "type": "clockwheel_entries",
        "id": cw.id + "",
        "attributes": { "position": cw.attributes["position"]}
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}clockwheel-entries/${cw.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Clockwheel entry saved !");
        dispatch(clockwheelUpdated(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

// export function deleteClockwheelEntrySuccess(deletedClockwheelEntry) {
//   return {
//     type: DELETE_CLOCKWHEEL_ENTRY_SUCCESS,
//     payload: deletedClockwheelEntry
//   };
// }

export function deleteClockwheelEntryFailure(response) {
  return {
    type: DELETE_CLOCKWHEEL_ENTRY_FAILURE,
    payload: response
  };
}

import * as React from 'react'

/* eslint-disable max-len */
import cls from './CrossfadeGraph.scss'
import {Scatter} from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';

defaults.global.animation = false;

// @flow
type CrossfadeGraphProps = {
  fadeIn: Float,
  fadeOut: Float,
  crossFade: Float,
  crossFadeType: String
}

class CrossfadeGraph extends React.Component<CrossfadeGraphProps> {
  constructor(props) {
    super(props);

    let labels = [];
    for (var j = 0.0; j < 300; j += 0.5) {
      labels.push(j);
    }

    this.state = {
      chart: null,
      labels: labels,
      crossFadeType: undefined,
      fadeIn: undefined,
      fadeOut: undefined,
      crossFade: undefined
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.fadeIn !== this.props.fadeIn
      || prevProps.fadeOut !== this.props.fadeOut
      || prevProps.crossFade !== this.props.crossFade
      || prevProps.crossFadeType !== this.props.crossFadeType ){
      this.setState({
        crossFadeType: this.props.crossFadeType,
        fadeIn: parseFloat(this.props.fadeIn),
        fadeOut: parseFloat(this.props.fadeOut),
        crossFade: parseFloat(this.props.crossFade)
      })
    }
  }

  getWaveForms(type, fadeIn, fadeOut, crossFade) {
    let plateauDuration = 10;
    let song1, song2, song3;
    if (type === 'lin') {
      song1 = this.createDataset(0, 10, 10, this.lin(0.5), plateauDuration, fadeIn, fadeOut);
      song2 = this.shiftDataset(song1, fadeIn + plateauDuration + fadeOut - crossFade);
      song3 = this.shiftDataset(song2, fadeIn + plateauDuration + fadeOut - crossFade);
    } else if (type === 'sin') {
      song1 = this.createDataset(-Math.PI / 2, Math.PI / 2, 10, Math.sin, plateauDuration, fadeIn, fadeOut);
      song2 = this.shiftDataset(song1, fadeIn + plateauDuration + fadeOut - crossFade);
      song3 = this.shiftDataset(song2, fadeIn + plateauDuration + fadeOut - crossFade);
    } else if (type === 'exp') {
      song1 = this.createDataset(-3, -1, 10, Math.exp, plateauDuration, fadeIn, fadeOut);
      song2 = this.shiftDataset(song1, fadeIn + plateauDuration + fadeOut - crossFade);
      song3 = this.shiftDataset(song2, fadeIn + plateauDuration + fadeOut - crossFade);
    } else if (type === 'log') {
      song1 = this.createDataset(0.5, 12, 10, Math.log, plateauDuration, fadeIn, fadeOut);
      song2 = this.shiftDataset(song1, fadeIn + plateauDuration + fadeOut - crossFade);
      song3 = this.shiftDataset(song2, fadeIn + plateauDuration + fadeOut - crossFade);
    }

    return [song1, song2, song3]
  }

  createDataset(start, end, samples, funcX, plateauDuration, fadeInTime, fadeOutTime) {
    let increment = (end - start) / (samples - 1);
    let i = 0;  // So that we don't duplicate in each for

    let fadeIn = {
      xValues: [],
      yValues: []
    };

    for (i = 0; i < samples; i++) {
      let x = start + i * increment;
      fadeIn.xValues.push(x);
      fadeIn.yValues.push(funcX(x))
    }

    // Because start and end are used only to find the Y values that best describe a function,
    // (for example, sin looks best in [-pi/2, pi/2] interval),
    // we need to reassign xValues to fit in [0, fadeInTime] (having the same number of samples)

    fadeIn.xValues = [];
    let fadeInResampleIncrement = fadeInTime / (samples - 1);
    for (i = 0; i < samples; i++) {
      fadeIn.xValues.push(i * fadeInResampleIncrement);
    }

    let fadeOut = {
      xValues: [],
      yValues: fadeIn.yValues.slice().reverse()
    };

    let fadeOutResampleIncrement = fadeOutTime / (samples - 1);
    let xEndFadeIn = fadeIn.xValues[fadeIn.xValues.length - 1];
    let xStartFadeOut = xEndFadeIn + plateauDuration;
    for (i = 0; i < samples; i++) {
      fadeOut.xValues.push(xStartFadeOut + i * fadeOutResampleIncrement);
    }

    let result = {
      xValues: [],
      yValues: []
    };
    result.xValues.push(...fadeIn.xValues, ...fadeOut.xValues);
    result.yValues.push(...fadeIn.yValues, ...fadeOut.yValues);

    return result;
  }

  getPairsXY(data) {
    let pairs = [];
    for (let i = 0; i < data.xValues.length; i++){
      pairs.push({
        x: data.xValues[i],
        y: data.yValues[i]
      });
    }
    return pairs;
  }

  shiftDataset(dataset, value) {
    return {
      xValues: dataset.xValues.map(function(x) { return x + value; }),
      yValues: dataset.yValues.slice()
    }
  }

  lin(slope) {
    return function(x) {
      return x * slope;
    }
  }

  render() {
    let {
      labels,
      fadeIn,
      fadeOut,
      crossFade,
      crossFadeType
    } = this.state;

    if ( crossFadeType === undefined
      || fadeIn === undefined
      || fadeOut === undefined
      || crossFade === undefined ) {
      return (<div></div>);
    }

    let songs = this.getWaveForms(crossFadeType, fadeIn, fadeOut, crossFade);

    let scatterData = {
      labels: labels,
      datasets: [
        {
          label: 'Song 1',
          data: this.getPairsXY(songs[0]),
          fill: false,
          lineTension: 0,
          borderColor: '#FF6906'
        },
        {
          label: 'Song 2',
          data: this.getPairsXY(songs[1]),
          fill: false,
          lineTension: 0,
          borderColor: '#38B249'
        },
      ]
    };

    return (
      <div className={ cls.crossFadeGraphWrapper }>
        <Scatter
          redraw={true}
          data={scatterData}
          options={{
            showLines: true,
            elements: {point: {radius: 0}},
            scales:{
              yAxes: [{
                  display: false //this will remove all the x-axis grid lines
              }],
              xAxes: [{
                display: false //this will remove all the x-axis grid lines
              }]
            }
          }}
        />
      </div>
    )
  }
}

export default CrossfadeGraph

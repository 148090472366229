import * as React from 'react'
import numberFormatZeros from '@lib/numberFormatZeros'
import classnames from 'classnames'
import DatePicker from "react-datepicker";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from "react-simple-maps"

import { connect } from 'react-redux'

import * as stationActions from '@redux/stations/actions'
import moment from 'moment'
import * as actions from '@redux/analytics/actions'
import * as configApp from '@redux/constants'

/* eslint-disable max-len */

import {
  UikButton,
  Uikon,
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
  UikTabContainer,
  UikTabItem,
  UikSelect,
  UikContentTitle,
  UikWidgetTable,
  UikContainerVertical,
  UikLayoutMain,
  UikFormInputGroup,

} from '@components'

import Modal from '@material-ui/core/Modal';

import {
  UikChart,
} from '@componentWrappers'

import TopBar from '../../../../@components/TopBar'

import cls from './stations-performance.scss'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// @flow


class AccountAnalytics extends React.Component<{
}> {

  constructor(props){
    super(props);

    this.state = {
      gradientFill: null,
      activeTab: 'Listeners',
      editStationsModalOpen: false,
      selectedStations: [],
      startDate: moment().subtract(1,'y').format().slice(0,19)+"Z",
      endDate: moment().format().slice(0,19)+"Z",
      realtime: false,
      dateRange: {
                  value: 'year',
                  label: (
                    <span>
                      <Uikon
                        style={ {
                          marginRight: '10px',
                        } }
                      >
                        calendar_2
                      </Uikon>
                      {' Last Year'}
                    </span>
                  ),
                }
    };

    this.props.fetchStations({perPage: 500, page: 1});
  }

  openEditStationsModal = () => {
    this.setState({ editStationsModalOpen: true });
  };

  handleEditUserModalClose = () => {
    this.setState({ editStationsModalOpen: false });

    this.props.fetchHistoricalListeners({stationIds: this.state.selectedStations, startDate: this.state.startDate, endDate: this.state.endDate});
    this.props.fetchHistoricalStations({stationIds: this.state.selectedStations, startDate: this.state.startDate, endDate: this.state.endDate});
    this.props.fetchHistoricalCountries({stationIds: this.state.selectedStations, startDate: this.state.startDate, endDate: this.state.endDate, countries: []});
    this.props.fetchHistoricalPlatforms({stationIds: this.state.selectedStations, startDate: this.state.startDate, endDate: this.state.endDate});
  };

  handleStationSelectClick = (stationId) => {
    let selectedStations = this.state.selectedStations.slice();
    if(selectedStations.indexOf(stationId) > -1){
      selectedStations.splice(selectedStations.indexOf(stationId),1);
    } else {
      selectedStations.push(stationId);
    }
    console.log('handleStationSelectClick', selectedStations, stationId);
    this.setState({selectedStations});
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.stations !== this.props.stations){
      let selectedStations = this.props.stations.map(st => st.id);
      this.setState({selectedStations});
      this.props.fetchHistoricalListeners({stationIds: selectedStations, startDate: this.state.startDate, endDate: this.state.endDate});
      this.props.fetchHistoricalStations({stationIds: selectedStations, startDate: this.state.startDate, endDate: this.state.endDate});
      this.props.fetchHistoricalCountries({stationIds: selectedStations, startDate: this.state.startDate, endDate: this.state.endDate, countries: []});
      this.props.fetchHistoricalPlatforms({stationIds: selectedStations, startDate: this.state.startDate, endDate: this.state.endDate});
    }
  }

  componentDidMount() {
  }

  handleDatePickerChange(dateName, date) {
    let newState = {"dateRange": dateRangeOptions.find(dro => dro.value === 'custom')};
    newState[dateName] = date;
    this.setState(newState);

    let analPayload = {"stationIds": [this.state.selectedStations]};

    if(dateName === "startDate") {
      analPayload["start_date"] = date;
      analPayload["end_date"] = this.state.endDate;
    } else {
      analPayload["start_date"] = this.state.startDate;
      analPayload["end_date"] = date;
    }

    // IMPROVE THIS... only call the fetch on opening the active tab...
    this.props.fetchHistoricalListeners(analPayload);
    this.props.fetchHistoricalStations(analPayload);
    this.props.fetchHistoricalCountries(analPayload);
    this.props.fetchHistoricalPlatforms(analPayload);
  }

  handleDropdownDateChange(event) {
    let newState = {"dateRange": dateRangeOptions.find(dro => dro.value === event.value)};
    if (event.value === 'realtime') {
      this.props.fetchRealtimeSessions({"stationIds": [this.state.stationId]});
      this.props.fetchRealtimeStations({"stationIds": [this.state.stationId]});
      this.props.fetchRealtimeCountries({"stationIds": [this.state.stationId]});
      this.props.fetchRealtimePlatforms({"stationIds": [this.state.stationId]});

      newState["realtime"] = true;
      this.setState(newState);
    } else if (event.value !== 'custom') {
      let startDate = moment().subtract(1, event.value).format().slice(0,19)+"Z";
      let endDate = moment().format().slice(0,19)+"Z";
      newState["startDate"] = startDate;
      newState["realtime"] = false;
      this.setState(newState);

      // IMPROVE THIS... only call the fetch on opening the active tab...
      this.props.fetchHistoricalListeners({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
      this.props.fetchHistoricalStations({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
      this.props.fetchHistoricalCountries({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
      this.props.fetchHistoricalPlatforms({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
    } else {
      this.setState(newState);
    }
  }

  setActiveTab = (activeTab) => {
    if (activeTab === 'Sessions') {
      const chart = document.getElementById('performance-chart')
      if (chart) {
        const ctx = chart.getContext('2d')
        if (ctx) {
          const gradientFill = ctx.createLinearGradient(0, 0, 0, 280)
          gradientFill.addColorStop(0, 'rgba(25, 145, 235, 0.15)')
          gradientFill.addColorStop(0.9, 'rgba(25, 145, 235, 0)')
          this.setState({
            gradientFill,
          })
        }
      }
    }
    this.setState({
      activeTab,
    })
  }

  render() {
    const { stations } = this.props;
    const { gradientFill, activeTab, selectedStations, realtime, startDate, endDate, dateRange } = this.state;
    const {
      realtimeSessions,
      realtimeStations,
      realtimeCountries,
      realtimePlatforms,
      historicalListeners,
      historicalStations,
      historicalCountries,
      historicalPlatforms,
    } = this.props;

    let stationsData = realtime ? realtimeStations["stations"] : historicalStations["stations"];
    let countriesData = realtime ? realtimeCountries["stations"] : historicalCountries["countries"];
    let platformsData = realtime ? realtimePlatforms["platforms"] : historicalPlatforms["platforms"];
    let listenersData = realtime ? realtimeSessions["sessions"] : historicalListeners["listeners"];

    console.log('stations',stations);

    // generate line chart labels + values
    let listeners = listenersData || {};
    let lineChartDataArray = [],
        lineChartLabels = [],
        lineChartValues = [];

    if (Object.keys(listeners).length){
      lineChartDataArray = listeners[Object.keys(listeners)[0]];
    }

    for (var i = 0; i < lineChartDataArray.length; ++i) {
      lineChartLabels.push(moment(lineChartDataArray[i].date).format("M/D"));
      lineChartValues.push(lineChartDataArray[i].reach);
    }

    let stationStats = (stationsData && stationsData[Object.keys(stationsData)[0]]) || {};

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title={`Account Analytics - ${selectedStations.length} Stations Selected`}/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikWidget margin>
            <UikWidgetHeader rightEl={ (
              <div className={ cls.dateInputs }>
                { dateRange.value !== "realtime" && (
                  <React.Fragment>
                    <UikFormInputGroup className={cls.dateInputWrapper}>
                      <DatePicker
                          selected={moment(startDate).toDate()}
                          onChange={this.handleDatePickerChange.bind(this, 'startDate')}
                          dateFormat="MMM d, yyyy"
                      />
                    </UikFormInputGroup>

                    <label className="uik-content-title__wrapper" style={{"padding": "0 10px"}}>-</label>

                    <UikFormInputGroup className={cls.dateInputWrapper}>
                      <DatePicker
                          selected={moment(endDate).toDate()}
                          onChange={this.handleDatePickerChange.bind(this, 'endDate')}
                          dateFormat="MMM d, yyyy"
                      />
                    </UikFormInputGroup>
                  </React.Fragment>
                )}

                <UikSelect
                  className={ cls.selectRange }
                  onChange={ this.handleDropdownDateChange.bind(this) }
                  defaultValue={ ["year"] }
                  value={[dateRange]}
                  options={ dateRangeOptions }
                  placeholder="Select Range"
                />
              </div>
              ) }
            >

              <div>
                <div
                  className={ cls.activeStations }
                  onClick={ this.openEditStationsModal.bind(this) }
                >
                  { selectedStations && selectedStations.forEach((selectStationId, index) => {
                      if(index > 7){
                        return;
                      } else if(index === 7){
                        return (<span className={cls.plusNumb}>+{selectedStations.length - 7}</span>)
                      }
                      let station = stations.find(st => st.id === selectStationId);
                      return (
                        <img
                          alt={ station.attributes.name }
                          src={ station.attributes.logo_uuid ? `${mediaStageBaseUrl}/download/${station.attributes.logo_uuid}.${station.attributes.logo_extension}` : '/img/icon-radio.jpg' }
                        />
                      )
                    })
                  }
                </div>
              </div>
            </UikWidgetHeader>

            <div className={ cls.boxes }>
              <div className={ cls.box }>
                <UikContentTitle>Sessions</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(stationStats["active_sessions"])}</span>
                </div>
              </div>
              <div className={ cls.box }>
                <UikContentTitle>Session Starts</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(stationStats["sessions_started"])}</span>
                </div>
              </div>
              <div className={ cls.box }>
                <UikContentTitle>Total Listening Hours</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(Math.floor(stationStats["tlh"]))}</span>
                </div>
              </div>
            </div>

          </UikWidget>
          <UikWidget margin>
            <UikTabContainer>
              {
                [
                  {
                    text: 'Listeners',
                  },
                  {
                    text: 'Countries',
                  },
                  {
                    text: 'Platforms / Devices',
                  },
                ].map(item => (
                  <UikTabItem
                    key={ item.text }
                    onClick={ this.setActiveTab.bind(null, item.text) }
                    className={ item.text === activeTab && 'active' }
                    { ...item }
                  />
                ))
              }
            </UikTabContainer>
            <div className={ classnames(cls.mainTab, {
              [cls.activeTab]: activeTab === 'Listeners',
            }) }
            >
              <div>
                <UikWidgetContent>
                {activeTab === 'Listeners' && (
                  <UikChart
                    chartType="Line"
                    data={ {
                      labels: lineChartLabels,
                      datasets: [
                        {
                          borderColor: '#1665D8',
                          backgroundColor: gradientFill || 'transparent',
                          label: 'Reach',
                          lineTension: 0,
                          pointRadius: 5,
                          pointBackgroundColor: '#1665D8',
                          pointBorderColor: '#FFF',
                          pointBorderWidth: 2,
                          data: lineChartValues,
                        },
                      ],
                    } }
                    height={ 480 }
                    id="performance-chart"
                    options={ {
                      scales: {
                        yAxes: [{
                          ticks: {
                            callback: value => `${numberFormatZeros(value)}   `,
                          },
                          gridLines: {
                            drawBorder: false,
                            drawTicks: true,
                          },
                        }],
                      },
                    } }
                  />
                  )}
                </UikWidgetContent>
              </div>
            </div>
            <div className={ classnames(cls.mainTab, {
              [cls.activeTab]: activeTab === 'Countries',
            }) }
            >
              <div className={cls.mapsWrapperStyles}>
                <ComposableMap
                  projectionConfig={{
                    scale: 205,
                    rotation: [-11,0,0],
                  }}
                  width={980}
                  height={551}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  >
                  <ZoomableGroup center={[0,20]} disablePanning>
                    <Geographies geography="/world-50m.json">
                      {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                        <Geography
                          key={i}
                          geography={geography}
                          projection={projection}
                          style={{
                            default: {
                              fill: "#ECEFF1",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none",
                            },
                            hover: {
                              fill: "#607D8B",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none",
                            },
                            pressed: {
                              fill: "#FF5722",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none",
                            },
                          }}
                        />
                      ))}
                    </Geographies>
                  </ZoomableGroup>
                </ComposableMap>
              </div>
              <div>
                <UikWidgetTable>
                  <thead>
                    <tr>
                      <th>
                        Country
                      </th>
                      <th>
                        Active Sessions
                      </th>
                      <th>
                        Session Starts
                      </th>
                      <th>
                        TLH
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(countriesData||{}).map((key,index) => (
                        <tr key={ Math.random() }>
                          <td>
                            {key}
                          </td>
                          <td>
                            {formatNumber(countriesData[key]["active_sessions"])}
                          </td>
                          <td>
                            {formatNumber(countriesData[key]["sessions_started"])}
                          </td>
                          <td>
                            {formatNumber(countriesData[key]["tlh"])}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </UikWidgetTable>
              </div>
            </div>
            <div className={ classnames(cls.mainTab, {
              [cls.activeTab]: activeTab === 'Platforms / Devices',
            }) }
            >
              <UikWidgetTable>
                <thead>
                  <tr>
                    <th>
                      Platform
                    </th>
                    <th>
                      Active Sessions
                    </th>
                    <th>
                      Session Starts
                    </th>
                    <th>
                      TLH
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(platformsData||{}).map((key,index) => (
                      <tr key={ Math.random() }>
                        <td>
                          {key}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["active_sessions"])}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["sessions_started"])}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["tlh"])}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </UikWidgetTable>
            </div>
          </UikWidget>
        </UikLayoutMain>

        <Modal
          open={this.state.editStationsModalOpen}
          onClose={this.handleEditUserModalClose}
        >
          <div className={ cls.modal }>
            <div className={ cls.modalHeader }>
              Select Stations
            </div>
            <div className={ classnames(cls.modalBody, cls.modalScrollBody) }>
              <div
                className={ cls.selectStations }
              >
                { stations && stations.map((station, index) => (
                    <div
                      className={ classnames(cls.station, {
                        [cls.selectedStation]: selectedStations.indexOf(station.id) > -1,
                      }) }
                      onClick={this.handleStationSelectClick.bind(this, station.id)}
                    >
                      <div
                        className={ cls.stationLogo }
                        style={{ "backgroundImage": "url(" + (station.attributes.logo_uuid ? `${mediaStageBaseUrl}/download/${station.attributes.logo_uuid}.${station.attributes.logo_extension}` : '/img/icon-radio.jpg') + ")"}}
                      ></div>
                      <div className={ cls.stationName }>{ station.attributes.name }</div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton)  }>
              <UikButton onClick={this.handleEditUserModalClose}>Cancel</UikButton>
              <UikButton success onClick={this.handleEditUserModalClose}>Accept</UikButton>
            </div>
          </div>
        </Modal>
      </UikContainerVertical>
    )
  }
}

function formatNumber(num) {
  let res = num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return isNaN(res) ? 0 : res;
}

const dateRangeOptions = [
  {
    value: 'year',
    label: (
      <span>
        <Uikon
          style={ {
            marginRight: '10px',
          } }
        >
          calendar_2
        </Uikon>
        {' Last Year'}
      </span>
    ),
  },
  {
    value: 'month',
    label: (
      <span>
        <Uikon
          style={ {
            marginRight: '10px',
          } }
        >
          calendar_2
        </Uikon>
        {' Last Month'}
      </span>
    ),
  },
  {
    value: 'week',
    label: (
      <span>
        <Uikon
          style={ {
            marginRight: '10px',
          } }
        >
        calendar_2
        </Uikon>
        {' Last Week'}
      </span>
    ),
  },
  {
    value: 'custom',
    label: (
      <span>
        <Uikon
          style={ {
            marginRight: '10px',
          } }
        >
        calendar_2
        </Uikon>
        {' Custom'}
      </span>
    ),
  },
  {
    value: 'realtime',
    label: (
      <span>
        <Uikon
          style={ {
            marginRight: '10px',
          } }
        >
        calendar_1
        </Uikon>
        {' Real Time'}
      </span>
    ),
  },
]

export default connect(
  store => ({
    stations: store.stations.stationsList.stations,
    realtimeSessions: store.analytics.realtimeSessions.stats,
    realtimeStations: store.analytics.realtimeStations.stats,
    realtimeCountries: store.analytics.realtimeCountries.stats,
    realtimePlatforms: store.analytics.realtimePlatforms.stats,
    historicalListeners: store.analytics.historicalListeners.stats,
    historicalStations: store.analytics.historicalStations.stats,
    historicalCountries: store.analytics.historicalCountries.stats,
    historicalPlatforms: store.analytics.historicalPlatforms.stats
  }),
  {
    fetchStations: stationActions.fetchStations,
    fetchRealtimeSessions: actions.fetchRealtimeSessions,
    fetchRealtimeStations: actions.fetchRealtimeStations,
    fetchRealtimePlatforms: actions.fetchRealtimePlatforms,
    fetchRealtimeCountries: actions.fetchRealtimeCountries,
    fetchHistoricalListeners: actions.fetchHistoricalListeners,
    fetchHistoricalStations: actions.fetchHistoricalStations,
    fetchHistoricalPlatforms: actions.fetchHistoricalPlatforms,
    fetchHistoricalCountries: actions.fetchHistoricalCountries,
  },
)(AccountAnalytics)
import initialState from './initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_INVITATIONS':
      return {
        ...state,
        invationsLoading: true,
      }
    case 'CREATE_INVITATION':
      return {
        ...state,
        invationsLoading: true,
      }
    case 'REQUEST_USERS':
      return {
        ...state,
        usersLoading: true,
      }
    case 'RECEIVE_USERS':
      return {
        ...state,
        usersLoading: false,
        users: action.users
      }
    case 'RECEIVE_USER':
      return {
        ...state,
        userLoading: false,
        loggedInUser: action.user
      }
    case 'RECEIVE_INVITATIONS':
      return {
        ...state,
        invationsLoading: false,
        invitations: action.payload
      }
    case 'RECEIVE_STATION_USER_ROLES':
      return {
        ...state,
        userRoles: action.payload
      }
    case 'CREATE_INVITATION_SUCCESS':
      let newInvites = state.invitations.slice();
      newInvites.push(action.payload);
      return {
        ...state,
        invationsLoading: false,
        invitations: newInvites
      }
    case 'STATION_USER_ROLE_UPDATED':
      let userRoles = state.userRoles;
      userRoles[userRoles.findIndex(el => el.id === action.payload.id)] = action.payload;
      return {
        ...state,
        userRoles: userRoles
      }
    default:
      return state
  }
}

export default [
  {
    text: 'Railway Deals',
    icon: '🚝',
  },
  {
    text: 'Hockey Guys UK',
    icon: '🏒',
  },
  {
    text: 'Basketball UK',
    icon: '🏀',
  },
  {
    text: 'Winter Sports',
    icon: '☃️',
  },
  {
    text: 'Ha! The best Jokes',
    icon: '🙊',
  },
  {
    text: '8GAG',
    icon: '🤣',
  },
  {
    text: 'Mentors',
    icon: '💎',
  },

]

import * as React from 'react'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetTable,
  UikTagContainer,UikTag,
} from '@components'

import cls from '../stations-users.scss'

class InvitationsList extends React.Component<{}> {

  render() {
    const { invitations }  = this.props;

    return (
      <UikWidget
        margin
      >
        <UikWidgetTable>
          <thead>
            <tr>
              <th>
                Email
              </th>
              <th>
                Access
              </th>
              <th>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {
              invitations.map(invitation => (
                <tr key={ `tr-${invitation.id}` }>
                  <td>
                    { invitation.attributes.user_email }
                  </td>
                  <td>
                    { invitation.attributes.access_level }
                  </td>
                  <td>
                    { invitation.attributes.status && (
                      <UikTagContainer className={ cls.tags }>
                        <UikTag
                          color={ invitation.attributes.status === "accepted" ? "green" : "orange" }
                          fill
                        >
                        { invitation.attributes.status }
                        </UikTag>
                      </UikTagContainer>
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default InvitationsList

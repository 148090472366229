import * as React from 'react'

/* eslint-disable max-len */
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames'

import {
  Uikon,
  UikWidget,
  UikWidgetTable,
  UikButton,
  UikDropdown,UikDropdownItem,
} from '@components'

import PacmanLoader from '../../../../../@components/PacmanLoader'

import cls from '../billing.scss'

// @flow

class StationsDashboard extends React.Component<{
}> {
  render() {
    const { editService, deleteService, services, loading } = this.props;

    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={ cls.table }>
          <thead>
            <tr>
              <th>
                Station&nbsp;&nbsp;
                <Tooltip title="These your active stations" placement="top">
                  <i className={ classnames("fas fa-question-circle", cls.tooltip)}></i>
                </Tooltip>
              </th>
              <th>
                Package&nbsp;&nbsp;
                <Tooltip title="This is subscription package for the station" placement="top">
                  <i className={ classnames("fas fa-question-circle", cls.tooltip)}></i>
                </Tooltip>
              </th>
              <th>
                Status&nbsp;&nbsp;
                <Tooltip title="This is the status of the station (Status can be Active, Suspended, ? ? ?" placement="top">
                  <i className={ classnames("fas fa-question-circle", cls.tooltip)}></i>
                </Tooltip>
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              services.map(service => (
                <tr key={ Math.random() /* pls don't do this in prod */ }>
                  <td>
                    {((service.station||{}).attributes||{}).name}
                  </td>
                  <td>
                    {((service.package||{}).attributes||{}).name}
                  </td>
                  <td>
                    {(service.attributes||{}).status}
                  </td>
                  <td>
                    <UikDropdown
                      DisplayComponent={
                      props => (
                        <UikButton
                          clear
                          icon={ <Uikon>more</Uikon> }
                          iconOnly
                          {...props}
                        />
                      )
                    }
                      position="bottomRight"
                    >
                      <UikDropdownItem onClick={editService.bind(this, service)}>
                        Edit
                      </UikDropdownItem>
                      <UikDropdownItem onClick={deleteService.bind(this, service)}>
                        Terminate
                      </UikDropdownItem>
                    </UikDropdown>
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { services.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No services found.</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default StationsDashboard

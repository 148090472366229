import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//TracksCategory list
export const FETCH_TRACKS_CATEGORIES = 'FETCH_TRACKS_CATEGORIES';
export const FETCH_TRACKS_CATEGORIES_SUCCESS = 'FETCH_TRACKS_CATEGORIES_SUCCESS';
export const FETCH_TRACKS_CATEGORIES_PAGE_SUCCESS = 'FETCH_TRACKS_CATEGORIES_PAGE_SUCCESS';
export const FETCH_TRACKS_CATEGORIES_FAILURE = 'FETCH_TRACKS_CATEGORIES_FAILURE';
export const RESET_TRACKS_CATEGORIES = 'RESET_TRACKS_CATEGORIES';

//Create new TRACKS_CATEGORY
export const CREATE_TRACKS_CATEGORY = 'CREATE_TRACKS_CATEGORY';
export const CREATE_TRACKS_CATEGORY_SUCCESS = 'CREATE_TRACKS_CATEGORY_SUCCESS';
export const CREATE_TRACKS_CATEGORY_FAILURE = 'CREATE_TRACKS_CATEGORY_FAILURE';
export const RESET_NEW_TRACKS_CATEGORY = 'RESET_NEW_TRACKS_CATEGORY';

//Update TRACKS_CATEGORY
export const UPDATE_TRACKS_CATEGORY = 'UPDATE_TRACKS_CATEGORY';
export const UPDATE_TRACKS_CATEGORY_SUCCESS = 'UPDATE_TRACKS_CATEGORY_SUCCESS';
export const UPDATE_TRACKS_CATEGORY_FAILURE = 'UPDATE_TRACKS_CATEGORY_FAILURE';

//Fetch TRACKS_CATEGORY
export const FETCH_TRACKS_CATEGORY = 'FETCH_TRACKS_CATEGORY';
export const FETCH_TRACKS_CATEGORY_SUCCESS = 'FETCH_TRACKS_CATEGORY_SUCCESS';
export const FETCH_TRACKS_CATEGORY_FAILURE = 'FETCH_TRACKS_CATEGORY_FAILURE';
export const RESET_ACTIVE_TRACKS_CATEGORY = 'RESET_ACTIVE_TRACKS_CATEGORY';

//Delete TRACKS_CATEGORY
export const DELETE_TRACKS_CATEGORY = 'DELETE_TRACKS_CATEGORY';
export const DELETE_TRACKS_CATEGORY_SUCCESS = 'DELETE_TRACKS_CATEGORY_SUCCESS';
export const DELETE_TRACKS_CATEGORY_FAILURE = 'DELETE_TRACKS_CATEGORY_FAILURE';
export const RESET_DELETED_TRACKS_CATEGORY = 'RESET_DELETED_TRACKS_CATEGORY';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestTracksCategories = () => ({
  type: FETCH_TRACKS_CATEGORIES,
})

export const receiveTracksCategories = (json) => ({
  type: FETCH_TRACKS_CATEGORIES_PAGE_SUCCESS,
  payload: json
})

export const requestDeleteTracksCategory = () => ({
  type: DELETE_TRACKS_CATEGORY,
})

export const tracksCategoryDeleted = (json) => ({
  type: DELETE_TRACKS_CATEGORY_SUCCESS,
  id: json
})

export const requestCreateTracksCategory = () => ({
  type: CREATE_TRACKS_CATEGORY,
})

export const requestUpdateTracksCategory = () => ({
  type: UPDATE_TRACKS_CATEGORY,
})

export const tracksCategoryUpdated = (json) => ({
  type: UPDATE_TRACKS_CATEGORY_SUCCESS,
  payload: json
})

function getTracksCategories(dispatch, stationId, page, tracks) {
  let url = `${BROADCASTER_ROOT_URL}tracks-categories/?filter[station]=${stationId}&page[number]=${page}`;
  const trackUUIDs = tracks ? tracks.map(t => t["id"]).join(',') : null;
  if (trackUUIDs) {
    url += `&filter[track_uuids]=${trackUUIDs}`
  }
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    }
  }).then(response => {
    if (response.status === 401) {
      dispatch(push('/'));
      return Promise.resolve();
    }

    return response.json();
  }).then(response => {
    dispatch(receiveTracksCategories(response.data));

    if (response['links'] && response['links']['next']) {
      getTracksCategories(dispatch, stationId, page + 1, tracks).then(() => Promise.resolve());
    } else {
      dispatch(fetchTracksCategoriesSuccess())
    }

    return true;
  })
}

export function fetchTracksCategories(stationId, tracks) {
  return function(dispatch) {

    dispatch(requestTracksCategories())
    getTracksCategories(dispatch, stationId, 1, tracks).then(() => Promise.resolve());
  }
}

export function fetchTracksCategoriesSuccess() {
  return {
    type: FETCH_TRACKS_CATEGORIES_SUCCESS,
  };
}

export function fetchTracksCategoriesFailure(error) {
  return {
    type: FETCH_TRACKS_CATEGORIES_FAILURE,
    payload: error
  };
}

export function createTracksCategory({stationId, trackId, categoryId}) {

  return function(dispatch) {

    dispatch(requestCreateTracksCategory())

    const bearerToken = ls.get('bearerToken');

    if (stationId === undefined || trackId === undefined || categoryId === undefined){
      return;
    }

    let data = {
      "data": {
          "type": "tracks_categories",
          "relationships": {
              "station": {
                  "data": {
                      "type": "stations",
                      "id": stationId
                  }
              },
              "track": {
                  "data": {
                      "type": "tracks",
                      "id": trackId
                  }
              },
              "category": {
                  "data": {
                      "type": "categories",
                      "id": categoryId
                  }
              }
          }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}tracks-categories/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(createTracksCategorySuccess(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createTracksCategorySuccess(newTracksCategory) {
  return {
    type: CREATE_TRACKS_CATEGORY_SUCCESS,
    payload: newTracksCategory
  };
}

export function createTracksCategoryFailure(error) {
  return {
    type: CREATE_TRACKS_CATEGORY_FAILURE,
    payload: error
  };
}

export function resetNewTracksCategory() {
  return {
    type: RESET_NEW_TRACKS_CATEGORY
  }
}
;

export function resetDeletedTracksCategory() {
  return {
    type: RESET_DELETED_TRACKS_CATEGORY
  }
}
;

export function fetchTracksCategorySuccess(activeTracksCategory) {
  return {
    type: FETCH_TRACKS_CATEGORY_SUCCESS,
    payload: activeTracksCategory
  };
}

export function fetchTracksCategoryFailure(error) {
  return {
    type: FETCH_TRACKS_CATEGORY_FAILURE,
    payload: error
  };
}

export function resetActiveTracksCategory() {
  return {
    type: RESET_ACTIVE_TRACKS_CATEGORY
  }
}


export function deleteTracksCategory(tcid) {

  return function(dispatch) {

    dispatch(requestDeleteTracksCategory())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}tracks-categories/${tcid}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(tracksCategoryDeleted(tcid))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}



export function updateTracksCategory(cat) {

  return function(dispatch) {

    dispatch(requestUpdateTracksCategory())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "categories",
        "id": cat.id + "",
        "attributes": cat.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}tracks-categories/${cat.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(tracksCategoryUpdated(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

// export function deleteTracksCategorySuccess(deletedTracksCategory) {
//   return {
//     type: DELETE_TRACKS_CATEGORY_SUCCESS,
//     payload: deletedTracksCategory
//   };
// }

export function deleteTracksCategoryFailure(response) {
  return {
    type: DELETE_TRACKS_CATEGORY_FAILURE,
    payload: response
  };
}

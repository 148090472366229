import * as React from 'react'
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames'

import { connect } from 'react-redux'
import ls from 'local-storage'
import * as actions from '@redux/stations/actions'
/* eslint-disable max-len */

import {
  UikButton,
  UikInput,
  UikFormInputGroup,
} from '@components'

import Select from 'react-select';

import cls from './onboarding-modals.scss'

// @flow

class NewStationModal extends React.Component<{
}> {

  constructor(props) {
    super(props);

    this.state = {
      "stationAttributes": {},
      "userId": ls.get("u"),
    }

    this.props.fetchGenres();

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleGenresChange = this.handleGenresChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  handleSaveClick = () => {
    // this.props.updateStation()
    let stationAttributes = Object.assign({},this.state.stationAttributes);
    this.props.createStation({
      userId: this.state.userId,
      stationAttributes: stationAttributes,
      packageId: this.props.package.id
    });

    let self = this;
    setTimeout(function(){
      self.props.onSuccess();
    }, 5000);
  }

  handleEditChange(event) {
    let stationAttributes = this.state.stationAttributes;
    stationAttributes[event.target.name] = event.target.value;
    this.setState({stationAttributes});
  }

  handleGenresChange(genres) {
    let stationAttributes = Object.assign({}, this.state.stationAttributes);
    stationAttributes["genres"] = (genres||[]).map(genre => genre.value);
    this.setState({stationAttributes});
  }

  render() {
    const { modalOpen, genres } = this.props;

    let selectedGenres = this.state.stationAttributes.genres || [];
    let genresOptions = (genres||[]).map((genre) => {
      return { value: genre.id, label: genre.attributes.name}
    });

    return (
      <Modal
        open={modalOpen}
        onClose={this.props.onModalClose}
      >
        <div className={ classnames(cls.modal, cls.modalLarge) }>
          <div className={ cls.modalHeader }>
            New Station Details
          </div>
          <div className={ cls.modalBody }>
            <form onSubmit={ () => {} }>
              <UikFormInputGroup>
                <UikFormInputGroup direction="horizontal">
                  <UikInput
                    label="Station Name"
                    name="name"
                    maxLength={40}
                    onChange={ this.handleEditChange }
                  />
                  <div>
                    <label className="uik-content-title__wrapper">
                      Genres ( up to 5 )
                    </label>
                    <Select
                      isMulti
                      name="categories"
                      className="multi-select"
                      classNamePrefix="select"
                      options={ selectedGenres.length > 4 ? [] : genresOptions }
                      onChange={ this.handleGenresChange }
                    />
                  </div>
                </UikFormInputGroup>
                <UikFormInputGroup>
                  <UikInput
                    label="Short Description"
                    placeholder="Describe what your station is streaming."
                    name="description"
                    onChange={ this.handleEditChange }
                  />
                </UikFormInputGroup>
                <h4>Social Links</h4>
                <UikFormInputGroup direction="horizontal">
                  <UikInput
                    label="Website "
                    placeholder="Your station Website URL"
                    name="website"
                    onChange={ this.handleEditChange }
                  />
                  <UikInput
                    label="Facebook "
                    placeholder="Your Facebook Fanpage URL"
                    name="facebook_url"
                    onChange={ this.handleEditChange }
                  />
                </UikFormInputGroup>
                <UikFormInputGroup direction="horizontal">
                  <UikInput
                    label="Twitter "
                    placeholder="Your Twitter URL"
                    name="twitter_url"
                    onChange={ this.handleEditChange }
                  />
                  <UikInput
                    label="Instagram "
                    placeholder="Your Instagram URL"
                    name="instagram_url"
                    onChange={ this.handleEditChange }
                  />
                </UikFormInputGroup>
              </UikFormInputGroup>
            </form>
          </div>
          <div className={ classnames(cls.modalFooter, cls.modalFooterRight) }>
            <UikButton error onClick={this.props.onCancel} style={{marginRight: '3px'}}>Cancel</UikButton>
            <UikButton success onClick={this.handleSaveClick}>Save</UikButton>
          </div>
        </div>
      </Modal>
    )
  }
}


export default connect(
  store => ({
    station: store.stations.activeStation,
    genres: store.stations.genres,
  }),
  {
    fetchGenres: actions.fetchGenres,
    createStation: actions.createStation,
    updateStation: actions.updateStation,
  },
)(NewStationModal)

import initialState from './initialState'

import {
  FETCH_SEPARATIONS, FETCH_SEPARATIONS_SUCCESS, FETCH_SEPARATIONS_FAILURE, RESET_SEPARATIONS,
  UPDATE_SEPARATION, UPDATE_SEPARATION_SUCCESS,  UPDATE_SEPARATION_FAILURE,
  FETCH_SEPARATION, FETCH_SEPARATION_SUCCESS,  FETCH_SEPARATION_FAILURE, RESET_ACTIVE_SEPARATION,
  CREATE_SEPARATION, CREATE_SEPARATION_SUCCESS, CREATE_SEPARATION_FAILURE, RESET_NEW_SEPARATION,
  DELETE_SEPARATION, DELETE_SEPARATION_SUCCESS, DELETE_SEPARATION_FAILURE, RESET_DELETED_SEPARATION,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_SEPARATIONS:// start fetching separation and set loading = true
    return { ...state, separationsList: {separations:[], error: null, loading: true} };
  case FETCH_SEPARATIONS_SUCCESS:
    return { ...state, separationsList: {separations: action.payload, error:null, loading: false} };
  case FETCH_SEPARATIONS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, separationsList: {separations: [], error: error, loading: false} };
  case RESET_SEPARATIONS:// reset separationList to initial state
    return { ...state, separationsList: {separations: [], error:null, loading: false} };

  case FETCH_SEPARATION:
    return { ...state, activeSeparation:{...state.activeSeparation, loading: true}};
  case FETCH_SEPARATION_SUCCESS:
    return { ...state, activeSeparation: {separation: action.payload, error:null, loading: false}};
  case FETCH_SEPARATION_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeSeparation: {separation: null, error:error, loading:false}};
  case RESET_ACTIVE_SEPARATION:
    return { ...state, activeSeparation: {separation: null, error:null, loading: false}};

  case UPDATE_SEPARATION:
    return { ...state, activeSeparation:{...state.activeSeparation, loading: true}};
  case UPDATE_SEPARATION_SUCCESS:
    return { ...state, activeSeparation: {separation: action.payload, error:null, loading: false}};
  case UPDATE_SEPARATION_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeSeparation: {separation: null, error:error, loading:false}};

  case CREATE_SEPARATION:
    return {...state, newSeparation: {...state.newSeparation, loading: true}}
  case CREATE_SEPARATION_SUCCESS:
    let newSeparations = state.separationsList.separations.slice();
    newSeparations.push(action.payload);
    return {...state,
      separationsList: { separations: newSeparations },
      newSeparation: { separation: action.payload,
      error:null,
      loading: false
    }}
  case CREATE_SEPARATION_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newSeparation: {separation:null, error:error, loading: false}}
  case RESET_NEW_SEPARATION:
    return {...state,  newSeparation:{separation:null, error:null, loading: false}}

  case DELETE_SEPARATION:
     return {...state, deletedSeparation: {...state.deletedSeparation, loading: true}}
  case DELETE_SEPARATION_SUCCESS:
    let newCws = state.separationsList.separations.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, separationsList: { separations: newCws }, deletedSeparation: {separation:action.payload, error:null, loading: false}}
  case DELETE_SEPARATION_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedSeparation: {separation:null, error:error, loading: false}}
  case RESET_DELETED_SEPARATION:
    return {...state,  deletedSeparation:{separation:null, error:null, loading: false}}
  default:
    return state;
  }
}

export default [
  {
    text: 'All',
    rightEl: '1057',
  },
  {
    text: 'Animations',
    rightEl: '456',
  },
  {
    text: 'Flat Design',
    rightEl: '354',
  },
  {
    text: 'Graphic Design',
    rightEl: '23',
  },
  {
    text: 'Illustration',
    rightEl: '76',
  },
  {
    text: 'Pattern',
    rightEl: '1',
  },
  {
    text: 'Pixels',
    rightEl: '68',
  },
  {
    text: 'Photography',
    rightEl: '12',
  },
  {
    text: '3D',
    rightEl: '44',
  },

]

import initialState from './initialState'

import {
  FETCH_FADES, FETCH_FADES_SUCCESS, FETCH_FADES_FAILURE, RESET_FADES,
  UPDATE_FADE, UPDATE_FADE_SUCCESS,  UPDATE_FADE_FAILURE,
  FETCH_FADE, FETCH_FADE_SUCCESS,  FETCH_FADE_FAILURE, RESET_ACTIVE_FADE,
  CREATE_FADE, CREATE_FADE_SUCCESS, CREATE_FADE_FAILURE, RESET_NEW_FADE,
  DELETE_FADE, DELETE_FADE_SUCCESS, DELETE_FADE_FAILURE, RESET_DELETED_FADE,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_FADES:// start fetching fade and set loading = true
    return { ...state, fadesList: {fades:[], error: null, loading: true} };
  case FETCH_FADES_SUCCESS:
    return { ...state, fadesList: {fades: action.payload, error:null, loading: false} };
  case FETCH_FADES_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, fadesList: {fades: [], error: error, loading: false} };
  case RESET_FADES:// reset fadeList to initial state
    return { ...state, fadesList: {fades: [], error:null, loading: false} };

  case FETCH_FADE:
    return { ...state, activeFade:{...state.activeFade, loading: true}};
  case FETCH_FADE_SUCCESS:
    return { ...state, activeFade: {fade: action.payload, error:null, loading: false}};
  case FETCH_FADE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeFade: {fade: null, error:error, loading:false}};
  case RESET_ACTIVE_FADE:
    return { ...state, activeFade: {fade: null, error:null, loading: false}};

  case UPDATE_FADE:
    return { ...state, activeFade:{...state.activeFade, loading: true}};
  case UPDATE_FADE_SUCCESS:
    return { ...state, activeFade: {fade: action.payload, error:null, loading: false}};
  case UPDATE_FADE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeFade: {fade: null, error:error, loading:false}};

  case CREATE_FADE:
    return {...state, newFade: {...state.newFade, loading: true}}
  case CREATE_FADE_SUCCESS:
    let newFades = state.fadesList.fades.slice();
    newFades.push(action.payload);
    return {...state,
      fadesList: { fades: newFades },
      newFade: { fade: action.payload,
      error:null,
      loading: false
    }}
  case CREATE_FADE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newFade: {fade:null, error:error, loading: false}}
  case RESET_NEW_FADE:
    return {...state,  newFade:{fade:null, error:null, loading: false}}

  case DELETE_FADE:
     return {...state, deletedFade: {...state.deletedFade, loading: true}}
  case DELETE_FADE_SUCCESS:
    let newCws = state.fadesList.fades.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, fadesList: { fades: newCws }, deletedFade: {fade:action.payload, error:null, loading: false}}
  case DELETE_FADE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedFade: {fade:null, error:error, loading: false}}
  case RESET_DELETED_FADE:
    return {...state,  deletedFade:{fade:null, error:null, loading: false}}

  default:
    return state;
  }
}

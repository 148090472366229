/* eslint-disable max-len */
import React, { Component } from 'react'
import classnames from 'classnames'
import { Route, Switch, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'

import {
  UikContainerHorizontal,
  UikContainerVertical,
} from '@components'

import Navigation from './components/Navigation'

import cls from './service-dashboard.scss'

// pages
import PageRealtimeAnalytics from './pages/Performance/realtime'
import PageHistoricalAnalytics from './pages/Performance/historical'
import PageRevenueAnalytics from './pages/Performance/revenue'
import PageDashboard from './pages/Dashboard'
import PageSchedule from './pages/Schedule'
import PageClockwheels from './pages/Clockwheels'
import PageClockwheel from './pages/Clockwheels/clockwheel'
import PagePlaylists from './pages/Playlists'
import PageTrackList from './pages/Playlists/playlist'
import PageMediaTracks from './pages/MediaTracks'
import PageMediaCategories from './pages/MediaCategories'
import PageRestrictions from './pages/Settings/restrictions'
import PageSourcesAutoDJ from './pages/Sources/autoDJ'
import PageSourcesLiveDJ from './pages/Sources/liveDJ'
import PageSourcesRelay from './pages/Sources/relay'
import PageUsers from './pages/Users'
import PageSettings from './pages/Settings'
import PageListen from './pages/Listen'
import PageContactUs from './pages/ContactUs'

// @flow

class StationsDashboard extends Component {
  render() {
    // TODO clean this up - router should extract the param
    var stationId = this.props.location.pathname.split('/')[2];
    return (
      <UikContainerHorizontal>
        <Navigation
          stationId={ stationId }
          pathname={this.props.location.pathname}
          className={ classnames(cls.stationsMenuAnimate, {
            [cls.stationsMenuOpen]: true,
          })
        }
          station="station-description-here"
        />
        <UikContainerVertical className={ classnames(cls.stationsMenuAnimate, {
          [cls.stationsMenuOpen]: true,
        }) }
        >
          <Switch>
            <Route
              component={ PageRealtimeAnalytics }
              path="/stations/:stationId/analytics/realtime"
            />
            <Route
              component={ PageHistoricalAnalytics }
              path="/stations/:stationId/analytics/historical"
            />
            <Route
              component={ PageRevenueAnalytics }
              path="/stations/:stationId/analytics/revenue"
            />
            <Route
              component={ PageRestrictions }
              path="/stations/:stationId/restrictions"
            />
            <Route
              component={ PageUsers }
              path="/stations/:stationId/users"
            />
            <Route
              component={ PageSettings }
              path="/stations/:stationId/settings"
            />
            <Route
              component={ PageMediaTracks }
              path="/stations/:stationId/media/tracks"
            />
            <Route
              component={ PageMediaCategories }
              path="/stations/:stationId/media/categories"
            />
            <Route
              component={ PageSchedule }
              path="/stations/:stationId/schedule/events"
            />
            <Route
              component={ PageClockwheel }
              path="/stations/:stationId/schedule/clockwheels/:clockwheelId"
            />
            <Route
              component={ PageClockwheels }
              path="/stations/:stationId/schedule/clockwheels"
            />
            <Route
              component={ PageTrackList }
              path="/stations/:stationId/schedule/playlists/:playlistId"
            />
            <Route
              component={ PagePlaylists }
              path="/stations/:stationId/schedule/playlists"
            />
            <Route
              component={ PageSourcesAutoDJ }
              path="/stations/:stationId/sources/autodj"
            />
            <Route
              component={ PageSourcesLiveDJ }
              path="/stations/:stationId/sources/livedj"
            />
            <Route
              component={ PageSourcesRelay }
              path="/stations/:stationId/sources/relay"
            />
            <Route
              component={ PageContactUs }
              exact
              path="/stations/:stationId/contact"
              strict
            />
            <Route
              component={ PageListen }
              path="/stations/:stationId/listen"
            />
            <Route
              component={ PageDashboard }
              path="/stations/:stationId"
            />
            <Redirect to="/stations/:stationId" />
          </Switch>
        </UikContainerVertical>
      </UikContainerHorizontal>
    )
  }
}

export default connect(
  store => ({
    // isMenuOpen: store.stations.isMenuOpen,
  }),
)(StationsDashboard)


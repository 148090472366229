import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//AutoDJSetting list
export const FETCH_AUTODJ_SETTINGS = 'FETCH_AUTODJ_SETTINGS';
export const FETCH_AUTODJ_SETTINGS_SUCCESS = 'FETCH_AUTODJ_SETTINGS_SUCCESS';
export const FETCH_AUTODJ_SETTINGS_FAILURE = 'FETCH_AUTODJ_SETTINGS_FAILURE';
export const RESET_AUTODJ_SETTINGS = 'RESET_AUTODJ_SETTINGS';

//Update AUTODJ_SETTING
export const UPDATE_AUTODJ_SETTING = 'UPDATE_AUTODJ_SETTING';
export const UPDATE_AUTODJ_SETTING_SUCCESS = 'UPDATE_AUTODJ_SETTING_SUCCESS';
export const UPDATE_AUTODJ_SETTING_FAILURE = 'UPDATE_AUTODJ_SETTING_FAILURE';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestAutoDJSettings = () => ({
  type: FETCH_AUTODJ_SETTINGS,
})

export const receiveAutoDJSettings = (json) => ({
  type: FETCH_AUTODJ_SETTINGS_SUCCESS,
  payload: json
})

export const requestUpdateAutoDJSetting = () => ({
  type: UPDATE_AUTODJ_SETTING,
})

export const autoDJSettingUpdated = (json) => ({
  type: UPDATE_AUTODJ_SETTING_SUCCESS,
  payload: json
})

export function fetchAutoDJSettings(id) {

  return function(dispatch) {

    dispatch(requestAutoDJSettings())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}autodj-settings/?filter[station]=${id}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveAutoDJSettings(json.data[0]))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function updateAutoDJSettings(autoDJ) {

  return function(dispatch) {

    dispatch(requestUpdateAutoDJSetting())

    const bearerToken = ls.get('bearerToken') || null;

    console.log('autoDJ ##### ',autoDJ);

    let data = {
      "data": {
        "type": "autodj_settings",
        "id": autoDJ.id + "",
        "attributes": autoDJ.attributes
      }
    }

    if(autoDJ["relationships"].hasOwnProperty("separation_rule") && autoDJ["relationships"]["separation_rule"]["data"] !== null){
      data.data["relationships"] = {"separation_rule": {"data": autoDJ["relationships"]["separation_rule"]["data"]}};
    }

    if(autoDJ["relationships"].hasOwnProperty("clockwheel") && autoDJ["relationships"]["clockwheel"]["data"] !== null){
      if (data.data.hasOwnProperty("relationships")){
        data.data["relationships"]["clockwheel"] = {"data": autoDJ["relationships"]["clockwheel"]["data"]};
      } else {
        data.data["relationships"] = {"clockwheel": {"data": autoDJ["relationships"]["clockwheel"]["data"]}};
      }
    }

    return fetch(`${BROADCASTER_ROOT_URL}autodj-settings/${autoDJ.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          console.log('json....',json);
          toast.success("AutoDJSetting has been saved !");
          dispatch(autoDJSettingUpdated(json.data));
        };
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchAutoDJSettingsSuccess(autoDJSettings) {
  return {
    type: FETCH_AUTODJ_SETTINGS_SUCCESS,
    payload: autoDJSettings
  };
}

export function fetchAutoDJSettingsFailure(error) {
  return {
    type: FETCH_AUTODJ_SETTINGS_FAILURE,
    payload: error
  };
}


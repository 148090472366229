import * as React from 'react'

import Modal from '@material-ui/core/Modal';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import * as actions from '@redux/invoices/actions'
import * as userActions from '@redux/users/actions'

import ls from 'local-storage'

/* eslint-disable max-len */
import { connect } from 'react-redux'

import Pagination from '../../../../@components/Pagination'
import SearchInput from '../../../../@components/SearchInput'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikButton,
  UikContentItem
} from '@components'

import TopBar from '../../../../@components/TopBar'
import InvoiceList from './components/InvoiceList'

import cls from './billing.scss'
// @flow
type InvoicesProps = {
  invoices: Array,
}

class InvoicesPage extends React.Component<InvoicesProps> {

  constructor(props) {
    super(props);

    this.state = {
      editAccessModalOpen: false,
      activeInvoice: {},
      perPage: 30,
      page: 1,
      searchQuery: '',
      invoiceId: -1,
      payModalOpen: false
    };

    this.props.fetchInvoices({perPage: 30, page: 1, query:''});

    this.handleSearch = this.handleSearch.bind(this);
  }

  handlePayClick = (invoiceId) => {
    this.setState({invoiceId: invoiceId, payModalOpen: true})
  };

  handlePayConfirmation() {
    this.props.updateInvoice({"id": this.state.invoiceId, "attributes": {"status": "Paid"}});
    this.setState({invoiceId: -1, payModalOpen: false})
  };

  handlePayCancellation() {
    this.setState({invoiceId: -1, payModalOpen: false})
  };

  handleDownloadInvoiceClick = () => {
    alert('download invoice...');
  };

  handleperPageChange(event) {
    this.setState({perPage: event.value, page: 1});
    this.props.fetchInvoices({
      page: 1,
      perPage: event.value,
      query: this.state.searchQuery
    });
  }

  handlePageChange(page) {
    this.setState({page});
    this.props.fetchInvoices({
      page: page,
      perPage: this.state.perPage,
      query: this.state.searchQuery
    });
  }

  handleSearch({query}) {
    query = query.trim();
    this.setState({'searchQuery': query});
    this.setState({'page': 1});
    var payload = {
      page: 1,
      perPage: this.state.perPage,
      query: query
    };
    this.props.fetchInvoices(payload);
  }

  render() {
    const { loggedInUser, invoices, totalInvoices, invoicesLoading } = this.props;
    const {
      perPage,
      page,
    } = this.state

    if (Object.entries(loggedInUser).length === 0){
      this.props.fetchUser(ls.get("u"))
    }

    const isStaff = ["staff","admin"].indexOf(loggedInUser.type) > -1;

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Invoices"/>
        <UikLayoutMain className={ cls.wrapper }>
          <Modal
            open={this.state.editAccessModalOpen}
            onClose={this.handleEditAccessModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Edit Access for "User"
              </div>
              <div className={ cls.modalBody }>
                <h3>Edit Service here</h3>
              </div>
              <div className={ cls.modalFooter }>
                <UikButton isExpanded success onClick={this.handleEditAccessModalClose}>Set Access</UikButton>
              </div>
            </div>
          </Modal>
          <Dialog
            open={this.state.payModalOpen}
            onClose={this.handlePayCancellation.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Are you sure you want to pay this invoice?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This invoice will be immediately paid using the currently active card set for this service.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <UikButton error onClick={this.handlePayCancellation.bind(this)}>
                Cancel
              </UikButton>
              <UikButton success onClick={this.handlePayConfirmation.bind(this)}>
                Confirm
              </UikButton>
            </DialogActions>
          </Dialog>
          { isStaff && <UikContentItem className={ cls.header }>
            <SearchInput
              onSearch={ this.handleSearch }
              collectionName="by Email or Mount"
            />
          </UikContentItem>}
          <InvoiceList
            invoices={invoices}
            payNow={this.handlePayClick}
            downloadInvoice={this.handleDownloadInvoiceClick}
            loading={invoicesLoading}
          />
        </UikLayoutMain>

        <Pagination
        showTotal={true}
        showingCount={invoices.length}
        total={ totalInvoices }
        page={ page }
        perPage={ perPage }
        changePage={ this.handlePageChange.bind(this) }
        changePerPage={ this.handleperPageChange.bind(this) }
        aroundPage = { 3 }
        collectionType="invoices"
        />
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    invoices: store.invoices.invoicesList.invoices,
    totalInvoices: store.invoices.totalInvoices,
    loggedInUser: store.users.loggedInUser,
    invoicesLoading: store.invoices.invoicesList.loading,
  }),
  {
    fetchUser: userActions.fetchUser,
    fetchInvoices: actions.fetchInvoices,
    updateInvoice: actions.updateInvoice
  },
)(InvoicesPage)

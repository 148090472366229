export const LIVE365_API = typeof window !== 'undefined' ? window.env.LIVE365_API : process.env.LIVE365_API

let broadcaster_service_root = "http://127.0.0.1:5002"
let user_service_root = "http://127.0.0.1:5001"
let media_service_root = "http://127.0.0.1:5004"
let streaming_root = "http://ls-stage.live365.net:8000"

if (LIVE365_API === "production") {
  broadcaster_service_root = "https://dashboard.live365.com"
  user_service_root = "https://accounts.live365.com"
  media_service_root = "https://media.live365.com"
  streaming_root = "http://streaming.live365.com"
}
else if (LIVE365_API === "beta") {
  broadcaster_service_root = "https://broadcaster-beta.live365.com"
  user_service_root = "https://accounts-beta.live365.com"
  media_service_root = "https://media-beta.live365.com"
  streaming_root = "http://edge-beta.exa.live365.net"
}
else if (LIVE365_API === "staging") {
  broadcaster_service_root = "https://broadcaster-stage.cdnstream.com"
  user_service_root = "https://accounts-stage.cdnstream.com"
  media_service_root = "https://media-stage.cdnstream.com"
  streaming_root = "http://stage-ls01-dal02.cdnstream.com:8000"
}

export const BROADCASTERAPI_SERVER = broadcaster_service_root
export const USERAPI_SERVER = user_service_root
export const MEDIAAPI_SERVER = media_service_root
export const STREAMING_SERVER = streaming_root

// Numerical constants
export const MILLISECONDS_CONVERSION_HOURS = 3600000
export const MILLISECONDS_CONVERSION_MINUTES = 60000
export const MILLISECONDS_CONVERSION_SECONDS = 1000

import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Fade list
export const FETCH_FADES = 'FETCH_FADES';
export const FETCH_FADES_SUCCESS = 'FETCH_FADES_SUCCESS';
export const FETCH_FADES_FAILURE = 'FETCH_FADES_FAILURE';
export const RESET_FADES = 'RESET_FADES';

//Create new FADE
export const CREATE_FADE = 'CREATE_FADE';
export const CREATE_FADE_SUCCESS = 'CREATE_FADE_SUCCESS';
export const CREATE_FADE_FAILURE = 'CREATE_FADE_FAILURE';
export const RESET_NEW_FADE = 'RESET_NEW_FADE';

//Update FADE
export const UPDATE_FADE = 'UPDATE_FADE';
export const UPDATE_FADE_SUCCESS = 'UPDATE_FADE_SUCCESS';
export const UPDATE_FADE_FAILURE = 'UPDATE_FADE_FAILURE';

//Fetch FADE
export const FETCH_FADE = 'FETCH_FADE';
export const FETCH_FADE_SUCCESS = 'FETCH_FADE_SUCCESS';
export const FETCH_FADE_FAILURE = 'FETCH_FADE_FAILURE';
export const RESET_ACTIVE_FADE = 'RESET_ACTIVE_FADE';

//Delete FADE
export const DELETE_FADE = 'DELETE_FADE';
export const DELETE_FADE_SUCCESS = 'DELETE_FADE_SUCCESS';
export const DELETE_FADE_FAILURE = 'DELETE_FADE_FAILURE';
export const RESET_DELETED_FADE = 'RESET_DELETED_FADE';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestFades = () => ({
  type: FETCH_FADES,
})

export const receiveFades = (json) => ({
  type: FETCH_FADES_SUCCESS,
  payload: json
})

export const requestDeleteFade = () => ({
  type: DELETE_FADE,
})

export const fadeDeleted = (cwId) => ({
  type: DELETE_FADE_SUCCESS,
  id: cwId
})

export const requestCreateFade = () => ({
  type: CREATE_FADE,
})

export const requestUpdateFade = () => ({
  type: UPDATE_FADE,
})

export const fadeUpdated = (json) => ({
  type: UPDATE_FADE_SUCCESS,
  payload: json
})

export function fetchFades(stationId) {

  return function(dispatch) {

    dispatch(requestFades())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}fade-options/?filter[station]=${stationId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveFades(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchFade(fadeId) {

  return function(dispatch) {

    dispatch(requestFades())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}fade-options/${fadeId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveFades(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createFade(fade, stationId) {

  return function(dispatch) {

    dispatch(requestCreateFade())

    const bearerToken = ls.get('bearerToken') || null;

    Object.keys(fade.attributes).forEach((key) => (fade.attributes[key] === null) && delete fade.attributes[key]);

    let data = {
      "data": {
        "type": "fade_options",
        "attributes": fade.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}fade-options/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Fade has been created !");
          dispatch(createFadeSuccess(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateFade(fade) {

  return function(dispatch) {

    dispatch(requestUpdateFade())

    const bearerToken = ls.get('bearerToken') || null;
    Object.keys(fade.attributes).forEach((key) => (fade.attributes[key] === null) && delete fade.attributes[key]);

    fade["attributes"]["crossfade_duration"] = parseFloat(fade["attributes"]["crossfade_duration"]);
    fade["attributes"]["fade_in"] = parseFloat(fade["attributes"]["fade_in"]);
    fade["attributes"]["fade_out"] = parseFloat(fade["attributes"]["fade_out"]);

    let data = {
      "data": {
        "type": "fade_options",
        "id": fade.id + "",
        "attributes": fade.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}fade-options/${fade.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Fade updated !");
          dispatch(fadeUpdated(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deleteFade(cwId) {

  return function(dispatch) {

    dispatch(requestDeleteFade())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}fade-options/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Fade has been deleted !");
        dispatch(fadeDeleted(cwId))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function fetchFadesSuccess(fades) {
  return {
    type: FETCH_FADES_SUCCESS,
    payload: fades
  };
}

export function fetchFadesFailure(error) {
  return {
    type: FETCH_FADES_FAILURE,
    payload: error
  };
}

export function createFadeSuccess(newFade) {
  return {
    type: CREATE_FADE_SUCCESS,
    payload: newFade
  };
}

export function createFadeFailure(error) {
  return {
    type: CREATE_FADE_FAILURE,
    payload: error
  };
}

export function resetNewFade() {
  return {
    type: RESET_NEW_FADE
  }
}
;

export function resetDeletedFade() {
  return {
    type: RESET_DELETED_FADE
  }
}

export function fetchFadeSuccess(activeFade) {
  return {
    type: FETCH_FADE_SUCCESS,
    payload: activeFade
  };
}

export function fetchFadeFailure(error) {
  return {
    type: FETCH_FADE_FAILURE,
    payload: error
  };
}

export function resetActiveFade() {
  return {
    type: RESET_ACTIVE_FADE
  }
}

export function deleteFadeFailure(response) {
  return {
    type: DELETE_FADE_FAILURE,
    payload: response
  };
}

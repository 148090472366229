/* eslint-disable max-len */
import * as React from 'react'
import { NavLink, Link } from 'react-router-dom'
import classnames from 'classnames'
import Gravatar from 'react-gravatar'
import * as actions from '@redux/stations/actions'
import * as analActions from '@redux/analytics/actions'
import * as configApp from '@redux/constants'
import * as userActions from '@redux/users/actions'
import ls from 'local-storage'

import { connect } from 'react-redux'

import {
  UikNavSection,
  UikNavLinkDark,
  UikNavLinkTwo,
  UikNavLinkTwoContainer,
  UikDivider,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
  UikNavPanel,
  UikContainerVertical,
  UikScrollArea,
  UikNavLinkSecondary,
} from '@components'

import cls from './stations-navigation.scss'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// @flow
type ServiceSettingsProps = {
  users: Array
}

class ServiceNavigation extends React.Component<ServiceSettingsProps>{
  static defaultProps = {
    className: '',
  }

  constructor(props) {
    super(props);

    this.state = {
      onAirState: false,
      currentListenerCount: 0,
      listenerCountInterval: null,
      stationId: this.props.stationId,
      stationPlayerState: "stopped",
      menuLinks: [
        {
          text: 'Overview',
          to: 'overview',
          exact: true,
          strict: true,
          icon: (
            <i className="fas fa-desktop"></i>
          ),
        },
        {
          text: 'Media',
          icon: (
            <i className="fas fa-music"></i>
          ),
          open: false,
          items: [
            {
              text: 'Tracks',
              to: 'media/tracks',
            },
            {
              text: 'Categories',
              to: 'media/categories',
            }
          ],
        },
        {
          text: 'Schedule',
          icon: (
            <i className="fas fa-clock"></i>
          ),
          open: false,
          items: [
            {
              text: 'Events',
              to: 'schedule/events',
            },
            {
              text: 'Clockwheels',
              to: 'schedule/clockwheels',
            },
            {
              text: 'Playlists',
              to: 'schedule/playlists',
            }
          ],
        },
        {
          text: 'Sources',
          icon: (
            <i className="fas fa-plug"></i>
          ),
          open: false,
          items: [
            {
              text: 'AutoDJ',
              to: 'sources/autodj',
            },
            {
              text: 'LiveDJ',
              to: 'sources/liveDJ',
            },
            {
              text: 'Relay',
              to: 'sources/relay',
            }
          ],
        },
        {
          text: 'Analytics',
          icon: (
            <i className="fas fa-chart-area"></i>
          ),
          open: false,
          items: [
            {
              text: 'Realtime',
              to: 'analytics/realtime'
            },
            {
              text: 'Historical',
              to: 'analytics/historical'
            },
            {
              text: 'Revenue',
              to: 'analytics/revenue'
            },
          ]
        },
        {
          text: 'Listen',
          to: 'listen',
          icon: (
            <i className="fas fa-play-circle"></i>
          ),
        },
        {
          text: 'Station Settings',
          icon: (
            <i className="fas fa-cog"></i>
          ),
          open: false,
          items: [
            {
              text: 'Station Profile',
              to: 'settings',
            },
            {
              text: 'Restrictions',
              to: 'restrictions',
            },
            {
              text: 'Users',
              to: 'users',
            }
          ],
        }
      ]
    };

    if (Object.keys(this.props.activeStation).length === 0) {
      this.props.fetchStation(this.state.stationId);
    }
    if(Object.keys(this.props.loggedInUser).length === 0){
      this.props.fetchUser(ls.get('u'));
    }

    this.props.fetchRealtimeStations({stationIds: [this.props.stationId]});

    this.handleStationPlayClick = this.handleStationPlayClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.realtimeStations !== this.props.realtimeStations || !this.state.currentListenerCount){
      let stats = this.props.realtimeStations;
      if (stats && stats["stations"] && stats["stations"][this.state.stationId]
          && stats.stations[this.state.stationId].active_sessions !== this.state.currentListenerCount) {
        this.setState({currentListenerCount: stats.stations[this.state.stationId].active_sessions||0});
      }
    }

    // loop every 5 mins to get listeners count...
    let self = this;
    if(this.props.activeStation.id && this.props.activeStation.id !== this.props.stationPolling.stationId){
      // station changed... start polling...
      clearInterval(this.props.stationPolling.intervalId);
      let intId = setInterval(function() {
          self.props.fetchRealtimeStations({
            stationIds: [self.state.stationId]
          });
        }, 50000);
      this.props.setStationPolling({stationId: this.props.activeStation.id, intervalId: intId})
    }
  }

  componentDidMount() {
  }

  handleStationPlayClick(){
    const asAttrs = (this.props.activeStation||{}).attributes || {};
    if ( this.state.stationPlayerState === "playing") {
      this.player.pause()
      this.setState({stationPlayerState: "stopped"})
      this.player.src = '';
    } else {
      this.player.src = `${configApp.STREAMING_SERVER}/${asAttrs["mount_id"]}`;
      this.player.play()
      this.setState({stationPlayerState: "playing"})
    }
  }

  handleNavLinkOpenClick = (linkText, e) => {
    let menuLinks = this.state.menuLinks.slice(0);
    for (var i = 0; i < menuLinks.length; ++i) {
      if(menuLinks[i].items){
        menuLinks[i].open = (menuLinks[i].text === linkText) ? !menuLinks[i].open : false;
      }
    }
    this.setState({menuLinks});
  }

  handleLogoutClick(e){
    e.preventDefault();
    localStorage.setItem('bearerToken', '');
    window.location = `${configApp.USERAPI_SERVER}/logout`;
  }

  render() {
    const {
      className,
      activeStation,
      loggedInUser
    } = this.props;
    const {
      stationPlayerState,
      currentListenerCount
    } = this.state;
    const menuLinks = this.state.menuLinks || [];
    const stationId = this.props.stationId;
    const asAttrs = (activeStation||{}).attributes || {};
    const pathname = this.props.pathname || '';

    let onAirState = asAttrs.on_air

    return (
      <UikNavPanel className={ classnames(cls.wrapper, className) }>
        <UikContainerVertical>
          <UikTopBar className={ cls.topBar }>
            <UikTopBarSection>
              <UikTopBarTitle className={ cls.title }>
                <div
                  className={ cls.navigationLogoWhite }
                  alt="navigationLogoWhite"
                ></div>
              </UikTopBarTitle>
            </UikTopBarSection>
          </UikTopBar>

          <UikScrollArea className={ cls.content }>
            <UikNavSection className={ cls.accountProfile }>
              { loggedInUser.email &&
                <Gravatar
                  className={ cls.avatar }
                  email={ loggedInUser.email }
                  size={50}
                />
              }
              <div className={ cls.accountDetails }>
                <div>
                  {loggedInUser.last_name ? `${loggedInUser.first_name} ${loggedInUser.last_name}` : '' }
                </div>
                <div>
                  <Link to="/account/stations">
                    View Account
                  </Link>
                </div>
              </div>
            </UikNavSection>
            <UikDivider className="muted-white"/>
            <UikNavSection className={ cls.stationNavProfile }>
              <div>
                <div className={cls.stationImgWrapper}>
                  <img alt="" src={ asAttrs.logo_uuid ? `${mediaStageBaseUrl}/download/${asAttrs.logo_uuid}.${asAttrs.logo_extension}` : "/img/icon-radio.jpg" }/>
                  <div className={ classnames(cls.stationPlayButton, {[cls.playing]: stationPlayerState === "playing"}) } onClick={ this.handleStationPlayClick }>
                    { stationPlayerState === "stopped" && (<i className="fas fa-play-circle"></i>)}
                    { stationPlayerState === "playing" && (<i className="fas fa-stop"></i>)}
                  </div>
                </div>
              </div>
              <div>
                <div className={ cls.onAir }>
                  { onAirState ? 'ON AIR' : 'OFF AIR' }
                </div>
                <div className={ cls.stats }>
                  <div className={ cls.statsValue }>{currentListenerCount}</div>
                  <p>Listeners</p>
                </div>
              </div>
            </UikNavSection>
            <h3 style={{color: 'white', paddingLeft: '15px', paddingRight: '15px', textAlign: 'center', marginTop: '0px'}}>{asAttrs.name}</h3>
            {
              loggedInUser.type === 'staff' && (
                <h5 style={{color: 'white', paddingLeft: '15px', paddingRight: '15px', textAlign: 'center', marginTop: '0px'}}>{asAttrs.mount_id}</h5>
              )
            }
            <UikDivider className="muted-white"/>
            <UikNavLinkTwoContainer>
              {
                menuLinks.map(({
                  to,
                  count,
                  text,
                  open,
                  icon,
                  items,
                  ...rest
                }) => (
                  <React.Fragment key={ text }>
                    <UikNavLinkTwo
                      key={ text }
                      Component={ NavLink }
                      className={ classnames(cls.navLink, {
                        [cls.open]: open,
                        [cls.activeLink]: pathname.indexOf(to) > -1
                      }) }
                      rightEl={
                        items ? (
                          <span className={ cls.navCaret }>

                          </span>
                        ) : null
                      }
                      icon={ icon }
                      to={ to ? `/stations/${stationId}/${to}` : '#' }
                      onClick={this.handleNavLinkOpenClick.bind(this,text)}
                      { ...rest }
                    >
                      {text}
                    </UikNavLinkTwo>
                    <div>
                      {
                        items && items.map(subLink => (
                          <UikNavLinkSecondary
                            Component={ NavLink }
                            key={ subLink.text }
                            to={ `/stations/${stationId}/${subLink.to}` }
                            className={ classnames(cls.navLinkSecondary, {
                              [cls.activeLink]: pathname.indexOf(subLink.to) > -1
                            }) }
                          >
                            {subLink.text}
                          </UikNavLinkSecondary>
                        ))
                      }
                    </div>
                  </React.Fragment>
                ))
              }
            </UikNavLinkTwoContainer>

            <UikNavSection>
              {/* This will return
              <a
                className={ cls.externalNavLink }
                href="http://help.live365.com/support/tickets"
                target="_blank"
                rel="noreferrer noopener"
              >
                Support Tickets
              </a> **/}
              <a
                className={ cls.externalNavLink }
                href="http://help.live365.com/support/solutions"
                target="_blank"
                rel="noreferrer noopener"
              >
                Knowledge Base
              </a>
              <UikNavLinkDark
                key="terms"
                Component={ NavLink }
                to="/account/terms"
              >
                Terms
              </UikNavLinkDark>
              <UikNavLinkDark
                key="logout"
                Component={ NavLink }
                to="/logout"
                onClick={this.handleLogoutClick.bind(this)}
              >
                Logout
              </UikNavLinkDark>
            </UikNavSection>

          </UikScrollArea>
        </UikContainerVertical>
        { asAttrs["mount_id"] && (
          <audio ref={ref => this.player = ref} />
        )}
      </UikNavPanel>
    )
  }
}

export default connect(
  store => ({
    loggedInUser: store.users.loggedInUser,
    activeStation: store.stations.activeStation,
    stationPolling: store.stations.stationPolling,
    realtimeStations: store.analytics.realtimeStations.stats,
  }),
  {
    fetchUser: userActions.fetchUser,
    fetchStation: actions.fetchStation,
    setStationPolling: actions.setPolling,
    fetchRealtimeStations: analActions.fetchRealtimeStations,
  },
)(ServiceNavigation)



import initialState from './initialState'

import {
  FETCH_TRACKS, FETCH_TRACKS_SUCCESS, FETCH_PLAYLIST_TRACKS_SUCCESS, FETCH_TRACKS_FAILURE, RESET_TRACKS, RESET_PLAYLIST_TRACKS,
  UPDATE_TRACK, UPDATE_TRACK_SUCCESS, UPDATE_TRACK_FAILURE,
  FETCH_TRACK, FETCH_TRACK_SUCCESS, FETCH_TRACK_FAILURE, RESET_ACTIVE_TRACK,
  CREATE_TRACK, CREATE_TRACK_SUCCESS, CREATE_TRACK_FAILURE, RESET_NEW_TRACK,
  DELETE_TRACK, DELETE_TRACK_SUCCESS, DELETE_TRACK_FAILURE, RESET_DELETED_TRACK,
  FETCH_PLAYED_QUEUE_SUCCESS, FETCH_PLAYING_QUEUE_SUCCESS, FETCH_UPCOMING_QUEUE_SUCCESS,
  FETCH_PLAYED_QUEUE,
  IMAGE_UPLOADED,
  REQUEST_ALL_CATEGORIES, REQUEST_ALL_CATEGORIES_SUCCESS, REQUEST_ALL_CATEGORIES_ERROR, RECEIVE_CATEGORIES_PAGE,
} from './actions';

export default function(state = initialState, action) {
  let error, newTracks, tracks;

  switch(action.type) {

  case FETCH_TRACKS:// start fetching track and set loading = true
    return { ...state, tracksList: {tracks:[], error: null, loading: true} };
  case FETCH_TRACKS_SUCCESS:
    return { ...state, tracksList: {tracks: action.payload, total: action.total, error:null, loading: false} };
  case FETCH_PLAYLIST_TRACKS_SUCCESS:
    return { ...state, playlistTracksList: {tracks: [...state.playlistTracksList.tracks, ...action.payload], total: action.total, error:null, loading: false} };
  case FETCH_TRACKS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, tracksList: {tracks: [], error: error, loading: false} };
  case RESET_TRACKS:// reset playlist to initial state
    return { ...state, tracksList: {tracks: [], error:null, loading: false} };
  case RESET_PLAYLIST_TRACKS:
    return { ...state, playlistTracksList: {tracks: [], total: action.total, error:null, loading: false} };

  case FETCH_TRACK:
    return { ...state, activeTrack:{...state.activeTrack, loading: true}};
  case FETCH_TRACK_SUCCESS:
    return { ...state, activeTrack: {track: action.payload, error:null, loading: false}};
  case FETCH_TRACK_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeTrack: {track: null, error:error, loading:false}};
  case RESET_ACTIVE_TRACK:
    return { ...state, activeTrack: {track: null, error:null, loading: false}};

  case UPDATE_TRACK:
    return { ...state, activeTrack:{...state.activeTrack, loading: true}};
  case UPDATE_TRACK_SUCCESS:
    // find track and update it
    tracks = state.tracksList.tracks.slice();
    tracks.find((track, i) => {
      if (track["id"] === action.payload["id"]) {
        action.payload["attributes"]["media_type"] = tracks[i]["attributes"]["media_type"];
        action.payload["updated"] = true;
        tracks[i] = action.payload;
        return true;
      }
      return false;
    });
    return { ...state, tracksList: {tracks: tracks, total: state.tracksList.total, error: error, loading: false}, activeTrack: {track: action.payload, error:null, loading: false}};
  case UPDATE_TRACK_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeTrack: {track: null, error:error, loading:false}};

  case CREATE_TRACK:
    return {...state, newTrack: {...state.newTrack, loading: true}}
  case CREATE_TRACK_SUCCESS:
    newTracks = state.tracksList.tracks.slice();
    newTracks.push(action.payload);
    return {...state,
      tracksList: { tracks: newTracks },
      newTrack: {track:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_TRACK_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newTrack: {track:null, error:error, loading: false}}
  case RESET_NEW_TRACK:
    return {...state,  newTrack:{track:null, error:null, loading: false}}

  case DELETE_TRACK:
     return {...state, deletedTrack: {...state.deletedTrack, loading: true}}
  case DELETE_TRACK_SUCCESS:
    newTracks = state.tracksList.tracks.filter(item => {
      return item["id"] !== action.uuid // return all the items not matching the action.id
    })
    return {...state, tracksList: { tracks: newTracks }}
  case DELETE_TRACK_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedTrack: {track:null, error:error, loading: false}}
  case RESET_DELETED_TRACK:
    return {...state,  deletedTrack:{track:null, error:null, loading: false}}

  case FETCH_PLAYED_QUEUE:
    return {...state, playedQueue: {queue:null, error:null, loading: true}}
  case FETCH_PLAYED_QUEUE_SUCCESS:
    return { ...state, playedQueue: {queue:action.payload, error:null, loading: true}}
  case FETCH_PLAYING_QUEUE_SUCCESS:
    return { ...state, playing: action.payload}
  case FETCH_UPCOMING_QUEUE_SUCCESS:
    return { ...state, upcomingQueue: {queue:action.payload, error:null, loading: true}}

  case IMAGE_UPLOADED:
    tracks = state.tracksList.tracks.slice();
    tracks.find((track, i) => {
      if (track["id"] === action["trackUUID"]) {
        tracks[i]["attributes"]["album_art_uuid"] = action.payload["attributes"]["uuid"];
        tracks[i]["attributes"]["album_art_extension"] = action.payload["attributes"]["extension"];
        return true;
      }
      return false;
    });
    return { ...state, tracksList: {tracks: tracks, total: state.tracksList.total, error: error, loading: false}};

  case REQUEST_ALL_CATEGORIES:
    return { ...state, allCategories: { categories: [], error: null, loading: true }}
  case RECEIVE_CATEGORIES_PAGE:
    return { ...state, allCategories: { categories: [ ...state.allCategories.categories, ...action.payload], error: null, loading: true }}
  case REQUEST_ALL_CATEGORIES_SUCCESS:
    return { ...state, allCategories: { categories: state.allCategories.categories, error: null, loading: false }}
  case REQUEST_ALL_CATEGORIES_ERROR:
    return { ...state, allCategories: { categories: state.allCategories.categories, error: action.payload, loading: false }}

  default:
    return state;
  }
}


import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Clockwheel list
export const FETCH_CLOCKWHEELS = 'FETCH_CLOCKWHEELS';
export const FETCH_CLOCKWHEELS_SUCCESS = 'FETCH_CLOCKWHEELS_SUCCESS';
export const FETCH_CLOCKWHEELS_PAGE_SUCCESS = 'FETCH_CLOCKWHEELS_PAGE_SUCCESS';
export const FETCH_CLOCKWHEELS_FAILURE = 'FETCH_CLOCKWHEELS_FAILURE';
export const RESET_CLOCKWHEELS = 'RESET_CLOCKWHEELS';

//Create new CLOCKWHEEL
export const CREATE_CLOCKWHEEL = 'CREATE_CLOCKWHEEL';
export const CREATE_CLOCKWHEEL_SUCCESS = 'CREATE_CLOCKWHEEL_SUCCESS';
export const CREATE_CLOCKWHEEL_FAILURE = 'CREATE_CLOCKWHEEL_FAILURE';
export const RESET_NEW_CLOCKWHEEL = 'RESET_NEW_CLOCKWHEEL';

//Update CLOCKWHEEL
export const UPDATE_CLOCKWHEEL = 'UPDATE_CLOCKWHEEL';
export const UPDATE_CLOCKWHEEL_SUCCESS = 'UPDATE_CLOCKWHEEL_SUCCESS';
export const UPDATE_CLOCKWHEEL_FAILURE = 'UPDATE_CLOCKWHEEL_FAILURE';

//Fetch CLOCKWHEEL
export const FETCH_CLOCKWHEEL = 'FETCH_CLOCKWHEEL';
export const FETCH_CLOCKWHEEL_SUCCESS = 'FETCH_CLOCKWHEEL_SUCCESS';
export const FETCH_CLOCKWHEEL_FAILURE = 'FETCH_CLOCKWHEEL_FAILURE';
export const RESET_ACTIVE_CLOCKWHEEL = 'RESET_ACTIVE_CLOCKWHEEL';

//Delete CLOCKWHEEL
export const DELETE_CLOCKWHEEL = 'DELETE_CLOCKWHEEL';
export const DELETE_CLOCKWHEEL_SUCCESS = 'DELETE_CLOCKWHEEL_SUCCESS';
export const DELETE_CLOCKWHEEL_FAILURE = 'DELETE_CLOCKWHEEL_FAILURE';
export const RESET_DELETED_CLOCKWHEEL = 'RESET_DELETED_CLOCKWHEEL';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestClockwheels = () => ({
  type: FETCH_CLOCKWHEELS,
})

export const receiveClockwheels = (json) => ({
  type: FETCH_CLOCKWHEELS_PAGE_SUCCESS,
  payload: json
})

export const requestClockwheel = () => ({
  type: FETCH_CLOCKWHEEL,
})

export const receiveClockwheel = (json) => ({
  type: FETCH_CLOCKWHEEL_SUCCESS,
  payload: json
})

export const requestDeleteClockwheel = () => ({
  type: DELETE_CLOCKWHEEL,
})

export const clockwheelDeleted = (cwId) => ({
  type: DELETE_CLOCKWHEEL_SUCCESS,
  id: cwId
})

export const requestCreateClockwheel = () => ({
  type: CREATE_CLOCKWHEEL,
})

export const requestUpdateClockwheel = () => ({
  type: UPDATE_CLOCKWHEEL,
})

export const clockwheelUpdated = (json) => ({
  type: UPDATE_CLOCKWHEEL_SUCCESS,
  payload: json
})

function getClockwheels(dispatch, stationId, page) {
  let url = `${BROADCASTER_ROOT_URL}clockwheels/?filter[station]=${stationId}&page[number]=${page}`;
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    }
  }).then(response => {
    if (response.status === 401) {
      dispatch(push('/'));
      return Promise.resolve();
    }

    return response.json();
  }).then(response => {
    dispatch(receiveClockwheels(response.data));

    if (response['links'] && response['links']['next']) {
      getClockwheels(dispatch, stationId, page + 1).then(() => Promise.resolve());
    } else {
      dispatch(fetchClockwheelsSuccess())
    }

    return true;
  })
}

export function fetchClockwheels(stationId) {

  return function(dispatch) {

    dispatch(requestClockwheels())
    getClockwheels(dispatch, stationId, 1).then(() => Promise.resolve());
  }
}

export function fetchClockwheel(id) {

  return function(dispatch) {

    dispatch(requestClockwheel())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}clockwheels/${id}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveClockwheel(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function createClockwheel(cw, stationId) {

  return function(dispatch) {

    dispatch(requestCreateClockwheel())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "clockwheels",
        "id": stationId + "",
        "attributes": cw.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}clockwheels/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Clockwheel has been created !");
        dispatch(createClockwheelSuccess(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateClockwheel(cw) {

  return function(dispatch) {

    dispatch(requestUpdateClockwheel())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "clockwheels",
        "id": cw.id + "",
        "attributes": cw.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}clockwheels/${cw.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Clockwheel has been saved !");
        dispatch(clockwheelUpdated(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deleteClockwheel(cwId) {

  return function(dispatch) {

    dispatch(requestDeleteClockwheel())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}clockwheels/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Clockwheel has been deleted !");
        dispatch(clockwheelDeleted(cwId))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function fetchClockwheelsSuccess() {
  return {
    type: FETCH_CLOCKWHEELS_SUCCESS,
  };
}

export function fetchClockwheelsFailure(error) {
  return {
    type: FETCH_CLOCKWHEELS_FAILURE,
    payload: error
  };
}

export function createClockwheelSuccess(newClockwheel) {
  return {
    type: CREATE_CLOCKWHEEL_SUCCESS,
    payload: newClockwheel
  };
}

export function createClockwheelFailure(error) {
  return {
    type: CREATE_CLOCKWHEEL_FAILURE,
    payload: error
  };
}

export function resetNewClockwheel() {
  return {
    type: RESET_NEW_CLOCKWHEEL
  }
}
;

export function resetDeletedClockwheel() {
  return {
    type: RESET_DELETED_CLOCKWHEEL
  }
}

export function fetchClockwheelSuccess(activeClockwheel) {
  return {
    type: FETCH_CLOCKWHEEL_SUCCESS,
    payload: activeClockwheel
  };
}

export function fetchClockwheelFailure(error) {
  return {
    type: FETCH_CLOCKWHEEL_FAILURE,
    payload: error
  };
}

export function resetActiveClockwheel() {
  return {
    type: RESET_ACTIVE_CLOCKWHEEL
  }
}





// export function deleteClockwheelSuccess(deletedClockwheel) {
//   return {
//     type: DELETE_CLOCKWHEEL_SUCCESS,
//     payload: deletedClockwheel
//   };
// }

export function deleteClockwheelFailure(response) {
  return {
    type: DELETE_CLOCKWHEEL_FAILURE,
    payload: response
  };
}

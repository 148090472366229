import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'
import { updateAutoDJSettings } from '../autoDJSettings/actions'

//Relay list
export const FETCH_RELAYS = 'FETCH_RELAYS';
export const FETCH_RELAYS_SUCCESS = 'FETCH_RELAYS_SUCCESS';
export const FETCH_RELAYS_FAILURE = 'FETCH_RELAYS_FAILURE';
export const RESET_RELAYS = 'RESET_RELAYS';

//Create new RELAY
export const CREATE_RELAY = 'CREATE_RELAY';
export const CREATE_RELAY_SUCCESS = 'CREATE_RELAY_SUCCESS';
export const CREATE_RELAY_FAILURE = 'CREATE_RELAY_FAILURE';
export const RESET_NEW_RELAY = 'RESET_NEW_RELAY';

//Update RELAY
export const UPDATE_RELAY = 'UPDATE_RELAY';
export const UPDATE_RELAY_SUCCESS = 'UPDATE_RELAY_SUCCESS';
export const UPDATE_RELAY_FAILURE = 'UPDATE_RELAY_FAILURE';

//Fetch RELAY
export const FETCH_RELAY = 'FETCH_RELAY';
export const FETCH_RELAY_SUCCESS = 'FETCH_RELAY_SUCCESS';
export const FETCH_RELAY_FAILURE = 'FETCH_RELAY_FAILURE';
export const RESET_ACTIVE_RELAY = 'RESET_ACTIVE_RELAY';

//Delete RELAY
export const DELETE_RELAY = 'DELETE_RELAY';
export const DELETE_RELAY_SUCCESS = 'DELETE_RELAY_SUCCESS';
export const DELETE_RELAY_FAILURE = 'DELETE_RELAY_FAILURE';
export const RESET_DELETED_RELAY = 'RESET_DELETED_RELAY';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1`;

export const requestRelays = () => ({
  type: FETCH_RELAYS,
})

export const receiveRelays = (json) => ({
  type: FETCH_RELAYS_SUCCESS,
  payload: json
})

export const requestRelay = () => ({
  type: FETCH_RELAY,
})

export const receiveRelay = (json) => ({
  type: FETCH_RELAY_SUCCESS,
  payload: json
})

export const requestDeleteRelay = () => ({
  type: DELETE_RELAY,
})

export const relayDeleted = (relayId) => ({
  type: DELETE_RELAY_SUCCESS,
  id: relayId
})

export const requestCreateRelay = () => ({
  type: CREATE_RELAY,
})

export const requestUpdateRelay = () => ({
  type: UPDATE_RELAY,
})

export const relayUpdated = (json) => ({
  type: UPDATE_RELAY_SUCCESS,
  payload: json
})

export function fetchRelays(stationId) {

  return function(dispatch) {

    dispatch(requestRelays())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}/relays/?filter[station]=${stationId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receiveRelays(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchRelay(fId) {

  return function(dispatch) {

    dispatch(requestRelay())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}/relays/${fId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveRelay(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createRelay({relay, autoDJId, stationId}) {

  return function(dispatch) {

    dispatch(requestCreateRelay())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "relays",
        "attributes": relay.attributes,
        "relationships": {
          "station": {
            "data": {
              "type": "stations",
              "id": stationId + ""
            }
          }
        }
      }
    }

    return fetch(`${BROADCASTER_ROOT_URL}/relays/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          // update autodj setting with relationship link
          let newAutoDJ = {
            "id": autoDJId,
            "relationships": {
              "relay_rule": {
                "data": {
                  "id": json.data.id,
                  "type": "relays"
                }
              }
            }
          }
          dispatch(updateAutoDJSettings(newAutoDJ));
          toast.success("Relay has been created !");
          dispatch(createRelaySuccess(json.data))
        };
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateRelay(relay) {

  return function(dispatch) {

    dispatch(requestUpdateRelay())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "relays",
        "id": relay.id + "",
        "attributes": relay.attributes
      }
    };

    console.log("DATA",data);

    return fetch(`${BROADCASTER_ROOT_URL}/relays/${relay.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Relay updated !");
          dispatch(relayUpdated(json.data))
        };
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deleteRelay(relayId) {

  return function(dispatch) {

    dispatch(requestDeleteRelay())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}/relays/${relayId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Relay address cleared");
        dispatch(relayDeleted(relayId))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function fetchRelaySuccess(relays) {
  return {
    type: FETCH_RELAYS_SUCCESS,
    payload: relays
  };
}

export function fetchRelayFailure(error) {
  return {
    type: FETCH_RELAYS_FAILURE,
    payload: error
  };
}

export function createRelaySuccess(newRelay) {
  return {
    type: CREATE_RELAY_SUCCESS,
    payload: newRelay
  };
}

export function createRelayFailure(error) {
  return {
    type: CREATE_RELAY_FAILURE,
    payload: error
  };
}

export function resetNewRelay() {
  return {
    type: RESET_NEW_RELAY
  }
}
;

export function resetDeletedRelay() {
  return {
    type: RESET_DELETED_RELAY
  }
}


export function resetActiveRelay() {
  return {
    type: RESET_ACTIVE_RELAY
  }
}

export function deleteRelayFailure(response) {
  return {
    type: DELETE_RELAY_FAILURE,
    payload: response
  };
}

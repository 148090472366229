import * as React from 'react'
import * as configApp from '@redux/constants'

import Select from 'react-select';

/* eslint-disable max-len */

import { connect } from 'react-redux'

import * as actions from '@redux/stations/actions'

import {
  UikWidget,
  UikWidgetContent,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidgetContainer,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './settings.scss'

import UploadImageModal from '../../../../@components/UploadImageModal'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// @flow
type StationProps = {
  station: Object,
}

class StationSettingsPage extends React.Component<StationProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      station_logo: '',
      station_cover: '',
      genres: []
    };

    this.props.fetchStation(this.state.stationId);
    this.props.fetchGenres();

    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleSaveChangesClick = this.handleSaveChangesClick.bind(this);
    this.handleGenresChange = this.handleGenresChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.station !== this.props.station){
      this.setState({genres: (this.props.station["attributes"]||{})["genres"]})
    }
  }

  handleCropChange = ({imageType, imageUrl}) => {
    console.log(imageType, imageUrl)
    let newState = {};
    newState[imageType] = imageUrl;
    this.setState(newState);
  }

  handleUploadImageModalSave = ({imageType, file}) => {
    console.log(this.props);
    this.props.uploadImage({stationId: this.state.stationId, mediaServiceUUID: this.props.station["attributes"].media_service_uuid,  imageType, file});
  }

  handleGenresChange(genres) {
    let newState = Object.assign({}, this.state);
    console.log(genres);
    newState["genres"] = (genres||[]).map(genre => parseInt(genre.value, 10));
    this.setState(newState);
  }

  handleEditChange(event) {
    let newState = {...this.state, ...this.props.station.attributes};
    newState[event.target.name] = event.target.value;
    Object.keys(newState).forEach((key) => (newState[key] === null) && delete newState[key]);
    this.setState(newState);
  }

  handleSaveChangesClick(event) {
    let updatedStation = Object.assign({},this.state);
    Object.keys(updatedStation).forEach((key) => (updatedStation[key] === null) && delete updatedStation[key]);
    console.log('updatedStation',updatedStation);
    this.props.updateStation({"id": this.state.stationId, "attributes": updatedStation});
  }

  render() {
    const { station, genres } = this.props;

    let selectedGenres = this.state.genres || [];
    let stationAttrs = (station||{}).attributes || {};

    let genresOptions = [];
    let defaultGenres = null;

    let coverImgUrl = stationAttrs.cover_uuid ? `url("${mediaStageBaseUrl}/download/${stationAttrs.cover_uuid}.${stationAttrs.cover_extension}")` : "url('http://placehold.jp/1400x300.png')";
    let stationLogoImgUrl = stationAttrs.logo_uuid ? `url("${mediaStageBaseUrl}/download/${stationAttrs.logo_uuid}.${stationAttrs.logo_extension}")` : "url('http://placehold.jp/1400x300.png')";

    if (genres.length){
      genresOptions = genres.map((genre) => {
        return { value: genre.id, label: genre.attributes.name}
      });
      defaultGenres = (stationAttrs.genres||[]).map((genreId) => {
        let genre = genres.find(g => g.id === genreId + "");
        return genre ? { value: genre.id, label: genre.attributes.name} : null
      }).filter(g => g !== null);
    }

    console.log('stationAttrs',stationAttrs);
    console.log('defaultGenres',defaultGenres);

    // Delay, wait for the paginated requests to finish
    if (defaultGenres && defaultGenres.length !== stationAttrs["genres"].length) {
      defaultGenres = null;
    }

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar
          title={ "Station Settings"}
        />
        <UikLayoutMain>
          <UikWidgetContainer className={ cls.stationLogoContainer }>
            <UikWidget style={{minHeight: '60px'}}>
              { stationAttrs.cover_uuid && (
                <div
                  className={ cls.stationCover }
                  style={ { backgroundImage: coverImgUrl } }
                />
              )}
              <div className={ cls.stationLogoUpload }>
                <UploadImageModal
                  onCropChange={this.handleCropChange.bind(this)}
                  onSave={this.handleUploadImageModalSave.bind(this)}
                  imageType='station_cover'
                  aspectRatio={16 / 9}
                  buttonText="Upload Cover Image"
                />
              </div>
            </UikWidget>
          </UikWidgetContainer>
          <div className={ cls.splitContent }>
            <UikWidgetContainer>
              <UikWidget>
                { stationAttrs.logo_uuid && (
                  <div
                    className={ cls.stationLogo }
                    style={ { backgroundImage: stationLogoImgUrl } }
                  />
                )}
                <UikWidgetContent className={ cls.userMeta }>
                  <h2>{stationAttrs.name}</h2>
                  <p>
                    {stationAttrs.description}
                  </p>
                  <UploadImageModal
                    onCropChange={this.handleCropChange.bind(this)}
                    onSave={this.handleUploadImageModalSave.bind(this)}
                    imageType='station_logo'
                    aspectRatio={1}
                    isExpanded={true}
                    buttonText="Upload Station Logo"
                  />
                </UikWidgetContent>
              </UikWidget>
            </UikWidgetContainer>
            <UikWidgetContainer className={ cls.basicProfileWidgetContainer } >
              <UikWidget>
                <UikWidgetContent>
                  <form onSubmit={ () => {} }>
                    <UikFormInputGroup>
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          defaultValue={ stationAttrs.name }
                          label="Station Name"
                          name="name"
                          onChange={ this.handleEditChange }
                          maxLength={40}
                        />
                        <div>
                          <label className="uik-content-title__wrapper">
                            Genres ( up to 5 )
                          </label>
                          { genres.length && defaultGenres && (
                            <Select
                              isMulti
                              name="categories"
                              defaultValue={defaultGenres}
                              options={ selectedGenres.length > 4 ? [] : genresOptions }
                              className="multi-select"
                              classNamePrefix="select"
                              onChange={ this.handleGenresChange }
                            />
                          )}
                        </div>
                      </UikFormInputGroup>
                      <UikFormInputGroup>
                        <UikInput
                          defaultValue={stationAttrs.description}
                          label="Short Description"
                          placeholder="Describe what your station is streaming."
                          name="description"
                          onChange={ this.handleEditChange }
                        />
                      </UikFormInputGroup>
                      <h4>Social Links</h4>
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          label="Website "
                          placeholder="Your station Website URL"
                          defaultValue={stationAttrs.website}
                          name="website"
                          onChange={ this.handleEditChange }
                        />
                        <UikInput
                          label="Facebook "
                          placeholder="Your Facebook Fanpage URL"
                          defaultValue={stationAttrs.facebook_url}
                          name="facebook_url"
                          onChange={ this.handleEditChange }
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          label="Twitter "
                          placeholder="Your Twitter URL"
                          defaultValue={stationAttrs.twitter_url}
                          name="twitter_url"
                          onChange={ this.handleEditChange }
                        />
                        <UikInput
                          label="Instagram "
                          placeholder="Your Instagram URL"
                          defaultValue={stationAttrs.instagram_url}
                          name="instagram_url"
                          onChange={ this.handleEditChange }
                        />
                      </UikFormInputGroup>
                    </UikFormInputGroup>
                  </form>
                </UikWidgetContent>
                <UikWidgetContent>
                  <UikButton success onClick={this.handleSaveChangesClick}>Save Settings</UikButton>
                </UikWidgetContent>
              </UikWidget>
            </UikWidgetContainer>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    station: store.stations.activeStation,
    genres: store.stations.genres,
  }),
  {
    fetchGenres: actions.fetchGenres,
    uploadImage: actions.uploadImage,
    fetchStation: actions.fetchStation,
    updateStation: actions.updateStation,
  },
)(StationSettingsPage)

// Libraries
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'
import * as actions from '@redux/docs/actions'

import {
  UikButton,
  UikFormInputGroup,
  UikTopBar,
  UikTopBarSection,
  Uikon,
  UikTopBarTitle,
  UikTopBarMenuDivider,
  UikSelect,
  UikNavBurger,
} from '@components'

import cls from './docs-headerLinks.scss'
// @flow
class DocsTopBar extends React.Component<{
  location: Object,
  history: Object,
  toggleMenu: Function,
  isMenuOpen: Boolean
}> {
  render() {
    const {
      location,
      history,
      toggleMenu,
      isMenuOpen,
    } = this.props
    const path = location.pathname.split('/').splice(0, 3).join('/')
    const defaultSelectValue = path === '/docs' ? '/docs/start' : path

    return (
      <UikTopBar className={ cls.topBar }>
        <UikButton
          className={ classnames(cls.btnMenu, {
            [cls.hasMenu]: [
              '/docs/start',
              '/docs/react',
            ].indexOf(defaultSelectValue) > -1,
          }) }
          onClick={ toggleMenu }
        >
          <UikNavBurger isOpen={ isMenuOpen } />
        </UikButton>
        <UikTopBarSection>
          <UikTopBarTitle>
            <i className="fas fa-palette"></i>
            Styleguide
          </UikTopBarTitle>
          <UikTopBarMenuDivider className={ cls.divider } />
        </UikTopBarSection>
      </UikTopBar>
    )
  }
}


export default withRouter(connect(
  store => ({
    isMenuOpen: store.docs.isMenuOpen,
  }),
  {
    toggleMenu: actions.toggleMenu,
  },
)(DocsTopBar))

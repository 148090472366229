import * as React from 'react'

/* eslint-disable max-len */

import {
  Uikon,
  UikWidget,
  UikWidgetTable,
  UikButton,
  UikDropdown,UikDropdownItem,
} from '@components'

import PacmanLoader from '../../../../../@components/PacmanLoader'

import cls from '../media-categories.scss'

// @flow
type CategoriesProps = {
  categories: Array
}

class CategoryList extends React.Component<CategoriesProps> {
  render() {
    const { editCategory, deleteCategory, categories, loading }  = this.props;
    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={cls.table}>
          <thead>
            <tr>
              <th>
                Category
              </th>
              <th>
                Color
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              categories.map(category => (
                <tr key={ `category-${category.id}` }>
                  <td>
                    {category.attributes.name}
                  </td>
                  <td>
                    <div
                      className={ cls.colorSquare}
                      style={{ backgroundColor: category.attributes.color }}>
                    </div>
                  </td>
                  <td>
                    <UikDropdown
                      DisplayComponent={
                      props => (
                        <UikButton
                          clear
                          icon={ <Uikon>more</Uikon> }
                          iconOnly
                          {...props}
                        />
                      )
                    }
                      position="bottomRight"
                    >
                      <UikDropdownItem onClick={editCategory.bind(this, category)}>
                        Edit
                      </UikDropdownItem>
                      <UikDropdownItem onClick={deleteCategory.bind(this, category)}>
                        Delete
                      </UikDropdownItem>
                    </UikDropdown>
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { categories.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No categories found.</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default CategoryList

import * as React from 'react'

/* eslint-disable max-len */

import {
  Uikon,
  UikAvatar,
  UikWidget,
  UikWidgetTable,
  UikButton,
  UikTagContainer,UikTag,
  UikDropdown,UikDropdownItem,
} from '@components'

import cls from '../stations-users.scss'
import tableCls from './multi-select-table.scss'
import PacmanLoader from '../../../../../@components/PacmanLoader'

// @flow

class StationsDashboard extends React.Component<{
}> {
  render() {
    const { editUser, deleteUser, users, loading }  = this.props;

    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={tableCls.actionsTable}>
          <thead>
            <tr>
              <th>
                Name
              </th>
              <th>
                Role
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              users.map(user => (
                <tr key={ user["id"] }>
                  <td>
                    <UikAvatar
                      highlighted
                      gravatarEmail={ (user["attributes"]||{}).email }
                      name={ (user["attributes"]||{}).name }
                      textBottom={ (user["attributes"]||{}).email }
                    />
                  </td>
                  <td>
                    <UikTagContainer className={ cls.tags }>
                      { (user["attributes"]||{}).level &&
                        <UikTag
                          color="green"
                          fill
                        >
                           { (user["attributes"]||{}).level }
                        </UikTag>
                      }
                    </UikTagContainer>
                  </td>
                  <td>
                    <UikDropdown
                      DisplayComponent={
                      props => (
                        <UikButton
                          clear
                          icon={ <Uikon>more</Uikon> }
                          iconOnly
                          {...props}
                        />
                      )
                    }
                      position="bottomRight"
                    >
                      <UikDropdownItem onClick={editUser.bind(this, user)}>
                        Edit
                      </UikDropdownItem>
                      <UikDropdownItem onClick={deleteUser.bind(this, user)}>
                        Delete
                      </UikDropdownItem>
                    </UikDropdown>
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { users.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No users found.</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default StationsDashboard

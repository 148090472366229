import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import initialState from './initialState'

import auth from './auth'
import stations from './stations'
import users from './users'
import tracks from './tracks'
import clockwheels from './clockwheels'
import clockwheelEntries from './clockwheelEntries'
import categories from './categories'
import playlists from './playlists'
import tutorials from './tutorials'
import services from './services'
import notifications from './notifications'
import packages from './packages'
import invoices from './invoices'
import autoDJSettings from './autoDJSettings'
import events from './events'
import separations from './separations'
import fades from './fades'
import broadcasterUsers from './broadcasterUsers'
import analytics from './analytics'
import restrictions from './restrictions'
import ui from './ui'
import docs from './docs'
import tracksCategories from './tracksCategories'
import relays from './relays'
import servers from './servers'

const appReducer = combineReducers(
  {
    routing: routerReducer,
    auth,
    stations,
    users,
    tracks,
    categories,
    clockwheels,
    clockwheelEntries,
    playlists,
    tutorials,
    services,
    notifications,
    packages,
    invoices,
    autoDJSettings,
    events,
    ui,
    docs,
    separations,
    fades,
    broadcasterUsers,
    analytics,
    restrictions,
    tracksCategories,
    relays,
    servers
  },
  initialState,
)

export default (state, action) => appReducer(state, action)

import * as React from 'react'
import Gravatar from 'react-gravatar'
import * as actions from '@redux/users/actions'
import ls from 'local-storage'

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import { connect } from 'react-redux'

import timezoneOptions from './assets/timezones'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetContent,
  UikSelect,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidgetContainer,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './settings.scss'

// @flow
type AccountSettingsProps = {
  users: Array,
  toggleMenu: Function
}
class AccountSettings extends React.Component<AccountSettingsProps> {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.userId,
      gravatarHelpVisible: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.showAvatarInstructions = this.showAvatarInstructions.bind(this);
  }

  handleChange(event) {
    const userId = this.props.userId || ls.get("u");
    let newState = {...this.state, userId: userId, ...this.props.loggedInUser};
    Object.keys(newState).forEach((key) => (newState[key] === null) && delete newState[key]);
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleCityChange (option) {
    console.log(option)
    // this.setState({ city: option.value, city: option });
  }

  showAvatarInstructions (option) {
    this.setState({ gravatarHelpVisible: true });
  }

  handleTimezoneChange (option) {
    this.setState({ timezone: option.value });
  }

  selectCountry (data) {
    this.setState({ country: data });
  }

  selectRegion (data) {
    this.setState({ state: data });
  }

  handleSaveClick = (data) => {
    const userId = this.props.userId || ls.get("u");
    let user = {...this.props.loggedInUser, ...this.state, userId: userId};
    Object.keys(user).forEach((key) => (user[key] === null) && delete user[key]);
    this.props.updateUser(user);
  };

  handleCropChange = (croppedImageUrl) => {
    this.setState({croppedImageUrl})
  }

  handleUploadImageModalSave = (file) => {
    // this.props.uploadAvatar({mediaServiceUUID: , file});
  }

  render() {
    const { loggedInUser } = this.props;
    const { gravatarHelpVisible } = this.state;

    if (Object.entries(loggedInUser).length === 0){
      this.props.fetchUser(ls.get("u"))
    }

    let country = this.state.country || loggedInUser.country  || "";
    let state = this.state.state || loggedInUser.state || "";

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Account Settings"
        />
        <UikLayoutMain>
          <div className={ cls.splitContent }>
            <UikWidgetContainer className={ cls.subNav }>
              <UikWidget>
                { loggedInUser.email &&
                  <Gravatar
                    className={ cls.profilePic }
                    email={ loggedInUser.email }
                    size={ 500 }
                  />
                }
                <UikWidgetContent className={ cls.userMeta }>
                  <h2>{ `${loggedInUser.first_name} ${loggedInUser.last_name}` }</h2>
                  <p className={ cls.userType }>
                    { `${loggedInUser.type}` }
                  </p>

                  { !gravatarHelpVisible && (
                    <p><a onClick={this.showAvatarInstructions}>Update Image</a></p>
                  )}
                  { gravatarHelpVisible && (
                    <div>
                      <hr/>
                      <p>
                        <br/>
                        For user images, we're using Gravatar, a globally recognized avatar.
                      </p>
                      <p>
                        If you want to update it, visit <a href="https://en.gravatar.com/" rel="noopener noreferrer" target="_blank">https://en.gravatar.com/</a>.
                      </p>
                      <p>Click "Create Your Own Gravatar".
                      </p>
                      <p>Sign up using the same email you use here.
                      </p>
                      <p>Upload an image.
                      </p>
                      <p>
                      Come back to this page, refresh and your image should show up above.
                      </p>
                    </div>
                  )}
                </UikWidgetContent>
              </UikWidget>
            </UikWidgetContainer>
            <UikWidgetContainer className={ cls.basicProfileWidgetContainer } >
              <UikWidget>
                <UikDivider />
                <form onSubmit={ () => {} }>
                  <UikWidgetContent>
                    <UikFormInputGroup>
                      <UikFormInputGroup direction="horizontal">
                        <UikInput
                          maxLength={30}
                          placeholder="John"
                          label="First Name"
                          name="first_name"
                          defaultValue={ loggedInUser.first_name }
                          onChange={ this.handleChange }
                        />
                        <UikInput
                          maxLength={30}
                          placeholder="Smith"
                          label="Last Name"
                          name="last_name"
                          defaultValue={ loggedInUser.last_name }
                          onChange={ this.handleChange }
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup horizontal>
                        <div>
                          <label className="uik-content-title__wrapper">Email</label><br/>
                          <label className={cls.readOnlyLabel}>{ loggedInUser.email }</label>
                        </div>
                        <UikInput

                          label="Phone Number"
                          name="phone"
                          placeholder="+1-000-000-0000"
                          defaultValue={ loggedInUser.phone }
                          onChange={ this.handleChange }
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup horizontal>
                        <UikInput
                          label="Street"
                          name="street"
                          placeholder=""
                          defaultValue={ loggedInUser.street }
                          onChange={ this.handleChange }
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup horizontal>
                        <UikInput
                          label="City"
                          name="city"
                          placeholder=""
                          defaultValue={ loggedInUser.city }
                          onChange={ this.handleChange }
                        />
                        <UikInput
                          label="ZIP / Postal Code"
                          name="postal_code"
                          placeholder=""
                          defaultValue={ loggedInUser.postal_code }
                          onChange={ this.handleChange }
                          maxLength={10}
                        />
                      </UikFormInputGroup>
                      <UikFormInputGroup horizontal>
                        <div>
                          <label className="uik-content-title__wrapper">Country</label>
                          <CountryDropdown
                            value={country}
                            priorityOptions={["US","CA","GB"]}
                            classes="form-control"
                            valueType="short"
                            onChange={(val) => this.selectCountry(val)} />
                        </div>
                        <div>
                          <label className="uik-content-title__wrapper">State</label>
                          <RegionDropdown
                            country={country}
                            classes="form-control"
                            value={state}
                            countryValueType="short"
                            onChange={(val) => this.selectRegion(val)} />
                        </div>
                      </UikFormInputGroup>
                      <UikFormInputGroup horizontal>
                        { loggedInUser.timezone && (
                          <UikSelect
                            label="Timezone"
                            name="timezone"
                            options={ timezoneOptions }
                            placeholder="Action"
                            position="bottomRight"
                            defaultValue={ [loggedInUser.timezone] }
                            onChange={ this.handleTimezoneChange }
                          />
                        )}
                      </UikFormInputGroup>
                    </UikFormInputGroup>
                  </UikWidgetContent>
                  <UikWidgetContent>
                    <UikButton success onClick={this.handleSaveClick.bind(this, this.state)}>Save Settings</UikButton>
                  </UikWidgetContent>
                </form>
              </UikWidget>
            </UikWidgetContainer>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    userId: store.auth.userId,
    loggedInUser: store.users.loggedInUser,
  }),
  {
    uploadAvatar: actions.uploadAvatar,
    fetchUser: actions.fetchUser,
    updateUser: actions.updateUser,
  },
)(AccountSettings)

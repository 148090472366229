import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Playlist list
export const FETCH_PLAYLISTS = 'FETCH_PLAYLISTS';
export const FETCH_PLAYLISTS_SUCCESS = 'FETCH_PLAYLISTS_SUCCESS';
export const FETCH_PLAYLISTS_PAGE_SUCCESS = 'FETCH_PLAYLISTS_PAGE_SUCCESS';
export const FETCH_PLAYLISTS_FAILURE = 'FETCH_PLAYLISTS_FAILURE';
export const RESET_PLAYLISTS = 'RESET_PLAYLISTS';

//Create new PLAYLIST
export const CREATE_PLAYLIST = 'CREATE_PLAYLIST';
export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS';
export const CREATE_PLAYLIST_FAILURE = 'CREATE_PLAYLIST_FAILURE';
export const RESET_NEW_PLAYLIST = 'RESET_NEW_PLAYLIST';

//Update PLAYLIST
export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const UPDATE_PLAYLIST_SUCCESS = 'UPDATE_PLAYLIST_SUCCESS';
export const UPDATE_PLAYLIST_FAILURE = 'UPDATE_PLAYLIST_FAILURE';

//Fetch PLAYLIST
export const FETCH_PLAYLIST = 'FETCH_PLAYLIST';
export const FETCH_PLAYLIST_SUCCESS = 'FETCH_PLAYLIST_SUCCESS';
export const FETCH_PLAYLIST_FAILURE = 'FETCH_PLAYLIST_FAILURE';
export const RESET_ACTIVE_PLAYLIST = 'RESET_ACTIVE_PLAYLIST';

//Delete PLAYLIST
export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_FAILURE = 'DELETE_PLAYLIST_FAILURE';
export const RESET_DELETED_PLAYLIST = 'RESET_DELETED_PLAYLIST';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestPlaylists = () => ({
  type: FETCH_PLAYLISTS,
})

export const receivePlaylists = (json) => ({
  type: FETCH_PLAYLISTS_PAGE_SUCCESS,
  payload: json
})

export const requestPlaylist = () => ({
  type: FETCH_PLAYLIST,
})

export const receivePlaylist = (json) => ({
  type: FETCH_PLAYLIST_SUCCESS,
  payload: json
})

export const requestDeletePlaylist = () => ({
  type: DELETE_PLAYLIST,
})

export const playlistDeleted = (json) => ({
  type: DELETE_PLAYLIST_SUCCESS,
  id: json
})

export const requestCreatePlaylist = () => ({
  type: CREATE_PLAYLIST,
})

export const requestUpdatePlaylist = () => ({
  type: UPDATE_PLAYLIST,
})

export const playlistUpdated = (json) => ({
  type: UPDATE_PLAYLIST_SUCCESS,
  payload: json
})

function getPlaylists(dispatch, stationId, page) {
  let url = `${BROADCASTER_ROOT_URL}playlists/?filter[station]=${stationId}&page[number]=${page}`;
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    }
  }).then(response => {
    if (response.status === 401) {
      dispatch(push('/'));
      return Promise.resolve();
    }

    return response.json();
  }).then(response => {
    dispatch(receivePlaylists(response.data));

    if (response['links'] && response['links']['next']) {
      getPlaylists(dispatch, stationId, page + 1).then(() => Promise.resolve());
    } else {
      dispatch(fetchPlaylistsSuccess())
    }

    return true;
  })
}

export function fetchPlaylists(stationId) {

  return function(dispatch) {

    dispatch(requestPlaylists());
    getPlaylists(dispatch, stationId, 1).then(() => Promise.resolve());
  }
}

export function fetchPlaylistsSuccess() {
  return {
    type: FETCH_PLAYLISTS_SUCCESS,
  };
}

export function fetchPlaylistsFailure(error) {
  return {
    type: FETCH_PLAYLISTS_FAILURE,
    payload: error
  };
}

export function createPlaylist(playlist, stationId) {

  return function(dispatch) {

    dispatch(requestCreatePlaylist())

    const bearerToken = ls.get('bearerToken');

    let data = {
      "data": {
        "type": "playlists",
        "id": stationId + "",
        "attributes": playlist.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}playlists/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Playlist has been created !");
          dispatch(createPlaylistSuccess(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createPlaylistSuccess(newPlaylist) {
  return {
    type: CREATE_PLAYLIST_SUCCESS,
    payload: newPlaylist
  };
}

export function createPlaylistFailure(error) {
  return {
    type: CREATE_PLAYLIST_FAILURE,
    payload: error
  };
}

export function resetNewPlaylist() {
  return {
    type: RESET_NEW_PLAYLIST
  }
}
;

export function resetDeletedPlaylist() {
  return {
    type: RESET_DELETED_PLAYLIST
  }
}
;

export function fetchPlaylist(playlistId) {

  return function(dispatch) {

    dispatch(requestPlaylist())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}playlists/${playlistId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receivePlaylist(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function fetchPlaylistSuccess(activePlaylist) {
  return {
    type: FETCH_PLAYLIST_SUCCESS,
    payload: activePlaylist
  };
}

export function fetchPlaylistFailure(error) {
  return {
    type: FETCH_PLAYLIST_FAILURE,
    payload: error
  };
}

export function resetActivePlaylist() {
  return {
    type: RESET_ACTIVE_PLAYLIST
  }
}


export function deletePlaylist(playlistId) {

  return function(dispatch) {

    dispatch(requestDeletePlaylist())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}playlists/${playlistId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(playlistDeleted(playlistId))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updatePlaylist(playlist) {

  return function(dispatch) {

    dispatch(requestUpdatePlaylist())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "playlists",
        "id": playlist.id + "",
        "attributes": playlist.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}playlists/${playlist.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Playlist updated !");
          dispatch(playlistUpdated(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deletePlaylistFailure(response) {
  return {
    type: DELETE_PLAYLIST_FAILURE,
    payload: response
  };
}

import * as React from 'react'
import classnames from 'classnames'
import * as configApp from '@redux/constants'
import { toast } from "react-toastify";

/* eslint-disable max-len */
import Modal from '@material-ui/core/Modal';
import Dropzone from 'react-dropzone-uploader'
import { connect } from 'react-redux'
import ls from 'local-storage'
import * as actions from '@redux/tracks/actions'

import {
  UikButton,
  UikProgressBar,
} from '@components'

import cls from '../media.scss'
import CategorizeTrackList from './CategorizeTrackList'
import VerifyTrackList from './VerifyTrackList'

const MEDIA_ROOT_URL = `${configApp.MEDIAAPI_SERVER}/`;

// @flow
type MediaUploaderModalProps = {
  tracks: Array,
  uploadTracksModalOpen: Boolean,
}

const resetState = {
  uploadStep: 1,
  uploadedFileCount: 0,
  droppedFiles: [],
  filesToUpload: [],
  failedUploads: [],
  uploadedFileUUIDs: [],
  categorizedFiles: [],
  metadataFiles: [],
  rejectedFiles: [],
  step2Complete: false,
  shouldBlockNavigation: false
};

class MediaUploaderModal extends React.Component<MediaUploaderModalProps> {

  constructor(props) {
    super(props);

    this.state = Object.assign({}, resetState);
    this.state.bearerToken = ls.get('bearerToken') || this.props.bearerToken;

    this.handleSubmitStep2 = this.handleSubmitStep2.bind(this);
    this.handleSubmitStep3 = this.handleSubmitStep3.bind(this);
    this.handleSubmitStep4 = this.handleSubmitStep4.bind(this);
    this.handleCategorizing = this.handleCategorizing.bind(this);
    this.handleUploadTracksModalClose = this.handleUploadTracksModalClose.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.uploadTracksModalOpen !== this.props.uploadTracksModalOpen){
      this.resetModalState();
    }
  }

  resetModalState(newState){
    newState = Object.assign(resetState, newState);
    newState.bearerToken = ls.get('bearerToken') || this.props.bearerToken;
    this.setState(newState)
  }

  handleUploadTracksModalClose = () => {
    if (this.state.uploadStep < 3) { // on step 3 onwards - uploading, don't close modal
      this.props.closeModal();
    }
  };

  handleDropzoneSubmit = (droppedFiles, allFiles) => {
    let filesToUpload = [];
    let categorizedFiles = {};
    droppedFiles.forEach(f => {
      f.file["id"] = f["meta"]["id"];
      filesToUpload.push(f.file);
      categorizedFiles[f["meta"]["id"]] = {"categories":[], "media_type": ""};
    })
    this.setState({droppedFiles, uploadStep: 2, filesToUpload, categorizedFiles})
  }

  handleCategorizing = (files) => {
    let step2Complete = files.reduce((sum, next) => sum && next["file"]["media_type"] && next["file"]["media_type"].length, true);
    this.setState({step2Complete});
  }

  handleSubmitStep2 = () => {
    // pass files to dropzone upload
    this.setState({uploadStep: 3, uploading: true})
  }

  handleSubmitStep3 = () => {
    this.setState({uploadStep: 4})
  }

  handleSubmitStep4 = () => {
    let self = this;
    // create categories for each track...
    this.state.metadataFiles.forEach((f, i) => {
      f["id"] = f["uuid"];
      self.props.updateTrack(f);
    });
    this.props.closeModal(true);
  }

  handleDropChangeStatus = (resp, status) => {
    let self = this;
    let droppedFiles = this.state.droppedFiles;
    // check in a second if it was fine...
    if (status === "done" && droppedFiles[resp.file.name]){
      clearTimeout(droppedFiles[resp.file.name]);
      delete droppedFiles[resp.file.name];
    } else {
      droppedFiles[resp.file.name] = setTimeout(function(resp){
        // this could def be improved but works for now... been a long day...
        if (self.state.rejectedFiles.indexOf(resp.file.name) === -1) {
          let rejectedFiles = [...self.state.rejectedFiles, resp.file.name];
          self.setState({rejectedFiles})
        }
        resp.remove();
      }, 700, resp)
    }
    this.setState(droppedFiles)
  }

  handleChangeStatus = (resp, status) => {
    let respJson, failedUploads, failedUpload, uploadStep;
    let uploadedFileCount = this.state.uploadedFileCount;
    if (status === 'headers_received') {
      toast.success(`${resp.meta.name} uploaded!`);
    } else if (status === 'done') {
      let newFile = {};
      let newState = {};
      respJson = JSON.parse(resp.xhr.response);
      let uuid = respJson["data"]["attributes"]["uuid"];
      // only continue if it's an audio file...
      if(!respJson["data"]["relationships"].hasOwnProperty("audio")){
        console.error('No audio relationship came back from backend...');
        failedUploads = this.state.failedUploads.slice();
        failedUploads.push(respJson);
        newState["failedUploads"] = failedUploads;
      } else {
        newFile["audioId"] = respJson["data"]["relationships"]["audio"]["data"]["id"];
        newFile["meta"] = respJson["data"]["relationships"]["audio"]["data"]["meta"];
        newFile["attributes"] = respJson["data"]["relationships"]["audio"]["data"]["meta"];
        newFile["attributes"]["extension"] = respJson["data"]["attributes"]["extension"];
        newFile["uuid"] = uuid;
        Object.keys(newFile["attributes"]).forEach((key) => (newFile["attributes"][key] === null) && delete newFile["attributes"][key]);

        let uploadedFileUUIDs = this.state.uploadedFileUUIDs.slice();
        uploadedFileUUIDs.push(uuid);
        newState["uploadedFileUUIDs"] = uploadedFileUUIDs;
        let metadataFiles = this.state.metadataFiles.slice();
        metadataFiles.push(newFile);
        newState["metadataFiles"] = metadataFiles;
      }
      newState["uploadedFileCount"] = uploadedFileCount + 1;
      uploadStep = newState["uploadedFileCount"] === this.state.droppedFiles.length ? 4 : 3;
      newState["uploadStep"] = uploadStep;
      this.setState(newState);
    } else if (status === 'aborted' || status === 'error_upload') {
      toast.error(`uploading ${resp.meta.name} failed`);
      failedUpload = {"data": {"attributes": {"filename": resp.meta.name}}};
      failedUploads = this.state.failedUploads.slice();
      failedUploads.push(failedUpload);
      uploadedFileCount++;
      uploadStep = uploadedFileCount === this.state.droppedFiles.length ? 4 : 3;
      this.setState({failedUploads, uploadedFileCount, uploadStep})
    }
  }

  dropzoneLayout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
      <div>
        <div className={ cls.dropzonePreviews }>
          {previews}

          { this.state.rejectedFiles.length > 0 && (
            <div className={ cls.dropzoneRejectedFiles }>
              <h4>Rejected Files</h4>
              { this.state.rejectedFiles.map(rFile =>
                <span key={`rejectedFile${rFile}`}>{rFile}</span>
              )}
            </div>
          )}
        </div>

        <div {...dropzoneProps} className={ cls.dropzone }>
          {files.length < maxFiles && input}
        </div>

        {files.length > 0 && (
          <div className={ cls.dropzoneFooter }>
            <UikButton onClick={this.props.closeModal}>Cancel</UikButton>
            { submitButton }
          </div>
        )}
      </div>
    )
  }

  handleDropzoneUploadSubmit(files, allFiles) {
  }

  dropzoneUploadingLayout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
      <div>
        <div className={ cls.dropzonePreviews }>
          { previews }
        </div>
      </div>
    )
  }

  getUploadParams = (data) => {
    let mediaType = data["file"]["media_type"];
    let url = `${MEDIA_ROOT_URL}upload/${((this.props.activeStation||{})["attributes"]||{})["media_service_uuid"]}/track/${mediaType}`;

    if (data["file"]["categories"] && data["file"]["categories"].length > 0) {
      url += `/${data["file"]["categories"].map(c => c.id).join(",")}`;
    }

    return {
      url: url,
      headers: {
        "Authorization": `Bearer ${this.state.bearerToken}`
      }
    }
  }

  render() {
    let {
      uploadStep,
      uploadedFileCount,
      droppedFiles,
      filesToUpload,
      failedUploads,
      categorizedFiles,
      metadataFiles,
      step2Complete,
    } = this.state;

    let modalTitle
      , modalContent = '';

    let fileTracks = droppedFiles.map(f => {
      return { file: f.file, title: f.meta.name}
    });

    const {
      stationId,
      uploadTracksModalOpen,
     } = this.props;

    switch (uploadStep) {
      case 1:
        modalTitle = "Upload Tracks";
        modalContent = <div>
          <div className={ classnames(cls.modalBody, cls.step1modal)}>
            <Dropzone
              LayoutComponent={this.dropzoneLayout.bind(this)}
              onSubmit={this.handleDropzoneSubmit.bind(this)}
              onChangeStatus={this.handleDropChangeStatus.bind(this)}
              accept="audio/mpeg,audio/x-m4a,.mp3"
              canCancel={true}
              multiple={true}
              inputContent="Drop audio files here or click to browse"
              submitButtonDisabled={files => files.length === 0}
              inputWithFilesContent={files => `Add more files`}
            />
          </div>
        </div>
        break;
      case 2:
        modalTitle = "Categorize Tracks";
        modalContent = <div>
          <div className={ classnames(cls.modalBody, cls.step2modal)}>
            <CategorizeTrackList
              tracks={fileTracks}
              categorizedFiles={categorizedFiles}
              categories={this.props.categories}
              onCategorize={this.handleCategorizing}
              stationId={stationId}
            />
          </div>
          <div className={ cls.modalFooter }>
            <UikButton disabled={step2Complete ? "" : "disabled"} success onClick={this.handleSubmitStep2}>Upload Files</UikButton>
          </div>
        </div>
        break;
      case 3:
        window.onbeforeunload = () => true
        modalTitle = "Uploading tracks";
        modalContent = <div>
          <div className={ classnames(cls.modalBody, cls.step3modal)}>
              <Dropzone
                LayoutComponent={this.dropzoneUploadingLayout.bind(this)}
                onChangeStatus={this.handleChangeStatus.bind(this)}
                getUploadParams={this.getUploadParams.bind(this)}
                accept="audio/mpeg,audio/x-m4a,.mp3"
                initialFiles={filesToUpload}
                onSubmit={this.handleDropzoneUploadSubmit.bind(this)}
                canCancel={false}
                canRemove={false}
                canRestart={false}
                autoProcessQueue={false}
                parallelUploads={2}
              />
            </div>
            <div className={ cls.modalFooter }>
              <UikButton error onClick={this.props.closeModal}>Cancel</UikButton>
            </div>
          </div>
        break;
      case 4:
        modalTitle = "Verify Tracks Metadata";
        modalContent = <div>
            <div className={ cls.modalBody }>
              {metadataFiles && metadataFiles.length > 0 && (
                <React.Fragment>
                  <p>Please ensure the following metadata is correct!</p>
                  <p>We use track metadata to pay royalties to artists for the performances of their content.</p>
                  <VerifyTrackList
                    fileTracks={fileTracks}
                    tracks={metadataFiles}
                  />
                </React.Fragment>
              )}
              { failedUploads.length > 0 && (
                <div>
                  <h5 className={cls.failedTitle}>Failed Uploads</h5>
                  <ul className={cls.fileList}>
                    { failedUploads.map(f =>
                      <li key={`failed-upload-${f.id}`}>{f["data"]["attributes"]["filename"]}</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            <div className={ cls.modalFooter }>
              <UikButton success onClick={this.handleSubmitStep4}>{ metadataFiles.length === 0 ? "Close" : "Save"}</UikButton>
            </div>
          </div>
        break;
      default:
      break;
    }

    return (
      <Modal
        open={uploadTracksModalOpen}
        onClose={this.handleUploadTracksModalClose}
      >
        <div className={ classnames(cls.modal, cls.large, {[cls.xlarge]: uploadStep === 4}) }>
          <div className={ cls.modalHeader }>
            <div className={ cls.modalTitle }>
              {modalTitle}
            </div>
            <div className={ cls.progressDots }>
              <div className={ classnames(cls.progressDot, { [cls.active]: uploadStep > 0}) }>1</div>
              <div className={ classnames(cls.progressDot, { [cls.active]: uploadStep > 1}) }>2</div>
              <div className={ classnames(cls.progressDot, { [cls.active]: uploadStep > 3}) }>3</div>
            </div>
            <div className={ cls.topRight }>
              { uploadStep === 3 && (
                <React.Fragment>
                  <div className={ cls.uploadTracksProgress }>
                    { uploadedFileCount } / { droppedFiles.length } tracks
                  </div>
                  <UikProgressBar
                    className={ cls.uploadTracksProgressBar }
                    fill={ uploadedFileCount / droppedFiles.length }
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          {modalContent}
        </div>

      </Modal>

    )
  }
}

export default connect(
  store => ({
    bearerToken: store.auth.bearerToken,
  }),
  {
    updateTrack: actions.updateTrack,
  },
)(MediaUploaderModal)

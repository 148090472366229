import initialState from './initialState'

import {
  FETCH_INVOICES, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_FAILURE, RESET_INVOICES,
  UPDATE_INVOICE, UPDATE_INVOICE_SUCCESS,  UPDATE_INVOICE_FAILURE,
  FETCH_INVOICE, FETCH_INVOICE_SUCCESS,  FETCH_INVOICE_FAILURE, RESET_ACTIVE_INVOICE,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_INVOICES:// start fetching invoice and set loading = true
    return { ...state, invoicesList: {invoices:[], error: null, loading: true} };
  case FETCH_INVOICES_SUCCESS:
    return { ...state, invoicesList: {invoices: action.payload, error:null, loading: false}, totalInvoices: action.meta.count };
  case FETCH_INVOICES_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, invoicesList: {invoices: [], error: error, loading: false} };
  case RESET_INVOICES:// reset invoiceList to initial state
    return { ...state, invoicesList: {invoices: [], error:null, loading: false} };

  case FETCH_INVOICE:
    return { ...state, activeInvoice:{...state.activeInvoice, loading: true}};
  case FETCH_INVOICE_SUCCESS:
    return { ...state, activeInvoice: {invoice: action.payload, error:null, loading: false}};
  case FETCH_INVOICE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeInvoice: {invoice: null, error:error, loading:false}};
  case RESET_ACTIVE_INVOICE:
    return { ...state, activeInvoice: {invoice: null, error:null, loading: false}};

  case UPDATE_INVOICE:
    return { ...state, activeInvoice:{...state.activeInvoice, loading: true}};
  case UPDATE_INVOICE_SUCCESS:
    return { ...state, activeInvoice: {invoice: action.payload, error:null, loading: false}};
  case UPDATE_INVOICE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeInvoice: {invoice: null, error:error, loading:false}};

  default:
    return state;
  }
}


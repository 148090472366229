import * as React from 'react'
import moment from 'moment'
import * as configApp from '@redux/constants'

import {
  UikWidget,
  UikWidgetHeader,
  UikWidgetTable,
  UikProgressBar,
} from '@components'

import cls from './analytics-most-visited.scss'

// @flow
type TrackQueueProps = {
  pathname: String,
  className: String,
  playing: Object,
  playedQueue: Array,
  fetchQueues: Function,
  onAir: Boolean,
  liveDj: Boolean
}

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

class TrackQueue extends React.Component<TrackQueueProps> {

  constructor(props) {
    super(props);

    this.state = {
      selectedTrack: null,
      playerState: "stopped",
      searchQuery: '',
      playingTime: 0,
      queueInterval: null,
      pollInterval: 4
    }
  }

  componentDidMount() {
  }

  componentWillUnmount(){
   clearInterval(this.state.queueInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    let self = this;
    if(prevProps.playedQueue !== this.props.playedQueue && this.props.playedQueue !== null){
      this.setState({ playedQueue: this.props.playedQueue });
    }
    if(this.state.queueInterval === null && this.props.onAir){
      let queueInterval = setInterval( () => {
        let playing = this.props.playing;
        let playingTime = moment().utc().diff(moment(this.props.playing.start), 's');
        let timeLeft = moment.duration((playing||0).duration||0) - playingTime;
        let pollInterval = self.state.pollInterval;
        pollInterval--;
        let doRefresh = false;
        if (!this.props.liveDj) {
          doRefresh = (timeLeft >= 0 && timeLeft < 5) || (pollInterval % 10 == 0 && timeLeft >= 5);
          if (timeLeft <= 0)
            playingTime = playing.duration;
        }
        if (pollInterval === 0 || doRefresh) {
          this.props.fetchQueues();
          pollInterval = 20;
        }
        this.setState({playingTime, pollInterval})
        if(self.props.pathname.indexOf("/stations/") !== 0 || self.props.pathname.indexOf("/overview") === -1) {
           clearInterval(queueInterval);
        }
      },1000);
      this.setState({queueInterval})
    }
  }

  playTrack(fileName){
    if ( this.state.playerState === "playing" && this.state.selectedTrack === fileName) {
      this.player.pause()
      this.setState({playerState: "stopped"})
      this.player.src = '';
    } else {
      this.player.src = mediaStageBaseUrl + "/download/" + fileName;
      this.player.play()
      this.setState({playerState: "playing", selectedTrack: fileName})
    }
  }

  render() {
    let {
      playing,
      onAir,
      className,
      liveDj
    } = this.props;

    const {
      playedQueue,
      playingTime
    } = this.state;

    let duration = playing.duration
    if (playing && typeof(duration) === 'number' || !duration) {
      duration = mmss(duration)
    }
    else
      duration = '';
    let progressPercent = 1;
    if (!liveDj)
      progressPercent = playingTime / moment.duration(duration.substr(0,8)).asSeconds();

    playedQueue && playedQueue.forEach(t => {
      if (typeof(t.duration) === 'number' || !t.duration) {
        console.log('duration not string', t.duration)
        t.duration = mmss(t.duration)
      }
    });

    return (
      <UikWidget
        margin
        className={className}
      >
        <UikWidgetHeader>
            Playlist
        </UikWidgetHeader>
        <UikWidgetTable>
          <tbody>
            <tr>
              <td>
                <div className={ cls.contentPageName }>
                  <div className={ cls.nowPlaying }>{onAir ? 'NOW PLAYING' : 'OFF AIR'}</div>
                  {onAir && (
                    <div className={ cls.playedCell }>
                      <div>{playing.artist + " - " + playing.title}</div>
                      {
                        !liveDj && (
                          <div>{moment(moment.duration(playingTime,"s").asMilliseconds()).format("mm:ss")}{duration && "/" + duration.slice(2,7)}</div>
                        )
                      }
                    </div>
                  )}
                </div>
                {onAir && !liveDj && (
                  <div className={ cls.trackTime }>
                    <UikProgressBar
                      fill={ progressPercent }
                      className={ cls.progressBar }
                    />
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </UikWidgetTable>
        <UikWidgetHeader>
            Last Played
        </UikWidgetHeader>
        <UikWidgetTable>
          <tbody>
            {
              (playedQueue||[]).map(track => (
                <tr key={ `playedTrack-${track.start}` }>
                  <td className={ cls.playedCell }>
                    <div className={ cls.contentPageName }>
                        <div className={ cls.lastPlayedTime }>{moment(track.start).fromNow()}</div>
                        {track.artist} - {track.title}
                    </div>
                    <div className={ cls.trackTime }>
                      {liveDj ? '' : track.duration && track.duration.slice(2,7)}
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </UikWidgetTable>
        <audio ref={ref => this.player = ref} />
      </UikWidget>
    )
  }
}

export default TrackQueue

function pad(num) {
  return ("0"+num).slice(-2);
}

function mmss(secs) {
  if (typeof(secs) !== 'number')
    return '';
  secs = Math.floor(secs)
  var minutes = Math.floor(secs / 60);
  secs = secs%60;
  // minutes = minutes%60;
  return `0:${pad(minutes)}:${pad(secs)}`;
}

import initialState from './initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_STATIONS':
      return {
        ...state,
        isFetching: true,
        stationsList: {"stations": [], "loading": true},
      }
    case 'REQUEST_GENRES':
    case 'REQUEST_STATION':
      return {
        ...state,
        isFetching: true,
      }
    case 'RECEIVE_STATIONS':
      return {
        ...state,
        isFetching: false,
        stationsList: {"stations": action.payload, "loading": false},
        totalStations: action.meta.count
      }
    case 'RESET_GENRES':
      return {
        ...state,
        genres: []
      }
    case 'RECEIVE_GENRES':
      return {
        ...state,
        isFetching: false,
        genres: [...state.genres, ...action.payload.data]
      }
    case 'RECEIVE_STATION':
      return {
        ...state,
        isFetching: false,
        activeStation: action.payload
      }
    case 'RECEIVE_STATION_SERVICE':
      return {
        ...state,
        isFetching: false,
        stationService: action.payload
      }
    case 'STATION_UPDATED':
      // find previous station, update it... or add it / push it at end...
      let newStations = state.stationsList.stations.slice();
      let stationIndex = newStations.findIndex(st => st.id === action.payload.id);
      if (stationIndex > -1) {
        newStations[newStations.findIndex(st => st.id === action.payload.id)] = action.payload;
      } else {
        newStations.push(action.payload);
      }
      return {
        ...state,
        isFetching: false,
        stationsList: {"stations": newStations, "loading": true},
        activeStation: action.payload
      }
    case 'SET_STATION_POLLING':
      return {
        ...state,
        stationPolling: action.payload
      }
    default:
      return state
  }
}

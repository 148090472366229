import * as React from 'react'
import * as actions from '@redux/relays/actions'
import { connect } from 'react-redux'
import * as autoDJActions from '@redux/autoDJSettings/actions'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetContent,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidgetContainer,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './sources.scss'

// @flow
type RelayProps = {
  relays: Array,
  autoDJSettings: Array,
}

class Relay extends React.Component<RelayProps> {

  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      autoDJSettings: null,
      relay: {"attributes":{"address":""}}
    };

    this.relayAddressRef = React.createRef();

    this.props.fetchAutoDJSettings(this.state.stationId);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.props.fetchRelays(this.state.stationId);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.activeRelay !== this.props.activeRelay){
      console.log('new active relay??',this.props.activeRelay);
      this.setState({relay: this.props.activeRelay || {}});
    }

    if(prevProps.autoDJSettings !== this.props.autoDJSettings){
      this.setState({ autoDJSettings: this.props.autoDJSettings });
    }
  }

  handleAddressChange(event) {
    let relay = Object.assign({}, this.state.relay);
    if(relay.hasOwnProperty("attributes")){
      relay["attributes"]["address"] = event.target.value;
    } else {
      relay["attributes"] = {"address": event.target.value};
    }
    this.setState({relay});
  }

  handleSaveSettingsClick() {
    let relay = Object.assign({}, this.state.relay);
    let relayAddress = this.relayAddressRef.current.input.value;
    if (!relay.hasOwnProperty("id")) {
      if ( relay["attributes"]
        && relay["attributes"].hasOwnProperty('address')
        && relay["attributes"]["address"].length > 0){
        this.props.createRelay({
          relay: {"attributes": {"address": relayAddress}},
          autoDJId: this.state.autoDJSettings["id"],
          stationId: this.state.stationId
        });
      }
    } else {
      if (relayAddress.length === 0){
        this.props.deleteRelay(relay.id);
      } else {
        relay.attributes.address = relayAddress;
        this.props.updateRelay(relay);
      }
    }
  }

  render() {
    let { relay } = this.state;
    let relayAttrs = relay["attributes"] || {"attributes":{}};
    let relayAddress = relayAttrs["address"] || '';
    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar
          title={ "Sources > Relay "}
        />
        <UikLayoutMain>
          <div className={ cls.splitContent }>
            <UikWidgetContainer className={ cls.basicProfileWidgetContainer } >
              <UikWidget>
                <UikWidgetContent>
                  <h3>Relay Settings</h3>
                  <UikDivider />
                  <div className={ cls.helpNote }>
                    Relay settings are used to pull content from a third party server into Live365. 
                    Only listeners tuned into the Live365 Service are covered under the Live365 Terms Of Service.
                  </div>
                </UikWidgetContent>
                <UikDivider />
                  <UikWidgetContent>
                    <UikFormInputGroup direction="vertical">
                      <UikInput
                        label="Relay Address"
                        name="relay_address"
                        placeholder="http://myserver.com:8000"
                        defaultValue={ relayAddress }
                        onChange={ this.handleAddressChange }
                        ref={this.relayAddressRef}
                      />
                      <div className={ cls.helpNote}>
                        <a 
                          href="http://help.live365.com/support/solutions/articles/43000532850-using-the-relay-feature-to-pull-streams-to-live365"
                          target="_blank"
                          rel="noreferrer noopener"
                        >Learn more about relays</a> or <a 
                          href="http://help.live365.com/support/tickets/new"
                          target="_blank"
                          rel="noreferrer noopener"
                        >contact support</a>.
                      </div>
                    </UikFormInputGroup>
                  </UikWidgetContent>
                  <UikWidgetContent>
                    <UikButton success onClick={ this.handleSaveSettingsClick.bind(this) }>Save Settings</UikButton>
                  </UikWidgetContent>
              </UikWidget>
            </UikWidgetContainer>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    autoDJSettings: store.autoDJSettings.autoDJSettings.autoDJSettings,
    activeRelay: store.relays.activeRelay.relay,
  }),
  {
    fetchAutoDJSettings: autoDJActions.fetchAutoDJSettings,
    createRelay: actions.createRelay,
    deleteRelay: actions.deleteRelay,
    updateRelay: actions.updateRelay,
    fetchRelays: actions.fetchRelays,
  },
)(Relay)

import * as React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import classnames from 'classnames'

import {
  Uikon,
  UikDropdown,
  UikDropdownItem,
  UikButton,
} from '@components'

import cls from './stations-list-item.scss'

// @flow
type StationsListItemProps = {
  imgUrl?: String,
  text?: React.Node,
  title?: React.Node,
  status?: React.Node,
  listeners?: React.Node,
  tlh?: React.Node,
  Component?: React.ElementType,
  match: Object,
  id: Number,
  mountId: String,
  isStaff?: Boolean,
  onAir?: Boolean,
}

const StationsListItem = ({
  imgUrl,
  title,
  text,
  status,
  listeners,
  tlh,
  Component,
  match,
  id,
  mountId,
  isStaff,
  onAir,
}: StationsListItemProps) => (
  <Component
    className={ cls.wrapper }
  >
    <td>
      <NavLink
        to={ `/stations/${id}/overview` }
        >
        <div
          className={ cls.imageWrapper }
          style={ {
            backgroundImage: `url("${imgUrl}")`
          } }>
          <div
            className={ classnames(cls.stationStatus, {
              [cls.onAir]: onAir,
            }) }>
            {onAir ? 'ON' : 'OFF'} AIR
          </div>
        </div>
        <div className={ cls.stationText }>
          <h2 className={ cls.title }>
            { title }
          </h2>
          { isStaff && (
            <div className={ cls.subtitle }>
              { mountId }
            </div>
          )}
        </div>
      </NavLink>
    </td>
    {/*<td>
      {listeners}
    </td>
    <td>
      <small
        className={ cls.tlhRatio }>???/???</small>
      <UikProgressBar
        className={ cls.progressBar }
        fill={ 0.7 }
      />
      <small
        className={ cls.resetText }>TLH resets May ???</small>
    </td>*/}
    <td>
      <UikDropdown
        className={ cls.dropdownFilter }
        DisplayComponent={
            props => (
              <UikButton
                { ...props }
                icon={ <Uikon>dropdown_arrow</Uikon> }
                iconRight
                transparent
              >
                Actions
              </UikButton>
            )
          }
        position="bottomRight"
      >
        <UikDropdownItem>
          <NavLink
            to={ `/stations/${id}/overview` }
            >
            Overview
          </NavLink>
         </UikDropdownItem>
        <UikDropdownItem>
          <NavLink
            to={ `/stations/${id}/analytics` }
            >
            Stats
          </NavLink>
        </UikDropdownItem>
      </UikDropdown>
    </td>
  </Component>
)

StationsListItem.defaultProps = {
  imgUrl: null,
  title: null,
  text: null,
  status: null,
  listeners: null,
  tlh: null,
  Component: 'tr',
}

export default withRouter(StationsListItem)

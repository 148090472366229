import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Package list
export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const FETCH_PACKAGES_SUCCESS = 'FETCH_PACKAGES_SUCCESS';
export const FETCH_PACKAGES_FAILURE = 'FETCH_PACKAGES_FAILURE';
export const RESET_PACKAGES = 'RESET_PACKAGES';

//Create new PACKAGE
export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAILURE = 'CREATE_PACKAGE_FAILURE';
export const RESET_NEW_PACKAGE = 'RESET_NEW_PACKAGE';

//Update PACKAGE
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILURE = 'UPDATE_PACKAGE_FAILURE';

//Fetch PACKAGE
export const FETCH_PACKAGE = 'FETCH_PACKAGE';
export const FETCH_PACKAGE_SUCCESS = 'FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILURE = 'FETCH_PACKAGE_FAILURE';
export const RESET_ACTIVE_PACKAGE = 'RESET_ACTIVE_PACKAGE';

//Delete PACKAGE
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAILURE = 'DELETE_PACKAGE_FAILURE';
export const RESET_DELETED_PACKAGE = 'RESET_DELETED_PACKAGE';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestPackages = () => ({
  type: FETCH_PACKAGES,
})

export const receivePackages = (json) => ({
  type: FETCH_PACKAGES_SUCCESS,
  payload: json
})

export const requestPackage = () => ({
  type: FETCH_PACKAGE,
})

export const receivePackage = (json) => ({
  type: FETCH_PACKAGE_SUCCESS,
  payload: json
})

export const requestDeletePackage = () => ({
  type: DELETE_PACKAGE,
})

export const packageDeleted = (cwId) => ({
  type: DELETE_PACKAGE_SUCCESS,
  id: cwId
})

export const requestCreatePackage = () => ({
  type: CREATE_PACKAGE,
})

export const requestUpdatePackage = () => ({
  type: UPDATE_PACKAGE,
})

export const packageUpdated = (json) => ({
  type: UPDATE_PACKAGE_SUCCESS,
  payload: json
})

export function fetchPackages() {

  return function(dispatch) {

    dispatch(requestPackages())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}packages/`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receivePackages(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchPackage(id) {

  return function(dispatch) {

    dispatch(requestPackage())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}packages/${id}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receivePackage(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function createPackage(cw, stationId) {

  return function(dispatch) {

    dispatch(requestCreatePackage())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "packages",
        "id": stationId + "",
        "attributes": cw.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}packages/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Package has been created !");
        dispatch(createPackageSuccess(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updatePackage(cw) {

  return function(dispatch) {

    dispatch(requestUpdatePackage())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "packages",
        "id": cw.id + "",
        "attributes": cw.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}packages/${cw.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Package has been saved !");
        dispatch(packageUpdated(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deletePackage(cwId) {

  return function(dispatch) {

    dispatch(requestDeletePackage())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}packages/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Package has been deleted !");
        dispatch(packageDeleted(cwId))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function fetchPackagesSuccess(packages) {
  return {
    type: FETCH_PACKAGES_SUCCESS,
    payload: packages
  };
}

export function fetchPackagesFailure(error) {
  return {
    type: FETCH_PACKAGES_FAILURE,
    payload: error
  };
}

export function createPackageSuccess(newPackage) {
  return {
    type: CREATE_PACKAGE_SUCCESS,
    payload: newPackage
  };
}

export function createPackageFailure(error) {
  return {
    type: CREATE_PACKAGE_FAILURE,
    payload: error
  };
}

export function resetNewPackage() {
  return {
    type: RESET_NEW_PACKAGE
  }
}
;

export function resetDeletedPackage() {
  return {
    type: RESET_DELETED_PACKAGE
  }
}

export function fetchPackageSuccess(activePackage) {
  return {
    type: FETCH_PACKAGE_SUCCESS,
    payload: activePackage
  };
}

export function fetchPackageFailure(error) {
  return {
    type: FETCH_PACKAGE_FAILURE,
    payload: error
  };
}

export function resetActivePackage() {
  return {
    type: RESET_ACTIVE_PACKAGE
  }
}





// export function deletePackageSuccess(deletedPackage) {
//   return {
//     type: DELETE_PACKAGE_SUCCESS,
//     payload: deletedPackage
//   };
// }

export function deletePackageFailure(response) {
  return {
    type: DELETE_PACKAGE_FAILURE,
    payload: response
  };
}

import * as React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'
import DatePicker from "react-datepicker"

import {
  UikButton,
  Uikon,
  UikFormInputGroup,
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
  UikTabContainer,
  UikTabItem,
  UikSelect,
  UikContentTitle,
  UikWidgetTable,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import {
  UikChart,
} from '@componentWrappers'

import { scaleLinear } from "d3-scale"

import TopBar from '../../../../@components/TopBar'

import * as actions from '@redux/analytics/actions'
import cls from './stations-performance.scss'

import numberFormatZeros from '@lib/numberFormatZeros'

const mapMinColor = "#CFD8DC";
const mapMaxColor = "#37474F";

class HistoricalAnalyticsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      startDate: moment().subtract(1,'month').format().slice(0,19)+"Z",
      endDate: moment().format().slice(0,19)+"Z",
      dateRange: { value: 'month', label: 'Last Month'},
      activeTab: 'Listeners',
      gradientFill: null,
      countriesData: {}
    }

    this.props.fetchHistoricalListeners({stationIds: [this.state.stationId], startDate: this.state.startDate, endDate: this.state.endDate});
    this.props.fetchHistoricalStations({stationIds: [this.state.stationId], startDate: this.state.startDate, endDate: this.state.endDate});
    this.props.fetchHistoricalCountries({stationIds: [this.state.stationId], startDate: this.state.startDate, endDate: this.state.endDate, countries: []});
    this.props.fetchHistoricalPlatforms({stationIds: [this.state.stationId], startDate: this.state.startDate, endDate: this.state.endDate});
  }

  componentDidMount() {
    const chart = document.getElementById('performance-chart')
    if (chart) {
      const ctx = chart.getContext('2d')
      if (ctx) {
        const gradientFill = ctx.createLinearGradient(0, 0, 0, 280)
        gradientFill.addColorStop(0, 'rgba(25, 145, 235, 0.15)')
        gradientFill.addColorStop(0.9, 'rgba(25, 145, 235, 0)')
        this.setState({
          gradientFill,
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let countriesData = null;
    if(this.props.historicalCountries && prevProps.historicalCountries !== this.props.historicalCountries){
      countriesData = this.props.historicalCountries["countries"];
    }
    if (countriesData) {
      let mapMaxValue = 0;
      let mapMinValue = 999999999;
      Object.keys(countriesData).forEach(cId => {
        mapMaxValue = Math.max(countriesData[cId]["active_sessions"],mapMaxValue);
        mapMinValue = Math.min(countriesData[cId]["active_sessions"],mapMinValue);
      })
      let customScale = scaleLinear()
        .domain([mapMinValue, mapMaxValue])
        .range([mapMinColor,mapMaxColor])

      this.setState({ countriesData, customScale });
    }
  }

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  }

  handleDatePickerChange = (dateName, date) => {
    console.log(dateName, date)
    let newState = {"dateRange": dateRangeOptions.find(dro => dro.value === 'custom')};
    newState[dateName] = moment(date).format().slice(0,19)+"Z";

    this.setState(newState);

    let analPayload = {"stationIds": [this.state.stationId]};
    if(dateName === "startDate") {
      analPayload["startDate"] = newState[dateName];
      analPayload["endDate"] = this.state.endDate;
    } else {
      analPayload["startDdate"] = this.state.startDate;
      analPayload["endDdate"] = newState[dateName];
    }

    // IMPROVE THIS... only call the fetch on opening the active tab...
    this.props.fetchHistoricalListeners(analPayload);
    this.props.fetchHistoricalStations(analPayload);
    this.props.fetchHistoricalCountries(analPayload);
    this.props.fetchHistoricalPlatforms(analPayload);
  }

  handleDropdownDateChange = (event) => {
    let newState = {"dateRange": dateRangeOptions.find(dro => dro.value === event.value)};
    if (event.value !== 'custom') {
      let startDate = moment().subtract(1, event.value).format().slice(0,19)+"Z";
      let endDate = moment().format().slice(0,19)+"Z";
      newState["endDate"] = endDate;
      newState["startDate"] = startDate;
      newState["realtime"] = false;
      this.setState(newState);

      // IMPROVE THIS... only call the fetch on opening the active tab...
      this.props.fetchHistoricalListeners({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
      this.props.fetchHistoricalStations({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
      this.props.fetchHistoricalCountries({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
      this.props.fetchHistoricalPlatforms({"stationIds": [this.state.stationId], startDate, "endDate": endDate});
    } else {
      this.setState(newState);
    }
  }

  render = () => {
    const {
      startDate,
      endDate,
      dateRange,
      activeTab,
      gradientFill,
      countriesData,
    } = this.state

    const {
      historicalListeners,
      historicalStations,
      historicalPlatforms,
    } = this.props;

    let stationsData = historicalStations["stations"];
    let platformsData = historicalPlatforms["platforms"];
    let listenersData = historicalListeners["listeners"];

    // console.log('historicalListeners["listeners"]', listenersData)

    let listeners = listenersData || {};
    let lineChartLabels = [];
    let lineChartDataArray = [];
    let lineChartValues = [];

    console.log(listeners);

    if (Object.keys(listeners).length){
      lineChartDataArray = listeners[Object.keys(listeners)[0]];
    }

    for (var i = 0; i < lineChartDataArray.length; ++i) {
      lineChartLabels.push(moment(lineChartDataArray[i].date).format("M/D"));
      lineChartValues.push(lineChartDataArray[i].reach);
    }

    // console.log(lineChartLabels)
    // console.log(lineChartValues)

    let stationStats = (stationsData && stationsData[Object.keys(stationsData)[0]]) || {};

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Realtime Analytics"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikWidget margin>
            <UikWidgetHeader rightEl={ (
              <div className={ cls.dateInputs }>
                <UikFormInputGroup className={ cls.dateInputWrapper }>
                  <DatePicker
                    selected={ moment(startDate).toDate() }
                    onChange={ (date) => this.handleDatePickerChange("startDate", date) }
                    dateFormat="MMM d, yyyy"
                  />
                </UikFormInputGroup>

                <label className="uik-content-title__wrapper" style={{"padding": "0 10px"}}>-</label>

                <UikFormInputGroup className={ cls.dateInputWrapper }>
                  <DatePicker
                      selected={ moment(endDate).toDate() }
                      onChange={ (date) => this.handleDatePickerChange("endDate", date) }
                      dateFormat="MMM d, yyyy"
                  />
                </UikFormInputGroup>

                <UikSelect
                  className={ cls.selectRange }
                  onChange={ this.handleDropdownDateChange }
                  defaultValue={ ["week"] }
                  value={[dateRange]}
                  options={ dateRangeOptions }
                  placeholder="Select Range"
                />
              </div>
              )}
            >
              Summary
            </UikWidgetHeader>
            <div className={ cls.boxes }>
              <div className={ cls.box }>
                <UikContentTitle>Sessions</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(stationStats["active_sessions"])}</span>
                </div>
              </div>
              <div className={ cls.box }>
                <UikContentTitle>Session Starts</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(stationStats["sessions_started"])}</span>
                </div>
              </div>
              <div className={ cls.box }>
                <UikContentTitle>Total Listening Hours</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(Math.floor(stationStats["tlh"]))}</span>
                </div>
              </div>
            </div>
          </UikWidget>
          <UikWidget margin>
            <UikTabContainer>
              {
                ['Listeners', 'Countries', 'Platforms/Devices'].map(tab => (
                  <UikTabItem key={ tab } onClick={ () => this.setActiveTab(tab) } className={ tab === activeTab && 'actve'} text={ tab } />
                ))
              }
            </UikTabContainer>
            <div className={ classnames(cls.mainTab, { [cls.activeTab]: activeTab === 'Listeners'}) }>
              <div>
                <UikWidgetContent>
                {activeTab === 'Listeners' && (
                  <UikChart
                    chartType="Line"
                    data={ {
                      labels: lineChartLabels,
                      datasets: [
                        {
                          borderColor: '#1665D8',
                          backgroundColor: gradientFill || 'transparent',
                          label: 'Reach',
                          lineTension: 0,
                          pointRadius: 5,
                          pointBackgroundColor: '#1665D8',
                          pointBorderColor: '#FFF',
                          pointBorderWidth: 2,
                          data: lineChartValues,
                        },
                      ],
                    } }
                    height={ 480 }
                    id="performance-chart"
                    options={ {
                      scales: {
                        yAxes: [{
                          ticks: {
                            callback: value => `${numberFormatZeros(value)}   `,
                          },
                          gridLines: {
                            drawBorder: false,
                            drawTicks: true,
                          },
                        }],
                      },
                    } }
                  />
                  )}
                </UikWidgetContent>
              </div>
            </div>
            <div className={ classnames(cls.mainTab, { [cls.activeTab]: activeTab === 'Countries'}) }>
              <div>
                <UikWidgetTable>
                  <thead>
                    <tr>
                      <th>
                        Country
                      </th>
                      <th>
                        Active Sessions
                      </th>
                      <th>
                        Session Starts
                      </th>
                      <th>
                        TLH
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(countriesData||{}).map((key,index) => (
                        <tr key={ Math.random() }>
                          <td>
                            {key}
                          </td>
                          <td>
                            {formatNumber(countriesData[key]["active_sessions"])}
                          </td>
                          <td>
                            {formatNumber(countriesData[key]["sessions_started"])}
                          </td>
                          <td>
                            {formatNumber(countriesData[key]["tlh"])}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </UikWidgetTable>
              </div>
            </div>
            <div className={ classnames(cls.mainTab, { [cls.activeTab]: activeTab === 'Platforms/Devices'}) }>
              <UikWidgetTable>
                <thead>
                  <tr>
                    <th>
                      Platform
                    </th>
                    <th>
                      Active Sessions
                    </th>
                    <th>
                      Session Starts
                    </th>
                    <th>
                      TLH
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(platformsData||{}).map((key,index) => (
                      <tr key={ Math.random() }>
                        <td>
                          {key}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["active_sessions"])}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["sessions_started"])}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["tlh"])}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </UikWidgetTable>
            </div>
          </UikWidget>
        </UikLayoutMain>
      </UikContainerVertical>
    );
  }
}

const dateRangeOptions = [
  { value: 'day', label: 'Last Day'},
  { value: 'week', label: 'Last Week' },
  { value: 'month', label: 'Last Month' },
  { value: 'year', label: 'Last Year' },
  { value: 'custom', label: 'Custom' },
]

export default connect(
  store => ({
    historicalListeners: store.analytics.historicalListeners.stats,
    historicalStations: store.analytics.historicalStations.stats,
    historicalCountries: store.analytics.historicalCountries.stats,
    historicalPlatforms: store.analytics.historicalPlatforms.stats
  }),
  {
    fetchHistoricalListeners: actions.fetchHistoricalListeners,
    fetchHistoricalStations: actions.fetchHistoricalStations,
    fetchHistoricalPlatforms: actions.fetchHistoricalPlatforms,
    fetchHistoricalCountries: actions.fetchHistoricalCountries
  },
)(HistoricalAnalyticsPage)

function formatNumber(num) {
  num = num || 0;
  return num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

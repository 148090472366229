import * as React from 'react'
import * as stationActions from '@redux/stations/actions'
import * as serverActions from '@redux/servers/actions';
import * as configApp from '@redux/constants'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { connect } from 'react-redux'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetContent,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidgetContainer,
  UikContainerVertical,
  UikLayoutMain,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './sources.scss'

class CopyToClipboardLabel extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div style={{textAlign: 'space-between'}}>
        {this.props.labelText} &nbsp;
        <CopyToClipboard text={this.props.copyValue}>
          <button onClick={function(e) {e.preventDefault(); toast.success("Copied to clipboard")}}>
            <i className="fas fa-clipboard"></i>
          </button>
        </CopyToClipboard>
      </div>
    )
  }
}

class LiveDJ extends React.Component<{
}> {

  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
    };

    this.props.fetchStation(this.state.stationId);
    this.props.fetchServer(this.state.stationId);
  }

  render() {
    const hostname = this.props.server ? this.props.server["attributes"]["host"] : '';

    const {
      mount_id,
      mount_password,
      mount_username
    } = (this.props.station||{}).attributes || {};

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar
          title={ "Sources > LiveDJ "}
        />
        <UikLayoutMain>
          <div className={ cls.splitContent }>
            <UikWidgetContainer className={ cls.basicProfileWidgetContainer } >
              <UikWidget>
                <UikWidgetContent>
                  <h3>Encoder Settings</h3>
                  <UikDivider />
                  <div className={ cls.helpNote }>
                    Encoder settings are used to push content to Live365. Live365 utilizes the Icecast2 Protocol.
                  </div>
                </UikWidgetContent>
                <UikDivider />
                <form onSubmit={ () => {} }>
                  <UikWidgetContent>
                    <UikFormInputGroup direction="horizontal">
                      <UikInput
                        label={<CopyToClipboardLabel labelText="Hostname" copyValue={hostname}/>}
                        name="hostname"
                        readOnly="readOnly"
                        value={hostname}
                      />
                      <UikInput
                        defaultValue="8000"
                        label={<CopyToClipboardLabel labelText="port" copyValue={8000} />}
                        name="port"
                        readOnly
                      />
                    </UikFormInputGroup>
                    <UikFormInputGroup horizontal>
                      <UikInput
                        defaultValue={`/${mount_id}_live`}
                        label={<CopyToClipboardLabel labelText="mount" copyValue={`/${mount_id}_live`} />}
                        name="mount"
                        readOnly
                      />
                    </UikFormInputGroup>
                    <UikFormInputGroup horizontal>
                      <UikInput
                        defaultValue={mount_username}
                        label={<CopyToClipboardLabel labelText="Username" copyValue="source" />}
                        name="username"
                        readOnly
                        value="source"
                      />
                      <UikInput
                        defaultValue={mount_password}
                        label={<CopyToClipboardLabel labelText="password" copyValue={mount_password} />}
                        name="password"
                        readOnly
                      />
                    </UikFormInputGroup>
                    <UikFormInputGroup vertical>
                      <UikDivider />
                      <div className={ cls.helpNote }>
                        <a 
                          href="http://help.live365.com/support/solutions/articles/43000533541-how-do-i-find-my-live-stream-credentials-"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                        Learn more about Encoder Settings
                        </a> or <a 
                          href="http://help.live365.com/support/tickets/new"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          contact support
                        </a>.
                      </div>
                    </UikFormInputGroup>
                  </UikWidgetContent>
                </form>
              </UikWidget>
            </UikWidgetContainer>
          </div>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    station: store.stations.activeStation,
    server: store.servers.activeStationServer
  }),
  {
    fetchStation: stationActions.fetchStation,
    fetchServer: serverActions.fetchServer
  },
)(LiveDJ)


import * as images from '@eAssets'

export default [
  {
    imgUrl: images.a01,
    name: 'Francisco Gibbs',
    status: 'uik_online',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a02,
    name: 'Della Garrett',
    status: 'uik_online',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a03,
    name: 'Louis Patrick',
    status: 'uik_online',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a04,
    name: 'Lester Berry',
    status: '12m',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a05,
    name: 'Maude Valdez',
    status: '5m',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a06,
    name: 'Cynthia Copeland',
    status: 'uik_online',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a07,
    name: 'Sadie Dawson',
    status: 'uik_online',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a08,
    name: 'Francisco Weber',
    status: '46m',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a09,
    name: 'Eula Osborne',
    status: 'uik_online',
    textBottom: '130 mutual friends',
  },
  {
    imgUrl: images.a10,
    name: 'Essie Bates',
    status: '22m',
    textBottom: '130 mutual friends',
  },
]

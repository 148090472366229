import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Service list
export const FETCH_SERVICES = 'FETCH_SERVICES';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const RESET_SERVICES = 'RESET_SERVICES';

//Create new SERVICE
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILURE = 'CREATE_SERVICE_FAILURE';
export const RESET_NEW_SERVICE = 'RESET_NEW_SERVICE';

//Update SERVICE
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILURE = 'UPDATE_SERVICE_FAILURE';

//Fetch SERVICE
export const FETCH_SERVICE = 'FETCH_SERVICE';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICE_FAILURE = 'FETCH_SERVICE_FAILURE';
export const RESET_ACTIVE_SERVICE = 'RESET_ACTIVE_SERVICE';

//Delete SERVICE
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';
export const RESET_DELETED_SERVICE = 'RESET_DELETED_SERVICE';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestServices = () => ({
  type: FETCH_SERVICES,
})

export const receiveServices = (json, meta) => ({
  type: FETCH_SERVICES_SUCCESS,
  payload: json,
  meta: meta
})

export const requestDeleteService = () => ({
  type: DELETE_SERVICE,
})

export const serviceDeleted = (cwId) => ({
  type: DELETE_SERVICE_SUCCESS,
  id: cwId
})

export const requestService = () => ({
  type: FETCH_SERVICE,
})

export const serviceReceived = (json) => ({
  type: FETCH_SERVICE_SUCCESS,
  payload: json.data
})

export const requestCreateService = () => ({
  type: CREATE_SERVICE,
})

export const requestUpdateService = () => ({
  type: UPDATE_SERVICE,
})

export const serviceUpdated = (json) => ({
  type: UPDATE_SERVICE_SUCCESS,
  payload: json
})

export function fetchServices({perPage, page}) {

  return function(dispatch) {

    dispatch(requestServices())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}services/?sort=status&page[size]=${perPage}&page[number]=${page}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receiveServices(json.data, json.meta))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchService(id) {

  return function(dispatch) {

    dispatch(requestService())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}services/${id}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(serviceReceived(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createService(cw, stationId) {

  return function(dispatch) {

    dispatch(requestCreateService())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "services",
        "id": stationId + "",
        "attributes": cw.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}services/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Service has been created !");
        dispatch(createServiceSuccess(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateService(cw) {

  return function(dispatch) {

    dispatch(requestUpdateService())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "services",
        "id": cw.id + "",
        "attributes": cw.attributes,
        "relationships": cw.relationships
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}services/${cw.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Service updated !");
        dispatch(serviceUpdated(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deleteService(cwId) {

  return function(dispatch) {

    dispatch(requestDeleteService())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}services/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Service has been deleted !");
        dispatch(serviceDeleted(cwId))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function fetchServicesSuccess(services) {
  return {
    type: FETCH_SERVICES_SUCCESS,
    payload: services
  };
}

export function fetchServicesFailure(error) {
  return {
    type: FETCH_SERVICES_FAILURE,
    payload: error
  };
}

export function createServiceSuccess(newService) {
  return {
    type: CREATE_SERVICE_SUCCESS,
    payload: newService
  };
}

export function createServiceFailure(error) {
  return {
    type: CREATE_SERVICE_FAILURE,
    payload: error
  };
}

export function resetNewService() {
  return {
    type: RESET_NEW_SERVICE
  }
}
;

export function resetDeletedService() {
  return {
    type: RESET_DELETED_SERVICE
  }
}

export function fetchServiceSuccess(activeService) {
  return {
    type: FETCH_SERVICE_SUCCESS,
    payload: activeService
  };
}

export function fetchServiceFailure(error) {
  return {
    type: FETCH_SERVICE_FAILURE,
    payload: error
  };
}

export function resetActiveService() {
  return {
    type: RESET_ACTIVE_SERVICE
  }
}

export function deleteServiceFailure(response) {
  return {
    type: DELETE_SERVICE_FAILURE,
    payload: response
  };
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          import * as React from 'react'
import classnames from 'classnames'

import Modal from '@material-ui/core/Modal';
import { SketchPicker } from 'react-color'
import * as actions from '@redux/categories/actions'

/* eslint-disable max-len */
import { connect } from 'react-redux'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikInput,
  UikFormInputGroup,
} from '@components'

import TopBar from '../../../../@components/TopBar'
import CategoryList from './components/CategoryList'
import SearchInput from '../../../../@components/SearchInput'

import cls from './media-categories.scss'
// @flow
type CategoriesProps = {
  categories: Array
}

class MediaCategories extends React.Component<CategoriesProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      addMode: true,
      editCategoryModalOpen: false,
      deleteCategoryModalOpen: false,
      activeCategory: {},
      searchQuery: ''
    };

    this.handleSearch = this.handleSearch.bind(this);

    this.props.fetchCategories(this.state.stationId);

  }

  handleAddCategoryModalOpen = () => {
    this.setState({
      addMode: true,
      editCategoryModalOpen: true,
      activeCategory: {"attributes":{"color": "#191DCB"}}
    });
  };

  handleEditCategoryModalOpen = (category) => {
    this.setState({ addMode: false, editCategoryModalOpen: true, activeCategory: category });
  };

  handleEditCategoryModalClose = () => {
    this.setState({ editCategoryModalOpen: false });
  };

  handleDeleteCategoryModalOpen = (category) => {
    this.setState({ deleteCategoryModalOpen: true, activeCategory: category});
  };

  handleDeleteCategoryModalClose = () => {
    this.setState({ deleteCategoryModalOpen: false });
  };

  handleDeleteCategoryClick = () => {
    this.props.deleteCategory(this.state.activeCategory.id);
    this.setState({ deleteCategoryModalOpen: false });
  };

  handleEditCategoryClick = () => {
    if(this.state.addMode){
      this.props.createCategory(this.state.activeCategory, this.state.stationId);
    } else {
      this.props.updateCategory(this.state.activeCategory);
    }
    this.setState({ editCategoryModalOpen: false });
  };

  handleEditChange(event) {
    let cat = this.state.activeCategory || {};
    cat["attributes"][event.target.name] = event.target.value;
    this.setState({activeCategory: cat});
  }

  handleColorChange(clr) {
    let ac = this.state.activeCategory;
    ac["attributes"]["color"] = (clr||{}).hex;
    this.setState({activeCategory: ac});
  }

  handleSearch({query}) {
    this.setState({'searchQuery': query});
  }

  render() {
    let { categories, categoriesLoading } = this.props;
    const { addMode, activeCategory, searchQuery } = this.state;
    let catAttrs = activeCategory.attributes || {};

    if (searchQuery && searchQuery.length) {
      categories = categories.filter(cat => {
        return (cat["attributes"] && cat["attributes"]["name"].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
      })
    }

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Categories"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            <SearchInput
              onSearch={ this.handleSearch }
              collectionName="Categories"
            />
            <UikButton primary onClick={this.handleAddCategoryModalOpen}>Add Category</UikButton>
          </UikContentItem>
          <Modal
            open={this.state.editCategoryModalOpen}
            onClose={this.handleEditCategoryModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                { addMode ? 'Add' : 'Edit' } Category
              </div>
              <div className={ cls.modalBody }>
                <UikFormInputGroup>
                  <UikInput
                    label="Name"
                    name="name"
                    defaultValue={ catAttrs.name }
                    onChange={ this.handleEditChange.bind(this) }
                    maxLength={40}
                  />
                </UikFormInputGroup>
                <br/>
                <UikFormInputGroup>
                  <label className="uik-content-title__wrapper">Color</label>
                  <SketchPicker
                    color={catAttrs.color}
                    onChangeComplete={ this.handleColorChange.bind(this) }
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.modalFooter }>
                <UikButton isExpanded success onClick={this.handleEditCategoryClick}>Save</UikButton>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deleteCategoryModalOpen}
            onClose={this.handleDeleteCategoryModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Delete Category
              </div>
              <div className={ cls.modalBody }>
                <p>Are you sure you wish to delete <b>{ catAttrs.name }</b> ?</p>
              </div>
              <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton)  }>
                <UikButton onClick={this.handleDeleteCategoryModalClose}>Cancel</UikButton>
                <UikButton error onClick={this.handleDeleteCategoryClick}>Delete</UikButton>
              </div>
            </div>
          </Modal>
          <CategoryList
            editCategory={this.handleEditCategoryModalOpen}
            deleteCategory={this.handleDeleteCategoryModalOpen}
            categories={categories}
            loading={categoriesLoading}
          />
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    categories: store.categories.categoriesList.categories,
    categoriesLoading: store.categories.categoriesList.loading,
  }),
  {
    fetchCategories: actions.fetchCategories,
    createCategory: actions.createCategory,
    updateCategory: actions.updateCategory,
    deleteCategory: actions.deleteCategory,
  },
)(MediaCategories)


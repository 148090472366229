import initialState from './initialState'

import {
  FETCH_PLAYLISTS, FETCH_PLAYLISTS_SUCCESS, FETCH_PLAYLISTS_PAGE_SUCCESS, FETCH_PLAYLISTS_FAILURE, RESET_PLAYLISTS,
  UPDATE_PLAYLIST, UPDATE_PLAYLIST_SUCCESS,  UPDATE_PLAYLIST_FAILURE,
  FETCH_PLAYLIST, FETCH_PLAYLIST_SUCCESS,  FETCH_PLAYLIST_FAILURE, RESET_ACTIVE_PLAYLIST,
  CREATE_PLAYLIST, CREATE_PLAYLIST_SUCCESS, CREATE_PLAYLIST_FAILURE, RESET_NEW_PLAYLIST,
  DELETE_PLAYLIST, DELETE_PLAYLIST_SUCCESS, DELETE_PLAYLIST_FAILURE, RESET_DELETED_PLAYLIST,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_PLAYLISTS:// start fetching playlists and set loading = true
    return { ...state, playlistsList: {playlists:[], error: null, loading: true} };
  case FETCH_PLAYLISTS_PAGE_SUCCESS:
    return { ...state, playlistsList: {playlists: [...state.playlistsList.playlists, ...action.payload], error:null, loading: true} };
  case FETCH_PLAYLISTS_SUCCESS:
    return { ...state, playlistsList: {playlists: state.playlistsList.playlists, error: null, loading: false}}
  case FETCH_PLAYLISTS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, playlistsList: {playlists: [], error: error, loading: false} };
  case RESET_PLAYLISTS:// reset playlistList to initial state
    return { ...state, playlistsList: {playlists: [], error:null, loading: false} };

  case FETCH_PLAYLIST:
    return { ...state, activePlaylist:{...state.activePlaylist, loading: true}};
  case FETCH_PLAYLIST_SUCCESS:
    return { ...state, activePlaylist: {playlist: action.payload, error:null, loading: false}};
  case FETCH_PLAYLIST_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activePlaylist: {playlist: null, error:error, loading:false}};
  case RESET_ACTIVE_PLAYLIST:
    return { ...state, activePlaylist: {playlist: null, error:null, loading: false}};

  case UPDATE_PLAYLIST:
    return { ...state, activePlaylist:{...state.activePlaylist, loading: true}};
  case UPDATE_PLAYLIST_SUCCESS:
    return {
      ...state,
      activePlaylist: {playlist: action.payload, error:null, loading: false},
      playlistsList: {playlists: [...state.playlistsList.playlists.filter(p => p.id !== action.payload.id), action.payload]}
    };
  case UPDATE_PLAYLIST_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activePlaylist: {playlist: null, error:error, loading:false}};

  case CREATE_PLAYLIST:
    return {...state, newPlaylist: {...state.newPlaylist, loading: true}}
  case CREATE_PLAYLIST_SUCCESS:
    let newPlaylists = state.playlistsList.playlists.slice();
    newPlaylists.push(action.payload);
    return {...state,
      playlistsList: { playlists: newPlaylists },
      newCategory: {category:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_PLAYLIST_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newPlaylist: {playlist:null, error:error, loading: false}}
  case RESET_NEW_PLAYLIST:
    return {...state,  newPlaylist:{playlist:null, error:null, loading: false}}

  case DELETE_PLAYLIST:
     return {...state, deletedPlaylist: {...state.deletedPlaylist, loading: true}}
  case DELETE_PLAYLIST_SUCCESS:
    let newCws = state.playlistsList.playlists.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, playlistsList: { playlists: newCws }, deletedPlaylist: {playlist:action.payload, error:null, loading: false}}
  case DELETE_PLAYLIST_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedPlaylist: {playlist:null, error:error, loading: false}}
  case RESET_DELETED_PLAYLIST:
    return {...state,  deletedPlaylist:{playlist:null, error:null, loading: false}}
  default:
    return state;
  }
}

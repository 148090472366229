import * as React from 'react'
import { NavLink } from 'react-router-dom'

/* eslint-disable max-len */
import classnames from 'classnames'

import {
  Uikon,
  UikWidget,
  UikWidgetTable,
  UikButton,
  UikDropdown,UikDropdownItem,
} from '@components'

import PacmanLoader from '../../../../../@components/PacmanLoader'

import cls from '../playlists.scss'

// @flow
type PlaylistsProps = {
  playlists: Array
}

class Playlists extends React.Component<PlaylistsProps> {
  render() {
    const { playlists, editPlaylist, deletePlaylist, loading }  = this.props;
    return (
      <UikWidget
        margin
        className={cls.vertScrollable}
      >
        <UikWidgetTable className={classnames(cls.table)}>
          <thead>
            <tr>
              <th>
                Playlist
              </th>
              <th>
                Color
              </th>
              <th>
                # of tracks
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              playlists.map(playlist => (
                <tr key={ `playlist-${playlist.id}` }>
                  <td>
                    <NavLink
                      to={ `/stations/${(((playlist.relationships||{}).station||{}).data||{}).id}/schedule/playlists/${playlist.id}` }
                      >{playlist.attributes.title}</NavLink>
                  </td>
                  <td>
                    <div
                      className={ cls.colorSquare}
                      style={{ backgroundColor: playlist.attributes.color }}>
                    </div>
                  </td>
                  <td>
                    { ( playlist.attributes.tracks && playlist.attributes.tracks.length ) || 0}
                  </td>
                  <td>
                    <UikDropdown
                      DisplayComponent={
                      props => (
                        <UikButton
                          clear
                          icon={ <Uikon>more</Uikon> }
                          iconOnly
                          {...props}
                        />
                      )
                    }
                      position="bottomRight"
                    >
                      <UikDropdownItem onClick={editPlaylist.bind(this, playlist)}>
                        Edit
                      </UikDropdownItem>
                      <UikDropdownItem onClick={deletePlaylist.bind(this, playlist)}>
                        Delete
                      </UikDropdownItem>
                    </UikDropdown>
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { playlists.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No playlists found.</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default Playlists

import React from 'react';

import * as broadcasterUserActions from '@redux/broadcasterUsers/actions'

/* eslint-disable max-len */
import { connect } from 'react-redux'
import ls from 'local-storage'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
} from '@components'

import TopBar from '../../../../@components/TopBar'
import PaymentList from './components/PaymentList'
import StripeCardModal from './components/StripeCardModal'

import cls from './billing.scss'


// @flow

class PaymentCardPage extends React.Component<{
  match: Object,
}> {

  constructor(props) {
    super(props);

    this.state = {
      addMode: true,
      editPaymentModalOpen: false,
      loggedInUser: this.props.loggedInUser
    }

    const userId = (this.state.loggedInUser||{}).hasOwnProperty('id') ? this.state.loggedInUser.id : ls.get('u');
    this.props.fetchBroadcasterUser(userId);

    this.handleEditPaymentModalClose = this.handleEditPaymentModalClose.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.broadcasterUser !== this.props.broadcasterUser){
      this.setState({editPaymentModalOpen: false})
    }
  }

  handleAddPaymentModalOpen = () => {
    this.setState({ addMode: true, editPaymentModalOpen: true });
  };

  handleEditPaymentModalOpen = () => {
    this.setState({ addMode: false, editPaymentModalOpen: true });
  };

  handleEditPaymentModalClose = () => {
    this.setState({ editPaymentModalOpen: false });
  };


  render() {
    const { broadcasterUser } = this.props;
    const { addMode, editPaymentModalOpen } = this.state;

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Payment"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            {( broadcasterUser === null
            || !broadcasterUser["attributes"].hasOwnProperty('card_last4')
            || broadcasterUser["attributes"]["card_last4"] === null )
            && (
              <UikButton primary onClick={this.handleAddPaymentModalOpen}>Add Card</UikButton>
            )}
          </UikContentItem>

          <StripeCardModal
            modalOpen={editPaymentModalOpen}
            handleEditPaymentModalClose={this.handleEditPaymentModalClose.bind(this)}
            broadcasterUser={broadcasterUser}
          />

          <PaymentList
            addMode={addMode}
            editPayment={this.handleEditPaymentModalOpen}
            broadcasterUser={broadcasterUser}
          />
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    loggedInUser: store.users.loggedInUser,
    broadcasterUser: store.broadcasterUsers.activeBroadcasterUser.broadcasterUser,
  }),
  {
    fetchBroadcasterUser: broadcasterUserActions.fetchBroadcasterUser,
    updateBroadcasterUser: broadcasterUserActions.updateBroadcasterUser
  },
)(PaymentCardPage)


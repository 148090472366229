import * as React from 'react'
import classnames from 'classnames'

import {
  Uikon,
  UikInput,
} from '@components'

import cls from './search-input.scss'

// @flow
type PaginationProps = {
  onSearch: Function,
  className?: String
}

class SearchInput extends React.Component<PaginationProps> {

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
    }

    this.searchInput = React.createRef();

    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
  }

  handleClearSearch() {
    this.searchInput.current.input.value = '';
    this.props.onSearch({query: ''});
    this.setState({"searchQuery": ""});
  }

  handleSearchKeyPress(event) {
    if(event.key === 'Enter'){
      this.props.onSearch({query: event.target.value});
      this.setState({"searchQuery": event.target.value});
    }
  }

  render() {
    const {
      className,
      collectionName
    } = this.props;

    const {
      searchQuery,
    } = this.state;

    return (
      <div className={ classnames(cls.searchWrapper, className) }>
        <UikInput
          clear
          icon={ (
            <Uikon>
              search_left
            </Uikon>
        ) }
          placeholder={`Search ${collectionName}`}
          onKeyPress={ this.handleSearchKeyPress }
          maxLength={30}
          ref={this.searchInput}
        />
        { searchQuery && searchQuery.length && (
          <a className={ cls.btnClearSearch } onClick={this.handleClearSearch}>
            <i className="fas fa-times"></i>
          </a>
        )}
      </div>
    )
  }
}

export default SearchInput

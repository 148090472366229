import fetch from 'cross-fetch'
import ls from 'local-storage'
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Notification list
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

//Update NOTIFICATION
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';

const USER_ROOT_URL = `${configApp.USERAPI_SERVER}/api/v1/`;

export const requestNotifications = () => ({
  type: FETCH_NOTIFICATIONS,
})

export const receiveNotifications = (json) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: json
})

export const requestUpdateNotification = () => ({
  type: UPDATE_NOTIFICATION,
})

export const notificationUpdated = (json) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: json
})

export const requestReadNotification = () => ({
  type: READ_NOTIFICATION,
})

export const notificationRead = (json) => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: json
})

export function fetchNotifications() {

  return function(dispatch) {

    dispatch(requestNotifications())

    const bearerToken = ls.get('bearerToken') || null;
    const userId = ls.get("u");
    let url = USER_ROOT_URL + `notifications/?filter[user]=${userId}`;

    return fetch(url, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push('/'));
        }

        return res.json();
      })
      .then(json => {
        dispatch(receiveNotifications(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function markNotificationAsRead(nId) {

  return function(dispatch) {

    dispatch(requestReadNotification())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "id": nId + "",
        "type": "notifications",
        "attributes": {
          "seen": true
        }
      }
    }
    let url = USER_ROOT_URL + `notifications/${nId}`;

    return fetch(url, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push('/'));
        }

        return res.json();
      })
      .then(json => {
        dispatch(notificationRead(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchNotificationsSuccess(notifications) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: notifications
  };
}

export function fetchNotificationsFailure(error) {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error
  };
}

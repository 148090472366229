import initialState from './initialState'

import {
  FETCH_SERVICES, FETCH_SERVICES_SUCCESS, FETCH_SERVICES_FAILURE, RESET_SERVICES,
  UPDATE_SERVICE, UPDATE_SERVICE_SUCCESS,  UPDATE_SERVICE_FAILURE,
  FETCH_SERVICE, FETCH_SERVICE_SUCCESS,  FETCH_SERVICE_FAILURE, RESET_ACTIVE_SERVICE,
  CREATE_SERVICE, CREATE_SERVICE_SUCCESS, CREATE_SERVICE_FAILURE, RESET_NEW_SERVICE,
  DELETE_SERVICE, DELETE_SERVICE_SUCCESS, DELETE_SERVICE_FAILURE, RESET_DELETED_SERVICE,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_SERVICES:// start fetching service and set loading = true
    return { ...state, servicesList: {services:[], error: null, loading: true} };
  case FETCH_SERVICES_SUCCESS:
    return { ...state, servicesList: {services: action.payload, error:null, loading: false}, totalServices: action.meta.count };
  case FETCH_SERVICES_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, servicesList: {services: [], error: error, loading: false} };
  case RESET_SERVICES:// reset serviceList to initial state
    return { ...state, servicesList: {services: [], error:null, loading: false} };

  case FETCH_SERVICE:
    return { ...state, activeService:{...state.activeService, loading: true}};
  case FETCH_SERVICE_SUCCESS:
    return { ...state, activeService: {service: action.payload, error:null, loading: false}};
  case FETCH_SERVICE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeService: {service: null, error:error, loading:false}};
  case RESET_ACTIVE_SERVICE:
    return { ...state, activeService: {service: null, error:null, loading: false}};

  case UPDATE_SERVICE:
    return { ...state, activeService:{...state.activeService, loading: true}};
  case UPDATE_SERVICE_SUCCESS:
    return { ...state, activeService: {service: action.payload, error:null, loading: false}};
  case UPDATE_SERVICE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeService: {service: null, error:error, loading:false}};

  case CREATE_SERVICE:
    return {...state, newService: {...state.newService, loading: true}}
  case CREATE_SERVICE_SUCCESS:
    let newServices = state.servicesList.services.slice();
    newServices.push(action.payload);
    return {...state,
      servicesList: { services: newServices },
      newService: { service: action.payload,
      error:null,
      loading: false
    }}
  case CREATE_SERVICE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newService: {service:null, error:error, loading: false}}
  case RESET_NEW_SERVICE:
    return {...state,  newService:{service:null, error:null, loading: false}}

  case DELETE_SERVICE:
     return {...state, deletedService: {...state.deletedService, loading: true}}
  case DELETE_SERVICE_SUCCESS:
    let newCws = state.servicesList.services.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, servicesList: { services: newCws }, deletedService: {service:action.payload, error:null, loading: false}}
  case DELETE_SERVICE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedService: {service:null, error:error, loading: false}}
  case RESET_DELETED_SERVICE:
    return {...state,  deletedService:{service:null, error:null, loading: false}}
  default:
    return state;
  }
}

import * as React from 'react'
import classnames from 'classnames'
import { toast } from "react-toastify";
import Select from 'react-select';

import Modal from '@material-ui/core/Modal';

import { connect } from 'react-redux'
import * as actions from '@redux/tracks/actions'
import * as stationActions from '@redux/stations/actions'
import * as categoriesActions from '@redux/categories/actions'
import * as tracksCategoriesActions from '@redux/tracksCategories/actions'
import * as configApp from '@redux/constants'

import { Link } from 'react-router-dom'

/* eslint-disable max-len */

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikInput,
  UikSelect,
  UikFormInputGroup,
  UikWidget,
  UikWidgetContent,
  UikCheckbox
} from '@components'

import TopBar from '../../../../@components/TopBar'
import TrackList from './components/TrackList'
import MediaUploaderModal from './components/MediaUploaderModal'
import Pagination from '../../../../@components/Pagination'
import UploadImageModal from '../../../../@components/UploadImageModal'
import SearchInput from '../../../../@components/SearchInput'

import cls from './media.scss'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;
const categoriesNoneOption = {"value": '[NONE]', "label": '[ NONE ]'};

// @flow
type TracksProps = {
  tracks: Array
}

class TracksPage extends React.Component<TracksProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      addMode: true,
      uploadTracksModalOpen: false,
      activeStation: {},
      editTrackModalOpen: false,
      deleteTrackModalOpen: false,
      activeTrack: {"attributes": []},
      activeTrackDirty: false,
      categoriesDirty: false,
      doNotPlayDirty: false,
      bulkAction: 'bulk',
      searchQuery: '',
      perPage: 50,
      page: 1,
      showFilters: false,
      tracks: [],
      selectedCategories: [],
      selectedIdx: [],
      sortBy: 'title',
      uploadedFileCount: 0,
      filters: {"media_type": '', "category": ''},
      bulkCategories: [],
      bulkMediaType: '',
      bulkEditAttrs: {
        title: '',
        artist: '',
        album: '',
        year: ''
      }
    };

    this.handleBulkActionChange = this.handleBulkActionChange.bind(this);
    this.handleBulkTypeChange = this.handleBulkTypeChange.bind(this);
    this.handleBulkEditChange = this.handleBulkEditChange.bind(this);
    this.handleBulkCategoriesSelect = this.handleBulkCategoriesSelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleTrackSelect = this.handleTrackSelect.bind(this);
    this.handleCloseBulkEdit = this.handleCloseBulkEdit.bind(this);
    this.onBulkEditTypeClick = this.onBulkEditTypeClick.bind(this);
    this.onBulkEditMetadataClick = this.onBulkEditMetadataClick.bind(this);
    this.handleCategoriesSelect = this.handleCategoriesSelect.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
    this.handleDeleteTrackModalClose = this.handleDeleteTrackModalClose.bind(this);
    this.handleDeleteTrackClick = this.handleDeleteTrackClick.bind(this);
    this.handleFiltersClick = this.handleFiltersClick.bind(this);
    this.handleMediaUploaderModalOpen = this.handleMediaUploaderModalOpen.bind(this);
    this.handleMediaUploaderModalClose = this.handleMediaUploaderModalClose.bind(this);

    this.props.fetchTracks({
      stationId: this.state.stationId,
      sortBy: this.state.sortBy
    });
    this.props.fetchStation(this.state.stationId);
    this.props.fetchAllCategories(this.state.stationId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tracks !== this.props.tracks) {
      let tracks_without_ads = this.props.tracks.filter(track => track["attributes"]["album"] !== "Live365 Ads");
      this.setState({ tracks: tracks_without_ads });
      if (tracks_without_ads.length) {
        this.props.fetchTracksCategories(this.state.stationId, tracks_without_ads);
      }
    }

    if (prevProps.tracksCategoriesLoading && !this.props.tracksCategoriesLoading) {
      this.props.fetchCategories(this.state.stationId);
    }

    if (prevProps.activeStation !== this.props.activeStation) {
      this.setState({ activeStation: this.props.activeStation });
    }
  }

  onBulkEditTypeClick = () => {
    const { selectedIdx } = this.state;
    let selectedIds = selectedIdx.map(idx => this.state.tracks[idx]["id"]);

    let newAttrs = {};
    newAttrs["category_ids"] = [];
    if (this.state.bulkMediaType.length) {
      newAttrs["media_type"] = this.state.bulkMediaType;
    }
    if (this.state.bulkCategories.length) {
      newAttrs["category_ids"] = this.state.bulkCategories.map(cat => parseInt(cat.id, 10));
    }
    if (this.state.selectedCategories.length === 1 && this.state.selectedCategories[0].value === "[NONE]") {
      newAttrs["category_ids"] = [];
    }
    newAttrs["track_uuids"] = selectedIds;

    if (selectedIdx.length > 0) {
      this.props.bulkUpdateTracks({
        stationId: this.state.stationId,
        newAttributes: newAttrs,
        page: this.state.page,
        perPage: this.state.perPage,
        sortBy: this.state.sortBy,
        filterBy: this.state.filters,
        query: this.state.searchQuery
      });
      this.handleCloseBulkEdit();
    } else {
      toast.error(`Please select at least 1 file to update`);
    }
  }

  onBulkEditMetadataClick = () => {
    const { tracks, selectedIdx } = this.state;
    if (selectedIdx.length > 0 ){
      tracks.length && selectedIdx.forEach(index => {
        // update track...
        let activeTrack = Object.assign({}, tracks[index]);
        Object.keys(this.state.bulkEditAttrs).forEach(attr => {
          if (this.state.bulkEditAttrs[attr].length) {
            activeTrack["attributes"][attr] = this.state.bulkEditAttrs[attr];
          }
        })
        this.props.updateTrack(activeTrack);
      });
      this.handleCloseBulkEdit();
    } else {
      toast.error(`Please select at least 1 file to update`);
    }
  }

  handleMediaUploaderModalOpen(){
    this.setState({ uploadTracksModalOpen: true});
  }

  handleMediaUploaderModalClose(refreshTracks){
    if (refreshTracks) {
      this.props.fetchTracks({
        stationId: this.state.stationId,
        perPage: this.state.perPage,
        sortBy: this.state.sortBy,
        filterBy: this.state.filters,
        query: this.state.searchQuery
      });
    }
    window.onbeforeunload = undefined
    this.setState({ uploadTracksModalOpen: false, selectedIdx: [], selectedTracks: []});
  }

  handleTrackSelect = (selectedIdx) => {
    this.setState({ selectedIdx });
  }

  handleCloseBulkEdit = () => {
    this.setState({bulkAction: 'bulk'})
  }

  handleFiltersClick = () => {
    this.setState({showFilters: true});
  }

  handleEditTrackModalOpen = (track) => {
    this.setState({
      addMode: false,
      editTrackModalOpen: true,
      activeTrack: track,
      activeTrackDirty: false,
      categoriesDirty: false,
      bulkCategories: track.categories,
      selectedMediaType: track["attributes"]["media_type"]
    });
  };

  handleEditTrackModalClose = () => {
    this.setState({ editTrackModalOpen: false });
  };

  handleEditTrackModalClick = () => {
    let activeTrack = Object.assign({},this.state.activeTrack);
    let actAttrs = activeTrack["attributes"] || {};
    let {
      activeTrackDirty,
      categoriesDirty,
      doNotPlayDirty
    } = this.state;

    if (activeTrackDirty){
      Object.keys(actAttrs).forEach((key) => (actAttrs[key] === null) && delete actAttrs[key]);
      this.props.updateTrack(activeTrack);
    }

    // only update categories if they have changed
    if(this.state.selectedMediaType !== activeTrack["attributes"]["media_type"] || categoriesDirty){
      let newAttrs = {
        "media_type": this.state.selectedMediaType,
        "track_uuids": [activeTrack["id"]],
      };
      if (activeTrack['categories']){
        newAttrs['category_ids'] = activeTrack["categories"].map(cat => parseInt(cat.id, 10))
      }
      else if (activeTrack.relationships.categories.data) {
        newAttrs['category_ids'] = activeTrack.relationships.categories.data.map(cat => parseInt(cat.id, 10))
      }
      this.props.bulkUpdateTracks({
        stationId: this.state.stationId,
        newAttributes: newAttrs,
        page: this.state.page,
        perPage: this.state.perPage,
        sortBy: this.state.sortBy,
        filterBy: this.state.filters,
        query: this.state.searchQuery
      });
    }

    if (doNotPlayDirty) {
      let newAttrs = {
        "track_uuids": [activeTrack["id"]],
        "do_not_play": activeTrack["do_not_play"]
      };
      if (activeTrack['categories']){
        newAttrs['category_ids'] = activeTrack["categories"].map(cat => parseInt(cat.id, 10))
      }
      else if (activeTrack.relationships.categories.data) {
        newAttrs['category_ids'] = activeTrack.relationships.categories.data.map(cat => parseInt(cat.id, 10))
      }
      this.props.bulkUpdateTracks({
        stationId: this.state.stationId,
        newAttributes: newAttrs,
        page: this.state.page,
        perPage: this.state.perPage,
        sortBy: this.state.sortBy,
        filterBy: this.state.filters,
        query: this.state.searchQuery
      })
    }

    this.setState({ editTrackModalOpen: false, categoriesDirty: false, activeTrackDirty: false, doNotPlayDirty: false});
  };

  handleDeleteTrackModalOpen = ({track, index}) => {
    let newState = {deleteTrackModalOpen: true};
    if (index !== undefined) {
      newState["selectedIdx"] = [index];
    }
    this.setState(newState);
  };

  handleDeleteTrackModalClose = () => {
    this.setState({ deleteTrackModalOpen: false });
  };

  handleDeleteTrackClick = () => {
    this.state.tracks.length && this.state.selectedIdx.forEach(index => {
      this.props.deleteTrack(this.state.tracks[index]["id"]);
    })
    this.setState({ bulkAction: 'bulk', selectedIdx: [], deleteTrackModalOpen: false });
  };

  handleBulkActionChange = (event) => {
    switch (event.value) {
      case 'delete':
        if(this.state.selectedIdx.length === 0){
          toast.error(`Please select files to delete`);
          this.handleCloseBulkEdit();
        } else {
          this.handleDeleteTrackModalOpen({});
        }
      break;
      case 'editType':
      case 'editMetadata':
        this.setState({
          bulkAction: event.value,
          bulkCategories: [],
          bulkMediaType: '',
          bulkEditAttrs: {
            title: '',
            artist: '',
            album: '',
            year: ''
          }
        });
      break;
      default:
      break;
    }
  };

  handleBulkTypeChange = (event) => {
    this.setState({bulkMediaType: event.value})
  };

  handleSortBy(sortBy) {
    this.setState({sortBy});
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: this.state.page,
      perPage: this.state.perPage,
      sortBy,
      filterBy: this.state.filters,
      query: this.state.searchQuery
    });
  }

  handleMediaTypeDropdownChange(event) {
    this.setState({selectedMediaType: event.value});
  }

  handleEditChange(event) {
    let track = this.state.activeTrack || {};
    track["attributes"][event.target.name] = event.target.value;
    this.setState({activeTrack: track, activeTrackDirty: true});
  }

  handleBulkEditChange(event) {
    let bulkEditAttrs = this.state.bulkEditAttrs;
    bulkEditAttrs[event.target.name] = event.target.value;
    this.setState({bulkEditAttrs});
  }

  handleSearch({query}) {
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: 1,
      perPage: this.state.perPage,
      sortBy: this.state.sortBy,
      filterBy: this.state.filters,
      query: query
    })
    this.setState({"searchQuery": query});
  }

  handleperPageChange(event) {
    this.setState({perPage: event.value, page: 1});
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: 1,
      perPage: event.value,
      sortBy: this.state.sortBy,
      filterBy: this.state.filters,
      query: this.state.searchQuery
    });
  }

  handlePageChange(page) {
    this.setState({page});
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: page,
      perPage: this.state.perPage,
      sortBy: this.state.sortBy,
      filterBy: this.state.filters,
      query: this.state.searchQuery
    });
  }

  handleCategoriesSelect(e){
    // populate categories...
    let newCats = (e||[]).map(selCat => {
      let category = (this.props.allCategories.find(cat => cat.id === selCat.value)||{})["attributes"];
      category.id = selCat.value;
      return category;
    });
    let activeTrack = Object.assign({},this.state.activeTrack);
    activeTrack["categories"] = newCats;
    this.setState({categoriesDirty: true, selectedCategories: e || [], activeTrack});
  }

  handleDoNotPlayChange = (e) => {
    const checked = e.target.checked;
    let activeTrack = Object.assign({},this.state.activeTrack);
    activeTrack["do_not_play"] = checked;
    this.setState({doNotPlayDirty: true, activeTrack});
  }

  handleBulkCategoriesSelect(e){
    e = e || [];
    let newCats = [];
    if (e.length && e[e.length-1].value === "[NONE]"){
      e = [categoriesNoneOption];
    } else {
      if (e.length > 1 && e[0].value === "[NONE]"){
        // first elem is NONE, but you're adding a new category, remove NONE, set that cat.
        e.shift();
      }
      newCats = e.map(selCat => {
        let cat = this.props.allCategories.find(cat => cat.id === selCat.value)||{};
        let newCat = cat["attributes"];
        newCat["id"] = cat["id"];
        return newCat;
      });
    }
    this.setState({selectedCategories: e, "bulkCategories": newCats});
  }

  handleFilterChange(fieldName, event) {
    let filters = Object.assign({},this.state.filters);
    filters[fieldName] = event.value;
    this.setState({ filters });
    this.props.fetchTracks({
      stationId: this.state.stationId,
      page: 1,
      perPage: this.state.perPage,
      sortBy: this.state.sortBy,
      filterBy: filters,
      query: this.state.searchQuery
    });
  }

  handleCropChange = ({imageType, imageUrl}) => {
    let newState = {};
    newState[imageType] = imageUrl;
    this.setState(newState);
  }

  handleUploadImageModalSave = ({imageType, file}) => {
    this.props.uploadImage({
      stationId: this.state.stationId,
      trackUUID: this.state.activeTrack["id"],
      mediaServiceUUID: this.state.activeStation["attributes"].media_service_uuid,
      imageType: "album_art",
      file
    });
  }

  getTrackCategories = (trackId) => {
    const trackCategories = this.props.tracksCategories.filter(tc => tc.relationships.track.data.id === `${trackId}`)
    const categoryIds = new Set(trackCategories.map(tc => tc.relationships.category.data.id));
    const categories = this.props.categories.filter(c => categoryIds.has(c.id))
    return categories;
  }

  render() {
    const {
      bulkAction,
      activeTrack,
      perPage,
      page,
      selectedIdx,
      showFilters,
      stationId,
      uploadTracksModalOpen,
      activeStation,
      selectedCategories,
    } = this.state;

    let tracks = this.state.tracks || [];
    const {
      totalTracks,
      categories,
      tracksLoading,
      allCategories
     } = this.props;

    const categoriesOptions = allCategories.map(cat => {return {"value": cat["id"], "label": cat["attributes"]["name"]}});

    let trackAttrs = activeTrack["attributes"] || {}
      , bulkEditWidget;

    let bulkEditAttrs = this.state.bulkEditAttrs;

    tracks.forEach(track => {
      track["categories"] = (((track["relationships"]||[])["categories"]||[])["data"]||[]).forEach(trackCat => {
        let cat = (categories.find((c) => c.id === trackCat.id)||{})["attributes"];
        if (cat){
          cat["id"] = trackCat.id;
          return cat;
        }
      })
    });

    bulkActionOptions[0].label = 'Bulk Actions' + (selectedIdx.length > 0 ? ` (${selectedIdx.length})` : '');

    let selectedBulkAction = bulkActionOptions.find(opt => opt.value === bulkAction)
    let selectedTracks = (tracks.length && selectedIdx.map(i => this.state.tracks[i])) || [];
    let activeTrackCats = activeTrack["categories"];

    if ( bulkAction === 'editType') {
      bulkEditWidget = <UikWidget
          margin
        >
          <UikWidgetContent>
            <h3>Bulk Edit</h3>
            <div className={ classnames(cls.bulkEditForm,cls.bulkTypeEdit) }>
              <div className={ cls.bulkEditFormCol }>
                <UikFormInputGroup>
                  <span className="uik-content-title__wrapper">Categories </span>
                  { categoriesOptions.length > 0 && (
                    <Select
                      isMulti
                      name="categories"
                      options={[categoriesNoneOption, ...categoriesOptions]}
                      className="multi-select"
                      classNamePrefix="select"
                      value={selectedCategories}
                      onChange={this.handleBulkCategoriesSelect}
                    />
                  )}
                  { categoriesOptions.length === 0 && (
                    <small>You have no categories set up, create one here <Link to={`/stations/${this.state.stationId}/media/categories`}>services</Link></small>
                  )}
                </UikFormInputGroup>
              </div>
              <div className={ cls.bulkEditFormCol }>
                <UikFormInputGroup>
                  <span className="uik-content-title__wrapper">Type</span>
                  <UikSelect
                    placeholder="Type"
                    className={cls.bulkTypeSelector}
                    onChange={ this.handleBulkTypeChange }
                    options={ mediaTypeOptionsShort }
                  />
                </UikFormInputGroup>
              </div>
              <div className={ classnames(cls.bulkEditFormCol, cls.bulkActions) }>
                <UikButton onClick={this.handleCloseBulkEdit}>Cancel</UikButton>
                <UikButton success className={ cls.btnBulkEditSave } onClick={this.onBulkEditTypeClick} disabled={selectedIdx.length === 0}>Save</UikButton>
              </div>
            </div>
          </UikWidgetContent>
        </UikWidget>;
     }

    if ( bulkAction === 'editMetadata') {
      bulkEditWidget = <UikWidget
          margin
        >
          <UikWidgetContent>
            <h3>Bulk Edit</h3>
            <div className={ cls.bulkEditForm }>
              <div className={ cls.bulkEditFormCol }>
                <UikFormInputGroup>
                  <UikInput
                    label="Title"
                    name="title"
                    placeholder=""
                    defaultValue={ bulkEditAttrs.title }
                    onChange={ this.handleBulkEditChange }
                    maxLength={40}
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.bulkEditFormCol }>
                <UikFormInputGroup>
                  <UikInput
                    label="Artist"
                    name="artist"
                    placeholder=""
                    defaultValue={ bulkEditAttrs.artist }
                    onChange={ this.handleBulkEditChange }
                    maxLength={40}
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.bulkEditFormCol }>
                <UikFormInputGroup>
                  <UikInput
                    label="Album"
                    name="album"
                    placeholder=""
                    defaultValue={ bulkEditAttrs.album }
                    onChange={ this.handleBulkEditChange }
                    maxLength={40}
                  />
                </UikFormInputGroup>
              </div>
              <div className={ cls.bulkEditFormCol }>
                <UikFormInputGroup>
                  <UikInput
                    label="Year"
                    name="year"
                    placeholder=""
                    defaultValue={ bulkEditAttrs.year }
                    onChange={ this.handleBulkEditChange }
                    maxLength={40}
                    max={2050}
                    min={1000}
                  />
                </UikFormInputGroup>
              </div>
              <div className={ classnames(cls.bulkEditFormCol, cls.bulkActions) }>
                <UikButton onClick={this.handleCloseBulkEdit}>Cancel</UikButton>
                <UikButton success className={ cls.btnBulkEditSave } onClick={this.onBulkEditMetadataClick} disabled={selectedIdx.length === 0}>Save</UikButton>
              </div>
            </div>
          </UikWidgetContent>
        </UikWidget>;
     }

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar
          title={`Media > Tracks (${tracks.length})`}
        />
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            <div className={ cls.bulkActionsWrapper }>
              <UikSelect
                className={ cls.selectRange }
                defaultValue={[bulkAction]}
                value={[selectedBulkAction]}
                onChange={ this.handleBulkActionChange }
                options={ bulkActionOptions }
              />
              <SearchInput
                onSearch={ this.handleSearch }
                collectionName="Tracks"
              />
            </div>
            <div className={ cls.topRightButtons }>
              { showFilters && (
                <div className={ cls.filters }>
                  <label className="uik-content-title__wrapper">
                    Filters
                  </label>
                  <UikSelect
                    placeholder="Category"
                    name="category"
                    options={[{label: 'Show all', value: ''}, ...categoriesOptions]}
                    onChange={ this.handleFilterChange.bind(this, "category") }
                  />
                  <UikSelect
                    placeholder="Media Type"
                    name="mediaType"
                    position="bottomRight"
                    options={[{label: 'Show all', value: ''}, ...mediaTypeOptionsShort]}
                    onChange={ this.handleFilterChange.bind(this, "media_type") }
                  />
                </div>
              )}
              { !showFilters && (
                <UikButton className={ cls.btnFilters } onClick={this.handleFiltersClick}>
                  <i className="fas fa-filter"></i>
                  Filters
                 </UikButton>
              )}
              <UikButton primary onClick={this.handleMediaUploaderModalOpen}>Add Track</UikButton>
            </div>
          </UikContentItem>

          <MediaUploaderModal
            uploadTracksModalOpen={uploadTracksModalOpen}
            stationId={stationId}
            activeStation={activeStation}
            tracks={tracks}
            categories={allCategories}
            closeModal={this.handleMediaUploaderModalClose}
          />

          <Modal
            open={this.state.editTrackModalOpen}
            onClose={this.handleEditTrackModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Edit Track
              </div>
              <div className={ cls.modalBody }>
                <div className={ cls.modalBodyCol2 }>
                  <UikFormInputGroup>
                    <UikInput
                      label="Title"
                      name="title"
                      defaultValue={ trackAttrs.title }
                      onChange={ this.handleEditChange }
                      maxLength={40}
                    />
                  </UikFormInputGroup>
                  <UikFormInputGroup>
                    <UikInput
                      label="Artist"
                      name="artist"
                      defaultValue={ trackAttrs.artist }
                      onChange={ this.handleEditChange }
                      maxLength={40}
                    />
                  </UikFormInputGroup>
                  <UikFormInputGroup>
                    <UikInput
                      label="Album"
                      name="album"
                      defaultValue={ trackAttrs.album }
                      onChange={ this.handleEditChange }
                      maxLength={40}
                    />
                  </UikFormInputGroup>
                  <UikFormInputGroup>
                    <UikInput
                      label="Year"
                      name="year"
                      defaultValue={ trackAttrs.year }
                      onChange={ this.handleEditChange }
                      maxLength={40}
                      max={2050}
                      min={1000}
                    />
                  </UikFormInputGroup>
                </div>
                <div className={ cls.modalBodyCol1 }>
                  <div className={ cls.albumArtWrapper }>
                    <img alt="" src={ trackAttrs["album_art_uuid"] ? `${mediaStageBaseUrl}/download/${trackAttrs["album_art_uuid"]}.${trackAttrs["album_art_extension"]}` : '/img/song_placeholder.png'}/>
                    <UploadImageModal
                      onCropChange={this.handleCropChange.bind(this)}
                      onSave={this.handleUploadImageModalSave.bind(this)}
                      imageType='album_art'
                      aspectRatio={1}
                      buttonText="Upload Album Art"
                      isExpanded={true}
                    />
                  </div>
                </div>
                <UikFormInputGroup>
                  <UikSelect
                    defaultValue={ [trackAttrs.media_type] }
                    label="Type"
                    name="media_type"
                    options={mediaTypeOptions}
                    onChange={ this.handleMediaTypeDropdownChange.bind(this) }
                  />
                </UikFormInputGroup>
                <UikFormInputGroup>
                  <span className="uik-content-title__wrapper">Categories</span>
                  {
                    categoriesOptions.length > 0 && activeTrack && (
                    <Select
                      isMulti
                      name="categories"
                      // defaultValue={(activeTrackCats||[]).map(t => {return {"value": t.id, "label": t.name}})}
                      defaultValue={this.getTrackCategories(activeTrack.id).map(c => ({value: c.id, label: c.attributes.name}))}
                      options={categoriesOptions}
                      className="multi-select"
                      classNamePrefix="select"
                      onChange={this.handleCategoriesSelect}
                    />
                  )}
                  { categoriesOptions.length === 0 && (
                    <small>You have no categories set up, create one here <Link to={`/stations/${this.state.stationId}/media/categories`}>services</Link></small>
                  )}
                </UikFormInputGroup>
                <UikFormInputGroup>
                  <UikCheckbox name="do_not_play" defaultChecked={trackAttrs.do_not_play} label="Do Not Play" onChange={this.handleDoNotPlayChange} />
                </UikFormInputGroup>
              </div>
              <div className={ cls.modalFooter }>
                <UikButton isExpanded success onClick={this.handleEditTrackModalClick}>Save</UikButton>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deleteTrackModalOpen}
            onClose={this.handleDeleteTrackModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Delete { selectedTracks.length } tracks ?
              </div>
              <div className={ classnames(cls.modalBody, cls.modalScrollBody) }>
                <p>Are you sure you wish to delete <b>{selectedTracks.length} track{selectedTracks.length > 1 ? 's' :''}</b></p>
                <ul className={ cls.deleteTrackList }>
                  { selectedTracks.map((track, index) => (
                    <li key={`deleteTrack${index}`}>
                      {`${((track||{})["attributes"]||{}).artist} - ${((track||{})["attributes"]||{}).title}`}
                    </li>
                    ))
                  }
                </ul>
              </div>
              <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton)  }>
                <UikButton onClick={this.handleDeleteTrackModalClose}>Cancel</UikButton>
                <UikButton error onClick={this.handleDeleteTrackClick}>Delete</UikButton>
              </div>
            </div>
          </Modal>

          {bulkEditWidget}
          <TrackList
            sortBy={this.handleSortBy}
            editTrack={this.handleEditTrackModalOpen}
            deleteTrack={this.handleDeleteTrackModalOpen}
            tracks={ tracks }
            loading={tracksLoading}
            onTrackSelect= {this.handleTrackSelect}
            categories={ this.props.categories }
            tracksCategories={ this.props.tracksCategories }
            categoriesLoading={ this.props.tracksCategoriesLoading || this.props.categoriesLoading }
          />
          <Pagination
            showTotal={true}
            showingCount={ tracks.length }
            total={ totalTracks }
            page={ page }
            perPage={ perPage }
            changePage={ this.handlePageChange.bind(this) }
            changePerPage={ this.handleperPageChange.bind(this) }
            collectionType="tracks"
            aroundPage = { 3 }
          />
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    activeStation: store.stations.activeStation,
    tracks: store.tracks.tracksList.tracks,
    tracksLoading: store.tracks.tracksList.loading,
    totalTracks: store.tracks.tracksList.total,
    bearerToken: store.auth.bearerToken,
    categories: store.categories.categoriesList.categories,
    categoriesLoading: store.categories.categoriesList.loading,
    tracksCategories: store.tracksCategories.tracksCategoriesList.tracksCategories,
    tracksCategoriesLoading: store.tracksCategories.tracksCategoriesList.loading,
    allCategories: store.tracks.allCategories.categories,
    allCategoriesLoading: store.tracks.allCategories.loading,
  }),
  {
    fetchStation: stationActions.fetchStation,
    uploadImage: actions.uploadImage,
    fetchTracks: actions.fetchTracks,
    updateTrack: actions.updateTrack,
    bulkUpdateTracks: actions.bulkUpdateTracks,
    deleteTrack: actions.deleteTrack,
    fetchCategories: categoriesActions.fetchCategories,
    fetchTracksCategories: tracksCategoriesActions.fetchTracksCategories,
    fetchAllCategories: actions.fetchAllCategories
  },
)(TracksPage)


const mediaTypeOptions = [{
    value: 'music',
    label: 'Music (music and copyright material)'
  },
  {
    value: 'talk',
    label: 'Talk (sermons, speeches, and live recordings)'
  },
  {
    value: 'id',
    label: 'ID (station identification such as sweepers and jingles)'
  },
  {
    value: 'promo',
    label: 'Promo (station promotion that is not considered an ID)'
  },
  {
    value: 'advert',
    label: 'Ad (advert replacement files)'
  }
];

const mediaTypeOptionsShort = [{
    value: 'music',
    label: 'Music'
  },
  {
    value: 'talk',
    label: 'Talk'
  },
  {
    value: 'id',
    label: 'ID'
  },
  {
    value: 'promo',
    label: 'Promo'
  },
  {
    value: 'advert',
    label: 'Ad'
  }
];

const bulkActionOptions = [{
    value: 'bulk',
    label: 'Bulk Actions'
  },
  {
    value: 'editType',
    label: 'Edit Type / Category'
  },
  {
    value: 'editMetadata',
    label: 'Edit Metadata'
  },
  {
    value: 'delete',
    label: 'Delete'
  }
];

import initialState from './initialState'

import {
  FETCH_RELAYS, FETCH_RELAYS_SUCCESS, FETCH_RELAYS_FAILURE, RESET_RELAYS,
  UPDATE_RELAY, UPDATE_RELAY_SUCCESS,  UPDATE_RELAY_FAILURE,
  FETCH_RELAY, FETCH_RELAY_SUCCESS,  FETCH_RELAY_FAILURE, RESET_ACTIVE_RELAY,
  CREATE_RELAY, CREATE_RELAY_SUCCESS, CREATE_RELAY_FAILURE, RESET_NEW_RELAY,
  DELETE_RELAY, DELETE_RELAY_SUCCESS, DELETE_RELAY_FAILURE, RESET_DELETED_RELAY,
} from './actions';

export default function(state = initialState, action) {
  let error, newRelays;
  switch(action.type) {

  case FETCH_RELAYS:// start fetching relay and set loading = true
    return { ...state, relaysList: {relays:[], error: null, loading: true} };
  case FETCH_RELAYS_SUCCESS:
    return { ...state, relaysList: {relays: action.payload, error:null, loading: false}, activeRelay: {relay: action.payload[0], error:null, loading: false} };
  case FETCH_RELAYS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, relaysList: {relays: [], error: error, loading: false} };
  case RESET_RELAYS:// reset separationList to initial state
    return { ...state, relaysList: {relays: [], error:null, loading: false} };

  case FETCH_RELAY:
    return { ...state, activeRelay:{...state.activeRelay, loading: true}};
  case FETCH_RELAY_SUCCESS:
    return { ...state, activeRelay: {relay: action.payload, error:null, loading: false}};
  case FETCH_RELAY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeRelay: {relay: null, error:error, loading:false}};
  case RESET_ACTIVE_RELAY:
    return { ...state, activeRelay: {relay: null, error:null, loading: false}};

  case UPDATE_RELAY:
    return { ...state, activeRelay:{...state.activeRelay, loading: true}};
  case UPDATE_RELAY_SUCCESS:
    return { ...state, activeRelay: {relay: action.payload, error:null, loading: false}};
  case UPDATE_RELAY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeRelay: {relay: null, error:error, loading:false}};

  case CREATE_RELAY:
    return {...state, newRelay: {...state.newRelay, loading: true}}
  case CREATE_RELAY_SUCCESS:
    newRelays = state.relaysList.relays.slice();
    newRelays.push(action.payload);
    return {...state,
      relaysList: { relays: newRelays },
      activeRelay: {relay: action.payload, error:null, loading: false},
      newRelay: { relay: action.payload,
      error:null,
      loading: false
    }}
  case CREATE_RELAY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newRelay: {relay:null, error:error, loading: false}}
  case RESET_NEW_RELAY:
    return {...state,  newRelay:{relay:null, error:null, loading: false}}

  case DELETE_RELAY:
     return {...state, deletedRelay: {...state.deletedRelay, loading: true}}
  case DELETE_RELAY_SUCCESS:
    newRelays = state.relaysList.relays.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state,
      activeRelay: {relay: {}, error:null, loading: false},
      relaysList: { relays: newRelays },
      deletedRelay: {relay:action.payload, error:null, loading: false}
    }
  case DELETE_RELAY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedRelay: {relay:null, error:error, loading: false}}
  case RESET_DELETED_RELAY:
    return {...state,  deletedRelay:{relay:null, error:null, loading: false}}
  default:
    return state;
  }
}

import { ConnectedRouter } from 'react-router-redux'
import * as React from 'react'
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux'
import { hydrate } from 'react-dom'
import { createStore, initialState } from '@redux'
import * as configApp from '@redux/constants'

import { createBrowserHistory } from 'history'
import App from '../universal/App'

Sentry.init({dsn: "https://a9dfa402489f40a280dfccae7fdd7601@sentry.int.live365.net/18", environment: configApp.LIVE365_API});

// eslint-disable-next-line
const initStore = window ? window.__STORE__ : initialState

const history = createBrowserHistory()
const store = createStore(initStore, history)


hydrate(
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <App history={ history } />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept('../universal/App', () => {
    const App = require('../universal/App').default // eslint-disable-line
    hydrate(
      <Provider store={ store }>
        <ConnectedRouter history={ history }>
          <App history={ history } />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root'),
    )
  })
}


import * as React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'

import {
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
  UikTabContainer,
  UikTabItem,
  UikContentTitle,
  UikWidgetTable,
  UikContainerVertical,
  UikLayoutMain,
  UikButton
} from '@components'

import { scaleLinear } from "d3-scale"
import TopBar from '../../../../@components/TopBar'
import * as actions from '@redux/analytics/actions'
import { banIp } from '@redux/restrictions/actions'
import cls from './stations-performance.scss'
import * as images from '@eAssets'

import numberFormatZeros from '@lib/numberFormatZeros'

const mapMinColor = "#CFD8DC";
const mapMaxColor = "#37474F";

class RealtimeAnalyticsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      activeTab: 'Sessions',
      countriesData: null,
    };

    this.props.fetchRealtimeSessions({"stationIds": [this.state.stationId]});
    this.props.fetchRealtimeStations({"stationIds": [this.state.stationId]});
    this.props.fetchRealtimeCountries({"stationIds": [this.state.stationId]});
    this.props.fetchRealtimePlatforms({"stationIds": [this.state.stationId]});
  }

  componentDidMount() {
    const chart = document.getElementById('performance-chart')
    if (chart) {
      const ctx = chart.getContext('2d')
      if (ctx) {
        const gradientFill = ctx.createLinearGradient(0, 0, 0, 280)
        gradientFill.addColorStop(0, 'rgba(25, 145, 235, 0.15)')
        gradientFill.addColorStop(0.9, 'rgba(25, 145, 235, 0)')
        this.setState({
          gradientFill,
        })
      }
    }
  }

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  }

  banIp = (ip) => {
    this.props.banIp(ip, this.state.stationId);
    // Not sure AIS will kick out that connection, though
    setTimeout(() => this.props.fetchRealtimeSessions({"stationIds": [this.state.stationId]}), 5000)
  }

  render = () => {
    const {
      activeTab,
    } = this.state;

    const {
      realtimeSessions,
      realtimeStations,
      realtimePlatforms,
      realtimeCountries
    } = this.props;

    let stationsData = realtimeStations["stations"];
    let platformsData = realtimePlatforms["platforms"];

    let realtimeSessionsData = realtimeSessions;

    let stationStats = (stationsData && stationsData[Object.keys(stationsData)[0]]) || {};

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Realtime Analytics"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikWidget margin>
            <UikWidgetHeader>Summary</UikWidgetHeader>
            <div className={ cls.boxes }>
              <div className={ cls.box }>
                <UikContentTitle>Active Sessions</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(stationStats["active_sessions"])}</span>
                </div>
              </div>
              <div className={ cls.box }>
                <UikContentTitle>Session Starts</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(stationStats["sessions_started"])}</span>
                </div>
              </div>
              <div className={ cls.box }>
                <UikContentTitle>Total Listening Hours</UikContentTitle>
                <div className={ cls.boxContent }>
                  <span className={ cls.boxValue }>{formatNumber(Math.floor(stationStats["tlh"]))}</span>
                </div>
              </div>
            </div>
          </UikWidget>
          <UikWidget margin>
            <UikTabContainer>
              {
                ['Sessions', 'Countries', 'Platforms/Devices'].map(tab => (
                  <UikTabItem key={ tab } onClick={ () => this.setActiveTab(tab) } className={ tab === activeTab && 'actve'} text={ tab } />
                ))
              }
            </UikTabContainer>
            <div className={ classnames(cls.mainTab, { [cls.activeTab]: activeTab === 'Sessions'}) }>
              <div>
                <UikWidgetContent>
                {activeTab === 'Sessions' && (
                  <UikWidgetTable>
                    <thead>
                      <tr>
                        <th>
                          Location
                        </th>
                        <th>
                          IP
                        </th>
                        <th>
                          Browser
                        </th>
                        <th>
                          Duration
                        </th>
                        <th>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        realtimeSessionsData && realtimeSessionsData.map((stats,index) => (
                          <tr key={ index }>
                            <td>
                              {stats["location"]["city"]}
                            </td>
                            <td>
                              {stats["ip"]}
                            </td>
                            <td>
                              {stats["platform"]}
                            </td>
                            <td>
                              {Math.round((new Date() - new Date(stats["connection_time"])) / 1000 / 60)} minutes
                            </td>
                            <td>
                              <UikButton primary onClick={ () => this.banIp(stats["ip"]) }>
                                <i className={ classnames("fas fa-ban", cls.tooltip)}></i>
                              </UikButton>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </UikWidgetTable>
                )}
                </UikWidgetContent>
              </div>
            </div>
            <div className={ classnames(cls.mainTab, { [cls.activeTab]: activeTab === 'Countries'}) }>
              <div>
                <UikWidgetTable>
                  <thead>
                    <tr>
                      <th>
                        Country
                      </th>
                      <th>
                        Active Sessions
                      </th>
                      <th>
                        Session Starts
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      realtimeCountries && Object.keys(realtimeCountries["countries"]||{}).map((key,index) => (
                        <tr key={ index }>
                          <td>
                            {key}
                          </td>
                          <td>
                            {realtimeCountries["countries"][key]["active_sessions"]}
                          </td>
                          <td>
                            {realtimeCountries["countries"][key]["sessions_started"]}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </UikWidgetTable>
              </div>
            </div>
            <div className={ classnames(cls.mainTab, { [cls.activeTab]: activeTab === 'Platforms/Devices'}) }>
              <UikWidgetTable>
                <thead>
                  <tr>
                    <th>
                      Platform
                    </th>
                    <th>
                      Active Sessions
                    </th>
                    <th>
                      Session Starts
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(platformsData||{}).map((key,index) => (
                      <tr key={ index }>
                        <td>
                          {key}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["active_sessions"])}
                        </td>
                        <td>
                          {formatNumber(platformsData[key]["sessions_started"])}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </UikWidgetTable>
            </div>
          </UikWidget>
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({realtimeSessions: store.analytics.realtimeSessions.stats,
    realtimeStations: store.analytics.realtimeStations.stats,
    realtimeCountries: store.analytics.realtimeCountries.stats,
    realtimePlatforms: store.analytics.realtimePlatforms.stats,
    realtimeCountries: store.analytics.realtimeCountries.stats
  }),
  {
    fetchRealtimeSessions: actions.fetchRealtimeSessions,
    fetchRealtimeStations: actions.fetchRealtimeStations,
    fetchRealtimePlatforms: actions.fetchRealtimePlatforms,
    fetchRealtimeCountries: actions.fetchRealtimeCountries,
    banIp: banIp
  },
)(RealtimeAnalyticsPage)

function formatNumber(num) {
  num = num || 0;
  return num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

import initialState from './initialState'

import {
    FETCH_SERVER, FETCH_SERVER_SUCCESS, FETCH_SERVER_ERROR, RESET_SERVER
} from './actions'


export default function(state = initialState, action) {
    switch(action.type) {
        case FETCH_SERVER:
            return {...state, error: null, loading: true}
        case FETCH_SERVER_SUCCESS:
            return {...state, activeStationServer: action.payload, loading: false}
        case FETCH_SERVER_ERROR:
            return {...state, activeStationServer: null, error: action.payload.message, loading: false}
        case RESET_SERVER:
            return {...state, activeStationServer: null}
        default:
            return state
    }
}

import * as React from 'react'
import classnames from 'classnames'

import {
  UikButton,
  UikButtonGroup,
  Uikon,
  UikSelect,
} from '@components'

import cls from './pagination.scss'

// @flow
type PaginationProps = {
  page: Number,
  perPage: Number,
  collectionType: String,
  total: Number,
  changePerPage: Function,
  changePage: Function,
  showTotal?: Boolean,
  aroundPage: Number
}

class Pagination extends React.Component<PaginationProps> {

  render() {
    let {
      page,
      perPage,
      collectionType,
      total,
      changePerPage,
      changePage,
      showTotal,
      showingCount,
      aroundPage
    } = this.props;

    showTotal = showTotal || false;

    const totalPages = perPage > total ? 1 :  Math.ceil(total / perPage);

    let pageBtns = [];

    for (var i = 1; i <= totalPages; ++i) {
      if ((i > page - aroundPage && i < page + aroundPage) 
      || i < aroundPage || i > (totalPages - aroundPage) + 1
      || aroundPage === null || aroundPage === undefined)
      {
        pageBtns.push(<UikButton
            key={ `btn-page-${i}` }
            transparent
            className={ classnames({
              [cls.pageActive]: page === i,
            }) }
            onClick={ changePage.bind(this, i) }
          >
            {i}
          </UikButton>
        )
      }
      else if (i < aroundPage + 1 || i > totalPages - aroundPage)
      {
        pageBtns.push(<UikButton
          disabled={ true }
          transparent
        >
          ...
        </UikButton>
      )
      }
    }


    return (
      <div
        className={ cls.paginationRow }
      >
        { showTotal && (
          <label className={ cls.showingLabel } >
            Showing { showingCount } out of { total } { collectionType }
          </label>
        )}
        <UikButtonGroup >
          <UikButton
            disabled={ page === 1 }
            icon={ <Uikon className={ cls.btnArrow }>arrow_left</Uikon> }
            iconOnly
            transparent
            onClick={ changePage.bind(this, page-1) }
          />
          { pageBtns }
          <UikButton
            disabled={ page === totalPages }
            icon={ <Uikon className={ cls.btnArrow }>arrow_right</Uikon> }
            iconOnly
            transparent
            onClick={ changePage.bind(this, page+1) }
          />
        </UikButtonGroup>

        <div
          className={ cls.perPage }
        >
          <UikSelect
            defaultValue={ [ perPage ] }
            label="per page"
            position="topLeft"
            onChange={ changePerPage.bind(this) }
            options={ [
              { value: 30, label: '30' },
              { value: 50, label: '50' },
              { value: 100, label: '100' },
              { value: 1000, label: '1000' },
              { value: 9999999, label: 'All' }
            ] }
          />
        </div>
      </div>
    )
  }
}

export default Pagination

import initialState from './initialState'

import {
  FETCH_CLOCKWHEEL_ENTRIES, FETCH_CLOCKWHEEL_ENTRIES_SUCCESS, FETCH_CLOCKWHEEL_ENTRIES_FAILURE, RESET_CLOCKWHEEL_ENTRIES,
  UPDATE_CLOCKWHEEL_ENTRY, UPDATE_CLOCKWHEEL_ENTRY_SUCCESS,  UPDATE_CLOCKWHEEL_ENTRY_FAILURE,
  FETCH_CLOCKWHEEL_ENTRY, FETCH_CLOCKWHEEL_ENTRY_SUCCESS,  FETCH_CLOCKWHEEL_ENTRY_FAILURE, RESET_ACTIVE_CLOCKWHEEL_ENTRY,
  CREATE_CLOCKWHEEL_ENTRY, CREATE_CLOCKWHEEL_ENTRY_SUCCESS, CREATE_CLOCKWHEEL_ENTRY_FAILURE, RESET_NEW_CLOCKWHEEL_ENTRY,
  DELETE_CLOCKWHEEL_ENTRY, DELETE_CLOCKWHEEL_ENTRY_SUCCESS, DELETE_CLOCKWHEEL_ENTRY_FAILURE, RESET_DELETED_CLOCKWHEEL_ENTRY,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_CLOCKWHEEL_ENTRIES:// start fetching clockwheelEntries and set loading = true
    return { ...state, clockwheelEntryList: {clockwheelEntries:[], error: null, loading: true} };
  case FETCH_CLOCKWHEEL_ENTRIES_SUCCESS:
    return { ...state, clockwheelEntryList: {clockwheelEntries: action.payload, error:null, loading: false} };
  case FETCH_CLOCKWHEEL_ENTRIES_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, clockwheelEntryList: {clockwheelEntries: [], error: error, loading: false} };
  case RESET_CLOCKWHEEL_ENTRIES:// reset clockwheelList to initial state
    return { ...state, clockwheelEntryList: {clockwheelEntries: [], error:null, loading: false} };

  case FETCH_CLOCKWHEEL_ENTRY:
    return { ...state, activeClockwheelEntry:{...state.activeClockwheelEntry, loading: true}};
  case FETCH_CLOCKWHEEL_ENTRY_SUCCESS:
    return { ...state, activeClockwheelEntry: {clockwheelEntry: action.payload, error:null, loading: false}};
  case FETCH_CLOCKWHEEL_ENTRY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeClockwheelEntry: {clockwheelEntry: null, error:error, loading:false}};
  case RESET_ACTIVE_CLOCKWHEEL_ENTRY:
    return { ...state, activeClockwheelEntry: {clockwheelEntry: null, error:null, loading: false}};

  case UPDATE_CLOCKWHEEL_ENTRY:
    return { ...state, activeClockwheelEntry:{...state.activeClockwheelEntry, loading: true}};
  case UPDATE_CLOCKWHEEL_ENTRY_SUCCESS:
    return { ...state, activeClockwheelEntry: {clockwheelEntry: action.payload, error:null, loading: false}};
  case UPDATE_CLOCKWHEEL_ENTRY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeClockwheelEntry: {clockwheelEntry: null, error:error, loading:false}};

  case CREATE_CLOCKWHEEL_ENTRY:
    return {...state, newClockwheelEntry: {...state.newClockwheelEntry, loading: true}}
  case CREATE_CLOCKWHEEL_ENTRY_SUCCESS:
    let newClockwheelEntries = state.clockwheelEntryList.clockwheelEntries.slice();
    newClockwheelEntries.push(action.payload);
    return {...state,
      clockwheelEntryList: { clockwheelEntries: newClockwheelEntries },
      newClockwheelEntry: {clockwheelEntry:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_CLOCKWHEEL_ENTRY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newClockwheelEntry: {clockwheelEntry:null, error:error, loading: false}}
  case RESET_NEW_CLOCKWHEEL_ENTRY:
    return {...state,  newClockwheelEntry:{clockwheelEntry:null, error:null, loading: false}}

  case DELETE_CLOCKWHEEL_ENTRY:
     return {...state, deletedClockwheelEntry: {...state.deletedClockwheelEntry, loading: true}}
  case DELETE_CLOCKWHEEL_ENTRY_SUCCESS:
    let newCws = state.clockwheelEntryList.clockwheelEntries.filter(item => {
      return item.id !== action.id; // return all the items not matching the action.id
    });
    return {...state, clockwheelEntryList: { clockwheelEntries: newCws }}
  case DELETE_CLOCKWHEEL_ENTRY_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedClockwheelEntry: {clockwheelEntry:null, error:error, loading: false}}
  case RESET_DELETED_CLOCKWHEEL_ENTRY:
    return {...state,  deletedClockwheelEntry:{clockwheelEntry:null, error:null, loading: false}}

  default:
    return state;
  }
}

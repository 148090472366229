import initialState from './initialState'

import {
  FETCH_PACKAGES, FETCH_PACKAGES_SUCCESS, FETCH_PACKAGES_FAILURE, RESET_PACKAGES,
  UPDATE_PACKAGE, UPDATE_PACKAGE_SUCCESS,  UPDATE_PACKAGE_FAILURE,
  FETCH_PACKAGE, FETCH_PACKAGE_SUCCESS,  FETCH_PACKAGE_FAILURE, RESET_ACTIVE_PACKAGE,
  CREATE_PACKAGE, CREATE_PACKAGE_SUCCESS, CREATE_PACKAGE_FAILURE, RESET_NEW_PACKAGE,
  DELETE_PACKAGE, DELETE_PACKAGE_SUCCESS, DELETE_PACKAGE_FAILURE, RESET_DELETED_PACKAGE,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_PACKAGES:// start fetching package and set loading = true
    return { ...state, packagesList: {packages:[], error: null, loading: true} };
  case FETCH_PACKAGES_SUCCESS:
    return { ...state, packagesList: {packages: action.payload, error:null, loading: false} };
  case FETCH_PACKAGES_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, packagesList: {packages: [], error: error, loading: false} };
  case RESET_PACKAGES:// reset packageList to initial state
    return { ...state, packagesList: {packages: [], error:null, loading: false} };

  case FETCH_PACKAGE:
    return { ...state, activePackage:{...state.activePackage, loading: true}};
  case FETCH_PACKAGE_SUCCESS:
    return { ...state, activePackage: {package: action.payload, error:null, loading: false}};
  case FETCH_PACKAGE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activePackage: {package: null, error:error, loading:false}};
  case RESET_ACTIVE_PACKAGE:
    return { ...state, activePackage: {package: null, error:null, loading: false}};

  case UPDATE_PACKAGE:
    return { ...state, activePackage:{...state.activePackage, loading: true}};
  case UPDATE_PACKAGE_SUCCESS:
    return { ...state, activePackage: {package: action.payload, error:null, loading: false}};
  case UPDATE_PACKAGE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activePackage: {package: null, error:error, loading:false}};

  case CREATE_PACKAGE:
    return {...state, newPackage: {...state.newPackage, loading: true}}
  case CREATE_PACKAGE_SUCCESS:
    let newPackages = state.packagesList.packages.slice();
    newPackages.push(action.payload);
    return {...state,
      packagesList: { packages: newPackages },
      newPackage: {package:action.payload,
      error:null,
      loading: false
    }}
  case CREATE_PACKAGE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, newPackage: {package:null, error:error, loading: false}}
  case RESET_NEW_PACKAGE:
    return {...state,  newPackage:{package:null, error:null, loading: false}}

  case DELETE_PACKAGE:
     return {...state, deletedPackage: {...state.deletedPackage, loading: true}}
  case DELETE_PACKAGE_SUCCESS:
    let newCws = state.packagesList.packages.filter(item => {
      return item.id !== action.id // return all the items not matching the action.id
    })
    return {...state, packagesList: { packages: newCws }, deletedPackage: {package:action.payload, error:null, loading: false}}
  case DELETE_PACKAGE_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return {...state, deletedPackage: {package:null, error:error, loading: false}}
  case RESET_DELETED_PACKAGE:
    return {...state,  deletedPackage:{package:null, error:null, loading: false}}
  default:
    return state;
  }
}

import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Category list
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_PAGE_SUCCESS = 'FETCH_CATEGORIES_PAGE_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const RESET_CATEGORIES = 'RESET_CATEGORIES';

//Create new CATEGORY
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const RESET_NEW_CATEGORY = 'RESET_NEW_CATEGORY';

//Update CATEGORY
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

//Fetch CATEGORY
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';
export const RESET_ACTIVE_CATEGORY = 'RESET_ACTIVE_CATEGORY';

//Delete CATEGORY
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const RESET_DELETED_CATEGORY = 'RESET_DELETED_CATEGORY';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestCategories = () => ({
  type: FETCH_CATEGORIES,
})

export const receiveCategories = (json) => ({
  type: FETCH_CATEGORIES_PAGE_SUCCESS,
  payload: json
})

export const requestDeleteCategory = () => ({
  type: DELETE_CATEGORY,
})

export const categoryDeleted = (json) => ({
  type: DELETE_CATEGORY_SUCCESS,
  id: json
})

export const requestCreateCategory = () => ({
  type: CREATE_CATEGORY,
})

export const requestUpdateCategory = () => ({
  type: UPDATE_CATEGORY,
})

export const categoryUpdated = (json) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: json
})

function getCategories(dispatch, stationId, page, tracksCategories) {
  let url = `${BROADCASTER_ROOT_URL}categories/?filter[station]=${stationId}&page[number]=${page}`;
  const ids = tracksCategories ? tracksCategories.map(tc => tc['relationships']['category']['data']['id']).join(',') : null;
  if (ids) {
    url += `&filter[id]=${ids}`;
  }
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    }
  }).then(response => {
    if (response.status === 401) {
      dispatch(push('/'));
      return Promise.resolve();
    }

    return response.json();
  }).then(response => {
    dispatch(receiveCategories(response.data));

    if (response['links'] && response['links']['next']) {
      getCategories(dispatch, stationId, page + 1, tracksCategories).then(() => Promise.resolve());
    } else {
      dispatch(fetchCategoriesSuccess())
    }

    return true;
  })
}

export function fetchCategories(stationId, tracksCategories) {

  return function(dispatch) {

    dispatch(requestCategories())
    getCategories(dispatch, stationId, 1, tracksCategories).then(() => Promise.resolve());
  }
}

export function fetchCategoriesSuccess() {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
  };
}

export function fetchCategoriesFailure(error) {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    payload: error
  };
}

export function createCategory(cat, stationId) {

  return function(dispatch) {

    dispatch(requestCreateCategory())

    const bearerToken = ls.get('bearerToken');

    let data = {
      "data": {
        "type": "categories",
        "id": stationId + "",
        "attributes": cat.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}categories/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Category has been created !");
          dispatch(createCategorySuccess(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createCategorySuccess(newCategory) {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: newCategory
  };
}

export function createCategoryFailure(error) {
  return {
    type: CREATE_CATEGORY_FAILURE,
    payload: error
  };
}

export function resetNewCategory() {
  return {
    type: RESET_NEW_CATEGORY
  }
}

export function resetDeletedCategory() {
  return {
    type: RESET_DELETED_CATEGORY
  }
}


export function fetchCategorySuccess(activeCategory) {
  return {
    type: FETCH_CATEGORY_SUCCESS,
    payload: activeCategory
  };
}

export function fetchCategoryFailure(error) {
  return {
    type: FETCH_CATEGORY_FAILURE,
    payload: error
  };
}

export function resetActiveCategory() {
  return {
    type: RESET_ACTIVE_CATEGORY
  }
}

export function deleteCategory(cwId) {

  return function(dispatch) {

    dispatch(requestDeleteCategory())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}categories/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Category has been created !");
          dispatch(categoryDeleted(cwId))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateCategory(cat) {

  return function(dispatch) {

    dispatch(requestUpdateCategory())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "categories",
        "id": cat.id + "",
        "attributes": cat.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}categories/${cat.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Category has been updated !");
          dispatch(categoryUpdated(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deleteCategoryFailure(response) {
  return {
    type: DELETE_CATEGORY_FAILURE,
    payload: response
  };
}

import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//BroadcasterUser list
export const FETCH_BROADCASTER_USERS = 'FETCH_BROADCASTER_USERS';
export const FETCH_BROADCASTER_USERS_SUCCESS = 'FETCH_BROADCASTER_USERS_SUCCESS';
export const FETCH_BROADCASTER_USERS_FAILURE = 'FETCH_BROADCASTER_USERS_FAILURE';
export const RESET_BROADCASTER_USERS = 'RESET_BROADCASTER_USERS';

//Create new BROADCASTER_USER
export const CREATE_BROADCASTER_USER = 'CREATE_BROADCASTER_USER';
export const CREATE_BROADCASTER_USER_SUCCESS = 'CREATE_BROADCASTER_USER_SUCCESS';
export const CREATE_BROADCASTER_USER_FAILURE = 'CREATE_BROADCASTER_USER_FAILURE';
export const RESET_NEW_BROADCASTER_USER = 'RESET_NEW_BROADCASTER_USER';

//Update BROADCASTER_USER
export const UPDATE_BROADCASTER_USER = 'UPDATE_BROADCASTER_USER';
export const UPDATE_BROADCASTER_USER_SUCCESS = 'UPDATE_BROADCASTER_USER_SUCCESS';
export const UPDATE_BROADCASTER_USER_FAILURE = 'UPDATE_BROADCASTER_USER_FAILURE';

//Fetch BROADCASTER_USER
export const FETCH_BROADCASTER_USER = 'FETCH_BROADCASTER_USER';
export const FETCH_BROADCASTER_USER_SUCCESS = 'FETCH_BROADCASTER_USER_SUCCESS';
export const FETCH_BROADCASTER_USER_FAILURE = 'FETCH_BROADCASTER_USER_FAILURE';
export const RESET_ACTIVE_BROADCASTER_USER = 'RESET_ACTIVE_BROADCASTER_USER';

//Delete BROADCASTER_USER
export const DELETE_BROADCASTER_USER = 'DELETE_BROADCASTER_USER';
export const DELETE_BROADCASTER_USER_SUCCESS = 'DELETE_BROADCASTER_USER_SUCCESS';
export const DELETE_BROADCASTER_USER_FAILURE = 'DELETE_BROADCASTER_USER_FAILURE';
export const RESET_DELETED_BROADCASTER_USER = 'RESET_DELETED_BROADCASTER_USER';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestBroadcasterUsers = () => ({
  type: FETCH_BROADCASTER_USERS,
})

export const receiveBroadcasterUsers = (json) => ({
  type: FETCH_BROADCASTER_USERS_SUCCESS,
  payload: json
})

export const requestBroadcasterUser = () => ({
  type: FETCH_BROADCASTER_USER,
})

export const receiveBroadcasterUser = (json) => ({
  type: FETCH_BROADCASTER_USER_SUCCESS,
  payload: json
})

export const requestDeleteBroadcasterUser = () => ({
  type: DELETE_BROADCASTER_USER,
})

export const requestCreateBroadcasterUser = () => ({
  type: CREATE_BROADCASTER_USER,
})

export const requestUpdateBroadcasterUser = () => ({
  type: UPDATE_BROADCASTER_USER,
})

export function fetchBroadcasterUsersSuccess(users) {
  return {
    type: FETCH_BROADCASTER_USERS_SUCCESS,
    payload: users
  };
}

export function fetchBroadcasterUsersFailure(error) {
  return {
    type: FETCH_BROADCASTER_USERS_FAILURE,
    payload: error
  };
}

export function createBroadcasterUserFailure(error) {
  return {
    type: CREATE_BROADCASTER_USER_FAILURE,
    payload: error
  };
}

export function fetchBroadcasterUserSuccess(activeBroadcasterUser) {
  return {
    type: FETCH_BROADCASTER_USER_SUCCESS,
    payload: activeBroadcasterUser
  };
}

export function fetchBroadcasterUserFailure(error) {
  return {
    type: FETCH_BROADCASTER_USER_FAILURE,
    payload: error
  };
}

export function createBroadcasterUserSuccess(bUser) {
  return {
    type: CREATE_BROADCASTER_USER_SUCCESS,
    payload: bUser
  };
}

export function updateBroadcasterUserFailure(error) {
  return {
    type: UPDATE_BROADCASTER_USER_FAILURE,
    payload: error
  };
}

export function updateBroadcasterUserSuccess(bUser) {
  return {
    type: UPDATE_BROADCASTER_USER_SUCCESS,
    payload: bUser
  };
}

export function deleteBroadcasterUserFailure(error) {
  return {
    type: DELETE_BROADCASTER_USER_FAILURE,
    payload: error
  };
}

export function deleteBroadcasterUserSuccess(cwId) {
  return {
    type: DELETE_BROADCASTER_USER_SUCCESS,
    payload: cwId
  };
}

export function fetchBroadcasterUsers({stationId}) {

  return function(dispatch) {

    dispatch(requestBroadcasterUsers())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}users/?filter[station]=${stationId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveBroadcasterUsers(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchBroadcasterUser(id) {

  return function(dispatch) {

    dispatch(requestBroadcasterUser())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}users/${id}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveBroadcasterUser(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function createBroadcasterUser(cw, stationId) {

  return function(dispatch) {

    dispatch(requestCreateBroadcasterUser())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "users",
        "id": stationId + "",
        "attributes": cw.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}users/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("BroadcasterUser has been created !");
          dispatch(createBroadcasterUserSuccess(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateBroadcasterUser(cw) {

  return function(dispatch) {

    dispatch(requestUpdateBroadcasterUser())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "users",
        "id": cw.id + "",
        "attributes": cw.attributes
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}users/${cw.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Saved");
          dispatch(updateBroadcasterUserSuccess(json.data))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function deleteBroadcasterUser(cwId) {

  return function(dispatch) {

    dispatch(requestDeleteBroadcasterUser())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}users/${cwId}`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("BroadcasterUser has been deleted !");
          dispatch(deleteBroadcasterUserSuccess(cwId))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

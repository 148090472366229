import * as React from 'react'
import classnames from 'classnames'

import cls from './pacman-loader.scss'

// @flow
type PacmanLoaderProps = {
  loading: Boolean
}

class PacmanLoader extends React.Component<PacmanLoaderProps> {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    const {
      loading
    } = this.props;

    return !loading ? null : (
      <div className={ classnames(cls.loadingWrapper, cls.pulsate) }>
        <div className={cls.loader}>
          <div className={cls.circles}>
            <span className={cls.one}></span>
            <span className={cls.two}></span>
            <span className={cls.three}></span>
          </div>
          <div className={cls.pacman}>
            <span className={cls.top}></span>
            <span className={cls.bottom}></span>
            <span className={cls.left}></span>
            <div className={cls.eye}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default PacmanLoader

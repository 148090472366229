import * as React from 'react'
import classnames from 'classnames'
import { toast } from 'react-toastify'

import Modal from '@material-ui/core/Modal';

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

/* eslint-disable max-len */

import {
  UikButton,
} from '@components'

import cls from './upload-image-modal.scss'

const ACCEPTED_FILE_TYPES = new Set([
  "image/png", "image/jpg", "image/jpeg", "image/gif"
])

// @flow
type UploadImageModalProps = {
}

class UploadImageModal extends React.Component<UploadImageModalProps> {
  constructor(props) {
    super(props);

    this.state = {
      uploadImageModalOpen: false,
      src: null,
      imageFile: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: this.props.aspectRatio || 1
      }
    };

    this.handleUploadImageModalOpen = this.handleUploadImageModalOpen.bind(this);
    this.handleUploadImageModalClose = this.handleUploadImageModalClose.bind(this);
    this.handleModalSave = this.handleModalSave.bind(this);
  }

  handleUploadImageModalClose = () => {
    this.setState({ uploadImageModalOpen: false });
  };

  handleUploadImageModalOpen = () => {
    this.setState({ uploadImageModalOpen: true });
  };

  handleModalSave = () => {
    this.props.onSave({file: this.state.imageFile, imageType: this.props.imageType});
    this.setState({ uploadImageModalOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
    // if(prevProps.tracks !== this.props.tracks){
    // }
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const uploadCandidate = e.target.files[0];

      if (!ACCEPTED_FILE_TYPES.has(uploadCandidate.type)) {
        toast.error("File type not supported");
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(uploadCandidate);
      this.setState({ uploadImageModalOpen: true, imageFile: e.target.files[0]});
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
      this.props.onCropChange({imageType: this.props.imageType, imageUrl: croppedImageUrl});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, src } = this.state;
    let buttonText = this.props.buttonText || "Upload Photo";

    return (
      <div>
        <Modal
          open={this.state.uploadImageModalOpen}
          onClose={this.handleUploadImageModalClose}
        >
          <div className={ cls.modal }>
            <div className={ cls.modalHeader }>
              {buttonText}
            </div>
            <div className={ classnames(cls.modalBody, cls.cropModal) }>
              { src && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
            </div>
            <div className={ cls.modalFooter }>
              <UikButton isExpanded success onClick={this.handleModalSave}>Save</UikButton>
            </div>
          </div>
        </Modal>

        <div className={ cls.fileInputWrapper }>
          <UikButton type="file" isExpanded={this.props.isExpanded}>{buttonText}</UikButton>
          <input type="file" onChange={this.onSelectFile} style={{cursor: 'pointer'}}/>
        </div>
      </div>
    )
  }
}

export default UploadImageModal

import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import moment from 'moment';
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Event list
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';
export const RESET_EVENTS = 'RESET_EVENTS';

//Create new EVENT
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';
export const RESET_NEW_EVENT = 'RESET_NEW_EVENT';

//Update EVENT
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

//Fetch EVENT
export const FETCH_EVENT = 'FETCH_EVENT';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';
export const RESET_ACTIVE_EVENT = 'RESET_ACTIVE_EVENT';

//Delete EVENT
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';
export const RESET_DELETED_EVENT = 'RESET_DELETED_EVENT';

//Update EVENT - show scheduled tracks
export const FETCH_ACTIVE_EVENT_TRACKS = 'FETCH_ACTIVE_EVENT_TRACKS';
export const FETCH_ACTIVE_EVENT_TRACKS_SUCCESS = 'FETCH_ACTIVE_EVENT_TRACKS_SUCCESS';
export const FETCH_ACTIVE_EVENT_TRACKS_FAILURE = 'FETCH_ACTIVE_EVENT_TRACKS_FAILURE';
export const RESET_ACTIVE_EVENT_TRACKS = 'RESET_ACTIVE_EVENT_TRACKS';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestEvents = () => ({
  type: FETCH_EVENTS,
})

export const receiveEvents = (json) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload: json
})

export const requestDeleteEvent = () => ({
  type: DELETE_EVENT,
})

export const eventDeleted = (event) => ({
  type: DELETE_EVENT_SUCCESS,
  event: event
})

export const requestCreateEvent = () => ({
  type: CREATE_EVENT,
})

export const requestUpdateEvent = () => ({
  type: UPDATE_EVENT,
})

export const requestUpdateEventFailure = (msg) => ({
  type: UPDATE_EVENT_FAILURE,
  message: msg
})

export const requestUpdateEventSuccess = (json) => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: json
})

export function fetchEvents(stationId, interval) {

  return function(dispatch) {

    dispatch(requestEvents())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}events/?filter[station]=${stationId}&filter[interval]=${interval}`, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      }
    })
    .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
    })
    .then(json => json.data)
    .then(events => {
      dispatch(receiveEvents(events))
    })
    .catch(err => {
      console.error(err);
    });
  }
}

function prepareNewEventPayload(newEvent){

  console.log(126,newEvent);

  let startTime = moment(newEvent.attributes.start_time).toDate() || new Date();
  startTime = startTime.toISOString().slice(0,19)+"+0000";

  // When Andrei wrote this, only God and he understood what he was doing. Now, God only knows.
  // 1. is_instance === true && is_recurring === false (Single Event)
  // 2. is_instance === false && is_recurring === true (Recurring Event - Parent)
  // 3. is_instance === true && is_recurring === true (Recurring Event - Instance)

  let isInstance = newEvent.attributes.hasOwnProperty("is_instance") ? newEvent.attributes.is_instance : !newEvent.attributes.is_recurring;

  let data = {
    "data": {
      "type": "events",
      "attributes": {
        "start_time": startTime,
        "duration": newEvent.attributes.duration,
        "title": newEvent.attributes.title,
        "is_instance": isInstance,
        "is_recurring": newEvent.attributes.is_recurring,
        "is_canceled": newEvent.attributes.is_canceled,
        "recurring_days": newEvent.attributes.recurring_days,
        "strict_start": newEvent.attributes.strict_start
      },
      "relationships": {}
    }
  };

  ["station", "parent_event", "clockwheel", "playlist"].forEach(relation => {
    if (newEvent["relationships"][relation]){
      data.data.relationships[relation] = newEvent["relationships"][relation];
    }
  })

  if (newEvent.attributes.is_recurring && newEvent.attributes.series_end){
    let seriesEnd = new Date(newEvent.attributes.series_end);
    seriesEnd = seriesEnd.toISOString().slice(0,19)+"+0000";
    data.data.attributes.series_end = seriesEnd;
  }

  return data;
}

export function createEvent({newEvent}) {

  return function(dispatch) {

    dispatch(requestCreateEvent())

    const bearerToken = ls.get('bearerToken') || null;

    let data = prepareNewEventPayload(newEvent);

    return fetch(`${BROADCASTER_ROOT_URL}events/`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      },
      body: JSON.stringify(data)
    })
    .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
      return res.json();
    })
    .then(json => {
      if (json["errors"] && json["errors"].length){
        let msg = json["errors"][0]["detail"];
        toast.error(msg);
        dispatch(createEventFailure(msg))
      } else {
        toast.success("Event has been created !");
        dispatch(createEventSuccess(json.data))
      }
    })
    .catch(err => {
      console.error(err);
    });
  }
}


export function createTwoEvents({newEvent, newEvent2}) {

  return function(dispatch) {

    dispatch(requestCreateEvent())

    const bearerToken = ls.get('bearerToken') || null;

    console.log({newEvent, newEvent2});

    let data = prepareNewEventPayload(newEvent);

    return fetch(`${BROADCASTER_ROOT_URL}events/`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      },
      body: JSON.stringify(data)
    })
    .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
      return res.json();
    })
    .then(json => {
      if (json["errors"] && json["errors"].length){
        let msg = json["errors"][0]["detail"];
        toast.error(msg);
        dispatch(createEventFailure(msg))
      } else {
        toast.success("Event has been created !");
        dispatch(createEventSuccess(json.data))

        data = prepareNewEventPayload(newEvent2);

        return fetch(`${BROADCASTER_ROOT_URL}events/`, {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${bearerToken}`
          },
          body: JSON.stringify(data)
        })
        .then(res => {
            if (res.status === 401) {
              dispatch(push("/"));
            }
          return res.json();
        })
        .then(json => {
          if (json["errors"] && json["errors"].length){
            let msg = json["errors"][0]["detail"];
            toast.error(msg);
            dispatch(createEventFailure(msg))
          } else {
            toast.success("Event has been created !");
            dispatch(createEventSuccess(json.data))
          }
        })
        .catch(err => {
          console.error(err);
        });
      }
    })
    .catch(err => {
      console.error(err);
    });
  }
}

export function updateEvent({activeEvent, clockwheelId, playlistId, stationId}) {

  return function(dispatch) {

    dispatch(requestUpdateEvent())

    const bearerToken = ls.get('bearerToken') || null;

    console.log('>>>> UPDATE: >>>',activeEvent);
    let startTime = activeEvent.attributes.start_time || activeEvent.attributes.start || new Date();
    startTime = typeof(startTime) !== "string" ? startTime.toISOString().slice(0,19)+"+0000" : startTime.slice(0,19)+"+0000";
    let seriesEnd = activeEvent.attributes.series_end || new Date();
    seriesEnd = typeof(seriesEnd) !== "string" ? seriesEnd.toISOString().slice(0,19)+"+0000" : seriesEnd.slice(0,19)+"+0000";

    let relationships = Object.assign({},activeEvent.relationships);
    delete relationships["station"];
    Object.keys(relationships).forEach((key) => (relationships[key]["data"] === null) && delete relationships[key]);

    // When Andrei wrote this, only God and he understood what he was doing. Now, God only knows.
    // 1. is_instance === true && is_recurring === false (Single Event)
    // 2. is_instance === false && is_recurring === true (Recurring Event - Parent)
    // 3. is_instance === true && is_recurring === true (Recurring Event - Instance)
    let isInstance = activeEvent.attributes.hasOwnProperty("is_instance") ? activeEvent.attributes.is_instance : !activeEvent.attributes.is_recurring;

    let data = {
      "data": {
        "id": activeEvent.id,
        "type": "events",
        "attributes": {
          "start_time": startTime,
          "series_end": seriesEnd,
          "crossfade": activeEvent.attributes.crossfade,
          "duration": activeEvent.attributes.duration,
          "title": activeEvent.attributes.title,
          "is_instance": isInstance,
          "is_recurring": activeEvent.attributes.is_recurring,
          "strict_start": activeEvent.attributes.strict_start
        },
        "relationships": relationships
      }
    };

    if (activeEvent.attributes.is_recurring) {
      data.data.attributes["recurring_days"] = activeEvent.attributes.recurring_days;
    }

    if (clockwheelId){
      data.data.relationships["clockwheel"] = {
        "data": {
          "type": "clockwheels",
          "id": clockwheelId
        }
      }
    }

    if (playlistId){
      data.data.relationships["playlist"] = {
        "data": {
          "type": "playlists",
          "id": playlistId
        }
      }
    }

    return fetch(`${BROADCASTER_ROOT_URL}events/${activeEvent.id}`, {
      method: "PATCH",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if (res.status === 401) {
        dispatch(push("/"));
      }
      return res.json();
    })
    .then(json => {
      if (json["errors"] && json["errors"].length){
        let msg = json["errors"][0]["detail"];
        toast.error(msg);
        dispatch(requestUpdateEventFailure(msg))
      } else {
        toast.success("Event has been saved !");
        dispatch(requestUpdateEventSuccess(json.data))
        dispatch(getActiveEventTracks(activeEvent));
      }
    })
    .catch(err => {
      console.error(err);
    });
  }
}

export function deleteEvent({activeEvent, secondAction}) {

  return function(dispatch) {

    dispatch(requestDeleteEvent())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "id": activeEvent.id,
        "type": "events",
        "attributes": {
          "is_canceled": true
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}events/${activeEvent.id}`, {
      method: "PATCH",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      },
      body: JSON.stringify(data)
    })
    .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
      if (res.status >= 400) {
        throw new Error('An error occurred: ' + res.statusText);
      }
      return res.json();
    })
    .then(json => {
      dispatch(eventDeleted(json.data));
      if (secondAction && secondAction.name === "createEvent"){
        dispatch(createEvent({newEvent: secondAction.payload}));
      } else {
        toast.success("Event deleted !");
      }
    })
    .catch(err => {
      console.error(err);
    });
  }
}


export function fetchEventsSuccess(events) {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: events
  };
}

export function fetchEventsFailure(error) {
  return {
    type: FETCH_EVENTS_FAILURE,
    payload: error
  };
}

export function createEventSuccess(newEvent) {
  return {
    type: CREATE_EVENT_SUCCESS,
    payload: newEvent
  };
}

export function createEventFailure(error) {
  return {
    type: CREATE_EVENT_FAILURE,
    payload: error
  };
}

export function resetNewEvent() {
  return {
    type: RESET_NEW_EVENT
  }
}
;

export function resetDeletedEvent() {
  return {
    type: RESET_DELETED_EVENT
  }
}

export function fetchEventSuccess(activeEvent) {
  return {
    type: FETCH_EVENT_SUCCESS,
    payload: activeEvent
  };
}

export function fetchEventFailure(error) {
  return {
    type: FETCH_EVENT_FAILURE,
    payload: error
  };
}

export function resetActiveEvent() {
  return {
    type: RESET_ACTIVE_EVENT
  }
}





// export function deleteEventSuccess(deletedEvent) {
//   return {
//     type: DELETE_EVENT_SUCCESS,
//     payload: deletedEvent
//   };
// }

export function deleteEventFailure(response) {
  return {
    type: DELETE_EVENT_FAILURE,
    payload: response
  };
}

export function fetchActiveEventTracks() {
  return {
    type: FETCH_ACTIVE_EVENT_TRACKS
  }
}

export function fetchActiveEventTracksSuccess(json) {
  return {
    type: FETCH_ACTIVE_EVENT_TRACKS_SUCCESS,
    payload: json
  }
}

export function fetchActiveEventTracksFailure(response) {
  return {
    type: FETCH_ACTIVE_EVENT_TRACKS_FAILURE,
    payload: response
  }
}

export function resetActiveEventTracks() {
  return {
    type: RESET_ACTIVE_EVENT_TRACKS
  }
}

function retrieveEventTracks(dispatch, stationId, eventId, page) {
  let url = `${BROADCASTER_ROOT_URL}tracks/?filter[station]=${stationId}&filter[event]=${eventId}&page[number]=${page}`;
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    }
  }).then(response => {
    if (response.status === 401) {
      dispatch(push('/'));
      return Promise.resolve();
    }

    return response.json();
  }).then(response => {
    dispatch(fetchActiveEventTracksSuccess(response.data));

    console.log(response)

    if (response['links'] && response['links']['next']) {
      retrieveEventTracks(dispatch, stationId, eventId, page + 1).then(() => Promise.resolve());
    }

    return true;
  })
}

export function getActiveEventTracks(event) {
  return function(dispatch) {
    dispatch(fetchActiveEventTracks());

    const stationId = event.relationships.station.data.id;
    retrieveEventTracks(dispatch, stationId, event.id, 1).then(() => Promise.resolve());
  }
}

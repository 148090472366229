import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Track list
export const FETCH_TRACKS = 'FETCH_TRACKS';
export const FETCH_FILTER_TRACKS = 'FETCH_FILTER_TRACKS';
export const FETCH_TRACKS_SUCCESS = 'FETCH_TRACKS_SUCCESS';
export const FETCH_PLAYLIST_TRACKS_SUCCESS = 'FETCH_PLAYLIST_TRACKS_SUCCESS';
export const FETCH_TRACKS_FAILURE = 'FETCH_TRACKS_FAILURE';
export const RESET_TRACKS = 'RESET_TRACKS';
export const RESET_PLAYLIST_TRACKS = 'RESET_PLAYLIST_TRACKS';

//Create new TRACK
export const CREATE_TRACK = 'CREATE_TRACK';
export const CREATE_TRACK_SUCCESS = 'CREATE_TRACK_SUCCESS';
export const CREATE_TRACK_FAILURE = 'CREATE_TRACK_FAILURE';
export const RESET_NEW_TRACK = 'RESET_NEW_TRACK';

//Update TRACK
export const UPDATE_TRACK = 'UPDATE_TRACK';
export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS';
export const UPDATE_TRACK_FAILURE = 'UPDATE_TRACK_FAILURE';

//Bulk Update TRACK
export const BULK_UPDATE_TRACKS = 'BULK_UPDATE_TRACKS';
export const BULK_UPDATE_TRACKS_SUCCESS = 'BULK_UPDATE_TRACKS_SUCCESS';
export const BULK_UPDATE_TRACKS_FAILURE = 'BULK_UPDATE_TRACKS_FAILURE';

//Fetch TRACK
export const FETCH_TRACK = 'FETCH_TRACK';
export const FETCH_TRACK_SUCCESS = 'FETCH_TRACK_SUCCESS';
export const FETCH_TRACK_FAILURE = 'FETCH_TRACK_FAILURE';
export const RESET_ACTIVE_TRACK = 'RESET_ACTIVE_TRACK';

//Delete TRACK
export const DELETE_TRACK = 'DELETE_TRACK';
export const DELETE_TRACK_SUCCESS = 'DELETE_TRACK_SUCCESS';
export const DELETE_TRACK_FAILURE = 'DELETE_TRACK_FAILURE';
export const RESET_DELETED_TRACK = 'RESET_DELETED_TRACK';

export const FETCH_PLAYED_QUEUE = 'FETCH_PLAYED_QUEUE';
export const FETCH_PLAYING_QUEUE = 'FETCH_PLAYING_QUEUE';
export const FETCH_UPCOMING_QUEUE = 'FETCH_UPCOMING_QUEUE';
export const FETCH_PLAYED_QUEUE_SUCCESS = 'FETCH_PLAYED_QUEUE_SUCCESS';
export const FETCH_PLAYING_QUEUE_SUCCESS = 'FETCH_PLAYING_QUEUE_SUCCESS';
export const FETCH_UPCOMING_QUEUE_SUCCESS = 'FETCH_UPCOMING_QUEUE_SUCCESS';

export const REQUEST_UPLOAD_IMAGE = 'REQUEST_UPLOAD_IMAGE';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;
const MEDIA_ROOT_URL = `${configApp.MEDIAAPI_SERVER}/`;

export function fetchTracksSuccess(json) {
  return {
    type: FETCH_TRACKS_SUCCESS,
    payload: json.data,
    total: json.meta && json.meta.count
  };
}

export function fetchTracksFailure(error) {
  return {
    type: FETCH_TRACKS_FAILURE,
    payload: error
  };
}

export function createTrackSuccess(newTrack) {
  return {
    type: CREATE_TRACK_SUCCESS,
    payload: newTrack
  };
}

export function createTrackFailure(error) {
  return {
    type: CREATE_TRACK_FAILURE,
    payload: error
  };
}

export function fetchTrackSuccess(activeTrack) {
  return {
    type: FETCH_TRACK_SUCCESS,
    payload: activeTrack
  };
}

export function fetchTrackFailure(error) {
  return {
    type: FETCH_TRACK_FAILURE,
    payload: error
  };
}

export function deleteTrackFailure(response) {
  return {
    type: DELETE_TRACK_FAILURE,
    payload: response
  };
}

export const requestTracks = () => ({
  type: FETCH_TRACKS,
})

export const resetPlaylistTracks = () => ({
  type: RESET_PLAYLIST_TRACKS
})

export const receivePlaylistTracks = (json) => ({
  type: FETCH_PLAYLIST_TRACKS_SUCCESS,
  payload: json.data,
  total: json.meta && json.meta.count
})

export const requestPlayedQueue = () => ({
  type: FETCH_PLAYED_QUEUE,
})

export const requestPlayingQueue = () => ({
  type: FETCH_PLAYING_QUEUE,
})

export const requestUpcomingQueue = () => ({
  type: FETCH_UPCOMING_QUEUE,
})

export const receivedPlayedQueue = (json) => ({
  type: FETCH_PLAYED_QUEUE_SUCCESS,
  payload: json,
})

export const receivedPlayingQueue = (json) => ({
  type: FETCH_PLAYING_QUEUE_SUCCESS,
  payload: json,
})

export const receivedUpcomingQueue = (json) => ({
  type: FETCH_UPCOMING_QUEUE_SUCCESS,
  payload: json,
})

export const requestDeleteTrack = () => ({
  type: DELETE_TRACK,
})

export const trackDeleted = (uuid) => ({
  type: DELETE_TRACK_SUCCESS,
  uuid: uuid
})

export const requestCreateTrack = () => ({
  type: CREATE_TRACK,
})

export const requestUpdateTrack = () => ({
  type: UPDATE_TRACK,
})

export const trackUpdated = (json) => ({
  type: UPDATE_TRACK_SUCCESS,
  payload: json
})

export const requestBulkUpdateTracks = () => ({
  type: BULK_UPDATE_TRACKS,
})

export const tracksBulkUpdated = (json) => ({
  type: BULK_UPDATE_TRACKS_SUCCESS,
  payload: json
})

export const requestUploadImage = () => ({
  type: REQUEST_UPLOAD_IMAGE,
})

export const imageUploaded = (trackUUID, json) => ({
  type: IMAGE_UPLOADED,
  trackUUID: trackUUID,
  payload: json
})

export function uploadImage({stationId, trackUUID, mediaServiceUUID, imageType, file}) {

  return function(dispatch) {

    const bearerToken = ls.get('bearerToken') || null;

    dispatch(requestUploadImage());

    const formData = new FormData();
    formData.append('file', file);

    return fetch(`${MEDIA_ROOT_URL}upload/${mediaServiceUUID}/${imageType}/${trackUUID}`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${bearerToken}`
      },
      body: formData,
    })
    .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
      return res.json();
    })
    .then(json =>{
      if (json["errors"] && json["errors"].length){
        let msg = json["errors"][0]["detail"];
        toast.error(msg);
      } else {
        toast.success("Image Uploaded");
        dispatch(imageUploaded(trackUUID, json.data))
      }
    })
    .catch(err => {
      console.error(err);
    });
  }
}

export function fetchTracks({stationId, perPage, page, sortBy, filterBy, query}) {

  return function(dispatch) {

    dispatch(requestTracks())

    const bearerToken = ls.get('bearerToken') || null;

    let filters = "";
    if(filterBy && Object.keys(filterBy).length > 0){
      Object.keys(filterBy).forEach(filter => {
        filters += filterBy[filter].length > 0 ? `&filter[${filter}]=${filterBy[filter]}` : '';
      })
    }

    perPage = perPage || 50;
    page = page || 1;
    sortBy = sortBy || 'title';

    let url = `${BROADCASTER_ROOT_URL}tracks/?filter[station]=${stationId}&page[size]=${perPage}&page[number]=${page}&sort=${sortBy}${filters}`;

    if (query && query.length){
      url += `&filter[search]=${query}`;
    }

    return fetch(url, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        dispatch(fetchTracksSuccess(json))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

function getTracks(dispatch, stationId, playlistId, page) {
  let url = `${BROADCASTER_ROOT_URL}tracks/?filter[station]=${stationId}&filter[playlist]=${playlistId}&page[number]=${page}`;
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      }
    }).then(response => {
      if (response.status === 401) {
        dispatch(push('/'));
        return Promise.resolve();
      }

      return response.json();
    }).then(response => {
      dispatch(receivePlaylistTracks(response));

      console.log('Logging response', response)

      if (response['links'] && response['links']['next']) {
        getTracks(dispatch, stationId, playlistId, page + 1).then(() => Promise.resolve());
      }

      return true;
    })
}

export function fetchPlaylistTracks({stationId, playlistId}) {

  return function(dispatch) {
    dispatch(resetPlaylistTracks())
    getTracks(dispatch, stationId, playlistId, 1).then(() => Promise.resolve());
  }
}

export function createTrack(cw, stationId) {

  return function(dispatch) {

    dispatch(requestCreateTrack())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "tracks",
        "id": stationId + "",
        "attributes": cw.attributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}tracks/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        toast.success("Track has been created !");
        dispatch(createTrackSuccess(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function updateTrack(track) {

  return function(dispatch) {

    dispatch(requestUpdateTrack())

    const bearerToken = ls.get('bearerToken') || null;

    // get audio ID
    return fetch(`${MEDIA_ROOT_URL}api/v1/audio/?filter[file_uuid]=${track["id"]}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {

          const audioId = json.data[0]["id"];

          let data = {
            "data": {
              "type": "audio",
              "id": audioId,
              "attributes": {
                "year": track.attributes.year && parseInt(track.attributes.year, 10),
                "media_type": track.attributes.media_type,
                "album": track.attributes.album,
                "artist": track.attributes.artist,
                "title": track.attributes.title
              }
            }
          };

          Object.keys(data["data"]["attributes"]).forEach((key) => (data["data"]["attributes"][key] === null) && delete data["data"]["attributes"][key]);

          return fetch(`${MEDIA_ROOT_URL}api/v1/audio/${audioId}`, {
              method: "PATCH",
              credentials: 'include',
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${bearerToken}`
              },
              body: JSON.stringify(data)
            })
            .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
              return res.json();
            })
            .then(json => {
              if (json["errors"] && json["errors"].length){
                let msg = json["errors"][0]["detail"];
                toast.error(msg);
              } else {
                toast.success("Track has been saved !");
                dispatch(trackUpdated(track))
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      })
  }
}

export function bulkUpdateTracks({stationId, newAttributes, page, perPage, sortBy, filterBy, query}) {

  return function(dispatch) {

    dispatch(requestBulkUpdateTracks())

    const bearerToken = ls.get('bearerToken') || null;

    console.log('newAttributes',newAttributes);

    let data = {
      "data": {
        "type": "edit_tracks",
        "attributes": newAttributes,
        "relationships": {
            "station": {
                "data": {
                    "type": "stations",
                    "id": stationId + "",
                }
            }
        }
      }
    };

    return fetch(`${BROADCASTER_ROOT_URL}bulk/tracks/`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Tracks have been saved !");
          dispatch(fetchTracks({stationId, perPage, page, sortBy, filterBy, query}))
          dispatch(tracksBulkUpdated(json))
        }
      })
      .catch(err => {
        console.error(err);
      });
    }
}

export function deleteTrack(fileUUID) {

  return function(dispatch) {

    dispatch(requestDeleteTrack())

    const bearerToken = ls.get('bearerToken') || null;

    // get audio ID
    return fetch(`${MEDIA_ROOT_URL}api/v1/files/?filter[uuid]=${fileUUID}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {

          const fileId = json.data[0]["id"];

          return fetch(`${MEDIA_ROOT_URL}api/v1/files/${fileId}`, {
              method: "DELETE",
              credentials: 'include',
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${bearerToken}`
              }
            })
            .then(res => {
              if (res.status === 401) {
                dispatch(push("/"));
              }
              return res.json();
            })
            .then(json => {
              if (json["errors"] && json["errors"].length){
                let msg = json["errors"][0]["detail"];
                toast.error(msg);
              } else {
                toast.success("Track has been deleted !");
                dispatch(trackDeleted(fileUUID))
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      })
  }
}


export function fetchPlayedQueue({mountId}) {

  return function(dispatch) {

    dispatch(requestPlayedQueue())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}${mountId}/played`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receivedPlayedQueue(json))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchPlayingQueue({mountId}) {

  return function(dispatch) {

    dispatch(requestPlayingQueue())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}${mountId}/playing`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receivedPlayingQueue(json))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchUpcomingQueue({mountId}) {

  return function(dispatch) {

    dispatch(requestUpcomingQueue())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}${mountId}/upcoming`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          dispatch(receivedUpcomingQueue(json))
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export const REQUEST_ALL_CATEGORIES = "REQUEST_ALL_CATEGORIES";

export const requestAllCategories = () => ({
  type: REQUEST_ALL_CATEGORIES
});

export const RECEIVE_CATEGORIES_PAGE = "RECEIVE_CATEGORIES_PAGE";

export const receiveCategoriesPage = (json) => ({
  type: RECEIVE_CATEGORIES_PAGE,
  payload: json
});

export const REQUEST_ALL_CATEGORIES_SUCCESS = "REQUEST_ALL_CATEGORIES_SUCCESS";

export const requestAllCategoriesSuccess = () => ({
  type: REQUEST_ALL_CATEGORIES_SUCCESS
});


export const REQUEST_ALL_CATEGORIES_ERROR = "REQUEST_ALL_CATEGORIES_ERROR";

export const requestAllCategoriesError = (err) => ({
  type: REQUEST_ALL_CATEGORIES_ERROR,
  payload: err
})

const getAllCategories = (dispatch, stationId, page) => {
  let url = `${BROADCASTER_ROOT_URL}categories/?filter[station]=${stationId}&page[number]=${page}`;
  const bearerToken = ls.get('bearerToken') || null;

  return fetch(url, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    }
  }).then(response => {
    if (response.status === 401) {
      dispatch(push('/'));
      return Promise.resolve();
    }

    return response.json();
  }).then(response => {
    dispatch(receiveCategoriesPage(response.data));

    if (response['links'] && response['links']['next']) {
      getAllCategories(dispatch, stationId, page + 1).then(() => Promise.resolve());
    } else {
      dispatch(requestAllCategoriesSuccess())
    }

    return true;
  })
}

export function fetchAllCategories(stationId) {
  return function(dispatch) {
    dispatch(requestAllCategories());
    getAllCategories(dispatch, stationId, 1).then(() => Promise.resolve());
  }
}

import * as React from 'react'
import classnames from 'classnames'
// @flow

import cls from './tag.scss'

type UikTagProps = {
  className?: String,
  Component?: React.ElementType,
  children?: React.Node,
  color?: 'white' | 'red' | 'orange' | 'blue' | 'yellow' | 'green' | 'violet',
  fill?: Boolean,
}

const UikTag = ({
  children,
  className,
  color,
  Component,
  fill,
  ...rest
}: UikTagProps) => (
  <Component
    className={ classnames(cls.wrapper, className, cls.fill)}
    style={{"backgroundColor":color||'', "color": getContrastYIQ(color)}}
    { ...rest }
  >
    {children}
  </Component>
)

UikTag.defaultProps = {
  className: null,
  Component: 'span',
  children: null,
  color: null,
  fill: false,
}

function getContrastYIQ(hexcolor){
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

export default UikTag


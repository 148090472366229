import initialState from './initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...state,
        isFetching: true,
      }
    case 'RECEIVE_LOGIN':
      return {
        ...state,
        isFetching: false,
        bearerToken: action.bearerToken,
        userId: action.userId
      }
    default:
      return state
  }
}

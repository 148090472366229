export default {
  stationsList: {stations:[], loading: false},
  genres: [],
  totalStations: 0,
  isFetching: false,
  activeStation: {},
  stationService: {},
  stationPackage: {},
  stationPolling: {
    stationId: null,
    intervalId: null
  }
}

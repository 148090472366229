import fetch from 'cross-fetch'
import ls from 'local-storage'
import { toast } from "react-toastify";
import { push } from 'react-router-redux'
import * as configApp from '@redux/constants'

//Restriction list
export const FETCH_RESTRICTIONS = 'FETCH_RESTRICTIONS';
export const FETCH_RESTRICTIONS_SUCCESS = 'FETCH_RESTRICTIONS_SUCCESS';
export const FETCH_RESTRICTIONS_FAILURE = 'FETCH_RESTRICTIONS_FAILURE';
export const RESET_RESTRICTIONS = 'RESET_RESTRICTIONS';

//Update RESTRICTION
export const UPDATE_RESTRICTION = 'UPDATE_RESTRICTION';
export const UPDATE_RESTRICTION_SUCCESS = 'UPDATE_RESTRICTION_SUCCESS';
export const UPDATE_RESTRICTION_FAILURE = 'UPDATE_RESTRICTION_FAILURE';

const BROADCASTER_ROOT_URL = `${configApp.BROADCASTERAPI_SERVER}/api/v1/`;

export const requestRestrictions = () => ({
  type: FETCH_RESTRICTIONS,
})

export const receiveRestrictions = (json) => ({
  type: FETCH_RESTRICTIONS_SUCCESS,
  payload: json
})

export const requestUpdateRestriction = () => ({
  type: UPDATE_RESTRICTION,
})

export const restrictionUpdated = (json) => ({
  type: UPDATE_RESTRICTION_SUCCESS,
  payload: json
})

export function fetchRestrictions(id) {

  return function(dispatch) {

    dispatch(requestRestrictions())

    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}restrictions/?filter[station]=${id}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json => {
        dispatch(receiveRestrictions(json.data))
      })
      .catch(err => {
        console.error(err);
      });
  }
}


export function updateRestriction(restriction) {

  return function(dispatch) {

    dispatch(requestUpdateRestriction())

    const bearerToken = ls.get('bearerToken') || null;

    let data = {
      "data": {
        "type": "restrictions",
        "id": restriction.id + "",
        "attributes": restriction.attributes
      }
    }

    return fetch(`${BROADCASTER_ROOT_URL}restrictions/${restriction.id}`, {
        method: "PATCH",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("Restriction has been saved !");
          dispatch(restrictionUpdated(json.data));
        };
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export function fetchRestrictionsSuccess(restrictions) {
  return {
    type: FETCH_RESTRICTIONS_SUCCESS,
    payload: restrictions
  };
}

export function fetchRestrictionsFailure(error) {
  return {
    type: FETCH_RESTRICTIONS_FAILURE,
    payload: error
  };
}

export function banIp(ip, stationId) {
  return function(dispatch) {
    const bearerToken = ls.get('bearerToken') || null;

    return fetch(`${BROADCASTER_ROOT_URL}restrictions/?filter[station]=${stationId}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`
        }
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        if (res.status >= 400) {
          throw new Error('An error occurred: ' + res.statusText);
        }
        return res.json();
      })
      .then(json => {
        const restrictionId = json["data"][0]["id"]
        const bannedIps = [ip, ...json["data"][0]["attributes"]["banned_ips"]]
        return fetch(`${BROADCASTER_ROOT_URL}restrictions/${restrictionId}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${bearerToken}`
          },
          body: JSON.stringify({
            data: {
              type: "restrictions",
              id: restrictionId,
              attributes: {
                banned_ips: bannedIps
              }
            }
          })
        })
      })
      .then(res => {
        if (res.status === 401) {
          dispatch(push("/"));
        }
        return res.json();
      })
      .then(json => {
        if (json["errors"] && json["errors"].length){
          let msg = json["errors"][0]["detail"];
          toast.error(msg);
        } else {
          toast.success("IP successfully banned.");
        };
      })
      .catch(err => {
        console.error(err);
      });
  }
}


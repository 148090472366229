import * as React from 'react'
import classnames from 'classnames'
import * as actions from '@redux/stations/actions'
import * as userActions from '@redux/users/actions'

import ls from 'local-storage'

import { connect } from 'react-redux'

/* eslint-disable max-len */

import ListingGridView from '../../components/ListingGridView'
import ListingListView from '../../components/ListingListView'

import OnboardingModals from '../../components/OnboardingModals'
import Pagination from '../../../../@components/Pagination'
import SearchInput from '../../../../@components/SearchInput'

import {
  Uikon,
  UikDivider,
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
} from '@components'

import TopBar from '../../../../@components/TopBar'

import cls from './stations-dashboard.scss'

// @flow
type StationsProps = {
  stations: Array,
  fetchStations: Function
}

class StationsPage extends React.Component<StationsProps> {
  constructor(props) {
    super(props);

    this.state = {
      view: 'list',
      addStationModalOpen: false,
      onboardingModalsOpen: true,
      perPage: 30,
      page: 1,
    }

    this.props.fetchStations({perPage: 30, page: 1});

    this.handleSearch = this.handleSearch.bind(this);
  }

  handleperPageChange(event) {
    this.setState({perPage: event.value, page: 1});
    this.props.fetchStations({
      page: 1,
      perPage: event.value,
      query: this.state.searchQuery
    });
  }

  handlePageChange(page) {
    this.setState({page});
    this.props.fetchStations({
      page: page,
      perPage: this.state.perPage,
      query: this.state.searchQuery
    });
  }

  handleAddUserModalOpen = () => {
    this.setState({ addStationModalOpen: true });
  };

  handleAddStationModalClose = () => {
    this.setState({ addStationModalOpen: false });
  };

  handleOnboardingModalsClose = () => {
    this.setState({ onboardingModalsOpen: false });
  };

  setView = (view) => {
    this.setState({
      view,
    })
  }

  toggleView = () => {
    const { view } = this.state
    this.setView(view === 'grid' ? 'list' : 'grid')
  }

  handleSearch({query}) {
    query = query.trim();
    var patt = /[a-zA-Z][\d]{5}/g;
    var payload = {
      page: 1,
      perPage: this.state.perPage
    };
    if(patt.test(query)){
      payload["mountId"] = query;
    } else {
      payload["query"] = query;
    }
    this.props.fetchStations(payload);
  }

  render() {
    // clean this logged in user stuff - just out of time right now...
    const { loggedInUser, totalStations, stationsLoading} = this.props;
    if (Object.entries(loggedInUser).length === 0){
      this.props.fetchUser(ls.get("u"))
    }
    const {
      view,
      onboardingModalsOpen,
      perPage,
      page,
    } = this.state
    const { stations } = this.props
    const isStaff = ["staff","admin"].indexOf(loggedInUser.type) > -1;

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title="Stations"/>
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            <h1 className={ cls.headline }>
              {stations.length} Stations
            </h1>
            <SearchInput
              onSearch={ this.handleSearch }
              collectionName="Stations"
            />
            <div className={ cls.viewSwitchWrapper }>
              <Uikon
                className={ classnames(cls.switchIcon, {
                  active: view === 'grid',
                }) }
                onClick={ this.toggleView }
              >
                view_grid
              </Uikon>
              <UikDivider direction="vertical" />
              <Uikon
                className={ classnames(cls.switchIcon, {
                  active: view === 'list',
                }) }
                onClick={ this.toggleView }
              >
                view_list
              </Uikon>
            </div>
          </UikContentItem>
          {
            view === 'grid' ? (
              <ListingGridView
                className={ cls.gridView }
                data={ stations }
              />
            ) : (
              <ListingListView
                data={ stations }
                loading={ stationsLoading }
                isStaff={ isStaff }
              />
            )
          }
        </UikLayoutMain>

        <Pagination
          showTotal={true}
          showingCount={stations.length}
          total={ totalStations }
          page={ page }
          perPage={ perPage }
          changePage={ this.handlePageChange.bind(this) }
          changePerPage={ this.handleperPageChange.bind(this) }
          aroundPage = { 3 }
          collectionType="stations"
        />

        <OnboardingModals
          modalsOpen={onboardingModalsOpen}
          onModalClose={this.handleOnboardingModalsClose.bind(this)}
        />
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    stations: store.stations.stationsList.stations,
    stationsLoading: store.stations.stationsList.loading,
    totalStations: store.stations.totalStations,
    loggedInUser: store.users.loggedInUser
  }),
  {
    fetchUser: userActions.fetchUser,
    fetchStations: actions.fetchStations,
  },
)(StationsPage)

import * as React from 'react'
import classnames from 'classnames'

import Modal from '@material-ui/core/Modal';
import * as actions from '@redux/clockwheelEntries/actions'
import * as clockwheelActions from '@redux/clockwheels/actions'
import * as categoriesActions from '@redux/categories/actions'

/* eslint-disable max-len */

import { connect } from 'react-redux'

import {
  UikContainerVertical,
  UikLayoutMain,
  UikContentItem,
  UikButton,
  UikSelect,
  UikFormInputGroup,
} from '@components'

import TopBar from '../../../../@components/TopBar'
import ClockwheelEntryList from './components/ClockwheelEntryList'

import cls from './clockwheels.scss'

// @flow
type ClockwheelEntriesProps = {
  clockwheelEntries: Array,
}

class ClockwheelEntriess extends React.Component<ClockwheelEntriesProps> {
  constructor(props) {
    super(props);

    this.state = {
      stationId: this.props.match.params.stationId,
      clockwheelId: this.props.match.params.clockwheelId,
      addMode: true,
      editClockwheelEntryModalOpen: false,
      deleteClockwheelEntryModalOpen: false,
      activeClockwheelEntry: {},
      validType: false,
      validAlgorithm: false
    };

    this.props.fetchClockwheel(this.state.clockwheelId);
    this.props.fetchClockwheelEntries(this.state.clockwheelId);
    this.props.fetchCategories(this.state.stationId);
  }

  handleAddClockwheelEntryModalOpen = () => {
    let position = this.props.clockwheelEntries ? this.props.clockwheelEntries.length + 1 : 1;
    this.setState({
      addMode: true,
      validType: false,
      validAlgorithm: false,
      editClockwheelEntryModalOpen: true,
      activeClockwheelEntry: {"attributes":{"position": position}}
    });
  };

  handleEditClockwheelEntryModalOpen = (cw) => {
    this.setState({ addMode: false, editClockwheelEntryModalOpen: true, activeClockwheelEntry: cw });
  };

  handleEditClockwheelEntryModalClose = () => {
    this.setState({ editClockwheelEntryModalOpen: false });
  };

  handleDeleteClockwheelEntryModalOpen = (cw) => {
    this.setState({ deleteClockwheelEntryModalOpen: true, activeClockwheelEntry: cw });
  };

  handleDeleteClockwheelEntryModalClose = () => {
    this.setState({ deleteClockwheelEntryModalOpen: false });
  };

  handleDeleteClockwheelEntryClick = () => {
    this.props.deleteClockwheelEntry(this.state.activeClockwheelEntry.id);
    this.setState({ deleteClockwheelEntryModalOpen: false });
  };

  handleEditClockwheelEntryClick = () => {
    if(this.state.addMode){
      this.props.createClockwheelEntry(this.state.activeClockwheelEntry, this.state.clockwheelId);
    } else {
      this.props.updateClockwheelEntry(this.state.activeClockwheelEntry);
    }
    this.setState({ editClockwheelEntryModalOpen: false });
  };

  handleDropdownChange(fieldName, event) {
    let acw = this.state.activeClockwheelEntry || {"attributes": {}};
    if (!acw.hasOwnProperty('attributes')) {
      acw["attributes"] = {};
    }
    acw["attributes"][fieldName] = event.value;
    this.setState({activeClockwheelEntry: acw, validAlgorithm: true});
  }

  handleTypeCategoryChange(event) {
    let acw = this.state.activeClockwheelEntry || {"attributes": {}};
    let vals = event.value.split("|");
    if(vals[0] === 'category'){
      if (acw.hasOwnProperty('relationships')) {
        acw["relationships"]["category"] = {
          "data": {
            "type": "categories",
            "id": vals[1]
          }
        }
      } else {
        acw["relationships"] = {
          "category": {
            "data": {
              "type": "categories",
              "id": vals[1]
            }
          }
        }
      }
      delete acw["attributes"]["media_type"];
    } else {
      acw["attributes"][vals[0]] = vals[1];
      delete (acw["relationships"]||{})["category"];
    }
    this.setState({activeClockwheelEntry: acw, validType: true});
  }

  render() {
    const { addMode, activeClockwheelEntry, validType, validAlgorithm } = this.state;
    const { clockwheel, clockwheelEntries, categories, clockwheelEntriesLoading } = this.props;
    let cwAttrs = (clockwheel||{}).attributes || {};
    let acwEntryAttrs = (activeClockwheelEntry||{}).attributes || {};
    const categoriesOptions = categories.map(cat => {return {"value": `category|${cat["id"]}`, "label": cat["attributes"]["name"]}});

    clockwheelEntries.forEach(cwe => {
      if(((cwe["relationships"]||{})['category']||{})['data'] !== null){
        cwe["category"] = categories.find(cat => cat.id === cwe["relationships"]["category"]["data"]["id"])
      }
    })

    console.log('clockwheelEntries',clockwheelEntries);

    return (
      <UikContainerVertical className={ cls.page }>
        <TopBar title={`Schedule > Clockwheels > ${cwAttrs.name}`} />
        <UikLayoutMain className={ cls.wrapper }>
          <UikContentItem className={ cls.header }>
            <h2>{cwAttrs.name} <small>({clockwheelEntries.length} entries)</small></h2>
            <UikButton primary onClick={this.handleAddClockwheelEntryModalOpen}>Add ClockwheelEntry</UikButton>
          </UikContentItem>

          <Modal
            open={this.state.editClockwheelEntryModalOpen}
            onClose={this.handleEditClockwheelEntryModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                { addMode ? 'Add' : 'Edit' } Clockwheel Entry
              </div>
              <div className={ cls.modalBody }>
                <UikFormInputGroup horizontal>
                  <UikSelect
                    defaultValue={ [acwEntryAttrs.media_type] }
                    label="Type or Category"
                    name="media_type"
                    options={mediaTypeOptions.concat(categoriesOptions)}
                    onChange={ this.handleTypeCategoryChange.bind(this) }
                  />
                </UikFormInputGroup>
                { acwEntryAttrs.media_type !== 'advert' &&
                  <UikFormInputGroup horizontal>
                    <UikSelect
                      defaultValue={ [acwEntryAttrs.select_type] }
                      label="Algorithm"
                      name="select_type"
                      options={[
                         {
                            value: 'random',
                            label: 'Random',
                          },
                          {
                            value: 'oldest_album',
                            label: 'Oldest Album',
                          },
                          {
                            value: 'oldest_artist',
                            label: 'Oldest Artist',
                          },
                          {
                            value: 'oldest_track',
                            label: 'Oldest Track',
                          },
                          {
                            value: 'recent_album',
                            label: 'Most Recent album',
                          },
                          {
                            value: 'recent_artist',
                            label: 'Most Recent Artist',
                          },
                          {
                            value: 'recent_track',
                            label: 'Most Recent Track',
                          }
                        ]}
                      onChange={ this.handleDropdownChange.bind(this, "select_type") }
                    />
                  </UikFormInputGroup>
                }
                { acwEntryAttrs.media_type === 'advert' &&
                  <UikFormInputGroup horizontal>
                    <UikSelect
                      defaultValue={ [acwEntryAttrs.select_type] }
                      label="Select Ad Length"
                      name="select_type"
                      options={[
                         {
                            value: "ad_30_sec",
                            label: "30 second ad",
                          },
                         {
                            value: "ad_60_sec",
                            label: "60 second ad",
                          },
                         {
                            value: "ad_120_sec",
                            label: "120 second ad",
                          },
                        ]}
                      onChange={ this.handleDropdownChange.bind(this, "select_type") }
                    />
                  </UikFormInputGroup>
                }
              </div>
              <div className={ cls.modalFooter }>
                <UikButton
                  isExpanded
                  success
                  onClick={this.handleEditClockwheelEntryClick}
                  disabled={ !validAlgorithm || !validType }
                >Save</UikButton>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deleteClockwheelEntryModalOpen}
            onClose={this.handleDeleteClockwheelEntryModalClose}
          >
            <div className={ cls.modal }>
              <div className={ cls.modalHeader }>
                Delete Clockwheel Entry
              </div>
              <div className={ cls.modalBody }>
                <p>Are you sure you wish to delete <b>{ acwEntryAttrs.media_type } - { acwEntryAttrs.select_type }</b> ?</p>
              </div>
              <div className={ classnames(cls.modalFooter, cls.modalFooterMultiButton)  }>
                <UikButton onClick={this.handleDeleteClockwheelEntryModalClose}>Cancel</UikButton>
                <UikButton error onClick={this.handleDeleteClockwheelEntryClick}>Delete</UikButton>
              </div>
            </div>
          </Modal>
          <ClockwheelEntryList
            editClockwheelEntry={this.handleEditClockwheelEntryModalOpen}
            deleteClockwheelEntry={this.handleDeleteClockwheelEntryModalOpen}
            clockwheelEntries={ clockwheelEntries || [] }
            updateClockwheelEntry={ this.props.updateClockwheelEntry }
            loading={ clockwheelEntriesLoading }
            categories={ this.props.categories || [] }
          />
        </UikLayoutMain>
      </UikContainerVertical>
    )
  }
}

export default connect(
  store => ({
    clockwheel: store.clockwheels.activeClockwheel.clockwheel,
    clockwheelEntries: store.clockwheelEntries.clockwheelEntryList.clockwheelEntries,
    clockwheelEntriesLoading: store.clockwheelEntries.clockwheelEntryList.loading,
    categories: store.categories.categoriesList.categories,
  }),
  {
    fetchClockwheelEntries: actions.fetchClockwheelEntries,
    fetchClockwheel: clockwheelActions.fetchClockwheel,
    createClockwheelEntry: actions.createClockwheelEntry,
    updateClockwheelEntry: actions.updateClockwheelEntry,
    deleteClockwheelEntry: actions.deleteClockwheelEntry,
    fetchCategories: categoriesActions.fetchCategories,
  },
)(ClockwheelEntriess)

const mediaTypeOptions = [{
    value: 'media_type|music',
    label: 'Music (music and copyright material)'
  },
  {
    value: 'media_type|talk',
    label: 'Talk (sermons, speeches, and live recordings)'
  },
  {
    value: 'media_type|id',
    label: 'ID (station identification such as sweepers and jingles)'
  },
  {
    value: 'media_type|promo',
    label: 'Promo (station promotion that is not considered an ID)'
  },
  {
    value: 'media_type|advert',
    label: 'Ad (advert replacement files)'
  }
];

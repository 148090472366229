import initialState from './initialState'

import {
  FETCH_AUTODJ_SETTINGS, FETCH_AUTODJ_SETTINGS_SUCCESS, FETCH_AUTODJ_SETTINGS_FAILURE, RESET_AUTODJ_SETTINGS,
  UPDATE_AUTODJ_SETTING, UPDATE_AUTODJ_SETTING_SUCCESS,  UPDATE_AUTODJ_SETTING_FAILURE,
} from './actions';

export default function(state = initialState, action) {
  let error;
  switch(action.type) {

  case FETCH_AUTODJ_SETTINGS:// start fetching autoDJSetting and set loading = true
    return { ...state, autoDJSettings: {autoDJSettings:[], error: null, loading: true} };
  case FETCH_AUTODJ_SETTINGS_SUCCESS:
    return { ...state, autoDJSettings: {autoDJSettings: action.payload, error:null, loading: false} };
  case FETCH_AUTODJ_SETTINGS_FAILURE:// return error and make loading = false
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, autoDJSettings: {autoDJSettings: [], error: error, loading: false} };
  case RESET_AUTODJ_SETTINGS:// reset autoDJSettingList to initial state
    return { ...state, autoDJSettings: {autoDJSettings: [], error:null, loading: false} };

  case UPDATE_AUTODJ_SETTING:
    return { ...state, activeAutoDJSetting:{...state.activeAutoDJSetting, loading: true}};
  case UPDATE_AUTODJ_SETTING_SUCCESS:
    return { ...state, activeAutoDJSetting: {autoDJSetting: action.payload, error:null, loading: false}};
  case UPDATE_AUTODJ_SETTING_FAILURE:
    error = action.payload || {message: action.payload.message};//2nd one is network or server down errors
    return { ...state, activeAutoDJSetting: {autoDJSetting: null, error:error, loading:false}};

  default:
    return state;
  }
}



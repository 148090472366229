import * as React from 'react'
import Modal from '@material-ui/core/Modal';
import { toast } from "react-toastify";
import * as broadcasterUserActions from '@redux/broadcasterUsers/actions'
import { connect } from 'react-redux'
import ls from 'local-storage'

/* eslint-disable max-len */

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';

import {
  UikButton,
  UikFormInputGroup,
} from '@components'

import cls from '../billing.scss'

// @flow
const stripeApiKey = "pk_live_dWSkP8t1pawtJg0N5mzWvk91";

class StripeCardModal extends React.Component<{
}> {

  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: this.props.loggedInUser,
      activeUser: {}
    }

    const userId = (this.state.loggedInUser||{}).hasOwnProperty('id') ? this.state.loggedInUser.id : ls.get('u');
    this.state.activeUser["id"] = userId;

    this.handleStripeCardResult = this.handleStripeCardResult.bind(this);
  }


  handleStripeCardResult = (event) => {
    console.log(event, this.props.broadcasterUser)
    if(event.hasOwnProperty("error") && event["error"].hasOwnProperty("message")){
      toast.error(event["error"]["message"]);
    } else {
      let user = this.state.activeUser;
      user["attributes"] = { "stripe_token": event.token.id };
      this.props.updateBroadcasterUser(user);
      this.props.onSuccess();
    }
  }

  render() {
    const { modalOpen, addMode} = this.props;

    return (
      <Modal
        open={modalOpen}
        onClose={this.props.handleEditPaymentModalClose}
      >
        <div className={ cls.modal }>
          <div className={ cls.modalHeader }>
            { addMode ? 'Add' : 'Edit' } Payment Card
          </div>
          <div className={ cls.modalBody }>
            <UikFormInputGroup className={ cls.stripeForm }>
              <StripeProvider apiKey={stripeApiKey}>
                <Elements>
                  <SplitFieldsForm handleResult={this.handleStripeCardResult} />
                </Elements>
              </StripeProvider>
            </UikFormInputGroup>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect(
  store => ({
    loggedInUser: store.users.loggedInUser,
    broadcasterUser: store.broadcasterUsers.activeBroadcasterUser.broadcasterUser,
  }),
  {
    fetchBroadcasterUser: broadcasterUserActions.fetchBroadcasterUser,
    updateBroadcasterUser: broadcasterUserActions.updateBroadcasterUser
  },
)(StripeCardModal)



const createOptions = () => {
  return {
    style: {
      base: {
        border: '1px solid #EAEDF3',
        borderRadius: '4px',
        width: '100%',
        background: 'white',
        padding: '10px 15px',
        fontSize: '16px',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
        marginBottom: '10px'
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class _SplitFieldsForm extends React.Component {
  state = {
    errorMessage: '',
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    } else {
      this.setState({errorMessage: null});
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createToken().then(this.props.handleResult);
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className="split-form">
          <label className="uik-content-title__wrapper">
            Card number
          </label>
          <CardNumberElement
            {...createOptions()}
            onChange={this.handleChange}
          />
          <label className="uik-content-title__wrapper">
            Expiration date
          </label>
          <CardExpiryElement
            {...createOptions()}
            onChange={this.handleChange}
          />
        </div>
        <div className="split-form">
          <label className="uik-content-title__wrapper">
            CVC
          </label>
          <CardCVCElement {...createOptions()} onChange={this.handleChange} />
          <label className="uik-content-title__wrapper">
            Zip code
          </label>
          <input
            name="name"
            type="text"
            placeholder=""
            className="StripeElement"
            required
          />
        </div>
        <div  className={ cls.stripeError } role="alert">
          {this.state.errorMessage}
        </div>
        <div className={ cls.modalFooter } style={{"paddingBottom": 0}}>
          <UikButton primary isExpanded type="submit">Save Card</UikButton>
        </div>
      </form>
    )
  }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);
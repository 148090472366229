import * as React from 'react'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetTable,
} from '@components'

import cls from '../billing.scss'

// @flow

class StationsDashboard extends React.Component<{
}> {
  render() {
    var editPayment = this.props.editPayment;
    let broadcasterUser = this.props.broadcasterUser || {};
    let attrs = broadcasterUser["attributes"] || {};
    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={ cls.table }>
          <thead>
            <tr>
              <th>
                Card Number
              </th>
              <th>
                Card Brand
              </th>
              <th>
                Expiration
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            { attrs["card_last4"] === null ?
              (<tr><td colSpan="10" className={ cls.noResults }>No payment card found</td></tr>)
              :
              (<tr key={ Math.random() }>
                <td>
                  **** **** **** {attrs.card_last4}
                </td>
                <td>
                  {attrs.card_brand}
                </td>
                <td>
                  {attrs.card_exp_year}
                </td>
                <td>
                  <a onClick={editPayment}>
                    <i className={`fas fa-pen`}></i>
                  </a>
                </td>
              </tr>)
            }
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default StationsDashboard

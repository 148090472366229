// Libraries
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// Containers

import ReactDocs from './ReactDocs'

import {
  UikContainerVertical,
} from '@components'

import Header from './components/Header'


const Docs = () => (
  <UikContainerVertical>
    <Header />
    <Switch>
      <Route
        component={ ReactDocs }
        path="/docs/react"
      />
      <Redirect to="/docs/react" />
    </Switch>
  </UikContainerVertical>
)

export default Docs

import * as React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import * as configApp from '@redux/constants'

import {
  Link
} from 'react-router-dom'

import StationsListItem from '../ListingListItem'
import cls from './stations-list-view.scss'
import classnames from 'classnames'
import PacmanLoader from '../../../../@components/PacmanLoader'

const mediaStageBaseUrl = configApp.MEDIAAPI_SERVER;

// @flow
type StationsListViewProps = {
  data: Array,
  loading: Boolean,
  isStaff: Boolean
}

const ListingListView = ({
  data,
  loading,
  isStaff,
}: StationsListViewProps) => (
  <div className={ cls.wrapper }>
    <table>
      <thead>
        <tr>
          <th>
            <span>Station</span>
            <Tooltip title="This is a list of your stations" placement="top">
              <i className={ classnames("fas fa-question-circle", cls.tooltip)}></i>
            </Tooltip>
          </th>
          {/*<th>
            <span>Listeners</span>
            <Tooltip title="This is your number of current listeners" placement="top">
              <i className={ classnames("fas fa-question-circle", cls.tooltip)}></i>
            </Tooltip>
          </th>
          <th>
            <span>TLH / TOTAL TLH</span>
            <Tooltip title="This is the amount of TLH you have accumulated against the Total TLH included in your package" placement="top">
              <i className={ classnames("fas fa-question-circle", cls.tooltip)}></i>
            </Tooltip>
          </th>*/}
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          data.map(item => (
            <StationsListItem
              key={ Math.random() }
              id={ item.id }
              imgUrl={ item.attributes.logo_uuid ? `${mediaStageBaseUrl}/download/${item.attributes.logo_uuid}.${item.attributes.logo_extension}` : '/img/icon-radio.jpg' }
              status={ item.attributes.status }
              listeners={ "???" }
              tlh={ "???" }
              mountId={ item.attributes.mount_id }
              isStaff={ isStaff }
              onAir={ item.attributes.on_air }
              text={ (
                <React.Fragment>
                  street
                  <br />
                  city
                  {', '}
                  state
                  {' '}
                  zip
                </React.Fragment>
              ) }
              title={ item.attributes.name }
            />
          ))
        }
        { loading && (
          <tr><td colSpan="10" className={ cls.noResults }>
            <PacmanLoader
              loading={true}
            />
          </td></tr>
        )}
        { data.length === 0 && !loading && (
          <tr><td colSpan="10" className={ cls.noResults }>
            No stations found.
            <br/>
            <br/>
            Create one in&nbsp;
            <Link to="/account/billing/services">
              services
            </Link>
          </td></tr>
        )}
      </tbody>
    </table>
  </div>
)

export default ListingListView

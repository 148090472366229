import Tutorials from './Tutorials'
import Knowledge from './Knowledge'
import Socialio from './Socialio'
import Documentation from './Documentation'
import Analytics from './Analytics'
import Dashboards from './Dashboards'

const AsyncRouteMap = {
  Tutorials,
  Knowledge,
  Socialio,
  Documentation,
  Analytics,
  Dashboards,
}

export default AsyncRouteMap

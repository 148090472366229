import * as images from '@eAssets'

export default [
  {
    imgUrl: [
      images.a01,
      images.a02,
    ],
    name: 'Z Girls',
    status: 'uik_online',
  },
  {
    imgUrl: [
      images.a11,
      images.a22,
      images.a02,
    ],
    name: 'Elementary School 16',
    status: '6m',
  },
  {
    imgUrl: [
      images.a17,
      images.a18,
      images.a19,
      images.a20,
    ],
    name: 'Drumm & Bass',
    status: 'uik_online',
  },
]

import * as React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import * as notificationActions from '@redux/notifications/actions'

import {
  UikDropdownItem,
  UikTopBar,
  UikTopBarSection,
  UikDivider,
  UikButton,
  UikTopBarTitle,
  UikDropdown,
} from '@components'

import cls from './top-bar.scss'

// @flow
type StationsTopBarProps = {
  children?: React.Node,
  toggleMenu: Function,
  className?: Boolean,
  rightEl?: React.node,
  title?: String
}

class StationsTopBar extends React.Component<StationsTopBarProps> {
  constructor(props) {
    super(props);

    this.state = {
    }
    this.props.fetchNotifications();
  }

  markAllAsRead(){
    let notifications = this.props.notifications || [];
    for (var i = 0; i < notifications.length; ++i) {
      if(!notifications[i]["attributes"]["seen"])
        this.props.markNotificationAsRead(notifications[i]["id"]);
    }
  }

  render() {
    let notifications = this.props.notifications || [];

    const {
      rightEl,
      className,
      title,
      children
    } = this.props;

    let unreadCount = notifications.length;
    for (var i = 0; i < notifications.length; ++i) {
      unreadCount = notifications[i]["attributes"]["seen"] ? unreadCount - 1 : unreadCount;
    }

    return (
      <UikTopBar
        className={ classnames(cls.wrapper, className) }
      >
        {title && <UikTopBarSection>
          <UikTopBarTitle>
            {title}
          </UikTopBarTitle>
        </UikTopBarSection> }
        {children}
        <UikTopBarSection className={ cls.actions }>
          {
            rightEl
          }
          <a
            className={ cls.getHelpLink}
            href="http://help.live365.com/support/home"
            target="_blank"
            rel="noreferrer noopener"
          >
            <i className="fas fa-question"></i> Get Help
          </a>
          <UikDivider
            className={ cls.actionMobileHidden }
            direction="vertical"
            margin
            style={{ display: 'none' }}
          />
        </UikTopBarSection>

        <UikTopBarSection>
          <UikDropdown
            className={ cls.notificationList }
            DisplayComponent={
                props => (
                  <UikButton
                    { ...props }
                    transparent
                    onMouseDown={this.markAllAsRead.bind(this)}
                  >
                    <i className="fas fa-bell"></i>
                    { unreadCount > 0 && <span className={cls.unreadCount}>{unreadCount}</span> }
                  </UikButton>
                )
              }
            position="bottomRight"
          >
            { notifications.slice(0).reverse().map((noti, index) => (
                <UikDropdownItem
                  key={`noti${index}`}
                  className={ cls.notificationItem }
                >
                  <div>{noti["attributes"].title}</div>
                  <p>
                  {noti["attributes"].text}
                  </p>
                </UikDropdownItem>
              )
             )}
          </UikDropdown>
        </UikTopBarSection>
      </UikTopBar>
    )
  }
}

StationsTopBar.defaultProps = {
  children: null,
  className: null,
  rightEl: null
}

export default connect(
  store => ({
    notifications: store.notifications.notificationsList.notifications,
  }),
  {
    fetchNotifications: notificationActions.fetchNotifications,
    markNotificationAsRead: notificationActions.markNotificationAsRead,
  },
)(StationsTopBar)

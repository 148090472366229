import * as React from 'react'

/* eslint-disable max-len */

import {
  UikWidget,
  UikWidgetTable,
  UikButton,
} from '@components'

import PacmanLoader from '../../../../../@components/PacmanLoader'

import cls from '../billing.scss'

// @flow

class StationsDashboard extends React.Component<{
}> {
  render() {
    const { payNow, invoices, loading } = this.props;

    return (
      <UikWidget
        margin
      >
        <UikWidgetTable className={ cls.table }>
          <thead>
            <tr>
              <th>
                Description
              </th>
              <th>
                Amount
              </th>
              <th>
                Due Date
              </th>
              <th>
                Paid Date
              </th>
              <th>
                Status
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {
              invoices.map(invoice => (
                <tr key={ Math.random() /* pls don't do this in prod */ }>
                  <td>
                    {invoice.attributes && invoice.attributes.description}
                  </td>
                  <td>
                    {invoice.attributes && 
                      new Intl.NumberFormat(navigator.language, {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(invoice.attributes.amount)}
                  </td>
                  <td>
                    {invoice.attributes && 
                      new Intl.DateTimeFormat(navigator.language, {
                        year: "numeric",
                        month: "short",
                        day: "2-digit"
                      }).format(new Date(invoice.attributes.due_date))}
                  </td>
                  <td>
                    {invoice.attributes && invoice.attributes.paid_date &&
                      new Intl.DateTimeFormat(navigator.language, {
                        year: "numeric",
                        month: "short",
                        day: "2-digit"
                      }).format(new Date(invoice.attributes.paid_date))}
                  </td>
                  <td>
                    {invoice.attributes && invoice.attributes.status}
                  </td>
                  <td>
                  {invoice.attributes && invoice.attributes.status !== 'Unpaid' ?
                    <UikButton xs style={{"marginRight":"0"}} disabled>Pay</UikButton> :
                    <UikButton success xs onClick={payNow.bind(this, invoice.id)} style={{"marginRight":"0"}}>Pay</UikButton> }
                    {/*<a href="#" onClick={downloadInvoice} className={ cls.btnDownload }>
                      <i className="fas fa-download"></i>
                    </a>*/}
                  </td>
                </tr>
              ))
            }
            { loading && (
              <tr><td colSpan="10" className={ cls.noResults }>
                <PacmanLoader
                  loading={true}
                />
              </td></tr>
            )}
            { invoices.length === 0 && !loading && (
              <tr><td colSpan="10" className={ cls.noResults }>No invoices found.</td></tr>
            )}
          </tbody>
        </UikWidgetTable>
      </UikWidget>
    )
  }
}

export default StationsDashboard
